import { Controller, useFormContext } from 'react-hook-form';
import type {
  CheckboxChangeEvent,
  CheckboxProps,
} from '@progress/kendo-react-inputs';
import { Checkbox } from '@progress/kendo-react-inputs';

type RHFCheckboxProps = {
  name: string;
} & CheckboxProps;

const RHFCheckbox = ({ name, ...other }: RHFCheckboxProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Checkbox
          value={value}
          onChange={(e: CheckboxChangeEvent) => onChange(e.value)}
          {...other}
        />
      )}
    />
  );
};

export default RHFCheckbox;
