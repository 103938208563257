import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../../shared/Icon/Icon';
import usePrevious from '../../../../shared/customHooks/usePrevious';
import type { RootState } from '../../../../../store/reducers/rootReducer';
import { useKeyboardShortcut } from '../../../../shared/customHooks/useKeyboardShortcut';
import { fetchGetJson as getSurveyThemes, fetchGetJson } from '../../../../../services/services';
import { SurveyContent } from './SurveyContents/SurveyContent';

interface Props {
  showDeleteElementModal: () => void
}

export const NewSurveyOptions = ({ showDeleteElementModal }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const wrapperRef = useRef(null)
  const [shouldFetchThemes, setShouldFetchThemes] = useState<boolean>(true)
  const { theData, selectedElement, themes, emailProviders } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  const prevSelectedElement: TODO = usePrevious(selectedElement)
  const prevSelectedItem: TODO = usePrevious(theData.selectedItem)

  useEffect(() => {
    if (themes === null && shouldFetchThemes) {
      setShouldFetchThemes(false)
      getSurveyThemes('themes', token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: 'SET_THEMES', payload: res })
          }
        })
    }
  }, [shouldFetchThemes, themes, dispatch, token])

  useEffect(() => {
    if (selectedElement && selectedElement?.id !== prevSelectedElement?.id) {
      dispatch({ type: 'SURVEY_SET_OPTIONS_TYPE', payload: { type: { type: selectedElement.type } } })
    }
  }, [selectedElement?.id, prevSelectedElement?.id])

  const getEmailProviders = useCallback(() => {
    if (emailProviders.data.length === 0) {
      dispatch({ type: 'SET_EMAIL_PROVIDERS', payload: { loading: true, data: [] } })
      fetchGetJson("emailproviders", token)
        .then(((res: TODO) => {
          if (res && !res.error && !res.message) {
            dispatch({ type: 'SET_EMAIL_PROVIDERS', payload: { loading: false, data: res.filter((el: { providerType: string }) => el.providerType === "sendgrid") } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          }
        }))
    }
  }, [dispatch, emailProviders.data.length, token])

  useEffect(() => {
    if (theData.selectedItem && theData.selectedItem?.id !== prevSelectedItem?.id) {
      dispatch({ type: 'SURVEY_SET_OPTIONS_TYPE', payload: { type: { type: theData.selectedItem.type } } })
      if (theData.selectedItem?.type === 'info' && theData.selectedItem?.attributes?.mailprovider?.length > 0 && emailProviders.data.length === 0) {
        getEmailProviders()
      }
    }
  }, [theData.selectedItem?.id, prevSelectedItem?.id])

  const handleDeleteQuestionsShortcut = useCallback(() => {
    if (theData.selectedItem.published) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'A published question can only be disabled.' } })
    } else {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem })
      showDeleteElementModal();
    }
  }, [theData.selectedItem, dispatch, showDeleteElementModal]);

  useKeyboardShortcut(['delete'], handleDeleteQuestionsShortcut);

  let options: null | ReactNode[] = null
  if (selectedElement && Object.keys(selectedElement).length > 0 && selectedElement.type !== 'quota') {
    if (theData?.elementTypes.length > 0 && themes?.length >= 0) {
      options = [];
    }
  } else if (selectedElement && Object.keys(selectedElement).length > 0 && selectedElement.type === 'quota') {
    options = [];
  }

  return (
    <div
      ref={wrapperRef}
      className='col-lg-3 survey-content bg-white border-left d-flex flex-column overflow-hidden'>
      <SurveyContent />
      {
        !theData.isSurveyComments && selectedElement && selectedElement.type !== 'quota' && selectedElement.type !== "r" && selectedElement.type !== "other" && selectedElement.type !== "h" && selectedElement.type !== "rc" && selectedElement.published === false &&
        <div className={`p-2 border-top d-flex ${selectedElement.published === false ? 'justify-content-between' : 'justify-content-end'}`}>
          <button
            type='button'
            className='btn btn-icon btn-transparent danger text-danger pl-1 pr-2'
            disabled={selectedElement.type === 'subq' && theData.selectedItem?.elements?.filter((el: { type: string }) => el.type === 'subq')?.length === 1}
            onClick={() => showDeleteElementModal()}>
            <Icon className='fill-danger' type="delete-alt" />
            <span className='ml-1'>Delete {theData?.elementTypes.length > 0 ? theData.elementTypes.find((el: TODO) => el.id === selectedElement.type).name.toLowerCase() : ''}</span>
          </button>
        </div>
      }
    </div>
  )
}
