import { onDragEnd } from './onDragEnd';

// ------------------------------------------------------------

const updateBannerColumn = (theState, activeQuestion) => {
  const { state, source, destination } = theState;

  if (!source.droppableId || !destination.droppableId) {
    return { ...theState };
  }

  const isAddingFromItems = source.droppableId === 'ITEMS';
  const isRemovingFromFirstColumn = source.droppableId === 'firstColumn';
  const isRemovingFromSecondColumn =
    source.droppableId === 'scndColumn' && destination.droppableId !== 'scndColumn';

  let bannerColumnState = { ...state.bannerColumn };

  // Handle different drag and drop scenarios
  if (isAddingFromItems) {
    // Add item to banner column for first column
    // !activeQuestion means first column was empty
    if (destination.droppableId === 'firstColumn') {

      // find the newly added firstColumn item and add the new firstColumn item to the bannerColumn
      const newFirstColumnItem = state.firstColumn[destination.index];
      bannerColumnState[newFirstColumnItem.id] = [];

      if(!activeQuestion) {
        // add the orphan scndColumn items to the newly added item in firstColumn
        bannerColumnState[state.firstColumn[0].id] = [...state.scndColumn];
      }
    }


    // Add item to banner column for active question
    if (destination.droppableId === 'scndColumn' && activeQuestion) {
      const existingItems = bannerColumnState[activeQuestion.id] || [];
      const newItem = state.scndColumn[destination.index];

      bannerColumnState[activeQuestion.id] = [...existingItems, newItem];
    }
  } else if (isRemovingFromSecondColumn) {
    // Filter out the item being removed from second column
    const currentItems = bannerColumnState[activeQuestion.id] || [];
    const filteredItems = currentItems.filter((_, index) => index !== source.index);

    // Update banner column state with filtered items
    bannerColumnState[activeQuestion.id] = filteredItems;

    // Keep the active question active
    state.firstColumn.forEach(el => {
      el.active = el.id === activeQuestion.id;
    });
  } else if (isRemovingFromFirstColumn) {
    if (state.firstColumn.length > 0) {
      // remove key from bannerColumn
      delete bannerColumnState[activeQuestion.id];
    } else {
      // reset the bannerColumn state
      bannerColumnState = {}
      state.scndColumn = []
    }
  }

  return {
    ...theState,
    state: {
      ...state,
      bannerColumn: bannerColumnState,
      // reset the state of draggable item source, destination
      source: {},
      destination: {},
    },
  };
};

const onDragEndWithBannerUpdate = (
  result,
  theState,
  source,
  destination,
  removeItem,
  secondColumnIndex,
  columns,
  theData,
  rangeItems
) => {
  // Save state of active question before drag end
  const currentActiveQuestion = theState.state.firstColumn.find(
    el => el.active
  );

  const dragEndState = onDragEnd(
    result,
    theState,
    source,
    destination,
    removeItem,
    secondColumnIndex,
    columns,
    theData,
    rangeItems
  );

  const bannerColumnState = updateBannerColumn(
    dragEndState,
    currentActiveQuestion
  );

  return bannerColumnState;
};

export { updateBannerColumn, onDragEndWithBannerUpdate };
