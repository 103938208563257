import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
//
import { useAppSelector } from 'app/hooks';
import type { EditableQuestion } from '../types';
import type { Question, QuestionHierarchy } from '../../../types';
import { ColumnSource } from '../types';
import { useFlexibleBanners } from '../../../hooks/useFlexibleBanners';
import { ActionContext } from '../config/actions';

// -----------------------------------------------------------

// Types
export interface QuestionEditorOptions {
  enableCustomRows: boolean;
  enablePasteEdits: boolean;
  editHierarchy: boolean;
  // TODO: Add more options as needed
  // customActions?: ActionConfig[]
  [key: string]: boolean;
}

interface QuestionEditorState {
  questions: EditableQuestion[];
  selected: EditableQuestion | null;
}

interface QuestionEditorContextValue extends QuestionEditorState {
  // Selection actions
  setSelected: (question: EditableQuestion) => void;
  clearSelection: () => void;
  isSelected: (id: string) => boolean;

  // Question updates
  updateQuestion: (id: string, updates: Partial<EditableQuestion>) => void;
  updateSelected: (updates: Partial<EditableQuestion>) => void;

  // Bulk actions
  updateQuestions: (updates: EditableQuestion[]) => void;

  // State checks
  hasChanges: boolean;
  options: QuestionEditorOptions;
  currentContext: ActionContext;
  setContext: (context: ActionContext) => void;

  getDataChanges: () => Question[];
}

// Initial state
const initialState: QuestionEditorState = {
  questions: [],
  selected: null,
};

const QuestionEditorContext = createContext<QuestionEditorContextValue | null>(
  null,
);

const updateRowWithBanners = (
  row: EditableQuestion,
  bannerColumns: EditableQuestion[],
): EditableQuestion => ({
  ...row,
  bannerColumns,
});

const findAndUpdateRow = (
  rows: EditableQuestion[],
  rowId: string,
  bannerColumns: EditableQuestion[],
): EditableQuestion[] => {
  const rowIndex = rows.findIndex(row => row.id === rowId);
  if (rowIndex >= 0) {
    return rows.map((row, index) =>
      index === rowIndex ? updateRowWithBanners(row, bannerColumns) : row,
    );
  }
  return rows;
};

const updateBannerColumnsInQuestion = (
  question: EditableQuestion,
  targetId: string,
  updates: Partial<EditableQuestion>,
): EditableQuestion | null => {
  if (!question.bannerColumns) return null;

  const updatedBannerColumns = question.bannerColumns.map(banner =>
    banner.id === targetId ? { ...banner, ...updates } : banner,
  );

  if (
    JSON.stringify(question.bannerColumns) !==
    JSON.stringify(updatedBannerColumns)
  ) {
    return { ...question, bannerColumns: updatedBannerColumns };
  }

  return null;
};

const processFlexibleBannerUpdates = (
  updates: EditableQuestion[],
  prevQuestions: EditableQuestion[],
): EditableQuestion[] => {
  return updates.map(question => {
    if (question.source === ColumnSource.ROW && question.bannerColumns) {
      const originalQuestion = prevQuestions.find(q => q.id === question.id);
      if (originalQuestion) {
        return {
          ...question,
          bannerColumns:
            question.bannerColumns || originalQuestion.bannerColumns,
        };
      }
    }
    return question;
  });
};

const mapHierarchyQuestions = (
  questionsData: Question[],
  hierarchyItems: QuestionHierarchy[],
) => {
  if (hierarchyItems.length === 0) {
    // TODO: Check if this is the correct behavior when there are no hierarchy items
    return questionsData;
  }

  return hierarchyItems
    .map(hierarchyItem => {
      const question = questionsData.find(q => q.title === hierarchyItem.id);
      if (!question) return null;

      return {
        ...question,
        content: hierarchyItem.editedText,
        groups: hierarchyItem.rows ?? question.groups,
        subGroups: hierarchyItem.cols ?? question.subGroups,
      };
    })
    .filter(Boolean);
};

// -------------------------------------------------------------------------------------------------------------

export const QuestionEditorProvider = ({
  children,
  options,
  initialContext = ActionContext.ROWS,
}: {
  children: React.ReactNode;
  options?: QuestionEditorOptions;
  initialContext?: ActionContext;
}) => {
  // State
  const [state, setState] = useState<QuestionEditorState>(initialState);
  const [originalQuestions, setOriginalQuestions] = useState<
    EditableQuestion[]
  >([]);
  const [currentContext, setContext] = React.useState(initialContext);

  // Selectors
  const reducerData = useAppSelector(state => state.stateReducer.state);
  const { firstColumn, scndColumn, checkedNum } = reducerData;

  const allAnalysisQuestions = useAppSelector(
    state => state.setInitialDataReducer.theData,
  );

  const { flexibleBannersOption, bannerColumnMap, currentBannerColumn } =
    useFlexibleBanners();

  // Map questions helper
  const mapQuestions = (
    questions: Question[],
    source: ColumnSource,
  ): EditableQuestion[] => {
    return (
      questions
        ?.filter(question => question.selected)
        .map(question => ({
          ...question,
          source,
        })) || []
    );
  };

  const sourceOfLastSelected = useMemo(() => {
    let source = ColumnSource.ROW;
    if (checkedNum.length > 0) {
      source = checkedNum[checkedNum.length - 1].source;
    }
    return source;
  }, [checkedNum]);

  /**
   * Resolves and returns the hierarchy data for a question based on the source column
   * and flexible banner settings.
   *
   * @param source - The source column to resolve hierarchy from ('firstColumn' or 'scndColumn')
   * @returns The hierarchy data of the selected question in the specified column, or undefined if none found
   */
  const resolveHierarchyQuestion = useCallback(
    (source: ColumnSource) => {
      const getHierarchy = (data: Question[]): QuestionHierarchy[] => {
        const selectedQuestion = data.find(question => question.selected);
        return selectedQuestion?.hierarchy ?? [];
      };

      // For second column with flexible banners enabled, use current banner column
      if (
        source === ColumnSource.COLUMN &&
        flexibleBannersOption.isOptionEnabled
      ) {
        return getHierarchy(currentBannerColumn);
      }

      // Otherwise use the appropriate column based on source
      return getHierarchy(reducerData[source]);
    },
    [currentBannerColumn, flexibleBannersOption.isOptionEnabled, reducerData],
  );

  // Load questions
  useEffect(() => {
    const selectedRows = mapQuestions(firstColumn, ColumnSource.ROW);

    if (options!.editHierarchy) {
      const hierarchyData = resolveHierarchyQuestion(sourceOfLastSelected);
      const hierarchyQuestions = mapHierarchyQuestions(
        allAnalysisQuestions,
        hierarchyData,
      );

      const hierarchyQuestionWithSource = hierarchyQuestions.map(
        question =>
          ({
            ...question,
            source: sourceOfLastSelected as ColumnSource,
          }) as EditableQuestion,
      );

      setOriginalQuestions(hierarchyQuestionWithSource);
      setState(prev => ({
        ...prev,
        questions: hierarchyQuestionWithSource,
        selected: hierarchyQuestionWithSource[0],
      }));

      return;
    }

    // Handle flexible banners
    if (flexibleBannersOption.isOptionEnabled) {
      // Process banner columns and update rows
      const updatedRows = [...selectedRows];

      Object.entries(bannerColumnMap).forEach(([rowId, bannerQuestions]) => {
        const bannerColumns = mapQuestions(
          bannerQuestions,
          ColumnSource.COLUMN,
        );

        if (bannerColumns.length === 0) return;

        const updatedRowsWithBanners = findAndUpdateRow(
          updatedRows,
          rowId,
          bannerColumns,
        );

        if (updatedRowsWithBanners !== updatedRows) {
          updatedRows.length = 0;
          updatedRows.push(...updatedRowsWithBanners);
        }
      });

      setOriginalQuestions(updatedRows);
      setState(prev => ({
        ...prev,
        questions: updatedRows,
        selected: updatedRows[0],
      }));
    } else {
      // Handle regular columns
      const selectedColumns = mapQuestions(scndColumn, ColumnSource.COLUMN);
      const allQuestions = [...selectedRows, ...selectedColumns];

      setOriginalQuestions(allQuestions);
      setState(prev => ({
        ...prev,
        questions: allQuestions,
        selected: allQuestions[0],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Sets the currently selected question in the state.
   *
   * @param question - The `EditableQuestion` object to set as the selected question.
   *
   * This function updates the `selected` property in the state with the provided question.
   * It uses the `useCallback` hook to memoize the function, ensuring that it does not get
   * recreated on every render unless the dependencies change.
   */
  const setSelected = useCallback((question: EditableQuestion) => {
    setState(prev => ({ ...prev, selected: question }));
  }, []);

  /**
   * Clears the current selection in the question editor context.
   *
   * This function updates the state by setting the `selected` property to `null`.
   * It uses the `useCallback` hook to memoize the function, ensuring that it
   * does not get recreated on every render unless the dependencies change.
   *
   * @remarks
   * - This function is useful for resetting the selection when needed.
   * - The `setState` function is used to update the context state.
   */
  const clearSelection = useCallback(() => {
    setState(prev => ({ ...prev, selected: null }));
  }, []);

  /**
   * Checks if a question with the given `id` is currently selected.
   *
   * @param id - The unique identifier of the question to check.
   * @returns `true` if the question with the given `id` is selected, otherwise `false`.
   *
   * The function compares the `id` of the currently selected question in the state
   * with the provided `id` to determine if they match.
   */
  const isSelected = useCallback(
    (id: string) => state.selected?.id === id,
    [state.selected],
  );

  /**
   * Updates a specific question in the state by merging the provided updates
   * into the question with the matching `id`.
   *
   * @param id - The unique identifier of the question to update.
   * @param updates - A partial object containing the properties to update in the question.
   *
   * The function iterates through the list of questions in the state and updates
   * the question with the matching `id`. If no question matches the `id`, the state remains unchanged.
   */
  const updateQuestion = useCallback(
    (id: string, updates: Partial<EditableQuestion>) => {
      setState(prev => {
        const updatedQuestions = prev.questions.map(question => {
          if (question.id === id) {
            return { ...question, ...updates };
          }

          // Handle banner column updates
          if (flexibleBannersOption.isOptionEnabled) {
            const updatedQuestion = updateBannerColumnsInQuestion(
              question,
              id,
              updates,
            );
            if (updatedQuestion) return updatedQuestion;
          }

          return question;
        });

        // Also update selected question if it's the one being modified
        const updatedSelected =
          prev.selected?.id === id
            ? { ...prev.selected, ...updates }
            : prev.selected;

        return {
          ...prev,
          questions: updatedQuestions,
          selected: updatedSelected,
        };
      });
    },
    [flexibleBannersOption.isOptionEnabled],
  );

  /**
   * Updates the currently selected question with the provided partial updates.
   * If no question is selected, the state remains unchanged.
   *
   * @param updates - A partial object containing the properties to update in the selected question.
   *
   * The function merges the updates into the currently selected question and updates the list of questions
   * to reflect the changes. If the `id` of the selected question matches a question in the list, that question
   * is replaced with the updated version.
   */
  const updateSelected = useCallback(
    (updates: Partial<EditableQuestion>) => {
      setState(prev => {
        if (!prev.selected) return prev;

        const updatedQuestion = { ...prev.selected, ...updates };

        // Update questions array
        const updatedQuestions = prev.questions.map(question => {
          if (question.id === updatedQuestion.id) {
            return updatedQuestion;
          }

          // Handle banner column updates
          if (flexibleBannersOption.isOptionEnabled) {
            const updatedQuestionWithBanners = updateBannerColumnsInQuestion(
              question,
              updatedQuestion.id,
              updatedQuestion,
            );
            if (updatedQuestionWithBanners) return updatedQuestionWithBanners;
          }

          return question;
        });

        return {
          ...prev,
          selected: updatedQuestion,
          questions: updatedQuestions,
        };
      });
    },
    [flexibleBannersOption.isOptionEnabled],
  );

  /**
   * Updates the list of editable questions in the state.
   *
   * @param updates - An array of `EditableQuestion` objects representing the updated questions.
   * The function merges the updates into the existing state while preserving other state properties.
   */
  const updateQuestions = useCallback(
    (updates: EditableQuestion[]) => {
      setState(prev => {
        // Update selected question reference if it exists in the updates
        const updatedSelected = prev.selected
          ? updates.find(q => q.id === prev.selected?.id) || prev.selected
          : prev.selected;

        // Handle flexible banners
        if (flexibleBannersOption.isOptionEnabled) {
          const updatedQuestions = processFlexibleBannerUpdates(
            updates,
            prev.questions,
          );
          return {
            questions: updatedQuestions,
            selected: updatedSelected,
          };
        }

        // Regular mode - just update the questions array
        return {
          questions: [...updates],
          selected: updatedSelected,
        };
      });
    },
    [flexibleBannersOption.isOptionEnabled],
  );

  /**
   * Determines if there are any changes between the original questions and the current state of questions.
   * Compares the serialized JSON representations of both arrays to detect differences.
   */
  const hasChanges = useMemo(() => {
    return (
      JSON.stringify(originalQuestions) !== JSON.stringify(state.questions)
    );
  }, [originalQuestions, state.questions]);

  /**
   * Generates a result object containing the updated questions in their normalized form.
   *
   * This function processes the current state of questions and maps them back to their
   * original column structure (firstColumn and scndColumn). It handles both regular questions
   * and flexible banner columns, ensuring that all updates are properly reflected in the result.
   *
   * The function:
   * 1. Creates normalized maps of the original columns for efficient lookups
   * 2. Maps edited questions back to their basic Question type (removing editor-specific properties)
   * 3. Updates the appropriate column based on the question's source
   * 4. Handles banner columns by updating both the second column and banner column map
   *
   * @returns {EditorChanges} An object containing:
   *   - firstColumn: Array of updated questions for the first column
   *   - scndColumn: Array of updated questions for the second column
   */
  const getQuestionsChanges = useCallback(() => {
    // Create normalized maps for both columns
    const normalizeColumn = (column: Question[]) =>
      column.reduce<Record<string, Question>>((acc, q) => {
        acc[q.id] = q;
        return acc;
      }, {});

    const mapToQuestion = (question: EditableQuestion) => {
      const { bannerColumns, source, ...rest } = question;
      return rest;
    };

    const normalizedFirst = normalizeColumn(firstColumn);
    const normalizedSecond = normalizeColumn(scndColumn);
    const updatedBannerColumn = { ...bannerColumnMap };

    // Update questions
    state.questions.forEach(question => {
      const { id, source, bannerColumns } = question;
      const mappedQuestion = mapToQuestion(question);

      if (source === ColumnSource.ROW) {
        normalizedFirst[id] = mappedQuestion;

        // Handle banner columns if they exist
        if (bannerColumns?.length) {
          // Update second column
          bannerColumns.forEach(bc => {
            normalizedSecond[bc.id] = mapToQuestion(bc);
          });

          // Update banner column
          updatedBannerColumn[id] = updatedBannerColumn[id].map(
            (banner: Question) => {
              const updatedBanner = bannerColumns.find(
                bc => bc.id === banner.id,
              );
              return updatedBanner ? mapToQuestion(updatedBanner) : banner;
            },
          );
        }
      } else {
        normalizedSecond[id] = mappedQuestion;
      }
    });

    // TODO: synchronize firstColumn and scndColumn changes with the bannerColumn
    return [
      {
        firstColumn: Object.values(normalizedFirst),
        scndColumn: Object.values(normalizedSecond),
        bannerColumn: updatedBannerColumn,
      },
    ];
  }, [bannerColumnMap, firstColumn, scndColumn, state.questions]);

  const getDataChanges = useCallback(() => {
    if (options!.editHierarchy) {
      return state.questions;
    }
    return getQuestionsChanges();
  }, [getQuestionsChanges, options, state.questions]);

  const contextValue = useMemo(
    () => ({
      // State
      ...state,

      // Selection actions
      setSelected,
      clearSelection,
      isSelected,

      // Question updates
      updateQuestion,
      updateSelected,

      // Bulk actions
      updateQuestions,

      // State checks
      hasChanges,
      options: {
        enableCustomRows: false,
        enablePasteEdits: false,
        editHierarchy: false,
        ...options,
        flexibleBannersEnabled: flexibleBannersOption.isOptionEnabled,
      },
      currentContext,
      setContext,

      // Data changes
      getDataChanges,
    }),
    [
      clearSelection,
      hasChanges,
      isSelected,
      setSelected,
      state,
      updateQuestion,
      updateQuestions,
      updateSelected,
      options,
      currentContext,
      setContext,
      getDataChanges,
      flexibleBannersOption.isOptionEnabled,
    ],
  );

  return (
    <QuestionEditorContext.Provider
      value={contextValue as QuestionEditorContextValue}
    >
      {children}
    </QuestionEditorContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useQuestionEditor = () => {
  const context = useContext(QuestionEditorContext);

  if (!context) {
    throw new Error(
      'useEditQuestions must be used within EditQuestionsProvider',
    );
  }

  return context;
};
