import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { NumericTextBox, Switch, Input } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../../../../../shared/Icon/Icon';

const grid = 8;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    cursor: 'grabbing',
    userSelect: "none",
    background: "white",
    width: isDragging && '59%',
    display: isDragging && 'flex',
    flexGrow: isDragging && '1',
    alignItems: isDragging && 'center',
    justifyContent: isDragging && 'space-around',
    border: isDragging ? '1px solid #dee2e6' : '',
    boxShadow: isDragging ? '0 0 1rem rgba(0, 0, 0, 0.15)' : '',
    ...draggableStyle
  }
};

const getListStyle = (isDraggingOver) => ({
  padding: grid,
  width: '100%',
  background: isDraggingOver ? "whitesmoke" : "white",
});

export const QuestionEditorColumnsTable = ({ question, source, updateQuestionState, setCheckedSubgroupItems, onSelectedChange, onActiveChange, onSuppressChange, onChangeQuestionRowText, onChangeQuestionRowWeightValue, deleteMergedColumn, onAddCategoryItem, deleteRow, onChangeQuestionCode, duplicateRow, isCreateHeaderDisabled }) => {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      question.subGroups,
      result.source.index,
      result.destination.index
    );
    updateQuestionState('subGroups', question, items)
  }

  const codeTwoInputs = question.subGroups?.length && question.subGroups[0].code && question.subGroups[0].code && question.subGroups[0].code.toString().includes(':');

  const onToggleSelectAllHandler = (e) => {
    const updatedQuestion = JSON.parse(JSON.stringify(question))
    updatedQuestion['subGroups'].forEach(item => {
      item.selected = e.target.checked
    })
    if (e.target.checked === true) {
      setCheckedSubgroupItems(updatedQuestion.subGroups.map(item => item.id))
    } else {
      setCheckedSubgroupItems([])
    }
    updateQuestionState('subGroups', question, updatedQuestion.subGroups)
  }

  const onToggleActivateAllHandler = (e) => {
    const updatedQuestion = JSON.parse(JSON.stringify(question))
    updatedQuestion['subGroups'].forEach(item => {
      item.active = e.value;
      item.suppress = false;
    })
    updateQuestionState('subGroups', question, updatedQuestion.subGroups)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <table
            className="table"
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            <thead className={`text-center ${snapshot.isDraggingOver ? 'bg-white' : ''}`}>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="k-checkbox"
                    checked={question.subGroups.length === question.subGroups.filter(item => item.selected).length}
                    onChange={(e) => onToggleSelectAllHandler(e)} />
                </th>
                <th>
                  <Switch
                    name="switch-checkbox"
                    checked={question.subGroups.length === question.subGroups.filter(item => item.active).length}
                    onChange={(e) => onToggleActivateAllHandler(e)}
                    size="small"
                  />
                </th>

                {codeTwoInputs ?
                  <>
                    <th>From</th>
                    <th>To</th>
                  </>
                  :
                  <>
                    <th>Suppress</th>
                    <th>Code</th>
                  </>
                }
                <th>Text</th>
                <th>Scaling factor</th>
                <th />
                <th />
              </tr>
            </thead>
            <tbody>
              {
                question.subGroups.map((row, index) => (
                  <Draggable key={row.id} draggableId={row.id} index={index}>
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <td className="align-middle">
                          <input
                            type="checkbox"
                            className="k-checkbox"
                            checked={row.selected}
                            onChange={() => { }}
                            onClick={(e) => { onSelectedChange(e, row.id, 'subGroups') }} />
                        </td>
                        <td className="align-middle">
                          <Switch
                            name="switch-checkbox"
                            checked={row.active}
                            onChange={() => { onActiveChange(row.id, 'subGroups') }}
                            size="small"
                          />
                        </td>

                        {codeTwoInputs ?
                          <>
                            <td className="align-middle">
                              <NumericTextBox
                                name="from"
                                size={'large'}
                                value={!Number.isNaN(Number.parseInt(row.code.split(':')[0])) ? Number.parseInt(row.code.split(':')[0]) : 0}
                                onChange={(e) => onChangeQuestionCode(e, row.id, 'subGroups', 'from')}
                              />
                            </td>
                            <td className="align-middle">
                              <NumericTextBox
                                name="to"
                                size={'large'}
                                value={!Number.isNaN(Number.parseInt(row.code.split(':')[1])) ? Number.parseInt(row.code.split(':')[1]) : 0}
                                onChange={(e) => onChangeQuestionCode(e, row.id, 'subGroups', 'to')}
                              />
                            </td>
                          </>
                          :
                          <>
                            <td className="align-middle">
                              <Switch
                                name="switch-checkbox"
                                checked={row.suppress}
                                onChange={() => { onSuppressChange(row.id, 'subGroups') }}
                                size="small"
                              />
                            </td>
                            <td className="align-middle">
                              <NumericTextBox
                                name="code"
                                className='w-100'
                                value={row.code && Number.parseInt(row.code)}
                                onChange={(e) => onChangeQuestionCode(e, row.id, 'subGroups')}
                              />
                            </td>
                          </>
                        }
                        <td className="align-middle">
                          <Input
                            className="form-control"
                            value={row.text}
                            onChange={(e) => onChangeQuestionRowText(e, row.id, 'subGroups')}>
                          </Input>
                        </td>
                        <td className="align-middle">
                          <NumericTextBox
                            disabled={row.merged || source === 'scndColumn'}
                            size={'large'}
                            value={row.weightValue && Number(row.weightValue)}
                            onChange={(e) => onChangeQuestionRowWeightValue(e, row.id, 'subGroups')}
                          >
                          </NumericTextBox>
                        </td>
                        <td className="align-middle">
                          <Tooltip anchorElement="target" position="top">
                            <button type='button' className="btn btn-transparent" onClick={() => duplicateRow(row.id, 'subGroups')} title='Copy'>
                              <Icon type='copy' style={{ fill: "text-muted" }} className='pe-none' />
                            </button>
                          </Tooltip>
                        </td>
                        <td className="align-middle">
                          <Tooltip anchorElement="target" position="top">
                            <button type='button' className="btn btn-transparent" onClick={() => deleteRow(row.id, 'subGroups')} title="Delete">
                              <Icon type='delete' className='pe-none fill-danger' />
                            </button>
                          </Tooltip>
                        </td>
                        {
                          row.merged ?
                            <td className="align-middle pl-0" style={{ maxWidth: "24px" }}>
                              <button type='button' className="cursor-pointer border-0 bg-white" onClick={() => deleteMergedColumn(question, row, "subGroups")}>
                                <i className="fas fa-trash-alt text-danger" />
                              </button>
                            </td>
                            :
                            <td />
                        }
                      </tr>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
      <div className={`${isCreateHeaderDisabled ? 'd-none' : 'd-flex'} border-top px-3 py-2`}
        onClick={onAddCategoryItem}
        style={{ cursor: "pointer" }}
      >
        <i className="fal fa-plus d-flex align-items-center text-primary mr-2" />
        <span className="text-dark flex-fill">Add empty row</span>
      </div>
    </DragDropContext>
  )
}
