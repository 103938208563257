import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Icon } from '../../../../../shared/Icon/Icon';
import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { useDebounce } from '../../../../../shared/customHooks/useDebounce';
import DisplaySettings from '../SurveyThemesTool/DisplaySettings/DisplaySettings';
import type { Type } from '../../../../../../interfaces/iconInterfaces/iconInterfaces';
import { returnSurveyDesignData } from '../../helpers/returnDataHelpers/returnDataHelpers';
import type { Languages } from '../../../../../../interfaces/surveyInterfaces/surveyIterfaces';
import FileManager from '../../../../../shared/FileManager/FileManager';
import { fetchGetJson, fetchPutJson, fetchPatchJson as setLanguageAsDefault } from '../../../../../../services/services';

type Tabs = {
  title: string;
  description: Type
}

const tabs: Tabs[] = [
  { title: 'Survey settings', description: 'globe' },
  { title: 'Theme settings', description: 'settings-theme' },
  { title: 'Advanced settings', description: 'settings-advanced' }
]

export const SurveyDesignSettingsTool: React.FC = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [settings, setSettings] = useState<TODO>(null)
  const [settingsValues, setSettingsValues] = useState<TODO>(null)
  const [selectedTab, setSelectedTab] = useState(0)
  const [showFileManager, setShowFileManager] = useState({ show: false, setting: null })
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))
  const [languages, setLanguages] = useState<Languages[]>([])
  const [updatedETag, setUpdatedETag] = useState(null);

  const params: { name: string, survey: string } = useParams()
  const saveWithDebounce = useDebounce(() => onUpdateSettings(""), 1000);

  useEffect(() => {
    fetchGetJson('resources/survey-options', token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setSettings({ displaySettings: res.displaySettings, themeSettings: res.themeSettings })
          if (settingsValues === null) {
            setSettingsValues({ displaySettings: theData.originalData.displaySettings, themeSettings: theData.originalData.themeSettings })
          }
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        }
      })
    fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/languages`, token)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setLanguages(res)
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        }
      })
    if (!theData.originalData || !theData.originalData._etag) {
      fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/index/etag`, token)
        .then((res: TODO) => {
          if (res && !res.error && !res.message) {
            setUpdatedETag(res.etag)
          }
        })
    }
  }, [])

  const onChangeSettingsValues = (value: TODO, setting: string) => {
    setSettingsValues({ ...settingsValues, displaySettings: { ...settingsValues.displaySettings, [setting]: value } })
    saveWithDebounce()
  }

  const onUpdateSettings = (id: TODO) => {
    dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'saving' })
    let body = {}
    if (id) {
      body = { ...theData.originalData, themeSettings: { id: id } }
    } else {
      body = { ...theData.originalData, displaySettings: settingsValues.displaySettings, languages: languages, _etag: updatedETag ? updatedETag : theData.originalData._etag }
    }
    fetchPutJson(`su/projects/${theData.originalData.projectId}/surveys/${theData.originalData.surveyId}/index`, token, body)
      .then((data: TODO) => {
        if (data && !data.error && !data.message) {
          const originalData = data
          const updatedData = returnSurveyDesignData(originalData)
          dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: updatedData, originalData: originalData } })
          setTimeout(() => {
            dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: '' })
          }, 1500);
          setUpdatedETag(data._etag)
        } else {
          dispatch({ type: 'SET_AUTOSAVE_STATUS', payload: 'error' })
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${data.error ? data.error : data.message}` } })
        }
      })
  }

  const updateDefaultLanguage = (e: TODO) => {
    const updatedLanguages = languages.map((el: TODO) => {
      return {
        ...el,
        isDefault: el.code === e ? true : false
      }
    })
    setLanguages(updatedLanguages)
    const langBody = { code: updatedLanguages.find((lang: TODO) => lang.isDefault).code }
    setLanguageAsDefault(`su/projects/${params.name}/surveys/${params.survey}/default-language`, token, langBody)
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          dispatch({ type: 'SHOW_SUCCESS_NOTIFICATION', payload: { msg: "Survey setting was updated" } })
          fetchGetJson(`su/projects/${params.name}/surveys/${params.survey}/index/etag`, token)
            .then((res: TODO) => {
              setUpdatedETag(res.etag)
            })
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
        }
      })
  }

  const setCustomSettingValues = (e: TODO) => {
    const { name, value } = e.target
    setSettingsValues({
      ...settingsValues,
      displaySettings: {
        ...settingsValues.displaySettings,
        [name]: value
      }
    })
    saveWithDebounce()
  }

  const onSelectFromFileManager = (value: TODO) => {
    setSettingsValues({ ...settingsValues, displaySettings: { ...settingsValues.displaySettings, [showFileManager.setting as keyof {}]: value } })
    saveWithDebounce()
  }

  return (
    <React.Fragment>
      {
        showFileManager.show &&
        <FileManager
          displayType="modal"
          type={'public'}
          onSelect={(value) => onSelectFromFileManager(value)}
          onHide={() => setShowFileManager({ show: false, setting: null })}
          token={token}
        />
      }
      <div className="row no-gutters flex-grow-1 h-100 survey-main overflow-hidden">
        <div className="col-lg-3 d-flex flex-column border-right survey-content bg-white">
          <div className="d-flex flex-column align-items-between justify-content-center border-bottom p-3 build-tab-header">
            <div className="d-flex align-items-center justify-content-between flex-grow-1">
              <h6 className='mb-0 lh-24'>Settings</h6>
            </div>
          </div>

          <div className="d-flex flex-column gap-sm p-3">
            {
              tabs.map((item, key) => (
                <div onClick={() => setSelectedTab(key)} key={key} className={`d-flex align-items-center survey-settings cursor-pointer ${selectedTab === key ? 'active' : ''}`}>
                  <Icon className='pe-none mr-1' type={item.description} /><span>{item.title}</span>
                </div>
              ))
            }
          </div>

        </div>
        <div className={"col-lg-9 survey-content bg-white d-flex flex-column"}>
          {
            settings?.displaySettings && settingsValues?.displaySettings &&
            <DisplaySettings
              settings={settings.displaySettings}
              settingsValues={settingsValues.displaySettings}
              onChangeSettingsValues={onChangeSettingsValues}
              onShowFileManager={(id: TODO) => setShowFileManager({ show: true, setting: id })}
              selectedTab={tabs[selectedTab]}
              languages={languages}
              updateDefaultLanguage={updateDefaultLanguage}
              setCustomSettingValues={setCustomSettingValues}
            />
          }
        </div>
      </div>
    </React.Fragment>
  )
}
