import { useEffect, useState, type FormEvent } from "react"
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@progress/kendo-react-inputs";
import { useParams } from "react-router-dom";

import { BaseDropDownList } from "../../../../../../../shared/Inputs/BaseDropDownList/BaseDropDownList"
import type { RootState } from "../../../../../../../../store/reducers/rootReducer";
import { fetchGetJson, fetchPostJson } from "../../../../../../../../services/services";
import { ErrorMessage } from "../../../../../../../shared/ErrorMessage/ErrorMessage";
import type { ReportTableTypes } from "../ReportsList";

type Props = {
  setShowAddReportsModal: (show: boolean) => void;
  dataState?: TODO;
  report?: TODO
  type: ReportTableTypes
}

export const AddReportsModal = ({ setShowAddReportsModal, dataState, report, type }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);

  const params: { name: string, dataset: string } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState(null);
  const [selectedReports, setSelectedReports] = useState({ id: "", name: "" });
  const [saveToExistingReports, setSaveToExistingReports] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = Object.fromEntries(new FormData(event.currentTarget)) as { reportName: string, reportFolderName: string };

    if (saveToExistingReports && !selectedReports.id && !selectedReports.name && !formData.reportName) {
      setErrorMessage("Please select a folder");
      return
    }

    if (!saveToExistingReports && !formData.reportName && !formData.reportFolderName) {
      setErrorMessage("Please fill in all the fields");
      return
    }

    setIsLoading(true);

    const body = saveToExistingReports ?
      {
        ...dataState,
        name: formData.reportName.trimEnd(),
        type: type,
      }
      :
      {
        name: formData.reportFolderName,
        reportSnapshot: {
          ...dataState,
          name: formData.reportName.trimEnd(),
          type: type,
        }
      }

    const postUrl = saveToExistingReports ? `an/projects/${params.name}/analysis/${params.dataset}/reportfolders/${selectedReports.id}/reportSnapshots` : `an/projects/${params.name}/analysis/${params.dataset}/reportfolders`;

    fetchPostJson(postUrl, token, body)
      .then((res: TODO) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          setIsLoading(false);
        } else {
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: "Report saved" } });
          setIsLoading(false);
          setShowAddReportsModal(false);
        }
      })
  }

  useEffect(() => {
    fetchGetJson(`an/projects/${params.name}/analysis/${params.dataset}/reportfolders`, token)
      .then((res: TODO) => {
        if (res.error || res.message) {
          return
        }
        setReports(res)
      })
  }, [token, params])

  return (
    <Dialog width={500} title={"Create report"} onClose={() => setShowAddReportsModal(false)}>
      <div className="d-flex flex-column p-3">
        <form className="d-flex flex-column justify-content-between gap-md" onSubmit={(e) => handleSubmit(e)}>
          <div className="d-flex flex-column gap-md mb-2">
            <div className="d-flex flex-column gap-sm">
              <label htmlFor="reportName" className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
                Report name
              </label>
              <input id="reportName" name="reportName" required className="form-control form-control-sm" defaultValue={report?.name} />
            </div>

            <div className="d-flex gap-sm align-items-center gap-md h-32">
              <p className="m-0">Save to existing report folder</p>
              <Switch size="small" checked={saveToExistingReports} disabled={!reports} onChange={() => setSaveToExistingReports(!saveToExistingReports)} />
            </div>

            {saveToExistingReports ?
              <div className="d-flex flex-column gap-sm w-100">
                <p className="m-0">Report folders</p>
                <BaseDropDownList
                  data={reports}
                  textField="name"
                  dataItemKey="id"
                  className="w-100"
                  value={selectedReports}
                  validityStyles={false}
                  onChange={(e: TODO) => setSelectedReports({ id: e.value.id, name: e.value.name })}
                />
              </div>
              :
              <div className="d-flex flex-column gap-sm">
                <label htmlFor='reportFolderName' className="d-flex gap-sm align-items-center m-0" style={{ whiteSpace: "nowrap" }}>
                  Folder name
                </label>
                <input id="reportFolderName" name="reportFolderName" required className="form-control form-control-sm" />
              </div>
            }
          </div>

          { errorMessage && <ErrorMessage type="alert" errorMessage={errorMessage} /> }

          <DialogActionsBar>
            <button type="button" className="btn btn-secondary" onClick={() => setShowAddReportsModal(false)}>Cancel</button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading}>
              { isLoading && <span className="spinner-border spinner-border-sm mr-2" /> }
              {saveToExistingReports ? "Save to folder" : "Create report"}
            </button>
          </DialogActionsBar>
        </form>
      </div>
    </Dialog>
  )
}
