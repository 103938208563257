import { useCallback } from 'react';

import { useSelection } from '../../hooks/useSelection';
import { useFlexibleBanners } from '../../hooks/useFlexibleBanners';

// --------------------------------------------------------------------------

export const useCrosstableSelection = () => {
  const {
    flexibleBannersOption,
    toggleSelected: toggleBannerSelection,
    selectBannerColumn,
    deselectBannerColumn,
  } = useFlexibleBanners();

  const {
    setActiveRow,
    selectAllRows,
    deselectAllRows,
    selectAllColumns,
    deselectAllColumns,
    selectQuestion,
    updateCheckedNum,
  } = useSelection('xt');

  const xtSelectQuestion = useCallback(
    (
      columnType: 'firstColumn' | 'scndColumn',
      questionId: string,
      isRangeSelection: boolean
    ) => {
      const isRowsColumn = columnType === 'firstColumn';
      selectQuestion(columnType, questionId, isRangeSelection);

      // If flexible banners are enabled, toggle the banner selection
      if (!isRowsColumn && flexibleBannersOption.isOptionEnabled) {
        toggleBannerSelection(questionId);
      }
    },
    [
      selectQuestion,
      flexibleBannersOption.isOptionEnabled,
      toggleBannerSelection,
    ]
  );

  const xtSelectAllColumns = useCallback(() => {
    if (flexibleBannersOption.isOptionEnabled) {
      selectBannerColumn();
    } else {
      selectAllColumns();
    }
  }, [
    flexibleBannersOption.isOptionEnabled,
    selectAllColumns,
    selectBannerColumn,
  ]);

  const xtDeselectAllColumns = useCallback(() => {
    if (flexibleBannersOption.isOptionEnabled) {
      deselectBannerColumn();
    } else {
      deselectAllColumns();
    }
  }, [
    deselectAllColumns,
    deselectBannerColumn,
    flexibleBannersOption.isOptionEnabled,
  ]);

  return {
    selectQuestion: xtSelectQuestion,
    updateCheckedNum,
    setActiveRow,
    selectAllRows,
    deselectAllRows,
    selectAllColumns: xtSelectAllColumns,
    deselectAllColumns: xtDeselectAllColumns,
  };
};
