import { Fragment, useEffect, useState } from 'react';
import JsxParser from 'react-jsx-parser';
import { useHistory } from 'react-router-dom';
import { DragDropContext } from '@hello-pangea/dnd';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@progress/kendo-react-dialogs';
import { DropDownButton, DropDownButtonItem } from '@progress/kendo-react-buttons';

import items from '../TabContent/exportItems';
import { ExportModal } from '../ExportModal/ExportModal';
import { Search } from '../../../shared/Search/Search';
import DroppableColumn from '../../DroppableColumn/DroppableColumn';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { ErrorMessage } from '../../../../../../shared/ErrorMessage/ErrorMessage';
import reportActions from '../TabContent/ReportTabContent/reportActions';
import { ExportActionItem } from '../../../shared/ExportActionItem/ExportActionItem';
import returnActionsData from '../../../shared/helpers/returnActionsData/returnActionsData';
import { InProgressOverlay } from '../../../../../../shared/InProgressOverlay/InProgressOverlay';
import ChartTypeContent from '../TabContent/ChartTabContent/ChartTypeContent/ChartTypeContent';
import { updateEditedTextField } from '../../../shared/helpers/resetEditedText/resetEditedText';
import returnUpdatedAnalysisActionItems from '../../../shared/helpers/returnUpdatedAnalysisActionItems/returnUpdatedAnalysisActionItems';
import { fetchPostJson as updateToplineData } from '../../../../../../../services/services';

interface Props {
  user: TODO
  token: string
  userData: TODO
  rangeItems: TODO
  datasetId: TODO
  datasetName: TODO
  datasetType: TODO
  optionsData: TODO
  onAutoSaveHandler: TODO
  analysisFunctionalities: TODO
  isLoading: TODO
  onSelectQuestion: TODO
  onCheckQuestionReportTab: TODO
  onDragStart: TODO
  onDragEnd: TODO
  onClose: TODO
}

export default function ReportModal({ onClose, onDragEnd, onDragStart, onCheckQuestionReportTab, onSelectQuestion, isLoading, rangeItems, user, token, datasetType, datasetId, datasetName, optionsData, onAutoSaveHandler, analysisFunctionalities }: Props) {
  const { state } = useSelector((theState: RootState) => (theState.stateReducer));
  const { defaultLanguage, languages, editingLanguage } = useSelector((theState: RootState) => (theState.setInitialDataReducer));
  const { theData, oldData } = useSelector((theState: RootState) => (theState.setInitialDataReducer));
  const userSettings = useSelector((state: RootState) => state.userSettingsReducer);
  const reportState: TODO = useSelector((theState: RootState) => theState.reportStateReducer);
  const [type, setType] = useState('')
  const [noData, setNoData] = useState(false)
  const [updateLanguage, setUpdateLanguage] = useState(null)
  const [errorMessage, setErrorMessage] = useState("");
  const [localTableState, setLocalTableState] = useState('')
  const [showExportModal, setShowExportModal] = useState<{ show: boolean, action: TODO, type: TODO, extension: TODO }>({ show: false, action: null, type: null, extension: null })

  const history = useHistory();
  const dispatch = useDispatch();
  const updatedItems = returnUpdatedAnalysisActionItems(analysisFunctionalities, items, datasetType, true)
  const allColumnsDisabled = reportState.questions.filter((e: TODO) => !e.disabled).length === 0

  useEffect(() => {
    if (reportState.columnData.length !== 0) {
      const type = reportState.questions[reportState.activeState].type
      setType(type)
      if (type === "data") {
        setType("rawData")
      } else if (type === "dig") {
        setType("digData")
      }
      setNoData(false)
      setLocalTableState('')
      const count = reportState.activeState
      const body = reportState.columnData[count].body
      body.language = editingLanguage
      const dataUrl = reportState.columnData[count].dataUrl
      let updatedBody: TODO;
      if (body.language && body.language !== defaultLanguage) {
        updatedBody = updateEditedTextField(body)
      }
      updateToplineData(dataUrl, token, updatedBody ? updatedBody : body)
        .then((res: TODO) => {
          if (res && (res.error || res.message)) {
            setErrorMessage(res.error ? res.error : res.message)
          } else {
            if (res.result) {
              setLocalTableState(res.result)
            } else {
              setLocalTableState(res)
            }
          }
        })
      onAutoSaveHandler(state)
    } else {
      setLocalTableState('')
      setNoData(true)
    }
  }, [token, reportState, state, onAutoSaveHandler, reportState.activeState, editingLanguage, defaultLanguage])

  useEffect(() => {
    if (updateLanguage) {
      dispatch({ type: "SET_EDITING_LANGUAGE", payload: updateLanguage, })
    }
  }, [dispatch, updateLanguage])

  const exportFunc = (props: TODO) => {
    if (reportState.questions && reportState.questions.length > 0 && allColumnsDisabled === false) {
      setShowExportModal({ show: true, action: props.item.action, type: props.item.actionName, extension: props.item.extension })
    }
  }

  const actionItemRender = (props: TODO) => {
    return (
      <ExportActionItem
        tab='report'
        user={user}
        props={props}
        state={state}
        reportState={reportState}
        disabled={allColumnsDisabled}
      />
    )
  }

  const onActionHandler = (action: string) => {
    const updatedReportState = JSON.parse(JSON.stringify(reportState))
    const updatedReportColumns = JSON.parse(JSON.stringify(updatedReportState.columnData))
    const updatedReportQuestions = JSON.parse(JSON.stringify(updatedReportState.questions))

    if (action === 'deleteAll') {
      dispatch({ type: 'REPORT_DELETE_SELECTED_QUESTIONS', payload: { questions: [], columnData: [] } })
    } else if (action === 'deleteSelected') {
      const updatedQuestions: TODO = []
      const updatedColumnData: TODO = []
      updatedReportQuestions.forEach((el: TODO, index: number) => {
        if (!el.selected) {
          updatedQuestions.push(el)
          updatedReportState.columnData.forEach((item: TODO, id: number) => {
            if (index === id) {
              updatedColumnData.push(item)
            }
          })
        }
      })
      dispatch({ type: 'REPORT_DELETE_SELECTED_QUESTIONS', payload: { questions: updatedQuestions, columnData: updatedColumnData } })
    } else if (action === 'enable') {
      updatedReportQuestions.filter((item: TODO) => item.selected).forEach((el: TODO) => {
        el.disabled = false
      })
      updatedReportQuestions.filter((item: TODO, id: TODO) => {
        if (item.selected) {
          updatedReportColumns[id].disabled = false
        }
        return null
      })
      updatedReportQuestions.forEach((item: TODO) => {
        item.selected = false
      })
      dispatch({ type: 'REPORT_ENABLE_SELECTED_QUESTIONS', payload: { questions: updatedReportQuestions, columns: updatedReportColumns } })
    } else if (action === 'disable') {
      updatedReportQuestions.filter((item: TODO) => item.selected).forEach((el: TODO) => {
        el.disabled = true
      })
      updatedReportQuestions.filter((item: TODO, id: TODO) => {
        if (item.selected) {
          updatedReportColumns[id].disabled = true
        }
        return null
      })
      updatedReportQuestions.forEach((item: TODO) => {
        item.selected = false
      })
      dispatch({ type: 'REPORT_DISABLE_SELECTED_QUESTIONS', payload: { questions: updatedReportQuestions, columns: updatedReportColumns } })
    } else if (action === 'select rows') {
      const rows = updatedReportQuestions
      rows.map((e: TODO) => e.selected = true)
      dispatch({ type: 'REPORT_UPDATE_QUESTIONS', payload: rows })
    } else if (action === 'deselect rows') {
      const rows = updatedReportQuestions
      rows.map((e: TODO) => e.selected = false)
      dispatch({ type: 'REPORT_UPDATE_QUESTIONS', payload: rows })
    }
  }

  const onLanguageChange = (e: TODO) => {
    setUpdateLanguage(e.item.text)
  }

  return (
    <Dialog onClose={onClose} className='import-dialog report-dialog' width={"95%"} height={"90%"} title="Report">
      <div className="d-flex h-100">
        {
          errorMessage &&
          <ErrorMessage type={"modal"} errorMessage={errorMessage} onHide={() => setErrorMessage("")} />
        }
        {
          showExportModal?.show &&
          <ExportModal
            tab={'report'}
            state={reportState}
            updatedLanguage={editingLanguage}
            defaultLanguage={defaultLanguage}
            token={token}
            history={history}
            editorValue={null}
            datasetId={datasetId}
            datasetName={datasetName}
            datasetType={datasetType}
            projectType={undefined}
            showExportModal={showExportModal}
            onExportHandler={returnActionsData}
            options={{ exportOptions: optionsData.exportOptions, values: optionsData.dropdownValues }}
            onHide={() => { setShowExportModal({ ...showExportModal, show: false }); dispatch({ type: 'CLOSE_EXPORT_MODAL' }); }}
            exportBody={updateEditedTextField(reportState.columnData)}
          />
        }

        {/* Sidebar */}
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <div className={`${userSettings.minimize ? "hide-sidebar" : "show-sidebar"} d-flex flex-column bg-white analyze-sidebar`}>
            <div className={"d-flex flex-wrap flex-column bg-white border-bottom"}>
              <Search oldData={oldData} dispatch={dispatch} />
            </div>
            {
              theData && theData.length > 0 && !isLoading ?
                <div className="d-flex flex-column overflow-hidden report-list crosstab h-100 w-100">
                  <DroppableColumn report={true} onSelectQuestion={onSelectQuestion} rangeItems={rangeItems} theData={reportState} onCheck={onCheckQuestionReportTab} />
                </div>
                : theData === null ?
                  <div className="d-flex w-100 h-100 justify-content-center align-items-center">
                    <span className="text-muted h6">No questions were found</span>
                  </div>
                  :
                  <InProgressOverlay
                    type="fullscreen"
                    theme="primary" />
            }
          </div>
        </DragDropContext>

        {/* Content */}
        <div className="d-flex flex-column flex-grow-1">
          <div className="d-flex justify-content-between flex-wrap border-left">
            <div className="btn-group m-2 analysis-actions">
              <div className="btn-group">
                <DropDownButton
                  text="Actions"
                  icon="fas fa fa-caret-down"
                  className='analyze-actions-button'
                  buttonClass="btn btn-outline-analyze d-flex flex-row-reverse rounded-right-0 px-2"
                  popupSettings={{ popupClass: 'p-0' }}
                  items={reportActions}
                  onItemClick={(e) => onActionHandler(e.item.action)}
                  itemRender={(props: TODO) => (
                    <div className="p-1 d-flex align-items-center justify-content-between">
                      <i className={`${props.item.icon} mr-3`} />
                      <span>{props.item.actionName}</span>
                    </div>
                  )}
                />
              </div>
              {languages.length > 1 ?
                <div className='btn-group'>
                  <DropDownButton
                    className="analyze-actions-button"
                    text={editingLanguage}
                    buttonClass="btn btn-outline-analyze d-flex flex-row-reverse rounded-left-0 px-2"
                    icon="fa fas fa-globe"
                    onItemClick={(e) => onLanguageChange(e)}
                    disabled={!reportState.columnData.length}
                    tabIndex={-1} >
                    {languages.map((item: TODO, key: number) =>
                      <DropDownButtonItem key={key} text={item} />
                    )}
                  </DropDownButton>
                </div>
                : null}
            </div>

            <div className="btn-group m-2 analysis-actions">
              <div className="btn-group">
                <DropDownButton
                  text="Export"
                  icon="fas fa fa-caret-down"
                  className='analyze-actions-button'
                  buttonClass={"btn btn-outline-analyze rounded-left-0 d-flex flex-row-reverse px-2"}
                  items={updatedItems}
                  onItemClick={(e) => exportFunc(e)}
                  itemRender={actionItemRender}
                  popupSettings={{ popupClass: 'export-dropdown' }}
                />
              </div>
            </div>
          </div>
          <div className={`d-flex overflow-hidden flex-fill border-table-area ${type !== '' ? type : ''}`}>
            {
              localTableState === "" ?
                noData ?
                  <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                    <span className="droppable-text text-center h5">No data to display</span>
                  </div>
                  :
                  <InProgressOverlay message="" className="" theme="primary" type="fullscreen" />
                :
                <Fragment>
                  {
                    type === 'chart' ?
                      <div className="w-100 d-flex flex-column overflow-auto">
                        <ChartTypeContent currentZoom={1} data={localTableState} />
                      </div>
                      :
                      <div className="w-100 d-flex flex-column overflow-hidden">
                        <JsxParser jsx={localTableState} />
                      </div>
                  }
                </Fragment>
            }
          </div>
        </div>
      </div>
    </Dialog>
  )
}
