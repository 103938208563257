import { useCallback, useMemo } from 'react';
//
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useQuestionEditor } from '../../contexts/QuestionEditorContext';
import type { CustomRowsResponse } from '../types';
import { useNotifications } from '../../../../hooks/useNotifications';

type ActionType = 'add' | 'edit' | 'delete';
type AnalysisType = 'crosstab';

// TODO: find a better place for this to be executed when the action is called

export const useCustomRowsSideFffects = (analysisType: AnalysisType) => {
  const dispatch = useAppDispatch();
  const { defaultLanguage } = useAppSelector(
    theState => theState.setInitialDataReducer,
  );

  const { selected: selectedQuestion, updateSelected } = useQuestionEditor();
  const state = useAppSelector(rootState => rootState.stateReducer.state);
  const notify = useNotifications();

  const questionInfo = useMemo(() => {
    if (!selectedQuestion) {
      return;
    }

    return {
      id: selectedQuestion.id,
      source: selectedQuestion.source,
    };
  }, [selectedQuestion]);

  const actions = useMemo(
    () => ({
      crosstab: {
        add: (data: CustomRowsResponse, id: string) => {
          dispatch({
            type: 'XT_ADD_CUSTOM_ROW_TO_COLUMN',
            payload: {
              data: data,
              questionInfo: questionInfo,
              defaultLanguage: defaultLanguage,
            },
          });
          notify.success('Custom row added successfully');
        },

        edit: (data: CustomRowsResponse, id: string) => {
          dispatch({
            type: 'XT_EDIT_CUSTOM_ROW_FROM_COLUMN',
            payload: {
              data: data,
              rowId: id,
              questionInfo: questionInfo,
            },
          });
          notify.success('Custom row updated successfully');
        },

        delete: (data: CustomRowsResponse, id: string) => {
          dispatch({
            type: 'XT_DELETE_CUSTOM_ROWS_FROM_COLUMN',
            payload: {
              data: data,
              questionInfo: questionInfo,
              defaultLanguage: defaultLanguage,
            },
          });
          notify.success('Custom row deleted successfully');
        },
      },
    }),
    [defaultLanguage, dispatch, notify, questionInfo],
  );

  const runSideEffect = useCallback(
    (
      action: ActionType,
      payload: { data: CustomRowsResponse; id?: string },
    ) => {
      if (!actions[analysisType]) {
        return;
      }

      actions[analysisType][action](payload.data, payload.id ?? '');

      // trigger UI update
      updateSelected({
        [questionInfo!.source]: [...state[questionInfo!.source]]
      });

    },
    [actions, analysisType, updateSelected, questionInfo, state],
  );

  return {
    runSideEffect: runSideEffect,
  };
};
