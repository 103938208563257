const CHART_CLEAR_ACTIONS = [
  {
    actionName: 'Clear all',
    action: 'CHART_CLEAR_QUESTIONS',
  },
  {
    actionName: 'Clear rows',
    action: 'CHART_CLEAR_QUESTION_ROWS',
  },
  {
    actionName: 'Clear columns',
    action: 'CHART_CLEAR_QUESTION_COLUMNS',
  },
  {
    actionName: 'Clear selected',
    action: 'CHART_CLEAR_SELECTED',
  },
  {
    actionName: 'Clear options for selected',
    action: 'CHART_CLEAR_SELECTED_QUESTION_OPTIONS',
  },
  {
    actionName: 'Clear options',
    action: 'CHART_CLEAR_QUESTION_OPTIONS',
  },
];

export default CHART_CLEAR_ACTIONS;
