import type {
  Question,
  QuestionsState,
} from '../../../../../../../interfaces/analysisInterfaces/analysisInterfaces';

export const returnUpdatedEditedHierarchyData = (
  state: QuestionsState,
  questions: Question[],
  currentFlexibleBannerColumn: Question[]
) => {
  const source: 'firstColumn' | 'scndColumn' = state.checkedNum[0].source;

  if (source) {
    const updatedActiveQuestion = state[source].find(el => el.selected);
    const isFlexibleBannersSecondColumn =
      state.checkedNum[0].source === 'scndColumn' &&
      state.questionOptions.XtFlexBanner;

    if (
      updatedActiveQuestion?.hierarchy &&
      updatedActiveQuestion.hierarchy.length > 0
    ) {
      if (source === 'firstColumn') {
        const activeQuestion = questions[0];
        updatedActiveQuestion.hierarchy[0].rows = activeQuestion.groups;
        updatedActiveQuestion.hierarchy[0].cols = activeQuestion.subGroups;
        updatedActiveQuestion.hierarchy[0].editedText = activeQuestion.content;
      } else if (source === 'scndColumn') {
        updatedActiveQuestion.hierarchy.forEach((el, id) => {
          el.rows = questions[id].groups;
          el.cols = questions[id].subGroups;
          el.editedText = questions[id].content;
        });
      }
    }
    const stateCopy = { ...state };
    let questionToBeReplaced = stateCopy.firstColumn.find(el => el.selected);
    questionToBeReplaced = updatedActiveQuestion;

    return {
      questionToBeReplaced: isFlexibleBannersSecondColumn ? resolveFlexibleBannersHierarchy(questions, currentFlexibleBannerColumn) : questionToBeReplaced,
      stateCopy: stateCopy,
    };
  }
};

const resolveFlexibleBannersHierarchy = (
  questions: Question[],
  currentBannerColumn: Question[]
) => {
  const selectedBannerColumn = currentBannerColumn.find(
    el => el.selected
  );
  if (selectedBannerColumn?.hierarchy && selectedBannerColumn.hierarchy.length > 0) {
    selectedBannerColumn.hierarchy.forEach((el, id) => {
      el.rows = questions[id].groups;
      el.cols = questions[id].subGroups;
      el.editedText = questions[id].content;
    });
    return selectedBannerColumn;
  }
};
