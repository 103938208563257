export interface AnalysisRouteParams {
  name: string;
  dataset: string;
  analysisType: string;
}

export const ActionTypes = {
  // Hierarchy actions
  HIERARCHY_ADD: 'add',
  HIERARCHY_EDIT: 'edit',
  HIERARCHY_CLEAR: 'clear',

  // Clear actions
  CLEAR_ALL: 'CLEAR_QUESTIONS',
  CLEAR_ROWS: 'XT_CLEAR_QUESTION_ROWS',
  CLEAR_COLUMNS: 'XT_CLEAR_QUESTION_COLUMNS',
  CLEAR_SELECTED: 'XT_CLEAR_SELECTED',
  CLEAR_SELECTED_OPTIONS: 'CLEAR_SELECTED_QUESTION_OPTIONS',
  CLEAR_OPTIONS: 'CLEAR_QUESTION_OPTIONS',
};

export const HIERARCHY_ACTIONS = [
  {
    actionName: 'Add hierarchy',
    action: ActionTypes.HIERARCHY_ADD,
    icon: 'far fa-plus-square',
  },
  {
    actionName: 'Edit hierarchy',
    action: ActionTypes.HIERARCHY_EDIT,
    icon: 'far fa-edit',
  },
  {
    actionName: 'Clear hierarchy',
    action: ActionTypes.HIERARCHY_CLEAR,
    icon: 'far fa-minus-square',
  },
];
