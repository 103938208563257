import { useQuestionEditor } from '../contexts/QuestionEditorContext';
//
import QuestionItem from './QuestionItem';

//---------------------------------------------------------------------------------

const QuestionList = () => {
  const { questions, selected, setSelected, options } = useQuestionEditor();

  return (
    <div className="d-flex flex-column gap-sm">
      {questions.map(question => (
        <QuestionItem
          key={question.id}
          question={question}
          selected={selected}
          onSelect={setSelected}
          config={{ expandable: options.flexibleBannersEnabled }}
        />
      ))}
    </div>
  );
};

export default QuestionList;
