import type { SVGIcon } from '@progress/kendo-svg-icons';

import type { ReactNode } from 'react';
import type { EditableQuestion } from '../types';

/**
 * Action context types supported in the editor
 */
export enum ActionContext {
  ROWS = 'groups',
  COLUMNS = 'subGroups',
}


/**
 * Base action configuration interface
 */
export interface ActionConfig {
  id: string;
  label: string;
  icon?: ReactNode;
  svgIcon?: SVGIcon;
  context: ActionContext[];
  isDisabled?: () => boolean;
  isVisible?: () => boolean;
  handler: (data?: EditableQuestion) => void | Promise<void>;
  order?: number;
}
