import * as React from 'react';
import { DragDropContext, Draggable } from '@hello-pangea/dnd';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { StrictModeDroppable } from './StrictModeDroppable';
import { Icon } from '../../shared/Icon/Icon';
import type { Audience } from '../../../interfaces/auddineceAccessInterfaces/auddineceAccessInterfaces';
import { setAudience } from '../../../store/reducers/audienceAccessReducer/audienceAccessReducer';
import { ErrorModal } from '../shared/ErrorModal';
import { fetchGetJson, fetchPutJson } from '../../../services/services';

interface CustomAudienceModalProps {
  handleModalVisibility: Function;
  editMode: boolean;
  selectedMarkets: {
    MarketName: {
      name: string;
      isoCode: string;
    } | null,
    IncidenceRate: string,
    TargetNumberOfCompletes: string
  }[]
  interviewLength: string
  isoLang: string
  userEmail: string
  onSubmitHandler: Function
  customAudienceModalValues: Audience
  token: TODO
}

interface Answer {
  id: number
  text: string
  quotaPercent?: string
  isSelected?: boolean
  psIds?: number[] | null
}

interface MarketData {
  [x: string]: TODO
  name: string
  answers: Answer[]
}

interface TargetingData {
  answerType: number
  category: string
  id: number
  psId?: string | null
  markets: MarketData
  name: string
  text: string
  useAsQuotas: boolean
  isInclude: boolean | null
  quotaGroups: TODO[]
  selectedAnswers: Answer[]
  openEndedAnswers?: TODO
}
interface SelectedQuestions {
  [questions: string]: Array<TargetingData>;
}

export const CustomAudienceModal: React.FunctionComponent<CustomAudienceModalProps> = ({ handleModalVisibility, selectedMarkets, editMode, onSubmitHandler, customAudienceModalValues, token, isoLang }) => {
  const countries = React.useMemo(() =>
    selectedMarkets.length ? selectedMarkets.map((item: {
      MarketName: {
        name: string;
        isoCode: string;
      } | TODO
    }) => item.MarketName.name) : [],
    [selectedMarkets]
  );
  const [didMount, setDidMount] = React.useState<boolean>(true);
  const [inputVelueType, setInputValueType] = React.useState<string>("%");
  const [selectedQuestions, setSelectedQuestions] = React.useState<SelectedQuestions>({ [`${isoLang}_${selectedMarkets[0].MarketName!.isoCode}`]: [] });
  const [targetingData, setTargetingData] = React.useState<TargetingData[]>([]);
  const [tabSelected, setTabSelected] = React.useState<string>(`${isoLang}_${selectedMarkets[0].MarketName!.isoCode}`);
  const categoriesObj = targetingData.length ? targetingData.reduce((acc: TODO, cur) => {
    if (!acc[cur['category']]) { acc[cur['category']] = []; }
    acc[cur['category']].push(cur);
    return acc;
  }, {}) : [];
  const categoriesList = categoriesObj && Object.keys(categoriesObj);
  const [currentDraggableItem, setCurrentDraggableItem] = React.useState<Answer | null>();
  const [validateError, setValidateError] = React.useState<{}>({})
  const [interlockedQuotasNextPage, setInterlockedQuotasNextPage] = React.useState<boolean>(false);
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [interlockedQuotasValueType, setInterlockedQuotasValueType] = React.useState<string>("%");
  const [interlockedQuotaGroups, setInterlockedQuotaGroups] = React.useState<Array<{ interlockedQuotaOptions: string[] }>>([
    { interlockedQuotaOptions: ["Income", "Children in family", "Gender"] }
  ]);
  const interlockedQuotas = false
  const params: { id: string, name: string } = useParams()
  const dispatch = useDispatch()
  const [searchKeyword, setSearchKeyword] = React.useState<string>("");
  const questionsArr: TODO = Object.values(categoriesObj).flat().map((item: TODO) => ({ ...item, answers: item.markets.map((market: MarketData) => market.answers).flat() }))

  React.useEffect(() => {
    if (didMount) {
      fetchGetJson(`au/targeting?markets=${isoLang}_${selectedMarkets[0].MarketName!.isoCode}`)
        .then((res: TODO) => {
          if (res) { setTargetingData(res) }
        })
        .catch((error: TODO) => { console.log(error) })
        .finally(() => {
          setDidMount(false)
        })
    }
  }, [didMount, countries, selectedMarkets, isoLang]);

  React.useEffect(() => {
    if (Array.isArray(customAudienceModalValues) && customAudienceModalValues.some(item => item.MarketName)) {
      handleModalVisibility({ show: false, editMode: false })
      onSubmitHandler()
    }
  }, [customAudienceModalValues, handleModalVisibility, onSubmitHandler]);

  React.useEffect(() => {
    if (editMode && customAudienceModalValues.id && targetingData.length) {
      handleModalVisibility(({ show: true, editMode: false }))
      let screeningData: TODO[] = []
      let quotasData: TODO[] = []
      if (customAudienceModalValues.screening.length) {
        screeningData = customAudienceModalValues.screening.map(screening => {
          const selectedQuestion = targetingData.find(question => question.id === screening.questionId)
          const selectedAnswers: TODO = []
          const openEndedAnswers: TODO = []
          const combineArr = [...screening.answers, ...(selectedQuestion?.markets[0].answers || [])]
          combineArr.forEach((el) => {
            if (el.text) {
              if (!selectedAnswers.some((item: Answer) => item.id === el.id)) {
                selectedAnswers.push({ ...el, isSelected: screening.answers.map(ans => ans.id).includes(el.id) });
              }
            } else {
              openEndedAnswers.push(el)
            }
          })
          return ({ ...selectedQuestion, selectedAnswers, openEndedAnswers, quotaGroups: [], isInclude: screening.isInclude })
        })
      }
      if (customAudienceModalValues.quotas.length) {
        quotasData = customAudienceModalValues.quotas.map(quota => {
          const selectedQuestion = targetingData.find(question => question.id === quota.questionId)
          const selectedAnswers: TODO = []
          const openEndedAnswers: TODO = []
          const quotaGroups: TODO = []
          quota.targets.forEach((el) => {
            if (el.answers.length === 1) {
              if (el.answers[0].text) {
                selectedAnswers.push({ ...el.answers[0], quotaPercent: Math.round((el.targetCompletes / customAudienceModalValues.quantity!) * 100), isSelected: el.targetCompletes ? true : false })
              } else {
                openEndedAnswers.push({ ...el.answers[0], quotaPercent: Math.round((el.targetCompletes / customAudienceModalValues.quantity!) * 100) })
              }
            } else if (el.answers.length > 1) {
              quotaGroups.push(el.answers.map(answer => ({ ...answer, quotaPercent: Math.round((el.targetCompletes / customAudienceModalValues.quantity!) * 100) })))
            } else {
              return
            }
          })
          const combineArr = [...selectedAnswers, ...(selectedQuestion?.markets[0]?.answers || [])]
          combineArr.forEach((el) => {
            if (el.text) {
              if (!selectedAnswers.some((item: Answer) => item.id === el.id)) {
                selectedAnswers.push({ ...el, quotaPercent: Math.round((el.targetCompletes / customAudienceModalValues.quantity!) * 100), isSelected: selectedAnswers.quotaPercent ? true : false })
              }
            } else {
              openEndedAnswers.push(el)
            }
          })

          return ({ ...selectedQuestion, selectedAnswers, openEndedAnswers, quotaGroups, useAsQuotas: true })
        })
      }
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...selectedQuestions[tabSelected], ...screeningData, ...quotasData] })
    }
  }, [editMode, customAudienceModalValues, selectedQuestions, tabSelected, targetingData, handleModalVisibility]);

  const handleSelectQuestion = (id: number, type: 'add' | 'remove') => {
    if (type === 'remove') {
      const questionToRemove = selectedQuestions[tabSelected].filter((question: TargetingData) => question.id !== id)
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToRemove] })
    } else {
      if (!selectedQuestions[tabSelected].find((question: TargetingData) => question.id === id)) {
        const questionToAdd = targetingData.filter(question => question.id === id).map((item) => {
          return {
            ...item, useAsQuotas: false, isInclude: true, quotaGroups: [], selectedAnswers: item.markets[0].answers, ...(item.answerType === 2) && {
              openEndedAnswers: [{
                "start": 0,
                "end": 0,
                quotaPercent: 0
              }]
            }
          }
        })
        setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...selectedQuestions[tabSelected], ...questionToAdd] })
      }
    }
  }

  const handleInputValueType = (inputValueType: string) => {
    if (inputValueType === "%") {
      setInputValueType("#");
    } else {
      setInputValueType("%");
    }
  }

  const handleIncludeExclude = (inputValue: string, id: number) => {
    if (inputValue === "Exclude") {
      const addedAnswer = selectedQuestions[tabSelected].map(question =>
        question.id === id ?
          {
            ...question, isInclude: false, selectedAnswers: question.selectedAnswers.map(item =>
              item &&
              { ...item, quotaPercent: "", isSelected: !item.isSelected }
            )
          } : question
      )
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: addedAnswer })
    }
    if (inputValue === "Include") {
      const addedAnswer = selectedQuestions[tabSelected].map(question =>
        question.id === id ?
          {
            ...question, isInclude: true, selectedAnswers: question.selectedAnswers.map(item =>
              item &&
              { ...item, quotaPercent: "", isSelected: !item.isSelected }
            )
          } : question
      )
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: addedAnswer })
    }
  }

  const handleSelectAnswer = (e: React.ChangeEvent<HTMLInputElement>, questionId: number, answerObj: Answer) => {
    if (e.target.checked) {
      const addedAnswer = selectedQuestions[tabSelected].map(question =>
        question.id === questionId ?
          {
            ...question, selectedAnswers: question.selectedAnswers.map(item =>
              !item.isSelected ?
                { ...item, quotaPercent: item.quotaPercent, isSelected: item.isSelected ? true : item.id === answerObj.id ? true : false } :
                { ...item, quotaPercent: item.quotaPercent, isSelected: true }
            )
          }
          : question
      )
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: addedAnswer })
    } else {
      const filteredAnswers = selectedQuestions[tabSelected].map(question =>
        question.id === questionId ?
          {
            ...question, selectedAnswers: question.selectedAnswers.map(item =>
              item &&
              { ...item, quotaPercent: item.quotaPercent, isSelected: item.id === answerObj.id ? e.target.checked : item.isSelected }
            )
          }
          : question
      )
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: filteredAnswers })
    }
  }

  const handleSelectAllAnswers = (questionId: number, tabSelected: string) => {
    const questionToUpdate = selectedQuestions[tabSelected].map(question =>
      question.id === questionId ?
        {
          ...question,
          selectedAnswers: question.selectedAnswers.map(item =>
            item &&
            { ...item, quotaPercent: "", isSelected: true },
          ), quotaGroups: [[]]
        }
        : question
    )
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToUpdate] })
  }

  const handleQuestionQuotaState = (id: number) => {
    const newQuestionsState = selectedQuestions[tabSelected].map(question =>
      question.id === id ?
        { ...question, useAsQuotas: !question.useAsQuotas, quotaGroups: question.quotaGroups.length ? question.quotaGroups : [[]] }
        : question
    );
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...newQuestionsState] })
  }

  const handleEditOpenEndedAnswer = (questionId: number, answerId: number, field: string, value: string) => {
    const newQuestionsState = selectedQuestions[tabSelected].map(question =>
      question.id === questionId ?
        { ...question, openEndedAnswers: question.openEndedAnswers.map((item: TODO, idx: number) => idx === answerId ? { ...item, [field]: value } : item) }
        : question
    );
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...newQuestionsState] })
  }

  const handleAddNewResponse = (questionId: number) => {
    let isValid = false
    const isTypeQuestionAgeValid = selectedQuestions[`${isoLang}_${selectedMarkets[0].MarketName!.isoCode}`].find(item => item.name === "AGE")
    if (isTypeQuestionAgeValid) {
      if (!handleErrorNewObject(isTypeQuestionAgeValid?.openEndedAnswers)) {
        isValid = false
      } else {
        isValid = true
      }
    } else {
      isValid = true
    }
    if (isValid || isTypeQuestionAgeValid?.openEndedAnswers.length < 1) {
      const newQuestionsState = selectedQuestions[tabSelected].map(question =>

        question.id === questionId ?
          {
            ...question, openEndedAnswers: [...question.openEndedAnswers, {
              "start": 0,
              "end": 0,
              quotaPercent: 0
            }]
          }
          : question
      );
      setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...newQuestionsState] })
    } else {
      setShowModal(true)
    }
  }

  const handleRemoveOpenEndedAnswer = (questionId: number, answerId: number) => {
    const newQuestionsState = selectedQuestions[tabSelected].map((question: TargetingData) => question.id === questionId ?
      {
        ...question, openEndedAnswers: question.openEndedAnswers.filter((answer: TODO, idx: number) =>
          idx !== answerId && answer)
      } : question);
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...newQuestionsState] })
  }

  const addQuotaGroup = (questinId: number) => {
    const newArr: [] = [];
    const arrayToUpdate = selectedQuestions[tabSelected]
    arrayToUpdate.map((question) => {
      return question.id === questinId && question.quotaGroups.push(newArr);
    })
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...arrayToUpdate] })
  }

  const removeQuotaGroup = (questionId: number, index: number) => {
    const arrayToUpdate = selectedQuestions[tabSelected];
    arrayToUpdate.map(question => {
      return question.id === questionId && question.quotaGroups.splice(index, 1);
    })
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...arrayToUpdate] })
  }

  const onDragEnd = (result: TODO) => {
    const { destination } = result;
    if (destination && currentDraggableItem && currentDraggableItem !== null) {
      const ids = destination.droppableId.split("-", 3);
      const newQuestionState = selectedQuestions[tabSelected];

      setSelectedQuestions({
        ...selectedQuestions, [tabSelected]:
          newQuestionState.map((question) => {
            if (question.id === parseInt(ids[1])) {
              question.quotaGroups[parseInt(ids[2])].push(currentDraggableItem)
              const newSelectedAnswers = question.selectedAnswers.filter(answer => answer.id !== currentDraggableItem?.id);
              return { ...question, selectedAnswers: newSelectedAnswers }
            } else {
              return question
            }
          })
      })
      setCurrentDraggableItem(null);
    };
    return;
  }

  const handleQuotaGroupAnswerDelete = (questionId: number, answerId: number, groupIndex: number) => {
    const questionToUpdate = selectedQuestions[tabSelected]
    questionToUpdate.map(question =>
      question.id === questionId ?
        question.quotaGroups[groupIndex] = question.quotaGroups[groupIndex].filter((answer: Answer) => answer.id !== answerId)
        : question
    )
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToUpdate] })
  }

  const handleQuotaRoundNumber = (value: TODO, questionId: number, answerId: number, isQuotaGroup: boolean) => {
    const questionToUpdate = selectedQuestions[tabSelected]
    if (!isQuotaGroup) {
      questionToUpdate.map(question =>
        question.id === questionId ?
          { ...question, selectedAnswers: question.selectedAnswers.map(answer => answer.id === answerId ? answer.quotaPercent = value : answer) }
          : question
      )
    } else {
      questionToUpdate.map(question =>
        question.id === questionId ?
          { ...question, quotaGroups: question.quotaGroups.map((quotaGroup, idx) => idx === answerId ? quotaGroup.map((item: { quotaPercent: number }) => item.quotaPercent = value) : quotaGroup) }
          : question
      )
    }
    setSelectedQuestions({ ...selectedQuestions, [tabSelected]: [...questionToUpdate] })
  }

  const handleInterlockedQuotas = (direction: string) => {
    if (interlockedQuotasNextPage === false && interlockedQuotas && direction === "Next") {
      setInterlockedQuotasNextPage(true);
    } else if (interlockedQuotasNextPage && direction === "Back") {
      setInterlockedQuotasNextPage(false);
    }
  }

  const handleInterlockedQuotasValueType = (inputValueType: string) => {
    if (inputValueType === "%") {
      setInterlockedQuotasValueType("#");
    } else {
      setInterlockedQuotasValueType("%");
    }
  }

  const addInterlockedQuotaGroup = () => {
    setInterlockedQuotaGroups([...interlockedQuotaGroups, { interlockedQuotaOptions: ["Income", "Children in family", "Gender"] }]);
  }

  const removeInterlockedQuotaGroup = (index: number) => {
    const newInterlockedQuotaGroups = interlockedQuotaGroups.splice(index, 1);
    setInterlockedQuotaGroups(newInterlockedQuotaGroups);
  }

  const handleErrorNewObject = (openEndedQuestions: TODO) => {
    let emptyFieldError = {}

    if (openEndedQuestions.length < 1) {
      emptyFieldError = Object.assign({ ...emptyFieldError, allAnswersDeleted: "No answers in Age Question. Make sure you have at least one answer or delete the Question." })
    }

    openEndedQuestions.forEach((answer: TODO, index: number) => {
      if (answer.start === 0) {
        const errorObj = { index: index, message: "0" }
        emptyFieldError = Object.assign({ ...emptyFieldError, start: errorObj })
      }
      if (answer.end === 0) {
        const errorObj = { index: index, message: "0" }
        emptyFieldError = Object.assign({ ...emptyFieldError, end: errorObj })
      }
      if (answer.start !== 0 && answer.start < 16) {
        const errorObj = { index: index, message: "under 16" }
        emptyFieldError = Object.assign({ ...emptyFieldError, start: errorObj })
      }
      if (answer.start > answer.end) {
        const errorObj = { index: index, message: "needed to be higher that starting age" }
        emptyFieldError = Object.assign({ ...emptyFieldError, end: errorObj })
      }
      if (answer.end > 99) {
        const errorObj = { index: index, message: "above 99" }
        emptyFieldError = Object.assign({ ...emptyFieldError, end: errorObj })
      }
    })
    if (openEndedQuestions.length > 1) {
      if (openEndedQuestions[openEndedQuestions.length - 1].start <= openEndedQuestions[openEndedQuestions.length - 2].end && openEndedQuestions[openEndedQuestions.length - 1].start >= 16) {
        openEndedQuestions.forEach((_answer: TODO, index: number) => {
          const errorObj = { index: index, message: "Overlapping with previous answer" }
          emptyFieldError = Object.assign({ ...emptyFieldError, start: errorObj })
        })
      }
    }
    if (Object.keys(emptyFieldError).length > 0) {
      setValidateError({ ...emptyFieldError })
      return false
    }
    return true
  }

  const handleContinueButton = () => {
    let isValid = false
    const isTypeQuestionAgeValid = selectedQuestions[`${isoLang}_${selectedMarkets[0].MarketName!.isoCode}`].find(item => item.name === "AGE")
    if (isTypeQuestionAgeValid) {
      if (!handleErrorNewObject(isTypeQuestionAgeValid?.openEndedAnswers)) {
        isValid = false
      } else {
        isValid = true
      }
    } else {
      isValid = true
    }

    const customAudienceModalData: TODO = countries.map(_market => {
      const screeningData = selectedQuestions[`${isoLang}_${selectedMarkets[0].MarketName!.isoCode}`].map((question) => !question.useAsQuotas ?
        {
          "questionId": question.id,
          "psId": question.psId ? question.psId : null,
          "name": question.name,
          "Text": question.text,
          "isInclude": question.isInclude ? true : false,
          "answers": question.answerType === 2 ? question.openEndedAnswers.map((answer: TODO) => (
            {
              "start": Number(answer.start),
              "end": Number(answer.end)
            })) : question.selectedAnswers.map(({ quotaPercent, ...rest }) => rest).filter(answer => answer.isSelected).map(answers => ({ id: answers.id, psIds: answers.psIds ? answers.psIds : null, text: answers.text }))
        } : [])
      const quotasData = selectedQuestions[`${isoLang}_${selectedMarkets[0].MarketName!.isoCode}`].map((question) => {
        const quotaGroupData =
          question.quotaGroups.flat().length && question.useAsQuotas ? question.quotaGroups.map((item) => ({
            "targetCompletes": inputVelueType === "%" ? Math.ceil((Number(item[0].quotaPercent) / 100) * Number(selectedMarkets[0].TargetNumberOfCompletes)) : Number(item[0].quotaPercent),
            "answers": item.map(({ quotaPercent, ...rest }: { quotaPercent: number }) => rest)
          })) : []
        const selectedAnswersData =
          question.selectedAnswers.length && question.useAsQuotas ? question.selectedAnswers.filter(ans => ans.isSelected).map((item) => ({
            "targetCompletes": inputVelueType === "%" ? Math.ceil((Number(item.quotaPercent) / 100) * Number(selectedMarkets[0].TargetNumberOfCompletes)) : Number(item.quotaPercent),
            "answers": [{
              "id": item.id,
              "text": item.text
            }]
          })) : []
        const openEndedAnswersData = question.openEndedAnswers && question.openEndedAnswers.length ? question.openEndedAnswers.map((answer: TODO) =>
        ({
          "targetCompletes": inputVelueType === "%" ? Math.ceil((Number(answer.quotaPercent) / 100) * Number(selectedMarkets[0].TargetNumberOfCompletes)) : Number(answer.quotaPercent),
          "answers": [
            {
              "start": Number(answer.start),
              "end": Number(answer.end)
            }]
        })) : []
        const quotasData = question.answerType === 2 && question.useAsQuotas ? openEndedAnswersData : [...quotaGroupData, ...selectedAnswersData]
        return (quotasData.length ? {
          "questionId": question.id,
          "name": question.name,
          "Text": question.text,
          "targets": quotasData.filter((item: TODO) => item.targetCompletes)
        } : [])
      })
      return ({
        screening: screeningData.flat(),
        quotas: quotasData.flat(),
        name: customAudienceModalValues.name,
        surveyType: customAudienceModalValues.surveyType,
        country: customAudienceModalValues.country?.isoCode || customAudienceModalValues.country,
        language: customAudienceModalValues.language?.isoCode || customAudienceModalValues.language,
        quantity: customAudienceModalValues.quantity,
        incidenceRate: customAudienceModalValues.incidenceRate,
        lengthOfInterview: customAudienceModalValues.lengthOfInterview,
        liveUrl: customAudienceModalValues.liveUrl,
        testUrl: customAudienceModalValues.testUrl,
        containsPersonalData: customAudienceModalValues.containsPersonalData,
        interlockedQuotas: [],
        launchDate: customAudienceModalValues.launchDate,
        targetEndDate: customAudienceModalValues.targetEndDate,
        completeAsap: customAudienceModalValues.completeAsap,
        panelType: customAudienceModalValues.panelType,
        targetType: customAudienceModalValues.targetType,
        clientCpi: customAudienceModalValues.clientCPI,
        cpi: customAudienceModalValues.cpi,
        etag: customAudienceModalValues.etag
      })
    })

    if (isValid) {
      setIsLoading(true)
      fetchPutJson(`au/a/projects/${params.name}/audiences/${params.id}`, token, customAudienceModalData[0])
        .then((res: TODO) => {
          dispatch(setAudience({ ...res }))
          handleModalVisibility({ show: false, editMode: false })
          setIsLoading(false)
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Created successfully' } })
        })
        .catch((error: TODO) => {
          setIsLoading(false)
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error.status !== 200 ? `Error creating: ${error.title}` : error.message } });
        })
        .finally(() => {
          handleModalVisibility({ show: false, editMode: false })
          setIsLoading(false)
        })
    } else {
      setShowModal(true)
    }
  }

  return <div className="cs-audience-wrapper">
    <div className="cs-audience-modal overflow-hidden">
      <div className="cs-audience-title-wrapper py-3 pr-3 pl-4 border-bottom d-flex justify-content-between align-items-center">
        <h6 className="text-primary stronger m-0">Custom audience</h6>
        <div onClick={() => handleModalVisibility({ show: false, editMode: false })}><Icon type="close" fill="#19394C" className="cursor-pointer"></Icon></div>
      </div>
      <div className="cs-audience-body position-relative ">
        {interlockedQuotasNextPage ?
          <div className=" w-100 bg-light">
            <div className="bg-white border-bottom d-flex w-100 px-3 pt-1">
              <div role="button" className=" p-2 mr-2 text-primary border-bottom border-primary border-2">
                Global
              </div>
              <div role="button" className="text-muted  p-2 mr-2">
                United Kingdom
              </div>
              <div role="button" className="text-muted  p-2 mr-2">
                Sweden
              </div>
            </div>
            <div className="p-3">
              {interlockedQuotaGroups.map((_group, index) => {
                return <div className="accordion mb-3" id={`accodrionExample${index}`} key={index}>
                  <div className="accordion-item">
                    <div className="accordion-header position-relative py-3" id="headingTwo">
                      <button className="accordion-button collapsed py-2 d-flex justify-content-between" type="button" data-bs-toggle="collapse" data-bs-target={`#main${index}`} aria-expanded="false" aria-controls={`main#${index}`}>
                        <span className="d-flex flex-column w-100-200">
                          <span className="text-primary mb-1">Interlocked quotas #{index + 1}</span>
                        </span>
                      </button>
                      <span className="d-flex align-items-center position-absolute top-50 end-0 translate-middle-y mr-5 z-index-2000">
                        <div className="form-check d-flex align-items-center text-primary form-switch mr-2">
                          <div className="cs-input-switch h-32" onClick={() => handleInterlockedQuotasValueType(interlockedQuotasValueType)}>
                            <div className={`cs-input-item d-flex justify-content-center ${interlockedQuotasValueType === "%" ? "bg-white" : ""}`}>%</div>
                            <div className={`cs-input-item d-flex justify-content-center ${interlockedQuotasValueType === "#" ? "bg-white" : ""}`}>#</div>
                          </div>
                        </div>|
                        <div onClick={() => removeInterlockedQuotaGroup(index)}>
                          <Icon type="delete-alt" fill="" className="ml-1 mr-2 cursor-pointer" />
                        </div>
                      </span>
                    </div>
                    <div id={`main${index}`} className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent={`accodrionExample${index}`}>
                      <div className="accordion-body">
                        <div className="border rounded-2 p-3 mb-4 d-flex">
                          <div className="bg-walr rounded-2 py-2 px-3 mr-2">
                            <div className="form-check">
                              <input className="form-check-input box-shadow-none" type="checkbox" value="" id="flexCheckDefault1" />
                              <label className="form-check-label " htmlFor="flexCheckDefault1">
                                Default checkbox
                              </label>
                            </div>
                          </div>
                          <div className="bg-light rounded-2 py-2 px-3">
                            <div className="form-check">
                              <input className="form-check-input box-shadow-none" type="checkbox" value="" id="flexCheckDefault2" />
                              <label className="form-check-label " htmlFor="flexCheckDefault2">
                                Default checkbox
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="d-flex align-items-center justify-content-center px-5 bg-light">
                            <p className="m-0">Gender</p>
                          </div>
                          <table className="table mb-0">
                            <thead>
                              <tr>
                                <th colSpan={4} className="border-right border-left border-light text-center bg-light">Children in family</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="border-bottom border-right w-25 border-left"></td>
                                <td className="border-bottom border-right w-25 text-end">Yes</td>
                                <td className="border-bottom border-right w-25 text-end">No</td>
                                <td className="border-bottom border-right w-25 text-end">Total</td>
                              </tr>
                              <tr>
                                <td className="border-bottom border-right w-25 border-left">Male</td>
                                <td className="border-bottom border-right w-25 text-end">25%</td>
                                <td className="border-bottom border-right w-25 text-end">30%</td>
                                <td className="border-bottom border-right w-25 text-end">100%</td>
                              </tr>
                              <tr>
                                <td className="border-bottom border-right w-25 border-left">Female</td>
                                <td className="border-bottom border-right w-25 text-end">25%</td>
                                <td className="border-bottom border-right w-25 text-end">30%</td>
                                <td className="border-bottom border-right w-25 text-end">100%</td>
                              </tr>
                              <tr>
                                <td className="border-bottom border-right w-25 border-left">Total</td>
                                <td className="border-bottom border-right w-25 text-end">25%</td>
                                <td className="border-bottom border-right w-25 text-end">30%</td>
                                <td className="border-bottom border-right w-25 text-end">100%</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              })}
              <button
                className="w-100 d-flex align-items-center justify-content-center py-2 rounded-2 border-1 border-dashed bg-transparent"
                onClick={addInterlockedQuotaGroup}>
                <Icon type="add-market" className="mr-1" /> Add market
              </button>
            </div>
          </div> :
          <>
            <div className="cs-audience-sidebar border-right overflow-hidden">
              <div className="input-group p-3">
                <input onChange={(e) => setSearchKeyword(e.target.value)} value={searchKeyword} type="search" className="form-control search-input" placeholder="Search target variables" />
              </div>
              <div className="cs-side-accordion-wrapper px-3 pb-3 pt-0 overflow-auto">
                {searchKeyword ?
                  <div className="accordion h-100" id="accordionExample">
                    <div className="accordion-item">
                      <div
                        className="mt-1 p-1">
                        {questionsArr.filter((question: TODO) => question.text.toLowerCase().includes(searchKeyword.toLowerCase()) || question.answers.find((answer: MarketData) => answer.text.toLowerCase().includes(searchKeyword.toLowerCase()))).map((data: TargetingData, index: number) =>
                          <div
                            key={index}
                            className={`d-flex flex-column accordion-body accordion-question mb-2 rounded d-flex align-items-start cursor-pointer position-relative ${selectedQuestions[tabSelected].find(question => question.id === data.id) && 'disabled'}`}
                            onClick={() => handleSelectQuestion(data.id, 'add')}>
                            <small className='text-disabled mb-1'>{data.category === "Custom" ? "Custom " : "Standard"}
                              <span><strong>{` (${data.psId ? "Pure Spectrum & " : ""}Lucid)`}</strong></span>
                            </small>
                            <span style={{ fontSize: '13px' }}>{data.text}</span>
                          </div>)}
                      </div>
                    </div>
                  </div>
                  :
                  <div className="accordion h-100" id="accordionExample">
                    {categoriesList && categoriesList.map((categoryName: string, idx: number) =>
                      <div key={idx} className="accordion-item">
                        <h2 className="accordion-header" id={`heading${idx}`}>
                          <button
                            className="accordion-button collapsed py-2"
                            type="button"
                            data-toggle="collapse"
                            data-target={`#collapse${idx}`}
                            aria-expanded="false"
                            aria-controls={`collapse${idx}`}>
                            <Icon type="audience-folder" className="mr-1" /> <span className="p-0 m-0 regular strong mt-05">{categoryName}</span>
                          </button>
                        </h2>
                        <div
                          id={`collapse${idx}`}
                          className="accordion-collapse collapse mt-1 p-1"
                          aria-labelledby={`heading${idx}`}
                          data-parent={`#collapse${idx}`}>
                          {categoriesObj[categoryName].map((data: TargetingData, index: number) =>
                            <div
                              key={index}
                              className={`accordion-body accordion-question mb-2 rounded d-flex align-items-center cursor-pointer ${selectedQuestions[tabSelected].find(question => question.id === data.id) && 'disabled'}`}
                              onClick={() => handleSelectQuestion(data.id, 'add')}>
                              <span style={{ fontSize: '13px' }}>{data.text}</span>
                            </div>)}
                        </div>
                      </div>
                    )}
                  </div>}
              </div>
            </div>
            <div className="cs-audience-main bg-white">
              <ul className="d-none nav nav-pills content-tabs h-48 flex-grow-1 pl-3 border-bottom">
                {countries.map((country: string, idx: number) =>
                  <li
                    className={`nav-link ${tabSelected === country ? 'active' : ''}`}
                    onClick={() => setTabSelected(country)}
                    key={idx} role="button">
                    {country}
                  </li>
                )}
              </ul>
              <div className={`cs-main-questions-wrapper p-3 ${didMount ? "d-flex justify-content-center align-items-center" : ""}`}>
                {didMount ? <div className="spinner-border" role="status" /> : null}
                {selectedQuestions[tabSelected].length > 0 ? selectedQuestions[tabSelected].map((question: TargetingData, idx: number) => {
                  return <div key={idx} className="accordion mb-2" id="accordionExample">
                    <div className="accordion-item bg-white">
                      <div className="accordion-header position-relative" id={`heading${idx}`}>
                        <button className="accordion-button collapsed py-2 d-flex justify-content-between" type="button" data-toggle="collapse" data-target={`#main${idx}`} aria-expanded="false" aria-controls="main">
                          <span className="d-flex flex-column w-100-200">
                            <span className="small text-indigo-dark strong mb-1">{question.name}</span>
                            <span className="large strong">{question.text}</span>

                          </span>
                        </button>
                        <span className="d-flex align-items-center position-absolute top-50 end-0 translate-middle-y mr-5 z-index-2000 accordion-header-right-side">
                          <div className="d-flex align-items-center text-primary form-switch mr-2">
                            <label className="d-flex align-items-center form-check-label medium strong">
                              <input
                                // replace class k-checkbox mr-1 with form-check-input after bootstrap version is updated
                                className="k-checkbox mr-2 cursor-pointer box-shadow-none"
                                type="checkbox"
                                role="switch"
                                id="useAsQuotas"
                                checked={question.useAsQuotas}
                                onChange={() => handleQuestionQuotaState(question.id)}
                              />
                              Use as quotas</label>
                          </div>
                          <div className='divider'></div>
                          <button className='btn btn-transparent p-1 mx-1' onClick={() => handleSelectQuestion(question.id, 'remove')}>
                            <Icon type="delete-alt" />
                          </button>
                        </span>
                      </div>
                      <div id={`main${idx}`} className="accordion-collapse collapse" aria-labelledby={`heading${idx}`} data-parent="#accordionExample">
                        <div className="accordion-body pt-0">
                          <div className="d-flex justify-content-between w-100 pb-3">
                            <div className="d-flex">
                              {!question.useAsQuotas && <div className="cs-input-switch h-32 mr-3" >
                                <div className={`cs-input-item d-flex justify-content-center ${question.isInclude ? "bg-white" : ""}`} onClick={(e) => handleIncludeExclude(e.currentTarget.innerHTML, question.id)}>Include</div>
                                <div className={`cs-input-item d-flex justify-content-center ${question.isInclude !== null && !question.isInclude ? "bg-white" : ""}`} onClick={(e) => handleIncludeExclude(e.currentTarget.innerHTML, question.id)}>Exclude</div>
                              </div>}
                              <button className="btn btn-shadow" onClick={() => handleSelectAllAnswers(question.id, tabSelected)}>Select all</button>
                            </div>
                            {question.useAsQuotas && <div className="cs-input-switch h-32" onClick={() => handleInputValueType(inputVelueType)}>
                              <div className={`cs-input-item d-flex justify-content-center ${inputVelueType === "%" ? "bg-white" : ""}`}>%</div>
                              <div className={`cs-input-item d-flex justify-content-center ${inputVelueType === "#" ? "bg-white" : ""}`}>#</div>
                            </div>}
                          </div>
                          {question.answerType === 2 ?
                            <div className="d-flex flex-column m-0">
                              {question.openEndedAnswers.map((item: TODO, idx: number) =>
                                <div key={idx} className="d-flex align-items-center m-0 mb-2">
                                  <Icon type="draggable" className="mr-1 cursor-pointer handle flex-shrink-0" />
                                  <input
                                    type="number"
                                    value={item.start}
                                    className="form-control w-64"
                                    onChange={(e) => handleEditOpenEndedAnswer(question.id, idx, 'start', e.target.value)}
                                  />
                                  <span className='mx-2'>to</span>
                                  <input
                                    type="number"
                                    value={item.end}
                                    className="form-control w-64"
                                    onChange={(e) => handleEditOpenEndedAnswer(question.id, idx, 'end', e.target.value)}
                                  />
                                  <button className='btn btn-transparent p-1 ml-2' onClick={() => handleRemoveOpenEndedAnswer(question.id, idx)}>
                                    <Icon type="delete-alt" />
                                  </button>
                                  {question.useAsQuotas && <div className="input-group max-width-85 h-100 align-items-center ml-auto">
                                    <input
                                      type="number"
                                      step={5}
                                      min={0}
                                      max={100}
                                      value={item.quotaPercent}
                                      className="form-control shadow-none  pl-2 pt-2 pb-2 pr-1 h-75"
                                      onChange={(e) => handleEditOpenEndedAnswer(question.id, idx, 'quotaPercent', e.target.value)}
                                    />
                                    <span
                                      className="input-group-text pl-2 pr-2 h-32 medium strong bg-white input-value-type"
                                      id="basic-addon1">
                                      {inputVelueType}
                                    </span>
                                  </div>}
                                </div>)}
                              <span className='cursor-pointer mt-3 text-blue-survey' onClick={() => handleAddNewResponse(question.id)}>
                                Add new response
                              </span>
                            </div>
                            :
                            question.useAsQuotas ?
                              <DragDropContext onDragEnd={onDragEnd}>
                                <div className='d-flex text-muted strong medium mb-2'>Answer text</div>
                                <StrictModeDroppable droppableId={"dropable-1"}>
                                  {(provided) => {
                                    return <div ref={provided.innerRef} {...provided.droppableProps}>
                                      {question.selectedAnswers.map((answer: Answer, index: number) => {
                                        return <Draggable draggableId={answer.id.toString()} index={index} key={index}>
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              className={`d-flex justify-content-between px-2 py-1 answer-row rounded cursor-pointer text-truncate
                                            ${snapshot.isDragging ? 'dragging' : ''}
                                            ${question.useAsQuotas && question.quotaGroups.find(group => group.length > 0 && group.find((el: TODO) => el.id === answer.id)) ? 'disabled' : ''}`}>
                                              <div className="d-flex align-items-center " onMouseDown={() => answer.isSelected && (!question.quotaGroups.find(group => group.length > 0 && group.find((el: TODO) => el.id === answer.id)) && setCurrentDraggableItem(answer))}>
                                                {question.useAsQuotas && <Icon type="draggable" className="mr-1 cursor-pointer handle" />}
                                                <div className="d-flex align-items-center m-0">
                                                  <label className="d-flex form-check-label ml-1 ">
                                                    <input
                                                      // replace class k-checkbox with form-check-input after bootstrap version is updated
                                                      className="k-checkbox mb-0 mr-2 box-shadow-none"
                                                      type="checkbox"
                                                      checked={answer.isSelected}
                                                      disabled={question.quotaGroups.find(group => group.length > 0 && group.find((el: TODO) => el.id === answer.id))}
                                                      onChange={(e) => { handleSelectAnswer(e, question.id, answer) }} />
                                                    <span className='answer-text'>{answer.text}</span>
                                                  </label>
                                                </div>
                                              </div>
                                              {question.useAsQuotas && <div className="input-group max-width-85 h-100 align-items-center">
                                                <input
                                                  type="number"
                                                  step={5}
                                                  min={0}
                                                  max={100}
                                                  value={question.selectedAnswers.find(item => item.text === answer.text)?.quotaPercent ?
                                                    question.selectedAnswers.find(item => item.text === answer.text)?.quotaPercent
                                                    : ""
                                                  }
                                                  disabled={!answer.isSelected}
                                                  className="form-control shadow-none  pl-2 pt-2 pb-2 pr-1 h-75"
                                                  onChange={(e) => handleQuotaRoundNumber(e.target.value.slice(0, 3), question.id, answer.id, false)}
                                                />
                                                <span
                                                  className="input-group-text pl-2 pr-2 h-32 medium strong bg-white input-value-type"
                                                  id="basic-addon1">
                                                  {inputVelueType}
                                                </span>
                                              </div>}
                                            </div>
                                          )}
                                        </Draggable>
                                      })}
                                      {provided.placeholder}
                                    </div>
                                  }}
                                </StrictModeDroppable>
                                <div className={`cs-new-group-wrapper ${question.useAsQuotas ? "h-auto mt-3" : "h-0"}`}>
                                  <button
                                    className="btn p-0 text-analyze strong mb-3"
                                    onClick={() => addQuotaGroup(question.id)}>
                                    Add new group
                                  </button>
                                  {question.quotaGroups.length > 0 && question.quotaGroups.map((group, index) => {
                                    return <StrictModeDroppable droppableId={`questionGroup-${question.id}-${index}`} key={index} >
                                      {(provided) => {
                                        return <div className="card mb-3" ref={provided.innerRef} {...provided.droppableProps}>
                                          <div className="card-header d-flex align-items-center justify-content-between px-4 py-2 bg-white">
                                            <div className='strong text-primary'>Group {index + 1}</div>
                                            <div className="d-flex">
                                              <div className="input-group max-width-90">
                                                <input onChange={(e) => handleQuotaRoundNumber(e.target.value.slice(0, 3), question.id, index, true)} type="number" className="form-control form-control-sm shadow-none h-100" placeholder="" />
                                                <span className="input-group-text pl-2 pr-2 h-100 h-32 medium strong bg-white input-value-type" id="basic-addon1">{inputVelueType}</span>
                                              </div>
                                              <div className="btn-group ml-2">
                                                <button type="button" className="btn btn-transparent p-1" onClick={() => removeQuotaGroup(question.id, index)}><Icon type="delete-alt" /></button>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="card-body d-flex flex-wrap p-4">
                                            {group.length <= 0 ?
                                              <div className="cs-dashed-box">
                                                <p className="text-muted m-0">Drag question answers here to add to group</p>
                                              </div>
                                              :
                                              group.map((answer: Answer) =>
                                                <div
                                                  className="btn py-1 px-2 bg-walr cursor-default text-primary mr-2 mb-2 d-flex align-items-center justify-content-lg-center"
                                                  key={answer.id}>
                                                  <span className="text-start">{answer.text}</span>
                                                  <span
                                                    className="ml-1 text-primary cursor-pointer"
                                                    onClick={() => handleQuotaGroupAnswerDelete(question.id, answer.id, index)}>
                                                    <Icon type="close" /></span>
                                                </div>
                                              )
                                            }
                                          </div>
                                          {provided.placeholder}
                                        </div>
                                      }}
                                    </StrictModeDroppable>
                                  })}
                                </div>
                              </DragDropContext> :
                              <>
                                {question.selectedAnswers && question.selectedAnswers.length && question.selectedAnswers.map((answer: Answer) => {
                                  return <div className="d-flex justify-content-between p-2 answer-row rounded cursor-pointer" key={answer.id}>
                                    <div className="d-flex align-items-center w-100">
                                      {question.useAsQuotas && <Icon type="draggable" className="mr-2 cursor-pointer" />}
                                      <div className="d-flex align-items-center w-100 m-0">
                                        <label className="d-flex align-items-center form-check-label w-100 ml-1 ">
                                          <input
                                            // replace class k-checkbox with form-check-input after bootstrap version is updated
                                            className="k-checkbox mb-0 mr-2 box-shadow-none"
                                            type="checkbox"
                                            checked={answer.isSelected}
                                            onChange={(e) => { handleSelectAnswer(e, question.id, answer) }
                                            } />
                                          {answer.text}
                                        </label>
                                      </div>
                                    </div>
                                    {question.useAsQuotas && <div className="input-group h-100 max-width-85 align-items-center">
                                      <input type="number" className="form-control shadow-none  pl-2 pt-2 pb-2 pr-1 h-75" placeholder="" />
                                      <span className="input-group-text pl-2 pr-2 h-75 " id="basic-addon1">{inputVelueType}</span>
                                    </div>}
                                  </div>
                                })}
                              </>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                }) : !didMount ? <div className='empty text-muted d-flex align-items-center justify-content-center p-4'>Drag and drop target variables here</div> : null}
              </div>
            </div>
            <div className="cs-audience-sidebar border-left py-3 px-0 d-flex flex-column justify-content-between overflow-auto">
              <div>
                <div className='border-bottom mb-3 px-3'>
                  <p className=" mb-3 stronger text-primary">Screening</p>
                  <div>
                    {selectedQuestions[tabSelected].map((question, idx) => {
                      if (question.answerType === 2 && !question.useAsQuotas) {
                        return <div className='mb-2' key={idx}>
                          <p className="strong medium mb-1">{question.text}</p>
                          <p className="text-primary">
                            {question.openEndedAnswers.map((answer: TODO, index: number) =>
                              <span className='screening-badge text-truncate mr-1' key={index}>{answer.start} to {answer.end}{question.openEndedAnswers[index + 1] && ''}</span>
                            )}
                          </p>
                        </div>
                      }
                      if (!question.useAsQuotas && question.selectedAnswers.length > 0) {
                        return <div className='mb-2' key={idx}>
                          <p className="strong medium mb-1">{question.text}</p>
                          <p className="text-primary">
                            {question.selectedAnswers.map((answer, index) =>
                              answer.isSelected && <span className='screening-badge text-truncate mr-1' key={index}>{answer.text}{question.selectedAnswers[index + 1] && ''}</span>
                            )}
                          </p>
                        </div>
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
                <div className='mb-3 px-3'>
                  <p className=" mb-3 stronger text-primary">Quotas</p>
                  <div>
                    {selectedQuestions[tabSelected].map((question, idx) => {
                      if (question.answerType === 2 && question.useAsQuotas) {
                        return <div className='mb-2' key={idx}>
                          <p className="strong medium mb-1">{question.text}</p>
                          {question.openEndedAnswers.length > 0 && question.openEndedAnswers.map((answer: TODO, index: number) =>
                            <span key={index} className='text-truncate screening-badge mr-1'>
                              <span>{answer.start} to {answer.end}</span>
                              {answer.quotaPercent && <span className='ml-1'>{answer.quotaPercent}{inputVelueType}</span>}
                            </span>)}
                        </div>
                      }
                      if (question.useAsQuotas && (question.quotaGroups.length > 0 || question.selectedAnswers.length > 0)) {
                        return <div className='mb-2' key={idx}>
                          <p className="strong medium mb-1">{question.text}</p>
                          {question.selectedAnswers.length > 0 && question.selectedAnswers.map((answer, index) =>
                            answer.isSelected &&
                            <span key={index} className='text-truncate screening-badge mr-1'>
                              <span>{answer.text}</span>
                              {answer.quotaPercent && <span className='ml-1'>{answer.quotaPercent}{inputVelueType}</span>}
                            </span>)}
                          {question.quotaGroups.length > 0 && question.quotaGroups.map((quotaGroup, index) =>
                            <p key={index} className="text-primary">
                              {quotaGroup.map((answer: Answer, idx: number) => idx < 1 &&
                                <span className='screening-badge text-truncate mr-1' key={idx}>{answer.text}</span>)}
                              {quotaGroup.length > 1 &&
                                <span
                                  className='cs-audience-tooltip'>
                                  +{quotaGroup.length - 1}
                                  <span
                                    className='cs-audience-tooltip-message'>
                                    {quotaGroup.map((answer: Answer, idx: number) => idx > 0 ? <span key={idx}>{answer.text}{quotaGroup[idx + 1] && ', '}</span> : null)}
                                  </span>
                                </span>}
                              {quotaGroup[index] && quotaGroup[index].quotaPercent && <span className='ml-1'>{quotaGroup[index].quotaPercent}{inputVelueType}</span>}
                            </p>)}
                        </div>
                      } else {
                        return null
                      }
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
      <div className="cs-audience-footer p-3 border-top">
        {interlockedQuotasNextPage &&
          <button type="button" onClick={() => handleInterlockedQuotas("Back")} className="btn btn-transparent border mr-2 ">
            Back
          </button>
        }
        <button type="button" className="btn btn-primary strong" onClick={() => interlockedQuotas ? handleInterlockedQuotas("Next") : handleContinueButton()}>Continue
          {
            isLoading &&
            <>
              <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true"></span>
            </>
          }
        </button>
      </div>
    </div >
    {showModal && <ErrorModal onHide={() => setShowModal(false)} message={validateError} />}
  </div >;
};
