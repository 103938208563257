export const surveyToolsIcons = ["editor", "logic", "themes", "languages", "settings-cog", "custom-questions", "survey-xml"] as const;
export type surveyToolsIconsType = (typeof surveyToolsIcons)[number];

type SurveyTools = {
  title: string;
  id: number;
  icon: surveyToolsIconsType
}

export const surveyTools: SurveyTools[] = [
  {
    title: "Editor",
    id: 0,
    icon: "editor",
  },
  {
    title: "Logic",
    id: 1,
    icon: "logic",
  },
  {
    title: "Themes",
    id: 3,
    icon: "themes"
  },
  {
    title: "Languages",
    id: 4,
    icon: "languages"
  },
  {
    title: "Settings",
    id: 5,
    icon: "settings-cog"
  },
  {
    title: "Custom",
    id: 6,
    icon: "custom-questions"
  },
  {
    title: "XML",
    id: 7,
    icon: "survey-xml"
  }
]