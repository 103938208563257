import { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';
//
import { DropDownButton } from '@progress/kendo-react-buttons';
import { moreVerticalIcon } from '@progress/kendo-svg-icons';
//
import { Field } from 'components/shared/hook-form';
import { useQuestionEditor } from '../../contexts/QuestionEditorContext';
import type { EditableQuestion } from '../../types';
import { useEditorActions } from '../../hooks/useEditorActions';

// -------------------------------------------------------------------------

const ContentToolbar = () => {
  const formData = useWatch<EditableQuestion>();
  const { currentContext } = useQuestionEditor();

  const { actions: allActions, executeAction } =
    useEditorActions(currentContext);

  const actions = useMemo(
    () =>
      allActions
        .filter(action => !action.isVisible || action.isVisible())
        .map(action => ({
          id: action.id,
          label: action.label,
          disabled: action.isDisabled?.(),
          iconClass: action.icon,
        })),
    [allActions],
  );

  const handleActionClick = useCallback(
    (action: any) => {
      executeAction(action.id);
    },
    [executeAction],
  );

  return (
    <div className="d-flex flex-row gap-md align-items-center">
      <div>
        <Field.Switch
          name="swapRowsCols"
          label="Swap Rows/Columns"
          size="small"
          disabled={!formData?.subGroups?.length}
        />
      </div>

      <div>
        <Field.Switch
          name="summaryValuesOnly"
          label="Show summary values only"
          size="small"
        />
      </div>

      {/* Actions Dropdown */}
      <DropDownButton
        textField="label"
        svgIcon={moreVerticalIcon}
        items={actions}
        onItemClick={e => handleActionClick(e.item)}
        className="text-analyze"
      />
    </div>
  );
};

export default ContentToolbar;
