import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import type { RootState } from "../../../../store/reducers/rootReducer";
import { ErrorMessage } from "../../../shared/ErrorMessage/ErrorMessage";
import { useFetchTags } from "../../../shared/customHooks/useFetchTags";
import { WorkflowToolInputTabContent } from "../WorkflowToolInputTabContent/WorkflowToolInputTabContent";
import { WorkflowToolOutputTabContent } from "../WorkflowToolOutputTabContent/WorkflowToolOutputTabContent";
import { InputModal } from "../../WorkflowTabContent/WorkflowDesignTabContent/helpers/InputModal/InputModal";
import { WorkflowToolGeneralTabContent } from "../WorkflowToolGeneralTabContent/WorkflowToolGeneralTabContent";
import type { FilterDataObject, ToolInfo, ToolReference } from "../../../../interfaces/workflowInterfaces/toolInterfaces";
import OptionsFilterBuilder from "../../../Analysis/Analyze/components/dashboard/main/OptionsFilterBuilder/OptionsFilterBuilder";
import { fetchPostJson as createTool, fetchPatchJson, fetchPutJson as updateTool } from "../../../../services/services";

interface Props {
  projectId: string,
  workflowId: string,
  token: string
  handleClose: () => void,
  updateData: () => void
  editMode: boolean
}

export const AddNewToolModal = ({ handleClose, token, projectId, workflowId, updateData, editMode }: Props) => {
  const { workflowData } = useSelector((theState: RootState) => theState.workflowStateReducer);
  const toolsNamesArr = workflowData.data?.toolReferences.length ?
    editMode && workflowData.selectedTool ? workflowData.data.toolReferences.filter((tool: ToolReference) => tool.referenceId !== workflowData.selectedTool.id).map((data: ToolReference) => data.referenceDisplayName) :
      workflowData.data.toolReferences.map((data: ToolReference) => data.referenceDisplayName) : [];
  const [toolData, setToolData] = useState<ToolInfo>({
    name: '', description: '', tags: [], toolType: '',
    output: { save: true, projectId: '', projectName: '', name: '', description: '' },
    input: {
      projectId: '',
      projectName: '',
      datasetId: '',
      datasetName: '',
      metadata: '',
      usePreFilter: true,
      preFilter: '',
      inputIsSurveyDataset: false,
      fromPreviousTool: false
    },
    inputA: {
      projectId: '',
      projectName: '',
      datasetId: '',
      datasetName: '',
      metadata: '',
      usePreFilter: true,
      preFilter: '',
      inputIsSurveyDataset: false,
      fromPreviousTool: false
    },
    inputB: {
      projectId: '',
      projectName: '',
      datasetId: '',
      datasetName: '',
      metadata: '',
      usePreFilter: true,
      preFilter: '',
      inputIsSurveyDataset: false,
      fromPreviousTool: false
    }
  })
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const tags = useFetchTags(token)
  const [showInputModal, setShowInputModal] = useState<{ show: boolean, type: 'input' | 'output' | null }>({
    show: false,
    type: null
  })
  const [showFilterBuilder, setShowFilterBuilder] = useState<boolean>(false)
  const [inputPath, setInputPath] = useState<{ input: string, inputA: string, inputB: string }>({
    input: '',
    inputA: '',
    inputB: ''
  })
  const [outputPath, setOutputPath] = useState<string>('')
  const [tabSelected, setTabSelected] = useState<string>('General')
  const [dataset, setDataset] = useState<'input' | 'inputA' | 'inputB'>('input')
  const [shouldUpdateMatchMap, setShouldUpdateMatchMap] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<FilterDataObject>({
    input: { languages: [], questions: [], defaultLanguage: '', cases: 0 },
    inputA: { languages: [], questions: [], defaultLanguage: '', cases: 0 },
    inputB: { languages: [], questions: [], defaultLanguage: '', cases: 0 }
  })
  const isToolDisabled = editMode && workflowData && workflowData.selectedTool && workflowData.selectedTool.isDisabled

  const dispatch = useDispatch()

  const handleUpdateToolData = (k: string, v: TODO) => {
    if (editMode) {
      dispatch({ type: 'UPDATE_TOOL', payload: { ...workflowData.selectedTool, [k]: v } })
    } else {
      setToolData(prevState => ({
        ...prevState,
        [k]: v
      }))
    }
  }

  const manuallyUpdateFilter = (k: string, v: TODO) => {
    if (editMode) {
      const updatedTool = JSON.parse(JSON.stringify(workflowData.selectedTool))
      updatedTool[k] = { ...updatedTool[k], ...v }
      dispatch({ type: 'UPDATE_TOOL', payload: updatedTool })
    } else {
      const updatedTool = JSON.parse(JSON.stringify(toolData))
      updatedTool[k] = { ...updatedTool[k], ...v }
      setToolData(updatedTool)
    }
  }

  const onCreateTool = () => {
    const isToolNameTaken = toolsNamesArr.length && toolsNamesArr.find((toolName: string) => !editMode ? toolName === toolData.name : (workflowData.selectedTool && toolName === workflowData.selectedTool.name))
    if (editMode ? workflowData.selectedTool?.name === '' : toolData.name === '') {
      setError('Name is required')
    } else if (toolData.toolType === "Coding" && !toolData.input?.datasetId) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Coding tool requires input dataset" } })
    } else if (editMode ? workflowData.selectedTool?.toolType === '' : toolData.toolType === '') {
      setError('Tool type is required')
    } else if (isToolNameTaken) {
      setError('A tool with this name already exists')
    } else {
      setIsLoading(true)
      if (editMode) {
        const body = workflowData.selectedTool
        if (shouldUpdateMatchMap) { body.matchMap = null }
        updateTool(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${workflowData.selectedTool.id}`, token, body)
          .then((updateRes: TODO) => {
            setIsLoading(false)
            if (updateRes && !updateRes.error && !updateRes.message) {
              dispatch({ type: 'LOADING_TOOL', payload: 'load' });
              dispatch({ type: 'SET_TOOL_SELECTED', payload: updateRes })
              handleClose()
            } else {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occured: ${updateRes.error ? updateRes.error : updateRes.message}` } })
            }
          })
      } else {
        const { input, inputA, inputB, output, ...body } = toolData;
        createTool(`projects/${projectId}/workflows/${workflowId}/tools`, token, body)
          .then((createRes: TODO) => {
            if (createRes.error || createRes.message) {
              setIsLoading(false)
              setError(createRes.error ? createRes.error : createRes.message)
            } else {
              const tool = createRes.tool
              const data = (toolData.toolType !== 'CleanAndDefine' && toolData.toolType !== 'Coding') ? { ...tool, inputA, inputB, output } : { ...tool, input, output }
              updateTool(`projects/${workflowData.data.projectId}/workflows/${workflowData.data.id}/tools/${tool.id}`, token, data)
                .then((updateRes: TODO) => {
                  if (updateRes && !updateRes.error && !updateRes.message) {
                    if (updateRes.toolType === "Coding") {
                      // Coding tool requires extra initialization during creation
                      fetchPatchJson(`projects/${updateRes.projectId}/workflows/${updateRes.workflowId}/tools/${updateRes.id}/init`, token)
                        .then((res: TODO) => res.json(res))
                        .then((initRes: TODO) => {
                          if (initRes && !initRes.error && !initRes.message) {
                            dispatch({ type: 'SET_TOOL_SELECTED', payload: initRes })
                            dispatch({ type: 'LOADING_WORKFLOW_DATA' })
                            handleClose()
                            updateData()
                          } else {
                            setIsLoading(false)
                            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occurred: ${initRes.error ? initRes.error : initRes.message}` } })
                          }
                        })
                    } else {
                      dispatch({ type: 'SET_TOOL_SELECTED', payload: updateRes })
                      dispatch({ type: 'LOADING_WORKFLOW_DATA' })
                      handleClose()
                      updateData()
                    }
                  } else {
                    setIsLoading(false)
                    dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `An error has occurred: ${updateRes.error ? updateRes.error : updateRes.message}` } })
                  }
                })
            }
          })
      }
    }
  }

  const onSaveInput = (inputData: TODO, projectValue: TODO, documentValue: TODO) => {
    if (showInputModal.type === 'input') {
      handleUpdateToolData(dataset, inputData)
      setInputPath(prevState => ({ ...prevState, [dataset]: `${projectValue.name}/${documentValue ? documentValue.name : ''}` }))
    }
    else {
      handleUpdateToolData('output', { ...(editMode ? workflowData.selectedTool.output : toolData.output), projectId: inputData.projectId, projectName: projectValue.name })
      setOutputPath(`${projectValue.name}`)
    }
    setShowInputModal(prevState => ({
      ...prevState,
      show: false
    }))
    setShouldUpdateMatchMap(true)
  }

  const onSaveFilterOptions = (data: TODO, expression: TODO) => {
    handleUpdateToolData(dataset, { ...(editMode ? workflowData.selectedTool[dataset] : toolData[dataset]), usePreFilter: true, preFilter: expression, metadata: { filterData: data } })
    setShowFilterBuilder(false)
  }

  return (
    <Dialog contentStyle={{ padding: '0px' }} title={`${editMode ? 'Edit' : 'Add new'} tool`} width={600} height={600} className="do-dialog" onClose={handleClose}>
      {showInputModal.show &&
        <InputModal
          form-control handleClose={() => setShowInputModal(prevState => ({
            ...prevState,
            show: false
          }))}
          token={token}
          input={dataset && editMode ? showInputModal.type === 'output' ? workflowData.selectedTool.output : workflowData.selectedTool[dataset] :
            showInputModal.type === 'output' ? toolData.output : toolData[dataset]}
          outputId={editMode ? workflowData.selectedTool.output.projectId : toolData.output.projectId}
          onSaveInput={onSaveInput}
          type={showInputModal.type}
          currentProjectId={workflowData.data.projectId}
        />
      }
      {showFilterBuilder &&
        <OptionsFilterBuilder
          handleClose={() => setShowFilterBuilder(false)}
          filterData={filterData[dataset]}
          manualFilterExpression={editMode ? workflowData.selectedTool[dataset].preFilter : toolData[dataset]?.preFilter}
          userData={null}
          setFilterData={(data: TODO) => setFilterData(prevState => ({ ...prevState, [dataset]: data }))}
          datasetId={editMode ? workflowData.selectedTool[dataset]?.datasetId : toolData[dataset]?.datasetId}
          projectId={editMode ? workflowData.selectedTool[dataset]?.projectId : toolData[dataset]?.projectId}
          isSurveyDataset={toolData[dataset]?.inputIsSurveyDataset || (workflowData.selectedTool ? workflowData.selectedTool[dataset]?.inputIsSurveyDataset : false)}
          onSaveFilterOptions={onSaveFilterOptions}
          combineFilterData={editMode ?
            workflowData.selectedTool[dataset].metadata?.filterData ? workflowData.selectedTool[dataset].metadata.filterData : []
            :
            toolData[dataset]?.metadata?.filterData ? toolData[dataset]?.metadata.filterData : []}
          option={"preFilter"}
          analysisType={editMode ? workflowData.selectedTool[dataset].inputIsSurveyDataset ? 'workflowSurvey' : 'workflowDataset' : toolData[dataset]?.inputIsSurveyDataset ? 'workflowSurvey' : 'workflowDataset'}
          token={token}
          workflowProjectId={editMode ? workflowData.selectedTool[dataset].projectId : toolData[dataset]?.projectId}
        />
      }
      <div className="row no-gutters">
        <ul className="h-auto nav nav-pills content-tabs flex-grow-1 border-bottom px-2">
          <li onClick={() => setTabSelected('General')} className="nav-item">
            <span className={`nav-link ${tabSelected === 'General' && 'active'}`}>General</span>
          </li>
          <li onClick={() => setTabSelected('input')} className="nav-item" style={!editMode && !toolData.toolType ? { pointerEvents: 'none', opacity: '50%' } : undefined}>
            <span className={`nav-link ${tabSelected === 'input' && 'active'}`}>Input</span>
          </li>
          <li onClick={() => setTabSelected('Output')} className="nav-item">
            <span className={`nav-link ${tabSelected === 'Output' && 'active'}`}>Output</span>
          </li>
        </ul>
        <div style={isToolDisabled ? { pointerEvents: 'none', opacity: '50%' } : undefined} className="w-100 d-flex flex-column h-100">
          <div className="row no-gutters px-4 pt-4 h-100 w-100">
            <div className="d-flex flex-column w-100">
              {
                tabSelected === 'General' &&
                <WorkflowToolGeneralTabContent
                  tags={tags}
                  editMode={editMode}
                  toolData={toolData}
                  handleUpdateToolData={handleUpdateToolData}
                />
              }
              {
                tabSelected === 'input' &&
                <WorkflowToolInputTabContent
                  editMode={editMode}
                  workflowData={workflowData}
                  toolData={toolData}
                  inputPath={inputPath}
                  manuallyUpdateFilter={(dataset: string, val: string) => manuallyUpdateFilter(dataset, { usePreFilter: true, preFilter: val })}
                  setDataset={(val) => setDataset(val)}
                  setShowInputModal={(val) => setShowInputModal(val)}
                  setShowFilterBuilder={(val) => setShowFilterBuilder(val)}
                />
              }
              {tabSelected === 'Output' &&
                <WorkflowToolOutputTabContent
                  editMode={editMode}
                  workflowData={workflowData}
                  toolData={toolData}
                  outputPath={outputPath}
                  handleUpdateToolData={handleUpdateToolData}
                  setShowInputModal={(val) => setShowInputModal(val)}
                />
              }
              {
                error !== null &&
                <ErrorMessage
                  onHide={() => setError(null)}
                  type="alert"
                  errorMessage={error}
                />
              }
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <button type="button" className="k-button btn btn-secondary" onClick={handleClose}>Cancel</button>
        <button type="button" disabled={isLoading} className="k-button btn btn-primary" onClick={() => onCreateTool()}>
          {isLoading && <span className="spinner-border spinner-border-sm mr-2" />}
          <span>{editMode ? 'Update' : 'Create'}</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}
