import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

/*
  Cursor jumps in React controlled inputs is a common issue. This is because the cursor position is reset every time the input value changes.
  This can be fixed by maintaining the cursor position and setting it back after the input value changes.
  https://giacomocerquone.com/blog/keep-input-cursor-still/
*/

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CustomInput = ({ value, onChange, ...props }: CustomInputProps) => {
  const [cursor, setCursor] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.setSelectionRange(cursor, cursor);
  }, [inputRef, cursor, value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCursor(e.target.selectionStart);
    onChange?.(e);
  };

  return (
    <input ref={inputRef} value={value} onChange={handleChange} {...props} />
  );
};

export default CustomInput;
