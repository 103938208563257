
import { Icon } from 'components/shared/Icon/Icon';
import type { ItemType} from '../../../../../shared/helpers/returnQuestionIcon/returnQuestionIcon';
import { returnQuestionIcon } from '../../../../../shared/helpers/returnQuestionIcon/returnQuestionIcon';

// ---------------------------------------------------------------------------------

type IconSize = 'sm' | 'md' | 'lg' | 'xl';

type QuestionIconProps = {
  type: ItemType;
  size?: IconSize;
  className?: string;
  style?: React.CSSProperties;
};

const QuestionIcon = ({ type, style, ...rest }: QuestionIconProps) => {
  const iconType = returnQuestionIcon(type);

  const styles = {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Icon
      type={iconType}
      style={{ ...styles, ...style }}
      {...rest}
    />
  );
};

export default QuestionIcon;
