import { useMemo } from 'react';
import { v4 as uuid } from 'uuid';
//
import { cellsMergeHorizontallyIcon, sortAscIcon } from '@progress/kendo-svg-icons';

//
import { useQuestionEditor } from '../contexts/QuestionEditorContext';
import type { QuestionGroup } from '../../../types';
import { ActionContext } from '../config/actions';
import { useContextForm } from './useContextFormData';
import { useCopyPasteEdits } from '../copy-paste-edits/useCopyPasteEdits';

// -------------------------------------------------------------------------

/**
 * Sets weight values for the selected context (groups or subGroups)
 * For non-merged rows, sets weight to the numeric code value
 * For merged rows, preserves existing weight value
 */
const setWeightValues = (data: QuestionGroup[] = []) => {
  const parseValue = (val: string) => {
    const num = Number(val);
    return isNaN(num) ? 0 : num;
  };

  const updatedContext = data.map(row => ({
    ...row,
    weightValue: row.merged ? row.weightValue : parseValue(row.code.toString()),
  }));

  return updatedContext;
};

/**
 * Merges selected rows in the current context (groups or subGroups)
 * Deselects and deactivates the merged rows
 * TODO: Implement actual merge logic using returnMergeRowGroupsData
 */
const mergeRows = (data: QuestionGroup[] = [], formatCodeAsText = false) => {
  const selected = data.filter(x => x.selected);
  if (selected.length <= 1) return;

  const toMerge = [] as {
    code: string | number;
    text: string;
    index: number;
  }[];

  const updatedContext = data.map((x, index) => {
    if (x.selected) {
      toMerge.push({ code: x.code, text: x.text, index });
      return {
        ...x,
        selected: false,
        active: false,
      };
    }
    return x;
  });

  // check code type and covert it to number if it is a string
  const code = toMerge.map(x => x.code);

  const mergedRow = {
    selected: false,
    active: true,
    id: uuid(),
    code: formatCodeAsText ? code.join(';') : Number(code[code.length - 1]),
    text: toMerge.map(x => x.text).join(' & '),
    merged: true,
  } as QuestionGroup;

  // insert mergeRow into the list at the index of the last selected row
  const lastSelectedIndex = Math.max(...toMerge.map(x => x.index));
  updatedContext.splice(lastSelectedIndex + 1, 0, mergedRow);

  return updatedContext;
};

// -------------------------------------------------------------------------

export const useDefaultActions = () => {
  const { currentContext, updateSelected } = useQuestionEditor();
  const { copyEdits, canPaste, pasteEdits } = useCopyPasteEdits();

  const { formData } = useContextForm(currentContext);

  const defaultActions = useMemo(() => {
    const selected = formData.filter(x => x.selected);

    return [
      {
        id: 'merge-rows',
        label: 'Merge rows',
        svgIcon: cellsMergeHorizontallyIcon,
        context: [ActionContext.ROWS, ActionContext.COLUMNS],
        isDisabled: () => selected.length <= 1,
        handler: () => {
          const updadatedContext = mergeRows(formData, currentContext === ActionContext.ROWS);
          updateSelected({ [currentContext]: updadatedContext });
        },
        isVisible: () => false,
        order: 1,
      },
      {
        id: 'set-weight-values',
        label: 'Set scaling factor',
        svgIcon: sortAscIcon,
        context: [ActionContext.ROWS, ActionContext.COLUMNS],
        isDisabled: () => false,
        handler: () => {
          const updadatedContext = setWeightValues(formData);
          updateSelected({ [currentContext]: updadatedContext });
        },
        isVisible: () => false,
        order: 2,
      },
      {
        id: 'edits-copy',
        label: 'Copy edits',
        icon: 'fa-light fa-copy',
        context: [ActionContext.ROWS, ActionContext.COLUMNS],
        isDisabled: () => false,
        handler: () => copyEdits(),
        order: 3,
      },
      {
        id: 'edits-paste',
        label: 'Paste edits',
        icon: 'fa-light fa-paste',
        context: [ActionContext.ROWS, ActionContext.COLUMNS],
        isDisabled: () => !canPaste,
        handler: () => pasteEdits(),
        order: 4,
      },
    ];
  }, [
    copyEdits,
    currentContext,
    formData,
    pasteEdits,
    updateSelected,
    canPaste,
  ]);

  return {
    actions: defaultActions,
  };
};
