import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

interface Props {
  onClose: () => void
  onHide: () => void
  discardUnsavedChanges: () => void
}

export const UnsavedChangesModal = ({ onClose, onHide, discardUnsavedChanges }: Props) => {
  const closeBoth = () => {
    onHide()
    onClose()
    discardUnsavedChanges()
  }

  return (
    <Dialog title="Advanced options" onClose={onHide} width={380}>
      <div className="p-5">
        <p className={`mb-0`}>You have unsaved changes. Are you sure you would like to close this window?</p>
      </div>
      <DialogActionsBar>
        <button type="button" className="k-button btn btn-secondary" onClick={onHide}>Go back</button>
        <button onClick={closeBoth} type="button" className="k-button btn btn-primary">
          <span>Close</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}
