import { SurveyThemesTool } from './SurveyThemesTool/SurveyThemesTool';
import { SurveyDesignEditorTool } from './SurveyDesignEditorTool/SurveyDesignEditorTool';
import { SurveyDesignSettingsTool } from './SurveyDesignSettingsTool/SurveyDesignSettingsTool';
import { SurveyDesignTranslationsTool } from './SurveyDesignTranslationsTool/SurveyDesignTranslationsTool';
import { SurveyCustomTabContent } from '../../SurveyCustomTabContent/SurveyCustomTabContent';
import SurveyDesignXMLTool from './SurveyDesignXMLTool/SurveyDesignXMLTool';

interface Props {
  toolSelected: number
  updateElements: () => void
  onCheckQuestion: () => void
  setQuestionSelected: () => void
  setToolSelected: (id: number) => void
}

export const SurveyDesignTools = ({ toolSelected, updateElements, setQuestionSelected, setToolSelected }: Props) => {
  switch (toolSelected) {
    case 0:
    case 1:
    case 2:
      return <SurveyDesignEditorTool
        toolSelected={toolSelected}
        setToolSelected={setToolSelected}
        updateElements={updateElements}
      />
    case 3:
      return <SurveyThemesTool
        updateElements={updateElements}
        setQuestionSelected={setQuestionSelected}
      />
    case 4:
      return <SurveyDesignTranslationsTool />
    case 5:
      return <SurveyDesignSettingsTool />
    case 6:
      return <SurveyCustomTabContent />
    case 7:
      return <SurveyDesignXMLTool />
    default:
      return null
  }
}