import { useCallback } from 'react';
//
import { useAppDispatch } from 'app/hooks';
import { getErrorMessage } from 'app/api/baseApi';
import { useCustomRowsData } from './useCustomRowsData';
import { useCustomRowsSideFffects } from './useCustomRowsSideFffects';
import { useNotifications } from '../../../../hooks/useNotifications';
import type { CustomRow } from '../types';

// -----------------------------------------------------------------------------

export const useCustomRowsActionHandlers = () => {
  const dispatch = useAppDispatch();
  const { saveCustomRow, deleteCustomRow, isLoading } = useCustomRowsData();
  const notify = useNotifications();

  const { runSideEffect } = useCustomRowsSideFffects('crosstab');

  const handleError = useCallback(
    (error: unknown) => {
      const errorMessage = getErrorMessage(error);
      notify.error(errorMessage);

      return errorMessage;
    },
    [notify],
  );

  const handleCreate = useCallback(
    async (data: CustomRow) => {
      try {
        const response = await saveCustomRow(data);
        dispatch({ type: 'ADD_CUSTOM_ROW_TO_STRUCTURE', payload: response });
        runSideEffect('add', { data: response });
        return response;
      } catch (error) {
        const errorMessage = handleError(error);
        throw new Error(errorMessage);
      }
    },
    [dispatch, handleError, runSideEffect, saveCustomRow],
  );

  const handleUpdate = useCallback(
    async (data: CustomRow) => {
      try {
        const response = await saveCustomRow(data);
        dispatch({
          type: 'UPDATE_CUSTOM_ROW_IN_STRUCTURE',
          payload: { data: response, id: data.id },
        });
        runSideEffect('edit', { data: response, id: data.id });
        return response;
      } catch (error) {
        const errorMessage = handleError(error);
        throw new Error(errorMessage);
      }
    },
    [dispatch, handleError, runSideEffect, saveCustomRow],
  );

  const handleDelete = useCallback(
    async (data: CustomRow) => {
      try {
        const response = await deleteCustomRow(data.id!);
        dispatch({
          type: 'DELETE_CUSTOM_ROW_FROM_STRUCTURE',
          payload: response,
        });

        runSideEffect('delete', { data: response });
        return response;
      } catch (error) {
        const errorMessage = handleError(error);
        throw new Error(errorMessage);
      }
    },
    [deleteCustomRow, dispatch, handleError, runSideEffect],
  );

  const executeAction = useCallback(
    (action: 'add' | 'edit' | 'delete', data: CustomRow) => async () => {
      switch (action) {
        case 'add':
          return handleCreate(data);
        case 'edit':
          return handleUpdate(data);
        case 'delete':
          return handleDelete(data);
        default:
          throw new Error(`Unknown action: ${action}`);
      }
    },
    [handleCreate, handleDelete, handleUpdate],
  );

  return {
    executeAction,
    isLoading,
  };
};
