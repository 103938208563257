export default (selectedGroup, user, groups) => {
  if (!selectedGroup) return false;

  const groupPlan = groups.find(el => el.text === selectedGroup).plan;

  if (user.isSubAccount) return false;

  const { plan } = user;

  const disableConditions = {
    basic: groupPlan !== 'basic',
    essential_yearly: groupPlan !== 'essential' && groupPlan !== 'basic',
    essential_monthly: groupPlan !== 'essential' && groupPlan !== 'basic',
    professional_monthly: groupPlan === 'enterprise',
    professional_yearly: groupPlan === 'enterprise'
  };

  return disableConditions[plan] || false;
};
