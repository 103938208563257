import RHFTextField from './RHFTextField';
import RHFCheckbox from './RHFCheckbox';
import RHFSwitch from './RHFSwitch';
import RHFNumberInput from './RHFNumberInput';

export const Field = {
  Switch: RHFSwitch,
  Text: RHFTextField,
  Checkbox: RHFCheckbox,
  NumberInput: RHFNumberInput,
};
