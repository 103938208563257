const DATA_TAB_CLEAR_OPTIONS = [
  {
    actionName: 'Clear all',
    action: 'RAW_DATA_CLEAR_QUESTIONS',
  },
  {
    actionName: 'Clear selected',
    action: 'RAW_DATA_CLEAR_SELECTED',
  },
  {
    actionName: 'Clear options',
    action: 'RAW_DATA_CLEAR_QUESTION_OPTIONS',
  },
];

export default DATA_TAB_CLEAR_OPTIONS;
