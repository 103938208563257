//
import type { EditableQuestion } from '../types';
import { ColumnSource } from '../types';

// ----------------------------------------------------------------------

type CompatibleQuestionItem = {
  id: string;
  isActive: boolean;
  title: string;
};

type PasteEditsForm = {
  compatibilityMode: string;
  compatibleQuestions: EditableQuestion[];
  selectedQuestion: EditableQuestion;
};

const CompatibleQuestionsList = ({
  compatibleQuestions,
}: {
  compatibleQuestions: CompatibleQuestionItem[];
}) => {
  return (
    <ul className="list-group">
      {compatibleQuestions.map(question => {
        const baseClassName = 'list-group-item';
        const activeClassName =
          'd-flex justify-content-between align-items-center';

        return (
          <li
            key={question.id}
            className={`${baseClassName} ${question.isActive ? activeClassName : ''}`}
          >
            {question.title}
            {question.isActive && (
              <span className="badge badge-analyze badge-pill">Active</span>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const PasteEditsForm = ({
  compatibilityMode,
  compatibleQuestions,
  selectedQuestion,
}: PasteEditsForm) => {
  const renderMessage = () => {
    if (compatibilityMode === 'multipleQuestionsIncludingActive') {
      return (
        <p className="m-0">
          The copied edits can be applied to multiple questions. Select the ones
          you want to update:
        </p>
      );
    }

    return (
      <div className="alert alert-info m-0">
        <i className="fa-regular fa-triangle-exclamation mr-2" />
        The copied edits are not compatible with the active question. You can
        paste the edits to the following selected questions instead:
      </div>
    );
  };

  const items = compatibleQuestions.map(question => ({
    id: question.id,
    isActive: question.id === selectedQuestion.id,
    title: question.source === ColumnSource.ROW ? `${question.title} (r)` : `${question.title} (c)`,
  }));

  return (
    <div className="p-3 d-flex flex-column gap-lg">
      {renderMessage()}

      <CompatibleQuestionsList compatibleQuestions={items} />
    </div>
  );
};

export default PasteEditsForm;
