
export const returnMultiSelectionData = (
  data: TODO[],
  checkedItems: string[],
  shouldDefineRange: boolean,
  itemId: string,
  updateCheckedItems?: (items: string[]) => void
) => {
  // Create deep copies to avoid mutating original data
  let updatedCheckedItems = [...checkedItems];
  const updatedItemsData = JSON.parse(JSON.stringify(data)) as TODO[];

  const isItemChecked = updatedCheckedItems.includes(itemId);

  if (isItemChecked) {
    // Uncheck item if already checked
    updatedCheckedItems = updatedCheckedItems.filter(id => id !== itemId);
  } else if (shouldDefineRange && updatedCheckedItems.length > 0) {
    // Handle range selection
    const selectedItemIndex = updatedItemsData.findIndex(el => el.id === itemId);
    const lastCheckedIndex = updatedItemsData.findIndex(
      el => el.id === updatedCheckedItems[updatedCheckedItems.length - 1]
    );

    const [startIndex, endIndex] = selectedItemIndex > lastCheckedIndex
      ? [lastCheckedIndex + 1, selectedItemIndex]
      : [selectedItemIndex, lastCheckedIndex - 1];

    const selectedItems = updatedItemsData
      .slice(startIndex, endIndex + 1)
      .map(el => el.id);

    updatedCheckedItems = [...updatedCheckedItems, ...selectedItems];
  } else {
    // Add single item
    updatedCheckedItems = [...updatedCheckedItems, itemId];
  }

  // Update checked items state
  if (updateCheckedItems) updateCheckedItems(updatedCheckedItems);

  // Update selection status for all items
  return updatedItemsData.map(item => ({
    ...item,
    selected: updatedCheckedItems.includes(item.id)
  }));
}
