import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable, Draggable } from '@hello-pangea/dnd';

import { Icon } from '../../../../../shared/Icon/Icon';
import { returnElementOptions, returnQuesElementIcon, returnSelectedElementData, returnSurveyDesignData } from '../returnDataHelpers/returnDataHelpers';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';
import { fetchPutJson } from '../../../../../../services/services';

export const SectionElements = ({
  sectionIndex,
  elements,
  setQuestionSelected,
  showAddElementModal,
  sectionLength,
  removeErrorBadge,
  onCheckQuestion,
  checkedElements,
  token,
  setLibraryModal,
  showDeleteElementModal,
  updateElements,
  checkElementsWholeObject,
  scrollElementIntoView
}) => {
  const params = useParams()
  const dispatch = useDispatch();
  const { selectedElement, theData } = useSelector((theState) => (theState.surveyInitialDataReducer))

  //this function updates the selected element
  const updateSelectedElement = useCallback((item, originalItem) => {
    const elementOptions =
      item.type !== "quota" ? [{
        type: originalItem.type,
        expanded: true,
        published: originalItem.attributes?.["miext:id"] ? true : false,
        isDisabled: originalItem.attributes?.["miext:disabled"] && originalItem.attributes["miext:disabled"] === "true" ? true : false,
        index: null,
        items: returnElementOptions(originalItem, originalItem.attributes?.["miext:disabled"] && originalItem.attributes["miext:disabled"] === "true" ? true : false, originalItem.type, null),
      },
      ] : null;
    dispatch({ type: "UPDATE_SURVEY_ELEMENT_DATA", payload: { item: item, originalItem: originalItem, elementOptions: elementOptions } });
    dispatch({ type: 'SAVE_QUESTION' })
    if (item.type === "quotastop") {
      dispatch({ type: 'SURVEY_RELOAD_OPTIONS', payload: { reload: false, expression: null } })
    }
  }, [dispatch])

  //this function is a handler for what the user clicks from the dropdown button menu
  const onLibraryHandler = (e, item, data, ids, dataToShow) => {
    if (e.item.text === "Add to library") {
      if (item.type === 'ques') {
        setLibraryModal(true)
        if (item.selected === false) {
          dispatch({ type: 'UPDATE_SELECTED_QUESTION', payload: item });
        } else if (item.selected === true) {
          item.selected = true
          return dispatch({ type: 'UPDATE_SELECTED_QUESTION', payload: item });
        }
        setQuestionSelected(item)
      } else {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Sorry, we currently don't support adding ${item.type} to library` } })
      }
    } else if (e.item.text === "Add question") {
      if (item.selected) {
        const updatedElement = JSON.parse(JSON.stringify(theData.selectedItem));

        const values = {}
        theData.originalData.languages.map((el) => el.code).forEach((el) => (values[el] = ""))
        updatedElement.elements.push({
          type: "subq",
          metadata: null,
          attributes: { type: "n", range: "*" },
          elements: [{ type: "stext", attributes: {}, metadata: null, elements: [{ type: "text", attributes: {}, textValues: values, metadata: null, }] }],
        })
        updatedElement._etag = theData.originalSelectedItem._etag;
        dispatch({ type: 'SURVEY_SET_SUBQUESTION_INDEX', payload: updatedElement.elements.filter((el) => el.type === "subq").length - 1 })
        const selectedItem = returnSelectedElementData(updatedElement, theData.data.find((el) => el.index === updatedElement.section).elements.find((el) => el.selected === true), theData.validationErrors, theData.data);
        selectedItem._etag = theData.originalSelectedItem._etag;
        updateSelectedElement(selectedItem, updatedElement)
      } else {
        setQuestionSelected(item)
      }
    } else if (e.item.text === "Split section") {
      const body = { _etag: theData.originalData._etag, elementId: item.id }
      fetchPutJson(`su/projects/${params.name}/surveys/${params.survey}/split-section`, token, body)
        .then((res) => {
          if (res && !res.error && !res.message) {
            const originalData = res;
            const data = returnSurveyDesignData(res);
            dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } });
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Test section has been split successfully' } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          }
        })
    }
  };

  const onQuestionClick = (e, item) => {
    if (e.metaKey || e.ctrlKey) {
      onCheckQuestion(item.id)
      checkElementsWholeObject(item)
    } else {
      setQuestionSelected(item);
      dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: false })
    }
  }

  //this fuction handles the chosen subquestion
  const onChangeSubquestion = (e) => {
    dispatch({ type: "SURVEY_SET_SUBQUESTION_INDEX", payload: e })
    if (selectedElement?.type === 'subq') {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem.elements.filter(el => el.type === 'subq')[e] })
    } else {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem })
    }
  }

  const unresolvedReviewComments = (item) => {
    if (!theData.surveyReviews || !theData.surveyReviews.length) return;

    const surveyQuestion = theData.surveyReviews.find((element) => element.questionId === item.id);

    const questionStatus = surveyQuestion ? surveyQuestion.comments.filter((item) => {
      return item.status === 0
    }) : null;

    return questionStatus ? questionStatus.length : null;
  }

  const itemRenderer = (item) => {
    switch (item.type) {
      case 'info':
        return (
          <div id={item.id} className={`w-100 flex-column ${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"}`}>
            <div className={`d-flex flex-row align-items-center justify-content-between flex-grow-1 pl-1 w-100 question ${item.selected ? 'question-selected' : ''} ${theData.checkedElements?.includes(item.id) ? "question-checked" : ""}`}>
              <div className={`d-flex flex-row align-items-center question-row ${item.isDisabled && "question-disabled"}`} onClick={(e) => { onQuestionClick(e, item); dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: false }); }}>
                <span className='survey-icon'>
                  <Icon className="survey-icon-fill" type="info" />
                </span>
                <span className='heading' aria-label={item.label ? item.label : 'Info element'}>
                  {item.label ? item.label : 'Info element'}
                </span>
              </div>
              <div className='d-flex more'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  items={[{ text: "Split section" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.originalData?.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more' />
                  )}
                />
              </div>
            </div>
          </div>
        );
      case 'goto':
        return (
          <div id={item.id} className={`w-100 flex-column ${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"}`}>
            <div className={`d-flex flex-row align-items-center justify-content-between flex-grow-1 pl-1 w-100 question ${item.selected ? 'question-selected' : ''} ${theData.checkedElements?.includes(item.id) ? "question-checked" : ""}`}>
              <div className={`d-flex flex-row align-items-center question-row ${item.isDisabled && "question-disabled"}`} onClick={(e) => { onQuestionClick(e, item); dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: false }); }}>
                <span className='survey-icon'>
                  <Icon className="survey-icon-fill" type="goto" />
                </span>
                <span className='heading' aria-label='Goto element'>
                  Goto element
                </span>
              </div>
              <div className='d-flex more'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  items={[{ text: "Split section" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.originalData?.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more' />
                  )}
                />
              </div>
              {/* <div className='d-flex more pr-1'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  items={[{ text: "Delete" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.originalData.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more-horizontal' />
                  )}
                />
              </div> */}
            </div>
          </div>
        );
      case 'quotastop':
        return (
          <div id={item.id} className={`w-100 flex-column ${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"}`}>
            <div className={`d-flex flex-row align-items-center justify-content-between flex-grow-1 pl-1 w-100 question ${item.selected ? 'question-selected' : ''} ${theData.checkedElements?.includes(item.id) ? "question-checked" : ""}`}>
              <div className={`d-flex flex-row align-items-center question-row ${item.isDisabled && "question-disabled"}`} onClick={(e) => { onQuestionClick(e, item); dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: false }); }}>
                <span className='survey-icon'>
                  <Icon className="survey-icon-fill" type="quota-stop" />
                </span>
                <span className='heading' aria-label={item.label ? item.label : 'Quota stop'}>
                  {item.label ? item.label : 'Quota stop'}
                </span>
              </div>
              <div className='d-flex more'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  items={[{ text: "Split section" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.originalData?.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more' />
                  )}
                />
              </div>
              {/* <div className='d-flex more pr-1'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  items={[{ text: "Delete" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.originalData.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more-horizontal' />
                  )}
                />
              </div> */}
            </div>
          </div>
        );
      case 'quota':
        return (
          <div id={item.id} className={`w-100 flex-column ${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"}`}>
            <div className={`d-flex flex-row align-items-center justify-content-between flex-grow-1 pl-1 w-100 question ${item.selected ? 'question-selected' : ''} ${theData.checkedElements?.includes(item.id) ? "question-checked" : ""}`}>
              <div className={`d-flex flex-row align-items-center question-row position-relative ${item.isDisabled && "question-disabled"}`} onClick={(e) => { onQuestionClick(e, item); dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: true }) }}>
                {unresolvedReviewComments(item) > 0 && (
                  <span className='bg-success text-white px-1 rounded position-absolute survey-comment-badge'>
                    {unresolvedReviewComments(item)}
                  </span>
                )}
                <span className='survey-icon'>
                  <Icon className="survey-icon-fill" type="quota" />
                </span>
                <span className='heading' aria-label={item.label}>
                  {item.label}
                </span>
              </div>
              <div className='d-flex more'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  items={[{ text: "Split section" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.originalData?.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more' />
                  )}
                />
              </div>

              {/* <div className='d-flex more pr-1'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  items={[{ text: "Delete" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.originalData.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more-horizontal' />
                  )}
                />
              </div> */}
            </div>
          </div>
        );
      default:
        if (item.referenceQuesTypes && item.referenceQuesTypes.length > 0) {
          if (item.referenceQuesTypes.length === 1 && item.referenceQuesTypes[0]) {
            let firstSubQuesType = item.referenceQuesTypes[0]
            let icon = returnQuesElementIcon(firstSubQuesType);

            return (
              <div id={item.id} className={`w-100 flex-column ${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"}`}>
                <div className={`d-flex flex-row align-items-center justify-content-between flex-grow-1 pl-1 w-100 question ${item.selected ? 'question-selected' : ''} ${theData.checkedElements?.includes(item.id) ? "question-checked" : ""}`}>
                  <div className={`d-flex flex-row align-items-center question-row position-relative ${item.isDisabled && "question-disabled"}`} onClick={(e) => onQuestionClick(e, item)}>
                    {unresolvedReviewComments(item) > 0 && (
                      <span className='bg-success text-white px-1 rounded position-absolute survey-comment-badge'>
                        {unresolvedReviewComments(item)}
                      </span>
                    )}

                    <span className='survey-icon'>
                      <Icon className="survey-icon-fill" type={icon} />
                    </span>
                    <span className='heading' aria-label={item.label}>
                      {item.label}
                    </span>

                  </div>
                  <div className='d-flex more'>
                    <DropdownButton
                      className="p-0"
                      hideChevron={true}
                      // items={[{ text: 'Add to library' }, { text: "Add question" }, { text: "Delete" }]}
                      items={[{ text: 'Add to library' }, { text: "Add question" }, { text: "Split section" }]}
                      onItemClick={(e) => onLibraryHandler(e, item, theData.selectedItem?.elements, theData.checkedElements, theData.data)}
                      renderMainButton={() => (
                        <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more' />
                      )}
                    />
                  </div>
                </div>
                <div className={`${theData.selectedItem?.id === item.id ? "survey-subquestion-container" : "survey-subquestion-container"} ${item.isDisabled && "question-disabled"}`}>
                  {theData.selectedItem?.id === item.id && theData?.selectedItem?.elements.filter(el => el.type === 'subq').map((el, index) => {
                    return el.type === 'subq' &&
                      <div key={index} onClick={() => onChangeSubquestion(index)} className={`w-100 d-flex flex-row align-items-center justify-content-start pl-3 py-1 gap-md ${theData.selectedSubquestion === index ? "survey-subquestion-selected" : "survey-subquestion"}`}>
                        <Icon type="sub-question" />
                        <p className='m-0'>
                          {theData?.selectedItem?.elements.length > 2 ? `Question ${index + 1}` : "Question"}
                        </p>
                      </div>
                  })}
                </div>
              </div>
            )
          }
        }
        return (
          <div id={item.id} className={`w-100 flex-column ${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"}`}>
            <div className={`d-flex flex-row align-items-center justify-content-between flex-grow-1 h-36 pl-1 question w-100 ${item.selected ? 'question-selected' : ''} ${theData.checkedElements?.includes(item.id) ? "question-checked" : ""}`}>
              <div className={`d-flex flex-row align-items-center question-row position-relative ${item.isDisabled && "question-disabled"}`} onClick={(e) => onQuestionClick(e, item)}>
                {unresolvedReviewComments(item) > 0 && (
                  <span className='bg-success text-white px-1 rounded position-absolute survey-comment-badge'>
                    {unresolvedReviewComments(item)}
                  </span>
                )}
                <span className='survey-icon'>
                  <Icon className="survey-icon-fill" type="general" />
                </span>

                <span className='heading' aria-label={item.label}>
                  {item.label}
                </span>
              </div>
              <div className='d-flex more'>
                <DropdownButton
                  className="p-0"
                  hideChevron={true}
                  // items={[{ text: 'Add to library' }, { text: "Add question" }, { text: "Delete" }]}
                  items={[{ text: 'Add to library' }, { text: "Add question" }, { text: "Split section" }]}
                  onItemClick={(e) => onLibraryHandler(e, item, theData.selectedItem?.elements, theData.checkedElements, theData.data)}
                  renderMainButton={() => (
                    <Icon className={theData.selectedItem?.id === item.id ? "" : ""} type='more' />
                  )}
                />
              </div>
            </div>
            <div className={`${theData.selectedItem?.id === item.id ? "survey-subquestion-container" : "survey-subquestion-container"} ${item.isDisabled && "question-disabled"}`}>
              {theData.selectedItem?.id === item.id && theData?.selectedItem?.elements.filter(el => el.type === 'subq').map((el, index) => {
                return el.type === 'subq' &&
                  <div key={index} onClick={() => onChangeSubquestion(index)} className={`w-100 d-flex flex-row align-items-center justify-content-start pl-3 py-1 gap-md ${theData.selectedSubquestion === index ? "survey-subquestion-selected" : "survey-subquestion"}`}>
                    <Icon type="sub-question" />
                    <p className='m-0'>
                      {theData?.selectedItem?.elements.length > 2 ? `Question ${index + 1}` : "Question"}
                    </p>
                  </div>
              })}
            </div>
          </div >
        );
    }
  };

  return (
    <React.Fragment>
      <Droppable droppableId={`${sectionIndex}`} type="elements">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={snapshot.isDraggingOver ? 'bg-dnd-active' : elements.length > 0 ? 'bg-dnd' : 'section-empty'}>
            {elements.length > 0 &&
              elements.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`survey-question ${snapshot.isDragging ? 'survey-question-dragging' : ''}`}>
                      {itemRenderer(item)}
                      {item.errors > 0 && !removeErrorBadge && <div className={`${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"} alert-pos`}>
                        <span />
                      </div>
                      }
                      {item.hasLogic && theData.showElementLogic && <div className={`${theData.toggleDisabledQuestions && item.isDisabled ? "d-none" : "d-flex"} logic-pos`}>
                        <span />
                      </div>
                      }
                    </div>
                  )}
                </Draggable>
              ))
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </React.Fragment>
  );
};
