import { Controller, useFormContext } from 'react-hook-form';
import type { SwitchProps } from '@progress/kendo-react-inputs';
import { Switch } from '@progress/kendo-react-inputs';

type RHFSwitchProps = {
  name: string;
  label?: string;
} & SwitchProps;

const RHFSwitch = ({ name, label, ...other }: RHFSwitchProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <>
          <Switch
            name={name}
            checked={value}
            onChange={e => onChange(e.value)}
            {...other}
          />
          {label && <span className="ml-2">{label}</span>}
        </>
      )}
    />
  );
};

export default RHFSwitch;
