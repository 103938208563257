const QTABLE_CLEAR_OPTIONS = [
  {
    actionName: 'Clear all',
    action: 'QTABLE_CLEAR_QUESTIONS',
  },
  {
    actionName: 'Clear selected',
    action: 'QTABLE_CLEAR_SELECTED',
  },
  {
    actionName: 'Clear options for selected',
    action: 'QTABLE_CLEAR_SELECTED_QUESTION_OPTIONS',
  },
  {
    actionName: 'Clear options',
    action: 'QTABLE_CLEAR_QUESTION_OPTIONS',
  },
];

export default QTABLE_CLEAR_OPTIONS;
