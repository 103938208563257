import { surveyApi } from 'app/api/surveyApi';
import type {
  CustomRowsCreateRequest,
  CustomRowsDeleteRequest,
  CustomRowsResponse,
  CustomRowsUpdateRequest,
} from './types';


const customRowsApi = surveyApi.injectEndpoints({
  endpoints: builder => ({
    createCustomRow: builder.mutation<
      CustomRowsResponse,
      CustomRowsCreateRequest
    >({
      query: body => {
        const { projectId, datasetId, ...rest } = body;

        return {
          url: `an/projects/${projectId}/analysis/${datasetId}/custom-rows`,
          method: 'POST',
          body: {
            ...rest,
          },
        };
      },
    }),

    updateCustomRow: builder.mutation<
      CustomRowsResponse,
      CustomRowsUpdateRequest
    >({
      query: body => {
        const { projectId, datasetId, ...rest } = body;

        return {
          url: `an/projects/${projectId}/analysis/${datasetId}/custom-rows`,
          method: 'PATCH',
          body: {
            ...rest,
          },
        };
      },
    }),

    deleteCustomRows: builder.mutation<
      CustomRowsResponse,
      CustomRowsDeleteRequest
    >({
      query: body => {
        const { projectId, datasetId, ...rest } = body;

        return {
          url: `an/projects/${projectId}/analysis/${datasetId}/custom-rows`,
          method: 'DELETE',
          body: {
            ...rest,
          },
        };
      },
    }),
  }),
});

export const {
  useCreateCustomRowMutation,
  useUpdateCustomRowMutation,
  useDeleteCustomRowsMutation,
} = customRowsApi;
