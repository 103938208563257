import { returnUpdatedAnalysisBody } from '../../returnUpdatedAnalysisBody/returnUpdatedAnalysisBody.js';
import { updateEditedTextField } from '../../resetEditedText/resetEditedText.js';
export default function returnExportBody(
  selectedTab,
  state,
  options,
  exportOptions,
  type,
  formData,
  overwriteIfExists,
  lang,
  defaultLanguage,
  projectType,
  exportBody
) {
  let body = {
    type: type,
    language: lang,
    options: exportOptions,
    saveOptions: {
      saveToProject: formData.saveToProject,
      fileName: formData.fileName,
      fileDescription: formData.fileDescription,
      overwriteIfExists: overwriteIfExists,
      tags: [],
    },
  };

  const isFlexibleBanner = options?.XtFlexBanner;

  if (selectedTab === 'report') {
    if (lang === defaultLanguage) {
      body.definitions = state
        .filter(e => !e.disabled)
        .map(item => item.body.definitions);
    } else {
      body.definitions = exportBody
        .filter(e => !e.disabled)
        .map(item => item.body.definitions);
    }
  } else if (selectedTab === 'topline') {
    body.definitions = {
      type: 'topline',
      options: options,
      questions: state.firstColumn.map(el => {
        return { id: el.title, subqIndex: el.subqIndex, disabled: false };
      }),
    };
  } else if (selectedTab === 'qt') {
    body.definitions = {
      type: 'qt',
      options: options,
      questions: state.firstColumn.map(el => {
        return {
          id: el.title,
          subqIndex: el.subqIndex,
          disabled: false,
          swapRowsCols: el.swapRowsCols,
          suppressGridCols: el.subGroups
            ?.filter(item => item.suppress)
            .map(item => {
              return item.code;
            }),
          summaryValuesOnly: el.summaryValuesOnly,
          options: el.options ? el.options : {},
          // editedText: lang === "en" || lang === undefined ? Array.isArray(el.content) ? el.content[0] : el.content : null,
          editedText:
            lang === defaultLanguage
              ? Array.isArray(el.content)
                ? el.content[0]
                : el.content
              : null,
          rows: el.groups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue:
                  typeof row.weightValue === 'number' ? row.weightValue : null,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
          cols: el.subGroups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue:
                  typeof row.weightValue === 'number' ? row.weightValue : null,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
        };
      }),
    };
  } else if (selectedTab === 'xt') {
    if (isFlexibleBanner) {
      const columnData = state?.firstColumn?.map(item => {
        return {
          body: returnUpdatedAnalysisBody(
            'datasets',
            'xt',
            state?.newQuestionOptions,
            state?.firstColumn,
            state?.scndColumn,
            item.id
          ),
        };
      });
      const exportBody = updateEditedTextField(columnData);
      const flexBannerUpdatedBody = [];

      const updatedExportBody = exportBody?.map(item => {
        const individualColQuestion =
          item?.body?.definitions?.rowQuestions[0]?.flexBannerId;

        return {
          ...item,
          body: {
            ...item.body,
            definitions: {
              ...item.body.definitions,
              colQuestions: state?.bannerColumn?.[individualColQuestion]?.map(
                col => ({
                  ...col,
                  id: col.title,
                })
              ),
            },
          },
        };
      });
      flexBannerUpdatedBody.push(...(updatedExportBody || []));

      body.definitions = [
        ...flexBannerUpdatedBody
          .filter(e => !e.disabled)
          .map(item => item.body.definitions),
      ];
    } else {
      body.definitions = {
        type: 'xt',
        options: options,
        rowQuestions: state.firstColumn.map(el => {
          return {
            id: el.title,
            subqIndex: el.subqIndex,
            isDisabled: false,
            swapRowsCols: el.swapRowsCols,
            suppressGridCols: el.subGroups
              ?.filter(item => item.suppress)
              .map(item => {
                return item.code;
              }),
            summaryValuesOnly: el.summaryValuesOnly,
            options: el.options ? el.options : {},
            // editedText: lang === "en" || lang === undefined ? Array.isArray(el.content) ? el.content[0] : el.content : null,
            editedText:
              lang === defaultLanguage
                ? Array.isArray(el.content)
                  ? el.content[0]
                  : el.content
                : null,
            hierarchy: el.hierarchy?.map(element => {
              return {
                id: element.id,
                subqIndex: element.subqIndex,
                isDisabled: element.isDisabled,
                editedText:
                  lang === 'en' || lang === undefined
                    ? element.editedText
                    : null,
                rows: element.rows
                  ?.filter(el => el.active)
                  .map(row => {
                    return {
                      code: row.code,
                      editedText: lang === defaultLanguage ? row.text : null,
                    };
                  }),
                cols: element.cols
                  ?.filter(el => el.active)
                  .map(col => {
                    return {
                      code: col.code,
                      editedText: lang === defaultLanguage ? col.text : null,
                    };
                  }),
              };
            }),
            rows: el.groups
              ?.filter(group => group.active)
              .map((row, id) => {
                return {
                  code: row.code,
                  weightValue:
                    typeof row.weightValue === 'number'
                      ? row.weightValue
                      : null,
                  isDisabled: false,
                  editedText: lang === defaultLanguage ? row.text : null,
                };
              }),
            cols: el.subGroups
              ?.filter(group => group.active)
              .map((row, id) => {
                return {
                  code: row.code,
                  weightValue:
                    typeof row.weightValue === 'number'
                      ? row.weightValue
                      : null,
                  isDisabled: false,
                  editedText: lang === defaultLanguage ? row.text : null,
                };
              }),
          };
        }),
        colQuestions: state.scndColumn.map(el => {
          return {
            options: {},
            id: el.title,
            subqIndex: el.subqIndex,
            isDisabled: false,
            editedText:
              lang === defaultLanguage
                ? Array.isArray(el.content)
                  ? el.content[0]
                  : el.content
                : null,
            hierarchy: el.hierarchy?.map(element => {
              return {
                id: element.id,
                subqIndex: element.subqIndex,
                isDisabled: element.isDisabled,
                editedText: element.editedText,
                rows: element.rows
                  ?.filter(el => el.active)
                  .map(row => {
                    return {
                      code: row.code,
                      editedText: lang === defaultLanguage ? row.text : null,
                    };
                  }),
                cols: element.cols
                  ?.filter(el => el.active)
                  .map(col => {
                    return {
                      code: col.code,
                      editedText: lang === defaultLanguage ? col.text : null,
                    };
                  }),
              };
            }),
            rows: el.groups
              ?.filter(group => group.active)
              .map((row, id) => {
                return {
                  code: row.code,
                  weightValue: id + 1,
                  isDisabled: false,
                  editedText: lang === defaultLanguage ? row.text : null,
                  ...(row?.bannerWeight && { bannerWeight: { a: row?.bannerWeight?.a, w: row?.bannerWeight?.w } }),
                };
              }),
            cols: el.subGroups
              ?.filter(group => group.active)
              .map((row, id) => {
                return {
                  code: row.code,
                  weightValue:
                    typeof row.weightValue === 'number'
                      ? row.weightValue
                      : null,
                  isDisabled: false,
                  editedText: lang === defaultLanguage ? row.text : null,
                };
              }),
          };
        }),
      };
    }
  } else if (selectedTab === 'data') {
    body.definitions = {
      type: 'data',
      options: options,
      questions: state.firstColumn.map(el => {
        return { id: el.title, subqIndex: el.subqIndex, disabled: false };
      }),
    };
  } else if (selectedTab === 'dig') {
    body.definitions = {
      type: 'dig',
      options: options,
      questions: state.firstColumn.map(el => {
        return {
          id: el.title,
          subqIndex: el.subqIndex,
          disabled: false,
          swapRowsCols: el.swapRowsCols,
          summaryValuesOnly: el.summaryValuesOnly,
          editedText:
            lang === defaultLanguage
              ? Array.isArray(el.content)
                ? el.content[0]
                : el.content
              : null,
          rows: el.groups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue: id + 1,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
          cols: el.subGroups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue:
                  typeof row.weightValue === 'number' ? row.weightValue : null,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
        };
      }),
    };
  } else if (selectedTab === 'g') {
    body.definitions = {
      type: 'g',
      options: options,
      rowQuestions: state.firstColumn.map(el => {
        return {
          id: el.title,
          subqIndex: el.subqIndex,
          disabled: false,
          editedText:
            lang === defaultLanguage
              ? Array.isArray(el.content)
                ? el.content[0]
                : el.content
              : null,
          swapRowsCols: el.swapRowsCols,
          summaryValuesOnly: el.summaryValuesOnly,
          options: el.options ? el.options : {},
          rows: el.groups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue: id + 1,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
          cols: el.subGroups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue:
                  typeof row.weightValue === 'number' ? row.weightValue : null,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
        };
      }),
      colQuestions: state.scndColumn.map(el => {
        return {
          id: el.title,
          subqIndex: el.subqIndex,
          disabled: false,
          editedText:
            lang === defaultLanguage
              ? Array.isArray(el.content)
                ? el.content[0]
                : el.content
              : null,
          rows: el.groups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue: id + 1,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
          cols: el.subGroups
            ?.filter(group => group.active)
            .map((row, id) => {
              return {
                code: row.code,
                weightValue:
                  typeof row.weightValue === 'number' ? row.weightValue : null,
                isDisabled: false,
                editedText: lang === defaultLanguage ? row.text : null,
              };
            }),
        };
      }),
    };
  } else {
    body = {};
  }
  return body;
}
