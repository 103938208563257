import type { ContentType } from '../types';
import { useAppSelector } from 'app/hooks';

// -------------------------------------------------------------------------------------------------

export const useStateMapper = (contentType: ContentType) => {
  const state = useAppSelector(rootState => {
    switch (contentType) {
      case 'xt':
        return rootState.stateReducer;
      case 'chart':
        return rootState.chartStateReducer;
      case 'qtable':
        return rootState.qTableStateReducer;
      case 'dig':
        return rootState.digStateReducer;
      case 'raw_data':
        return rootState.rawDataStateReducer;
      case 'topline':
        return rootState.toplineStateReducer;

      default:
        throw new Error(
          `Unknown contentType: ${contentType} in useStateMapper function`
        );
    }
  });

  return state;
};
