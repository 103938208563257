export type CrossTabQuestionEditorCopyData = {
  selectedQuestionId: string
  groups: TODO[]
} | null

export const returnQuestionEditorActionButtonsData = (mergeRowsBtnActive: boolean, selectedQuestion: string, crossTabQuestionEditorCopyData: CrossTabQuestionEditorCopyData, selectedQuestionRows: TODO[], selectedGroupTab: number) => {
  const selectedRow = selectedQuestionRows?.filter(row => row.selected).length === 1 ? selectedQuestionRows[0] : null;
  const areAllSelectedRowsCustom = selectedQuestionRows?.filter(row => row.selected).every((row: TODO) => row.isCustom) && selectedQuestionRows?.filter(row => row.selected && row.isCustom).length > 0;

  return [
    {
      actionName: 'Merge rows',
      action: 'mergeRows',
      disabled: !mergeRowsBtnActive
    },
    {
      actionName: 'Set scaling factor',
      action: 'setWeightValues'
    },
    {
      actionName: 'Copy edits',
      action: 'copyEdits',
    },
    {
      actionName: 'Paste edits',
      action: 'pasteEdits',
      disabled: !(typeof crossTabQuestionEditorCopyData?.selectedQuestionId === 'string' && selectedQuestion !== crossTabQuestionEditorCopyData.selectedQuestionId)
    },
    {
      actionName: 'Add custom row',
      action: 'addCustomRow',
      disabled: selectedGroupTab !== 0
    },
    {
      actionName: 'Edit custom row',
      action: 'editCustomRow',
      disabled: selectedGroupTab !== 0 ? true : selectedRow ? selectedRow.isCustom !== true : true
    },
    {
      actionName: 'Delete custom rows',
      action: 'deleteCustomRows',
      disabled: !areAllSelectedRowsCustom || selectedGroupTab !== 0
    }
  ]
}
