//
import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
//
import type { QuestionEditorOptions } from './contexts/QuestionEditorContext';
import {
  QuestionEditorProvider,
  useQuestionEditor,
} from './contexts/QuestionEditorContext';
import { ActionDialogProvider } from './contexts/ActionDialogContext';
import QuestionList from './question-list/QuestionList';
import QuestionDetails from './question-details/QuestionDetails';
import type { Question } from '../../types';

import './styles.scss';

// ---------------------------------------------------------------------------------

interface QuestionEditorProps {
  title?: string;
  options?: Partial<QuestionEditorOptions>;
  onUpdate: (data: Question[]) => void;
  onClose: () => void;
}

const QuestionEditorDialog = ({
  onUpdate,
  onClose,
  title = 'Question Editor',
}: QuestionEditorProps) => {
  const { getDataChanges } = useQuestionEditor();

  const handleClose = () => {
    onClose();
  };

  const handleUpdate = () => {
    const data = getDataChanges();
    onUpdate(data);
  };

  return (
    <Dialog
      width="80%"
      height="90%"
      title={title}
      onClose={handleClose}
      className="question-editor"
    >
      <div className="d-flex flex-row vh-100">
        <div className="question-editor__left-pane">
          <QuestionList />
        </div>
        <div className="d-flex flex-column flex-grow-1 bg-white">
          <QuestionDetails />
        </div>
      </div>
      <DialogActionsBar>
        <Button id="close" className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn btn-analyze" id="update" onClick={handleUpdate}>
          Update
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

const QuestionEditor = ({ options, ...rest }: QuestionEditorProps) => {
  const defaultOptions = {
    enableCustomRows: false,
    enablePasteEdits: false,
    editHierarchy: false,
  };

  const providerOptions = {
    ...defaultOptions,
    ...(options ?? {}),
  };

  return (
    <QuestionEditorProvider options={providerOptions}>
      <ActionDialogProvider>
        <QuestionEditorDialog {...rest} />
      </ActionDialogProvider>
    </QuestionEditorProvider>
  );
};

export default QuestionEditor;
