import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import type { Question } from '../../types';

// --------------------------------------------------------------------------

type ChartState = {
  firstColumn: Question[];
  scndColumn: Question[];
};

export const useChartClearActions = () => {
  const dispatch = useAppDispatch();
  const state: ChartState = useAppSelector(
    rootState => rootState.stateReducer.state
  );

  const clearSelected = useCallback(() => {
    const firstColumn = state.firstColumn.filter(q => !q.selected);
    const scndColumn = state.scndColumn.filter(q => !q.selected);

    if (
      firstColumn.length > 0 &&
      !firstColumn.some(q => q.active) &&
      state.firstColumn.length > 1
    ) {
      // Set the last question in the first column as active
      firstColumn[firstColumn.length - 1].active = true;
    }

    const payload = {
      firstColumn,
      scndColumn,
    };

    dispatch({ type: 'CHART_CLEAR_SELECTED', payload });
  }, [state.firstColumn, state.scndColumn, dispatch]);

  return {
    clearSelected,
  };
};
