import { useState, useEffect, useCallback, type ReactNode } from "react";
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from '../../../../../../store/reducers/rootReducer';
import { useDebounce } from '../../../../../shared/customHooks/useDebounce';
import { SurveyOptionsHeader } from "./SurveyOptionsHeader/SurveyOptionsHeader";
import { SurveyComments } from "./SurveyComments/SurveyComments";
import { returnSurveyOptionsByType } from '../../helpers/returnSurveyOptionsByType/returnSurveyOptionsByType';
import { SurveyAdditionalOptions } from "./SurveyAdditionalOptions/SurveyAdditionalOptions";
import { fetchGetJson as getSurveyStructure, fetchGetJson } from '../../../../../../services/services';

export const SurveyContent = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const [options, setOptions] = useState<null | ReactNode[]>([]);
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);
  const params: { name: string, survey: string } = useParams();

  const dispatch = useDispatch();
  const { theData, selectedElement, themes, emailProviders } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))

  // Set element on component load. Required to persist some of the data from the logic layer (Specifically filters)
  useEffect(() => {
    if (theData.selectedItem.elements) {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem.elements.filter((el: any) => el.type === 'subq')[theData.selectedSubquestion] })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedElement && Object.keys(selectedElement).length > 0 && selectedElement.type !== 'quota') {
        if (theData?.elementTypes.length > 0 && themes?.length >= 0) {
          const values = selectedElement.attributes ? selectedElement.attributes : {};
          const listItemsDropdown = undefined;
          const listItemValue = undefined;
          const isSectionOptionsDialogOpen = false;
          const loopType = undefined;
          const updateLoopType = () => {};
          setOptions(returnSurveyOptionsByType(theData, selectedElement, values, onValueChange, showFilterBuilder, themes, listItemsDropdown, listItemValue, emailProviders, getEmailProviders, isSectionOptionsDialogOpen, loopType, updateLoopType))
        }
      } else if (selectedElement && Object.keys(selectedElement).length > 0 && selectedElement.type === 'quota') {
        setOptions([]);
      }
  }, [theData, themes])

  const getEmailProviders = useCallback(() => {
      if (emailProviders.data.length === 0) {
        dispatch({ type: 'SET_EMAIL_PROVIDERS', payload: { loading: true, data: [] } })
        fetchGetJson("emailproviders", token)
          .then(((res: TODO) => {
            if (res && !res.error && !res.message) {
              dispatch({ type: 'SET_EMAIL_PROVIDERS', payload: { loading: false, data: res.filter((el: { providerType: string }) => el.providerType === "sendgrid") } })
            } else {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
            }
          }))
      }
    }, [dispatch, emailProviders.data.length, token])

  const onValueChange = (e: TODO, type: string, option: { id: string }) => {
    const newValue = type === 'css' ? e.value?.id : type === 'string' ? e.value : type === "checkbox" ? e.target?.checked.toString() : e.value.text
    const updatedSelectedElement = JSON.parse(JSON.stringify(selectedElement))
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    updatedSelectedElement.attributes = { ...updatedSelectedElement.attributes, [option.id]: newValue }
    if (option.id === 'miext:disabled') {
      updatedSelectedElement.disabled = newValue === 'true'
      updatedSelectedElement.isDisabled = newValue === 'true'
    }
    dispatch({ type: 'SET_SELECTED_ELEMENT', payload: updatedSelectedElement })

    if (selectedElement.type === 'subq') {
      updatedSelectedItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].attributes = { ...updatedSelectedElement.attributes, [option.id]: newValue }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else if (selectedElement.type === 'r' || selectedElement.type === "other") {
      const updatedSelectedElementAttributes = updatedSelectedElement.attributes ? updatedSelectedElement.attributes : {}
      const findSelectedElement = updatedSelectedItem.elements.find((el: TODO) => el.type === 'rgroup').elements.find((el: TODO) => el.id === selectedElement.id)
      findSelectedElement.attributes = { ...updatedSelectedElementAttributes, [option.id]: newValue }
      if (option.id === 'miext:disabled') {
        findSelectedElement.disabled = newValue === 'true'
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else if (selectedElement.type === 'h') {
      const updatedSelectedElementAttributes = updatedSelectedElement.attributes ? updatedSelectedElement.attributes : {}
      const findSelectedElement = updatedSelectedItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: TODO) => el.type === 'hgroup').elements.find((el: TODO) => el.id === selectedElement.id)
      findSelectedElement.attributes = { ...updatedSelectedElementAttributes, [option.id]: newValue }
      if (option.id === 'miext:disabled') {
        findSelectedElement.disabled = newValue === 'true'
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    } else {
      // If element type is ques/quota/quotastop/info/goto
      if (option.id === 'quota' && type === 'quotaStop') {
        updatedSelectedItem.attributes = { ...updatedSelectedElement.attributes, [option.id]: e.value.referenceDisplayLabel }
        updatedSelectedItem.referenceQuotas.setQuota = e.value
      } else {
        updatedSelectedItem.attributes = { ...updatedSelectedElement.attributes, [option.id]: newValue }
      }
      if (option.id === 'miext:disabled') {
        updatedSelectedItem.disabled = newValue === 'true'
        updatedSelectedItem.isDisabled = newValue === 'true'
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem });

      const updatedData = JSON.parse(JSON.stringify(theData));
      let foundIndexEl: TODO = null;

      updatedData.data.some((section: TODO) => {
        return section.elements.some((element: TODO) => {
          if (element.id === updatedSelectedItem.id) {
            foundIndexEl = element;
            return true;
          }
          return false;
        });
      });

      if (foundIndexEl) {
        foundIndexEl.isDisabled = newValue === 'true';
        dispatch({ type: 'SEARCH_SURVEY_DATA', payload: updatedData.data })
      }
    }
    saveWithDebounce()
  }

  const showFilterBuilder = (option: string, optionName?: string, previousItemLabel?: string) => {
    const element: { metadata?: { filterData: unknown }, elements: unknown } = JSON.parse(JSON.stringify(theData.originalSelectedItem))
    getSurveyStructure(`su/projects/${params.name}/surveys/${params.survey}/structure`, token)
      .then((res: { error?: string, message?: string, questions: unknown[] }) => {
        if (res.error || res.message) {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message ? res.message : "Something went wrong" } })
        } else {
          if (option === "if" && optionName === "Condition") {
            // This block removes all items that come after the selected goto element
            const reversedItemList = JSON.parse(JSON.stringify(res.questions)).reverse();
            let previousItemReversedIndex: number = res.questions.length;
            if (previousItemLabel) {
              previousItemReversedIndex = res.questions.length - reversedItemList.findIndex((el: { qno: string }) => el.qno.includes(previousItemLabel));
            }
            const updatedItemList = res.questions.slice(0, previousItemReversedIndex);
            res.questions = updatedItemList;
          }

          dispatch({
            type: 'SURVEY_DISPLAY_FILTER_BUILDER', payload: {
              display: true,
              filterData: res,
              filterBuilderOptionId: option,
              combineFilterData: element.metadata?.filterData ? element.metadata.filterData : []
            }
          })
        }
      })
  }

  return (
    <>
      <div>
        <SurveyOptionsHeader
          theData={theData}
          selectedElement={selectedElement}
          subquestion={theData.selectedItem.type === 'ques' ? theData.selectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion] : null}
        />
      </div>
      {theData.isSurveyComments && (
        <div className='d-flex flex-column gap-md px-4 pt-4 pb-2 h-100 overflow-auto'>
          <SurveyComments />
        </div>
      )}
      {!theData.isSurveyComments && selectedElement && selectedElement.type !== 'quota' && selectedElement.type !== 'rc' && (
        <div className='d-flex flex-column gap-md px-4 pt-4 pb-2 h-100 overflow-auto'>
          <SurveyAdditionalOptions
            theData={theData}
            selectedElement={selectedElement}
            onValueChange={onValueChange}
          />
          {
            options ?
              <form className="d-flex flex-column gap-md">
                {options.map((option) => option)}
              </form>
                : null
          }
        </div>
      )}
    </>
  )
}
