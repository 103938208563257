import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import type { ContentType, Question } from '../types';

// -------------------------------------------------------------------------------------------------

export const useClearActions = (contentType: ContentType) => {
  const dispatch = useAppDispatch();
  const state = useAppSelector(rootState => rootState.stateReducer.state);

  // Action type creators
  const createActionType = useCallback(
    (action: string) => `${contentType.toUpperCase()}_${action}`,
    [contentType]
  );

  const clearColumns = () => {
    if (state.scndColumn && state.scndColumn.length > 0) {
      dispatch({ type: createActionType('CLEAR_QUESTION_COLUMNS') });
    }
  };

  const clearRows = () => {
    if (state.firstColumn && state.firstColumn.length > 0) {
      dispatch({ type: createActionType('CLEAR_QUESTION_ROWS') });
    }
  };

  const clearSelected = () => {
    if (state.checkedNum && state.checkedNum.length > 0) {
      const firstColumn: Question[] = state.firstColumn.filter(
        (q: Question) => !q.selected
      );

      if (
        firstColumn.length > 0 &&
        !firstColumn.some(q => q.active) &&
        state.firstColumn.length > 1
      ) {
        // Set the last question in the first column as active
        firstColumn[firstColumn.length - 1].active = true;
      }

      const payload = {
        firstColumn,
        displayTable: firstColumn.length === 0 ? null : state.displayTable,
        checkedNum: [],
      };

      dispatch({ type: createActionType('CLEAR_SELECTED'), payload });
    }
  };

  return {
    clearColumns,
    clearRows,
    clearSelected,
  };
};
