import { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
//
import { Checkbox, Switch } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button } from '@progress/kendo-react-buttons';
import { plusIcon } from '@progress/kendo-svg-icons';
//
import { v4 as uuid } from 'uuid';
//
import { Field } from 'components/shared/hook-form';
import CodeRange from '../../components/CodeRange';
import { ColumnSource } from '../../types';
import type { QuestionGroup } from '../../../../types';
import type { EditableQuestion } from '../../types';

// ----------------------------------------------------------------------

const EmptyResponseColumns = () => {
  return (
    <div className="d-flex p-3 mt-3 text-muted">
      The selected question doesn't have any response columns
    </div>
  );
};

const QuestionColumns = () => {
  const { control } = useFormContext<EditableQuestion>();
  const { fields, update, remove, append } = useFieldArray({
    control: control,
    name: 'subGroups',
  });

  const questionSource = useWatch({ control, name: 'source' }); // source of the question we are editing (row or column)
  const questionType = useWatch({ control, name: 'type' }); // type of the question we are editing (f, h, l, s, d)
  const subGroups = useWatch({ control, name: 'subGroups', defaultValue: [] });

  const getRowAtIndex = useCallback(
    (index: number) => subGroups[index],
    [subGroups],
  );

  const isCodeRangeFormat = subGroups.some(item =>
    item?.code.toString().includes(':'),
  );

  const editableTypes = ['f', 'h', 'l', 's', 'd'];
  const canEditSubGroups =
    subGroups.length > 0 || editableTypes.includes(questionType);
  const canAddNewRow = editableTypes.includes(questionType);

  const toggleSelectAll = useCallback(
    (value: boolean) => {
      fields.forEach((subgroup, index) => {
        update(index, { ...subgroup, selected: value });
      });
    },
    [fields, update],
  );

  const toggleActivateAll = useCallback(
    (value: boolean) => {
      fields.forEach((subgroup, index) => {
        update(index, { ...subgroup, active: value, suppress: false });
      });
    },
    [fields, update],
  );

  const toggleActiveSwitch = useCallback(
    (index: number, value: boolean) => {
      const row = getRowAtIndex(index);

      if (value) {
        update(index, { ...row, active: true, suppress: false });
      } else {
        update(index, { ...row, active: false });
      }
    },
    [getRowAtIndex, update],
  );

  const deleteRow = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  const duplicateRow = useCallback(
    (index: number) => {
      const row = getRowAtIndex(index);
      append({ ...row, id: uuid() });
    },
    [append, getRowAtIndex],
  );

  const addRow = () => {
    append({
      id: uuid(),
      code: '0:0',
      text: '',
      selected: false,
      active: true,
    } as QuestionGroup);
  };

  if (!canEditSubGroups) {
    return <EmptyResponseColumns />;
  }

  return (
    <div className="d-flex flex-column">
      <table className="table table-hover table-sm">
        <thead className="text-center bg-light">
          <tr>
            <th className="checkbox">
              <Checkbox
                checked={
                  subGroups.length > 0 && subGroups.every(item => item.selected)
                }
                onChange={e => toggleSelectAll(e.value)}
              />
            </th>
            <th className="switch">
              <Switch
                size="small"
                checked={
                  subGroups.length > 0 && subGroups.every(item => item.active)
                }
                onChange={e => toggleActivateAll(e.value)}
              />
            </th>
            {isCodeRangeFormat ? (
              <>
                <th style={{ maxWidth: '120px' }}>From</th>
                <th style={{ maxWidth: '120px' }}>To</th>
              </>
            ) : (
              <>
                <th style={{ width: '100px' }}>Suppress</th>
                <th style={{ width: '100px' }}>Code</th>
              </>
            )}
            <th style={{ minWidth: '360px' }}>Text</th>
            <th style={{ maxWidth: '120px' }}>Scaling factor</th>
            <th style={{ minWidth: '45px' }} />
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td className="checkbox">
                <Field.Checkbox
                  name={`subGroups.${index}.selected`}
                  size="small"
                />
              </td>
              <td className="switchbox">
                <Field.Switch
                  name={`subGroups.${index}.active`}
                  size="small"
                  onChange={e => toggleActiveSwitch(index, e.value)}
                />
              </td>

              {isCodeRangeFormat ? (
                <td colSpan={2}>
                  <CodeRange name={`subGroups.${index}.code`} size="small" />
                </td>
              ) : (
                <>
                  <td className="text-center">
                    <Field.Switch
                      name={`subGroups.${index}.suppress`}
                      size="small"
                      disabled={field.active}
                    />
                  </td>
                  <td>
                    <Field.NumberInput
                      name={`subGroups.${index}.code`}
                      size="small"
                    />
                  </td>
                </>
              )}

              <td>
                <Field.Text
                  name={`subGroups.${index}.text`}
                  placeholder="Enter text"
                  className="form-control form-control-sm"
                  size="small"
                />
              </td>

              <td>
                <Field.NumberInput
                  name={`subGroups.${index}.weightValue`}
                  disabled={
                    field.merged || questionSource === ColumnSource.COLUMN
                  }
                  size="small"
                />
              </td>

              <td>
                <span className="d-flex flex-row justify-content-end">
                  <Tooltip
                    anchorElement="target"
                    position="top"
                    parentTitle={true}
                  >
                    <Button
                      id={`copy-column-row_${index}`}
                      type="button"
                      iconClass={'fa fa-regular fa-clone text-primary'}
                      fillMode={'flat'}
                      title={'Clone'}
                      onClick={() => duplicateRow(index)}
                      size={'small'}
                    />
                  </Tooltip>

                  <Tooltip
                    anchorElement="target"
                    position="top"
                    parentTitle={true}
                  >
                    <Button
                      id={`delete-column-row_${index}`}
                      type="button"
                      iconClass={'fa fa-regular fa-trash-can text-danger'}
                      fillMode={'flat'}
                      title={field.merged ? 'Delete merged' : 'Delete'}
                      onClick={() => deleteRow(index)}
                      size={'small'}
                    />
                  </Tooltip>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-start">
        {canAddNewRow && (
          <Button
            type="button"
            svgIcon={plusIcon}
            fillMode={'flat'}
            onClick={addRow}
          >
            Add new row
          </Button>
        )}
      </div>
    </div>
  );
};

export default QuestionColumns;
