import { Switch } from '@progress/kendo-react-inputs';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';
import { Fragment } from 'react';

interface Props {
  settings: any
  settingsValues: any
  onChangeSettingsValues: (a: any, b: any) => void
  languages: any
  updateDefaultLanguage: (name: any) => void
}

export const SurveySettingsTabContent = ({ settings, onChangeSettingsValues, settingsValues, languages, updateDefaultLanguage }: Props) => {
  const dropDownLanguages = languages.map((lang: { name: string, code: string, isDefault: boolean, title: string }) => {
    return {
      ...lang,
      title: `${lang.name} (${lang.code})`
    }
  });
  return (
    <div className='d-flex flex-column w-50 gap-lg'>
      <div className='d-flex flex-column gap-sm'>
        <p className='m-0'>Survey language</p>
        <div className="d-flex flex-column survey-option">
          <DropdownButton className='btn-block' items={dropDownLanguages} textField="title" text={dropDownLanguages.find((lang: any) => lang.isDefault)?.title} onItemClick={(e) => updateDefaultLanguage(e.item.code)} />
        </div>
      </div>
      {[
        ...settings.filter(({ type }: { type: string }) => type === 'String'),
        ...settings.filter(({ type }: { type: string }) => type !== 'String' && type !== 'Url' && type !== "reopenType")
      ].map((item, key) => (
        <Fragment key={key}>
          {
            item.type === 'Boolean' ?
              <div className="d-flex">
                <Switch checked={settingsValues[item.id]} onChange={(e) => onChangeSettingsValues(e.value, item.id)} className='mr-3' size="small" />
                <p className="m-0">
                  {item.name}
                </p>
              </div>
              :
              <Fragment>
                {
                  item.type === 'String' ? (
                    <div className="d-flex flex-column gap-sm">
                      <p className="m-0">
                        {item.name}
                      </p>
                      <input
                        name={item.name}
                        type="text"
                        className="form-control"
                        onChange={(e) => onChangeSettingsValues(e.target.value, item.id)}
                        value={settingsValues[item.id] === null ? '' : settingsValues[item.id]}
                      />
                    </div>
                  ) : null
                }
              </Fragment>
          }
        </Fragment>
      ))}
    </div>
  )
}
