import { Controller, useFormContext } from 'react-hook-form';
//
import { NumericTextBox } from '@progress/kendo-react-inputs';

// ----------------------------------------------------------------------

export const CodeRange = ({
  value,
  onChange,
  size = 'medium',
}: {
  value: string; // Format: "start:end"
  onChange: (start: number, end: number) => void;
  size?: 'small' | 'medium' | 'large';
}) => {
  const [startStr, endStr] = value.split(':');
  const start = Number.parseInt(startStr) || 0;
  const end = Number.parseInt(endStr) || 0;

  const handleStartIndexChange = (newValue: number | null) => {
    const validStartIndex = Math.min(Number(newValue) || 0, end);
    onChange(validStartIndex, end);
  };

  const handleEndIndexChange = (newValue: number | null) => {
    const validEndIndex = Math.max(Number(newValue) || 0, start);
    onChange(start, validEndIndex);
  };

  return (
    <div className="d-flex" style={{ gap: '0.5rem' }}>
      <NumericTextBox
        name="startIndex"
        value={start}
        min={0}
        max={end}
        onChange={e => handleStartIndexChange(e.value)}
        size={size}
      />
      <NumericTextBox
        name="endIndex"
        value={end}
        min={start}
        onChange={e => handleEndIndexChange(e.value)}
        size={size}
      />
    </div>
  );
};

type RHFCodeRangeProps = {
  name: string;
  size?: 'small' | 'medium' | 'large';
};

const RHFCodeRange = ({ name, ...other }: RHFCodeRangeProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <CodeRange
          value={value}
          onChange={(start, end) => onChange(`${start}:${end}`)}
          {...other}
        />
      )}
    />
  );
};

export default RHFCodeRange;
