import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useFlexibleBanners } from '../../hooks/useFlexibleBanners';
import type { Question } from '../../types';

// --------------------------------------------------------------------------

type CrosstableState = {
  firstColumn: Question[];
  scndColumn: Question[];
  bannerColumn: Record<string, Question[]>;
};

export const useCrosstableClearActions = () => {
  const dispatch = useAppDispatch();
  const state: CrosstableState = useAppSelector(
    rootState => rootState.stateReducer.state
  );

  const { flexibleBannersOption } = useFlexibleBanners();

  const clearSelected = useCallback(() => {
    const firstColumn = state.firstColumn.filter(q => !q.selected);
    const scndColumn = state.scndColumn.filter(q => !q.selected);

    if (
      firstColumn.length > 0 &&
      !firstColumn.some(q => q.active) &&
      state.firstColumn.length > 1
    ) {
      // Set the last question in the first column as active
      firstColumn[firstColumn.length - 1].active = true;
    }

    const bannerColumn = { ...state.bannerColumn };
    if (flexibleBannersOption.isOptionEnabled) {
      // delete all selected rows from bannerColumn
      state.firstColumn
        .filter(q => q.selected)
        .forEach(q => {
          delete bannerColumn[q.id];
        });

      // delete all selected columns from bannerColumn
      Object.keys(bannerColumn).forEach(key => {
        bannerColumn[key] = bannerColumn[key].filter(b => !b.selected);
      });
    }

    const payload = {
      firstColumn,
      scndColumn,
      bannerColumn,
    };

    dispatch({ type: 'XT_CLEAR_SELECTED', payload });
  }, [
    state.firstColumn,
    state.scndColumn,
    state.bannerColumn,
    flexibleBannersOption.isOptionEnabled,
    dispatch,
  ]);

  return {
    clearSelected,
  };
};
