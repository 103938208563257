import { v4 as uuid } from 'uuid';

export const validateQuestionId = (id, data, type) => {
  const isMultiple = type === 'multiple';
  const list = data.map(el => isMultiple ? el.qno : el.qno.slice(0, -2));

  const ALPHANUMERIC_REGEX = /^[a-z0-9]+$/i;
  const DUPLICATE_MESSAGE = 'Another question with this Id already exists. Please try another one.';
  const FORMAT_MESSAGE = 'Please match the requested question Id format.';

  if (isMultiple) {
    if (list.some(r => id.includes(r))) {
      return DUPLICATE_MESSAGE;
    }
    return id.every(p => ALPHANUMERIC_REGEX.test(p)) ? '' : FORMAT_MESSAGE;
  }

  if (list.includes(id)) {
    return DUPLICATE_MESSAGE;
  }
  return ALPHANUMERIC_REGEX.test(id) ? '' : FORMAT_MESSAGE;
};

export const checkCalcValues = (rows, type) => {
  const calculations = rows.map(el => el.calc);
  return calculations.includes(null) || calculations.includes('');
};

export const initializeQuestionData = (importedCreatedQuestion, type) => {
  const mapRowsWithIds = rows => rows.map(row => ({
    ...row,
    id: uuid(),
    inEdit: true
  }));

  const createSingleQuestion = question => ({
    id: question.id,
    text: question.text,
    type: question.questionType,
    rows: mapRowsWithIds(question.rows)
  });

  const createMultipleQuestions = questions => questions.map(question => ({
    id: question.id,
    text: question.text,
    type: question.questionType,
    uId: question.uId,
    rows: mapRowsWithIds(question.rows)
  }));

  if (importedCreatedQuestion.length === 1) {
    return createSingleQuestion(importedCreatedQuestion[0]);
  } else if (importedCreatedQuestion.length > 1) {
    return createMultipleQuestions(importedCreatedQuestion);
  }

  return { id: '', text: '', type: type, rows: [] };
};
