import { useWatch } from 'react-hook-form';
import type { EditableQuestion } from '../types';
import type { QuestionGroup } from '../../../types';
import type { ActionContext } from '../config/actions';

export const useContextForm = (context: ActionContext) => {
  const formData = useWatch<EditableQuestion>({
    name: context,
    defaultValue: [],
  }) as QuestionGroup[];

  const selected = formData.filter(x => x.selected);

  return {
    formData,
    selected,
  };
};
