export const returnHighestCodeValue = (elements: { type: string, attributes: { code?: string, redid: string } }[]) => {
  const allCodes: number[] = []
  elements.forEach((el) => {
    const {attributes, type} = el
    // attributes?.code includes the ? because JS files are using it, once these are converted to TS this could be removed. We are unsure if it's truly defined.
    if ((attributes?.code) && (type === 'r' || type === 'h' || type === 'other')) {
      const code = Number(attributes.code)
      if (!isNaN(code))
      {
        allCodes.push(code)
      }
    }
  })

  if (allCodes.length > 0) {
    return (Math.max(...allCodes) + 1).toString()
  } else {
    return '1'
  }
}
