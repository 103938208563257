import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@progress/kendo-react-dialogs';

import FilterBuilder from '../../../../Analysis/Analyze/components/dashboard/main/FilterBuilder/FilterBuilder';

export const SurveyFilterBuilder = ({ handleClose, filterData, surveyId, combineFilterData, manualFilterExpression, option, token, type, onSaveFilter, onSaveTextFilter, selectedTab }) => {
  const dispatch = useDispatch();
  const { theData, selectedElement } = useSelector((theState) => theState.surveyInitialDataReducer)

  const onSaveFilterOptions = (expression) => {
    if (type === 'options') {
      const updatedSelectedElement = JSON.parse(JSON.stringify(selectedElement))
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      updatedSelectedElement.attributes = { ...updatedSelectedElement.attributes, [option]: expression }
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: updatedSelectedElement })
      if (selectedElement.type === 'r' || selectedElement.type === 'other') {
        const updatedSelectedElementAttributes = updatedSelectedElement.attributes ? updatedSelectedElement.attributes : {}
        updatedSelectedItem.elements.find((el) => el.type === 'rgroup').elements.find((el, key) => key === selectedElement.index).attributes = { ...updatedSelectedElementAttributes, [option]: expression }
        dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      } else if (selectedElement.type === 'info' || selectedElement.type === 'goto') {
        updatedSelectedItem.attributes = { ...updatedSelectedItem.attributes, [option]: expression }
        dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      }
      dispatch({ type: 'SAVE_QUESTION' })
      dispatch({ type: 'SURVEY_RELOAD_OPTIONS', payload: { reload: true, expression: expression } })
      handleClose()
    } else if (type === "textElements") {
      onSaveTextFilter(expression)
    } else if (type === 'lists') {
      onSaveFilter(expression)
    } else if (type === "list-filters") {
      onSaveFilter(expression)
    } else {
      onSaveFilter(expression)
    }
  }

  return (
    <Dialog onClose={handleClose} height="90%" width="90%" className="filter-builder">
      <FilterBuilder
        handleClose={handleClose}
        filterData={filterData}
        datasetId={surveyId}
        onSaveFilterOptions={onSaveFilterOptions}
        combineFilterData={combineFilterData}
        manualFilterExpression={manualFilterExpression}
        option={option}
        token={token}
        type='survey'
      />
    </Dialog >
  )
}
