import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
//
import { useAppSelector } from 'app/hooks';
import { returnAnalysisActionsItems } from 'components/Analysis/Analyze/components/shared/helpers/returnAnalysisActionsItems/returnAnalysisActionsItems';
import type { AnalysisRouteParams } from '../types';
import { ActionTypes } from '../types';

// --------------------------------------------------------------------------

export const useAnalysisActions = () => {
  const { analysisType } = useParams<AnalysisRouteParams>();
  const { copyData } = useAppSelector(
    rootState => rootState.setInitialDataReducer
  );
  const { state, undoQuestionOptions, redoQuestionOptions } = useAppSelector(
    rootState => rootState.stateReducer
  );

  const isFlexibleBannerEnabled = !!state.questionOptions.XtFlexBanner;
  const hasNoRows = !state.firstColumn.length;

  const baseActions = useMemo(
    () =>
      returnAnalysisActionsItems(
        undoQuestionOptions,
        redoQuestionOptions,
        false,
        analysisType,
        hasNoRows || isFlexibleBannerEnabled,
        state.checkedNum,
        copyData.elements
      ),
    [
      undoQuestionOptions,
      redoQuestionOptions,
      analysisType,
      hasNoRows,
      isFlexibleBannerEnabled,
      state.checkedNum,
      copyData.elements,
    ]
  );

  // Additional actions
  const chartAction = {
    actionName: 'Copy to Chart',
    action: 'copyToChart',
    iconClass: 'fas fa-copy',
    disabled: isFlexibleBannerEnabled,
  };

  // Combine actions
  const actions = useMemo(
    () => [...baseActions, chartAction],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [baseActions, isFlexibleBannerEnabled]
  );

  const clearActions = useMemo(
    () => [
      { actionName: 'Clear all', action: ActionTypes.CLEAR_ALL },
      ...(isFlexibleBannerEnabled
        ? []
        : [
            { actionName: 'Clear rows', action: ActionTypes.CLEAR_ROWS },
            { actionName: 'Clear columns', action: ActionTypes.CLEAR_COLUMNS },
          ]),
      { actionName: 'Clear selected', action: ActionTypes.CLEAR_SELECTED },
      { actionName: 'Clear options for selected', action: ActionTypes.CLEAR_SELECTED_OPTIONS },
      { actionName: 'Clear options', action: ActionTypes.CLEAR_OPTIONS },
    ],
    [isFlexibleBannerEnabled]
  );

  return {
    actions,
    clearActions,
  };
};

export default useAnalysisActions;
