import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input, type InputChangeEvent } from '@progress/kendo-react-inputs';
import { useMemo, useState, useCallback } from 'react';
import { BaseDropDownList as WeightSetDropDownList } from '../../../../../../shared/Inputs/BaseDropDownList/BaseDropDownList';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

interface WeightSetBuilderProps {
  handleClose: () => void;
  updateWeightSet: () => void;
  weightSets: WeightSet[];
  setWeightSets: React.Dispatch<React.SetStateAction<WeightSet[]>>;
  weightOptions: string[];
}

interface BannerWeight {
  w?: string;
  a?: string;
}

interface WeightSet {
  selected: boolean;
  id: string;
  title: string;
  content: string;
  subqIndex: number;
  groups: Group[];
  subGroups: unknown;
  type: string;
  active: boolean;
  options: unknown;
}

interface Group {
  id: string;
  selected: boolean;
  active: boolean;
  code: number;
  text: string;
  bannerWeight?: BannerWeight;
}

/**
 * Finds duplicate bannerWeight abbreviations where the same "a" value
 * is used with more than one unique "w".
 *
 * @param bannerWeights Array of BannerWeight objects.
 * @returns Array of duplicate abbreviation strings.
 */
function findDuplicates(bannerWeights: BannerWeight[]): string[] {
  const aToWs: Record<string, Set<string>> = {};

  for (const item of bannerWeights) {
    if (!item.a) continue;
    if (!aToWs[item.a]) {
      aToWs[item.a] = new Set();
    }
    if (item.w) {
      aToWs[item.a].add(item.w);
    }
  }

  const duplicates: string[] = [];
  for (const a in aToWs) {
    if (aToWs[a].size > 1) {
      duplicates.push(a);
    }
  }
  return duplicates;
}

/**
 * Finds weight codes that have mismatched bannerWeight abbreviations.
 * For each bannerWeight.w, all associated bannerWeight.a should be the same.
 *
 * @param bannerWeights Array of BannerWeight objects.
 * @returns Array of weight codes (w) that have mismatched abbreviations.
 */
function findMismatchedWeights(bannerWeights: BannerWeight[]): string[] {
  const wToAs: Record<string, Set<string>> = {};

  for (const item of bannerWeights) {
    if (!item.w) continue;
    if (!wToAs[item.w]) {
      wToAs[item.w] = new Set();
    }
    if (item.a) {
      wToAs[item.w].add(item.a);
    }
  }

  const mismatchedWs: string[] = [];
  for (const w in wToAs) {
    if (wToAs[w].size > 1) {
      mismatchedWs.push(w);
    }
  }
  return mismatchedWs;
}

const WeightSetBuilder = ({
  handleClose,
  updateWeightSet,
  weightSets,
  weightOptions,
  setWeightSets,
}: WeightSetBuilderProps) => {

  const [selectedTabId, setSelectedTabId] = useState<string>(weightSets[0].id);
  const [duplicateWeightAbbreviations, setDuplicateWeightAbbreviations] = useState<string[]>([]);
  const [mismatchedWeightCodes, setMismatchedWeightCodes] = useState<string[]>([]);

  const selectedWeightSet = useMemo(
    () => weightSets.find(ws => ws.id === selectedTabId) || weightSets[0],
    [weightSets, selectedTabId]
  );

  const formattedWeightOptions = useMemo(
    () =>
      weightOptions.map(option => {
        if (option === 'None') return { text: option, id: '' };
        return { text: option, id: option };
      }),
    [weightOptions]
  );

  const selectedTabIndex = weightSets.findIndex(el => el.id === selectedWeightSet.id);

  const handleWeightAbbreviationChange = useCallback(
    (value: string, groupId: string) => {
      setWeightSets(prev =>
        prev.map(ws => {
          if (ws.id !== selectedWeightSet.id) return ws;
          return {
            ...ws,
            groups: ws.groups.map(group =>
              group.id === groupId
                ? { ...group, bannerWeight: { w: group.bannerWeight?.w, a: value } }
                : group
            ),
          };
        })
      );
    },
    [selectedWeightSet.id, setWeightSets]
  );

  const handleWeightSelectChange = useCallback(
    (value: { id: string; text: string }, groupId: string) => {
      setWeightSets(prev =>
        prev.map(ws => {
          if (ws.id !== selectedWeightSet.id) return ws;
          return {
            ...ws,
            groups: ws.groups.map(group =>
              group.id === groupId
                ? { ...group, bannerWeight: { w: value.id, a: group.bannerWeight?.a } }
                : group
            ),
          };
        })
      );
    },
    [selectedWeightSet.id, setWeightSets]
  );

  if (!weightSets?.length) {
    return null;
  }

  const handleUpdate = () => {
    setDuplicateWeightAbbreviations([]);
    setMismatchedWeightCodes([]);

    const bannerWeights: BannerWeight[] = weightSets.flatMap(weightSet =>
      weightSet.groups
        .map(group => group.bannerWeight)
        .filter((bw): bw is BannerWeight => Boolean(bw && bw.a))
    );

    const duplicateAbbrevs = findDuplicates(bannerWeights);
    const mismatchedWs = findMismatchedWeights(bannerWeights);

    setDuplicateWeightAbbreviations(duplicateAbbrevs);
    setMismatchedWeightCodes(mismatchedWs);

    if (duplicateAbbrevs.length === 0 && mismatchedWs.length === 0) {
      updateWeightSet();
    }
  };

  const isErrorField = (group: Group): boolean => {
    const bw = group.bannerWeight;
    if (!bw) return false;
    return Boolean(
      (bw.a && duplicateWeightAbbreviations.includes(bw.a)) ||
      (bw.w && mismatchedWeightCodes.includes(bw.w))
    );
  };

  const tabHasErrors = (ws: WeightSet): boolean =>
    ws.groups.some(group => isErrorField(group));

  return (
    <Dialog height="90%" width="90%" className="filter-builder">
      <TabStrip
        className="analyze-tabs question-editor-tabs"
        selected={selectedTabIndex}
        onSelect={e => setSelectedTabId(weightSets[e.selected].id)}
      >
        {weightSets.map(weightSet => (
          <TabStripTab
            key={weightSet.id}
            title={
              <>
                {weightSet.title}
                {tabHasErrors(weightSet) && (
                  <i className="ml-1 text-danger fa-solid fa-circle-exclamation" title="Errors in this tab" />
                )}
              </>
            }
          >
            <div className="p-4">
              {(duplicateWeightAbbreviations.length > 0 || mismatchedWeightCodes.length > 0) && (
                <div className="h-25">
                  {duplicateWeightAbbreviations.length > 0 && (
                    <p className="text-danger">
                      There are duplicate weight abbreviations for different weight sets.
                    </p>
                  )}
                  {mismatchedWeightCodes.length > 0 && (
                    <p className="text-danger">
                      One or more banner abbreviation does not match.
                    </p>
                  )}
                </div>
              )}
              <table className="table">
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Banner Name</th>
                    <th>Select Weight Set</th>
                    <th>Weight Abbreviation</th>
                  </tr>
                </thead>
                <tbody>
                  {weightSet.id === selectedWeightSet.id &&
                    selectedWeightSet.groups.map(group => (
                      <tr key={group.id}>
                        <td>{group.code}</td>
                        <td>
                          <p className="text-left">{group.text}</p>
                        </td>
                        <td>
                          <WeightSetDropDownList
                            className="form-control h-32 p-0"
                            value={formattedWeightOptions.find(
                              option => option.id === group?.bannerWeight?.w
                            )}
                            data={formattedWeightOptions}
                            onChange={(e: DropDownListChangeEvent) =>
                              handleWeightSelectChange(e.value, group.id)
                            }
                            textField="text"
                            dataItemKey="id"
                          />
                        </td>
                        <td>
                          <Input
                            onChange={(e: InputChangeEvent) =>
                              handleWeightAbbreviationChange(e.value, group.id)
                            }
                            value={group?.bannerWeight?.a || ''}
                            maxLength={12}
                            style={{
                              borderColor: isErrorField(group) ? '#ef4c52' : undefined,
                            }}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </TabStripTab>
        ))}
      </TabStrip>
      <DialogActionsBar>
        <button type="button" className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </button>
        <button type="button" className="btn btn-primary" onClick={handleUpdate}>
          Update
        </button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default WeightSetBuilder;