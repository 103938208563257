import { useState } from 'react';

const FlexibleBannersOptionBanner = () => {
  const styles = {
    banner: {
      backgroundColor: '#b4f6e9',
      border: '1px solid #dfdfdf',
      borderBottom: 'none',
      color: 'rgba(23,33,71,0.85)',
    },
    closeButton: {
      color: '#172147',
    },
    hide: {
      display: 'none',
    },
  };

  const [isVisible, setIsVisible] = useState(true);

  return (
    <div
      className={`${isVisible ? 'd-flex' : 'd-none'}`}
      style={styles.banner}
    >
      <div className='d-flex align-items-center flex-fill p-2'>
        <i className='fa-light fa-circle-info ml-2 mr-2' />
        <small>
          Flexible Banners option enabled. Banners/column variables may differ
          for each row variable.
        </small>
      </div>
      <button
        type='button'
        className='close mr-5 text-primary'
        data-dismiss='alert'
        aria-label='Close'
        style={styles.closeButton}
        onClick={() => setIsVisible(false)}
      >
        <span aria-hidden='true' className='font-weight-light'>
          &times;
        </span>
      </button>
    </div>
  );
};

export default FlexibleBannersOptionBanner;
