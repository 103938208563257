import { useMemo } from 'react';
//
import { useQuestionEditor } from '../contexts/QuestionEditorContext';
import type { ActionConfig, ActionContext } from '../config/actions';
import { useDefaultActions } from './useDefaultActions';

// -------------------------------------------------------------------------

/**
 * Helper to get available actions for a given context
 */
const getAvailableActions = (
  context: ActionContext,
  actions: ActionConfig[],
): ActionConfig[] => {
  return actions
    .filter(
      action =>
        action.context.includes(context) &&
        (!action.isVisible || action.isVisible()),
    )
    .sort((a, b) => (a.order || 0) - (b.order || 0));
};

/**
 * Hook for managing editor actions with dynamic options and handlers
 */
export const useEditorActions = (
  context: ActionContext,
  customActions: ActionConfig[] = [],
) => {
  const { options } = useQuestionEditor();
  const { actions } = useDefaultActions();

  const availableActions = useMemo(() => {
    const defaultActions = getAvailableActions(context, actions);

    // TODO: Filter actions based on editor options (e.g. enable copy/paste)
    const filteredActions = defaultActions.filter(action => {
      if (action.id.startsWith('edits-') && !options.enablePasteEdits) {
        return false;
      }
      return true;
    });

    // Combine with custom actions
    return [...filteredActions, ...customActions].sort(
      (a, b) => (a.order || 0) - (b.order || 0),
    );
  }, [context, actions, customActions, options.enablePasteEdits]);

  const executeAction = async (actionId: string) => {
    const action = availableActions.find(a => a.id === actionId);
    if (!action) {
      console.warn(`Action ${actionId} not found`);
      return;
    }

    if (action.isDisabled?.()) {
      return;
    }

    try {
      await action.handler();
    } catch (error) {
      console.error(`Error executing action ${actionId}:`, error);
    }
  };

  return {
    actions: availableActions,
    executeAction,
    allActions: actions,
  };
};
