import { useEffect, useCallback, memo } from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
//
import { Card, CardBody } from '@progress/kendo-react-layout';
//
import { Field } from 'components/shared/hook-form';
import { useQuestionEditor } from '../contexts/QuestionEditorContext';
import { useActionDialog } from '../contexts/ActionDialogContext';
import type { EditableQuestion } from '../types';
import { ColumnSource } from '../types';
import { ActionContext } from '../config/actions';
import ContextActions from './components/ContextActions';
import QuestionRows from './manage-rows/QuestionRows';
import QuestionColumns from './manage-colums/QuestionColumns';
import ContentToolbar from './components/ContentToolbar';
import ContentHeader from './components/ContentHeader';

import '../styles.scss';

// ----------------------------------------------------------------

const EmptyContent = memo(() => (
  <div className="d-flex text-muted vh-100 align-items-center justify-content-center">
    No data to display. Please select a question from the list.
  </div>
));

const FieldGroup = memo(
  ({
    label,
    name,
    placeholder,
  }: {
    label: string;
    name: string;
    placeholder: string;
  }) => (
    <div className="input-group input-group-sm">
      <div className="input-group-prepend b-0">
        <span className="input-group-text w-10">{label}</span>
      </div>
      <Field.Text
        name={name}
        placeholder={placeholder}
        className="form-control form-control-sm"
      />
    </div>
  ),
);

const QuestionDetailsForm = memo(() => {
  const { selected, updateQuestion, currentContext } = useQuestionEditor();
  const {
    reset,
    getValues,
    formState: { isDirty },
  } = useFormContext<EditableQuestion>();

  const handleBlur = useCallback(() => {
    if (selected && isDirty) {
      const currentValues = getValues();
      if (JSON.stringify(currentValues) !== JSON.stringify(selected)) {
        updateQuestion(selected.id, currentValues);
      }
    }
  }, [selected, isDirty, getValues, updateQuestion]);

  // Reset form when selection changes
  useEffect(() => {
    if (selected) {
      reset({ ...selected });
    }
  }, [selected, reset]);

  if (!selected) {
    return <EmptyContent />;
  }

  const sourceLabel = {
    [ColumnSource.ROW]: 'row',
    [ColumnSource.COLUMN]: 'column',
  }[selected.source];

  return (
    <form onBlur={handleBlur} className="question-editor__form">
      <div className="question-editor__content-inner">
        <div className="d-flex align-items-center justify-content-between p-4 mr-3">
          <ContentHeader
            title={selected.title}
            subtitle={`Source: ${sourceLabel}`}
            iconType={selected.type}
          />
          <ContentToolbar />
        </div>

        <section
          className="bg-white p-4 mr-3"
          style={{ borderTop: '1px solid #e0e0e0' }}
        >
          <div className="d-flex flex-column gap-md">
            <FieldGroup
              label="Table title"
              name="tableTitle"
              placeholder="Table title/description here"
            />
            <FieldGroup
              label="Question text"
              name="content"
              placeholder="Question content here"
            />
          </div>

          <div className="question-editor__content-inner-toolbar mt-5">
            <ContextActions />
          </div>

          <Card className="question-editor__content-inner-card mt-2 shadow-sm">
            <CardBody className="p-2">
              {currentContext === ActionContext.ROWS && <QuestionRows />}
              {currentContext === ActionContext.COLUMNS && <QuestionColumns />}
            </CardBody>
          </Card>
        </section>
      </div>
    </form>
  );
});

const QuestionDetails = () => {
  const { renderDialog } = useActionDialog();

  const formMethods = useForm<EditableQuestion>({
    mode: 'onBlur',
    defaultValues: {
      id: '',
      title: '',
      content: '',
      groups: [],
      subGroups: [],
      swapRowsCols: false,
      summaryValuesOnly: false,
    },
  });

  return (
    <FormProvider {...formMethods}>
      <QuestionDetailsForm />
      {renderDialog()}
    </FormProvider>
  );
};

export default QuestionDetails;
