import React from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { NumericTextBox, Switch, Input } from '@progress/kendo-react-inputs';

const grid = 8;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => {
  return {
    cursor: 'grabbing',
    userSelect: "none",
    background: "white",
    width: isDragging && '59%',
    display: isDragging && 'flex',
    flexGrow: isDragging && '1',
    alignItems: isDragging && 'center',
    justifyContent: isDragging && 'space-around',
    border: isDragging ? '1px solid #dee2e6' : '',
    boxShadow: isDragging ? '0 0 1rem rgba(0, 0, 0, 0.15)' : '',
    ...draggableStyle
  }
};

const getListStyle = (isDragging) => ({
  padding: grid,
  width: '100%',
  background: isDragging ? "whitesmoke" : "white",
});

export default function QuestionEditorRowsTable({ question, source, onSelectedChange, setCheckedGroupItems, onActiveChange, onChangeQuestionRowText, onChangeQuestionRowWeightValue, updateQuestionState, deleteMergedRow }) {
  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      question.groups,
      result.source.index,
      result.destination.index
    );
    updateQuestionState('groups', question, items)
  }

  const onToggleSelectAllHandler = (e) => {
    const updatedQuestion = JSON.parse(JSON.stringify(question))
    updatedQuestion['groups'].forEach(item => {
      item.selected = e.target.checked
    })
    if (e.target.checked === true) {
      setCheckedGroupItems(updatedQuestion.groups.map(item => item.id))
    } else {
      setCheckedGroupItems([])
    }
    updateQuestionState('groups', question, updatedQuestion.groups)
  }

  const onToggleActivateAllHandler = (e) => {
    const updatedQuestion = JSON.parse(JSON.stringify(question))
    updatedQuestion['groups'].forEach(item => {
      item.active = e.value
    })
    updateQuestionState('groups', question, updatedQuestion.groups)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <table
            className="table"
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}>
            <thead className={`text-center ${snapshot.isDraggingOver ? 'bg-white' : ''}`}>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    className="k-checkbox"
                    checked={question.groups.length === question.groups.filter(item => item.selected).length}
                    onChange={(e) => onToggleSelectAllHandler(e)} />
                </th>
                <th>
                  <Switch
                    name="switch-checkbox"
                    checked={question.groups.length === question.groups.filter(item => item.active).length}
                    onChange={(e) => onToggleActivateAllHandler(e)}
                    size="small"
                  />
                </th>
                <th>Code</th>
                <th>Text</th>
                <th>{question.subGroups.length === 0 ? 'Scaling factor' : ''}</th>
                <th>Define row</th>
              </tr>
            </thead>
            <tbody>
              {
                question.groups.map((row, index) => (
                  <Draggable key={row.id} draggableId={row.id} index={index} >
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...{ ...provided.dragHandleProps, tabIndex: -1 }}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}>
                        <td className="align-middle">
                          <input
                            tabIndex="-1"
                            type="checkbox"
                            className="k-checkbox"
                            checked={row.selected}
                            onChange={() => { }}
                            onClick={(e) => { onSelectedChange(e, row.id, 'groups') }} />
                        </td>
                        <td className="align-middle">
                          <Switch
                            tabIndex={-1}
                            name="switch-checkbox"
                            checked={row.active}
                            onChange={() => { onActiveChange(row.id, 'groups') }}
                            size="small"
                          />
                        </td>
                        <td className="align-middle">
                          {row.code}
                        </td>
                        <td className="align-middle" style={{ width: snapshot.isDragging && '360px' }}>
                          <Input
                            tabIndex="0"
                            className="form-control"
                            value={row.text}
                            placeholder='Enter text'
                            onChange={(e) => onChangeQuestionRowText(e, row.id, 'groups')}>
                          </Input>
                        </td>
                        {
                          question.subGroups.length === 0 ?
                            <td className="align-middle" style={{ width: snapshot.isDragging && '400px' }}>
                              <NumericTextBox
                                tabIndex={0}
                                size="large"
                                disabled={row.merged || source === 'scndColumn'}
                                value={row.weightValue}
                                onChange={(e) => onChangeQuestionRowWeightValue(e, row.id, 'groups')}>
                              </NumericTextBox>
                            </td>
                            : null
                        }
                        {
                          row.merged &&
                          <td className="align-middle" style={{ maxWidth: "24px", paddingLeft: "0" }}>
                            <button type="button" className="cursor-pointer border-0 bg-white" onClick={() => deleteMergedRow(question, row, "groups")}>
                              <i className="fas fa-trash-alt text-danger" />
                            </button>
                          </td>
                        }
                        <td>
                          <Input
                            disabled
                            className="form-control"
                            value={row.customExpr || ""}
                          />
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
}
