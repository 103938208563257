import { useCallback } from 'react';

import { v4 as uuid } from 'uuid';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import type { ContentType, Question } from '../types';
import { useStateMapper } from './useStateMapper';

//--------------------------------------------------------------------

export type CheckedItem = {
  id: string;
  source: 'firstColumn' | 'scndColumn';
};

export type CopiedItem = {
  id: string;
  source: 'firstColumn' | 'scndColumn';
} & Question;

//--------------------------------------------------------------------

export const insertElementsAtIndex = (
  destination: Question[],
  elements: Question[],
  index: number
): { id: string }[] => {
  const result = [...destination];
  if (index >= 0) {
    result.splice(index + 1, 0, ...elements);
  } else {
    result.push(...elements);
  }

  return result;
};

export const getLastCheckedIndex = (
  column: { id: string }[],
  checkedItems: CheckedItem[],
  source: string
) => {
  const sourceChecked = checkedItems.filter(el => el.source === source);
  const lastChecked = sourceChecked[sourceChecked.length - 1];
  return lastChecked ? column.findIndex(el => el.id === lastChecked.id) : -1;
};

//-------------------------------------------------------------------------------

export const useCopyPaste = (contentType: ContentType) => {
  const dispatch = useAppDispatch();

  const { state } = useStateMapper(contentType);
  const { copyData } = useAppSelector(
    rootState => rootState.setInitialDataReducer
  );

  const dispatchError = useCallback(
    (message: string) => {
      dispatch({
        type: 'SHOW_ERROR_NOTIFICATION',
        payload: { msg: message },
      });
    },
    [dispatch]
  );

  const copyQuestions = useCallback(() => {
    const selectedItems = state.checkedNum
      .map((checkedEl: CheckedItem) => {
        const item = state[checkedEl.source].find(
          (el: Question) => el.id === checkedEl.id
        );

        return (
          item && {
            ...item,
            id: uuid(),
            selected: false,
            active: false,
            source: checkedEl.source,
          }
        );
      })
      .filter(Boolean);

    if (selectedItems.length > 0) {
      dispatch({
        type: 'SET_COPIED_ELEMENTS',
        payload: { source: contentType, elements: selectedItems },
      });
      dispatch({
        type: 'SHOW_ACTION_NOTIFICATION',
        payload: { msg: 'The selected rows/columns have been copied' },
      });
    } else {
      dispatchError('Selected row/column not found');
    }
  }, [state, contentType, dispatch, dispatchError]);


  const pasteQuestions = useCallback(() => {
    // Early validation
    if (copyData.source !== contentType) {
      dispatchError('Cannot paste rows/columns from different source');
      return;
    }

    if (!copyData.elements?.length) {
      dispatchError('No copied rows/columns found');
      return;
    }

    const reducerType = `${contentType.toUpperCase()}_PASTE_QUESTIONS`;

     // Filter elements by source
     const copiedElements = {
      firstColumn: copyData.elements
        .filter((el: CopiedItem) => el.source === 'firstColumn')
        .map(({ source, ...el }: CopiedItem) => el),

      secondColumn: copyData.elements
        .filter((el: CopiedItem) => el.source === 'scndColumn')
        .map(({ source, ...el }: CopiedItem) => el),
    };

    // Get insertion indices
    const firstColumnIndex = getLastCheckedIndex(
      state.firstColumn,
      state.checkedNum,
      'firstColumn'
    );
    const secondColumnIndex = getLastCheckedIndex(
      state.scndColumn,
      state.checkedNum,
      'scndColumn'
    );

    // Create updated columns
    const updatedColumns = {
      firstColumn: insertElementsAtIndex(
        state.firstColumn,
        copiedElements.firstColumn,
        firstColumnIndex
      ),
      scndColumn: insertElementsAtIndex(
        state.scndColumn,
        copiedElements.secondColumn,
        secondColumnIndex
      ),
    };

    dispatch({ type: reducerType, payload: updatedColumns });
    dispatch({
      type: 'SET_COPIED_ELEMENTS',
      payload: { source: null, elements: null },
    });
    dispatch({
      type: 'SHOW_ACTION_NOTIFICATION',
      payload: { msg: 'The selected rows/columns have been pasted' },
    });
  }, [
    contentType,
    copyData.elements,
    copyData.source,
    dispatch,
    dispatchError,
    state.checkedNum,
    state.firstColumn,
    state.scndColumn,
  ]);

  return {
    copyQuestions,
    pasteQuestions,
  };
};
