import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { surveyTools } from './toolTabs';
import { Icon } from '../../../shared/Icon/Icon';
import { parseJwt } from '../../../shared/helpers/decodeJWT/decodeJWT';
import { SurveyDesignTools } from './SurveyDesignTools/SurveyDesignTools';
import { updateLanguagesList } from '../../../shared/LanguagesList/updateLanguagesList';
import { checkDisabledTab, returnElementOptions, returnSelectedElementData, returnSurveyDesignData, returnValidationErrors, } from './helpers/returnDataHelpers/returnDataHelpers';
import { fetchGetJson as getSurveyIndex, fetchGetJson as getSurveyOptions, fetchGetJson as getSurveyElement, fetchGetJson as getSurveyQuota, fetchGetJson as validateSurvey, fetchGetJson as getSurveyReviews, rsAppUrl } from '../../../../services/services';

export const SurveyDesignTabContent = ({ user, token }) => {
  const dispatch = useDispatch();
  const [didMount, setDidMount] = useState(true)
  const [toolSelected, setToolSelected] = useState(0)
  const { data } = useSelector(theState => (theState.breadcrumbStateReducer));
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer));
  const { survey } = useParams();
  const customerId = user[`${rsAppUrl}/customerid`].split(',')

  let tools = surveyTools;

  const parsedToken = parseJwt(token);
  const canCreateCustomQuestions = parsedToken[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/customQuestionAccess`];
  const canShowXMLViewer = customerId.some(id => ['024f7442-48b8-4b31-9b07-92421f0e602f', '3ef11f3f-290d-4a64-925c-34ae02f863ce'].includes(id));

  tools = tools.filter(tool => {
    if (!canCreateCustomQuestions && !canShowXMLViewer) return tool.id !== 6 && tool.id !== 7;
    if (!canCreateCustomQuestions) return tool.id !== 6;
    if (!canShowXMLViewer) return tool.id !== 7;
    return true;
  });

  useEffect(() => {
    if (didMount && theData.allOptions && theData.allOptions.length === 0) {
      setDidMount(false)
      getSurveyOptions('resources/survey-options', token)
        .then(res => {
          if (res && (res.error || res.message)) {
            dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: res.message ? res.message : res.error, type: 'modal' } })
          } else if (res) {
            const body = {
              allOptions: res.surveyAttributes,
              elements: res.surveyElements,
              dropdownValues: res.surveyEnumerations,
              visualisationSettings: res.visualisationSettings,
              supportedFonts: res.supportedFonts
            }
            dispatch({ type: 'SET_SURVEY_OPTIONS', payload: body })
          }
        })

      // get reviews for the survey
      getSurveyReviews(`sur/reviews/${customerId}/${survey}`).then(res => {
        if (res && !res.error && !res.message) {
          dispatch({ type: 'UPDATE_SURVEY_REVIEW', payload: { surveyReviews: res } })
          dispatch({ type: 'SET_IS_LOADING_COMMENTS', payload: false });
        } else if (res.error || res.message) {
          dispatch({ type: 'UPDATE_SURVEY_REVIEW', payload: { surveyReviews: [] } })
          dispatch({ type: 'SET_IS_LOADING_COMMENTS', payload: false });
        }
      })
    }
  }, [theData.allOptions, didMount, token, dispatch, survey, customerId])

  const updateElements = (elementId, validate) => {
    // This gets all the elements contained in the survey which are displayed in design editor: Sections > their elements
    getSurveyIndex(`su/projects/${data.projectId}/surveys/${data.documentId}/index`, token)
      .then(res => {
        if (res && !res.error && !res.message) {
          const originalData = res
          originalData.languages = updateLanguagesList(originalData.languages)
          const data = returnSurveyDesignData(res, elementId, theData.validationErrors)
          dispatch({ type: 'UPDATE_SURVEY_DATA', payload: { data: data, originalData: originalData } })
          if (elementId) {
            data.forEach(el => {
              if (el.elements.filter(item => item.selected === true).length !== 0) {
                // And then this calls the function which gets additional info only for the selected element
                returnSelectedElementRes(el.elements.find(item => item.selected === true), data, validate)
              }
            })
          } else {
            dispatch({ type: 'SET_IS_LOADING_OPTIONS', payload: false })
          }
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        }
      })
  }

  const returnSelectedElementRes = (item, data, validate) => {
    if (item.type !== 'quota') {
      getSurveyElement(`su/projects/${item.projectId}/surveys/${item.surveyId}/elements/${item.id}`, token)
        .then(res => {
          if (res && !res.error && !res.message) {
            onSelectSurveyElement(res, item, data, validate)
            dispatch({ type: 'SET_IS_LOADING_OPTIONS', payload: false })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
          }
        })
    } else if (item.type === 'quota') {
      getSurveyQuota(`projects/${item.projectId}/surveys/${item.surveyId}/quotas/${item.quotaId}/extended`, token)
        .then(res => {
          dispatch({ type: 'SET_IS_LOADING_OPTIONS', payload: false })
          if (res) {
            if (res && !res.error && !res.message) {
              let originalQuota = JSON.parse(JSON.stringify(res.quotaDocument));
              originalQuota.quotaCells = res.cells
              originalQuota.disabled = res.disabled
              originalQuota.published = res.published
              originalQuota.quotaTable = res.quotaTable
              onSelectSurveyElement(originalQuota, item, data, validate)
            } else {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
            }
          }
        })
    }
  }

  const onSelectSurveyElement = (res, item, data, validate) => {
    if (validate) {
      validateSurvey(`su/projects/${theData.originalSelectedItem.projectId}/surveys/${theData.originalSelectedItem.surveyId}/validate`, token)
        .then(validationRes => {
          if (validationRes && (validationRes.error || validationRes.message)) {
            dispatch({ type: 'UPDATE_ERROR_MESSAGE', payload: { msg: validationRes.message ? validationRes.message : validationRes.error, type: 'modal' } })
          } else {
            let errors = []
            if (validationRes.isValid === false) {
              dispatch({ type: "SET_SHOW_ERROR_LOG", payload: true })
              if (validationRes.errors.length > 0) {
                errors = returnValidationErrors(validationRes)
              }
              else {
                errors = theData.validationErrors
              }
            } else {
              dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'No validation errors were found.' } })
            }
            dispatch({
              type: 'UPDATE_SURVEY_VALIDATION_ERRORS',
              payload: {
                errors: errors,
                originalItem: res,
                selectedItem: theData.selectedItem ? returnSelectedElementData(res, theData.data.find(el => el.index === theData.selectedItem.section).elements.find(el => el.selected === true), errors, theData.data) : null,
                data: returnSurveyDesignData(theData.originalData, theData.selectedItem ? theData.selectedItem.id : null, errors)
              }
            })
            dispatch({ type: 'TOGGLE_DISABLED_QUESTIONS', payload: false });
          }
        })
    } else {
      const selectedItem = returnSelectedElementData(res, item, theData.validationErrors, theData.data)
      const elementOptions = res.type !== 'quota-document' ? [{
        type: res.type,
        expanded: true,
        published: res.attributes?.['miext:id'] ? true : false,
        isDisabled: res.attributes?.['miext:disabled'] && res.attributes['miext:disabled'] === "true" ? true : false,
        index: null,
        items: returnElementOptions(res, res.attributes?.['miext:disabled'] && res.attributes['miext:disabled'] === "true" ? true : false, res.type, null)
      }] : null
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: selectedItem })
      dispatch({ type: 'SURVEY_DATA_SELECT_ELEMENT', payload: { data: data, item: selectedItem, originalItem: res, elementOptions: elementOptions } })
    }
  }

  return (
    <div className="flex-grow-1 d-flex flex-column">
      <div className="container-fluid h-100 p-0">
        <div className="d-flex h-100">
          <div className="d-flex flex-column align-items-center border-right build-nav">
            <ul>
              {
                tools.map((tool) => (
                  <li key={tool.id}>
                    <button
                      type='button'
                      aria-label={tool.title}
                      name={tool.title}
                      disabled={checkDisabledTab(tool, theData.selectedItem)}
                      className={`btn ${toolSelected === tool.id ? 'nav-item-selected' : ''} d-flex align-items-center justify-content-center shadow-none border-0 mt-1 mb-1 nav-item`}
                      onClick={() => setToolSelected(tool.id)}
                    >
                      <Icon className={`${toolSelected === tool.id ? 'fill-analyze' : 'fill-primary'}`} type={tool.icon} />
                      {/* {
                    key === 0 && theData.validationErrors.length > 0 &&
                    <span className="badge badge-danger rounded-pill counter">{theData.validationErrors.length}</span>
                  } */}
                    </button>
                    <span className='tooltip'>{tool.title}</span>
                  </li>
                ))
              }
            </ul>
          </div>
          <SurveyDesignTools
            toolSelected={toolSelected}
            setToolSelected={(id) => setToolSelected(id)}
            updateElements={updateElements}
          />
        </div>
      </div >
    </div >
  );
}
