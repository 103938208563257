import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import type { CopiedItem } from '../../hooks/useCopyPaste';
import {
  getLastCheckedIndex,
  insertElementsAtIndex,
  useCopyPaste,
} from '../../hooks/useCopyPaste';
import { useFlexibleBanners } from '../../hooks/useFlexibleBanners';

//--------------------------------------------------------------------

export const useCrosstableCopyPaste = () => {
  const dispatch = useAppDispatch();
  const { state } = useAppSelector(rootState => rootState.stateReducer);
  const { copyData } = useAppSelector(
    rootState => rootState.setInitialDataReducer,
  );

  const { copyQuestions, pasteQuestions } = useCopyPaste('xt');
  const {
    flexibleBannersOption: flexibleBannersOption,
    currentBannerRow,
    currentBannerColumn,
  } = useFlexibleBanners();

  const dispatchError = useCallback(
    (msg: string) => {
      dispatch({
        type: 'SHOW_ERROR_NOTIFICATION',
        payload: { msg },
      });
    },
    [dispatch],
  );

  const pasteXtQuestions = useCallback(() => {
    // Early validation
    if (copyData.source !== 'xt') {
      dispatchError('Cannot paste rows/columns from different source');
      return;
    }

    if (!copyData.elements?.length) {
      dispatchError('No copied rows/columns found to paste');
      return;
    }

    // Filter elements by source
    const copiedElements = {
      firstColumn: copyData.elements
        .filter((el: CopiedItem) => el.source === 'firstColumn')
        .map(({ source, ...el }: CopiedItem) => el),

      secondColumn: copyData.elements
        .filter((el: CopiedItem) => el.source === 'scndColumn')
        .map(({ source, ...el }: CopiedItem) => el),
    };

    // Get insertion indices and create updated columns
    const firstColumnIndex = getLastCheckedIndex(
      state.firstColumn,
      state.checkedNum,
      'firstColumn',
    );

    const updateBannerColumn = () => {
      // If no rows are selected, paste to the current banner column
      if (copiedElements.firstColumn.length === 0) {
        return {
          ...state.bannerColumn,
          [currentBannerRow.id]: insertElementsAtIndex(
            currentBannerColumn,
            copiedElements.secondColumn,
            -1,
          ),
        };
      }

      // If rows are selected, paste the selected columns to the selected rows
      const bannerColumn: { [key: string]: CopiedItem[] } = {};
      copiedElements.firstColumn.forEach((el: CopiedItem) => {
        bannerColumn[el.id] = copiedElements.secondColumn;
      });

      return {
        ...state.bannerColumn,
        ...bannerColumn,
      };
    };

    const updatedColumns = {
      firstColumn: insertElementsAtIndex(
        state.firstColumn,
        copiedElements.firstColumn,
        firstColumnIndex,
      ),
      scndColumn: insertElementsAtIndex(
        state.scndColumn,
        copiedElements.secondColumn,
        -1,
      ),
      bannerColumn: updateBannerColumn(),
    };

    // Dispatch updates
    dispatch({
      type: 'XT_PASTE_QUESTIONS',
      payload: updatedColumns,
    });

    dispatch({
      type: 'SET_COPIED_ELEMENTS',
      payload: { source: null, elements: null },
    });

    dispatch({
      type: 'SHOW_ACTION_NOTIFICATION',
      payload: { msg: 'The selected rows/columns have been pasted' },
    });
  }, [
    copyData.elements,
    copyData.source,
    currentBannerColumn,
    currentBannerRow,
    dispatch,
    dispatchError,
    state.bannerColumn,
    state.checkedNum,
    state.firstColumn,
    state.scndColumn,
  ]);

  return {
    copyQuestions,
    pasteQuestions: () => {
      return flexibleBannersOption.isOptionEnabled
        ? pasteXtQuestions()
        : pasteQuestions();
    },
  };
};
