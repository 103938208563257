import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
//
import {
  useCreateCustomRowMutation,
  useDeleteCustomRowsMutation,
  useUpdateCustomRowMutation,
} from '../api';
//
import { useQuestionEditor } from '../../contexts/QuestionEditorContext';
import type { CustomRow } from '../types';

// -----------------------------------------------------------------------------

export const useCustomRowsData = () => {
  const { name, dataset } = useParams<{
    name: string;
    dataset: string;
  }>();
  const { selected } = useQuestionEditor();
  const [create, { isLoading: isCreating, isError: isCreateError }] =
    useCreateCustomRowMutation();
  const [update, { isLoading: isUpdating, isError: isUpdateError }] =
    useUpdateCustomRowMutation();
  const [deleteRow, { isLoading: isDeleting, isError: isDeleteError }] =
    useDeleteCustomRowsMutation();

  const addCustomRow = useCallback(
    async (data: CustomRow) => {
      const toSave = {
        projectId: name,
        datasetId: dataset,
        questionId: selected!.title,
        rows: [
          {
            code: Number(data.code),
            text: data.text,
            expression: data.customExpr!,
          },
        ],
      };

      return await create(toSave).unwrap();
    },
    [create, dataset, name, selected],
  );

  const updateCustomRow = useCallback(
    async (data: CustomRow) => {
      const toSave = {
        projectId: name,
        datasetId: dataset,
        questionId: selected!.title,
        rows: [
          {
            rowMiextId: data.id!,
            expression: data.customExpr!,
          },
        ],
      };

      return await update(toSave).unwrap();
    },
    [dataset, name, selected, update],
  );

  const saveCustomRow = useCallback(
    async (data: CustomRow) => {
      if (!data.id) {
        return await addCustomRow(data);
      }

      return await updateCustomRow(data);
    },
    [addCustomRow, updateCustomRow],
  );

  const deleteCustomRow = useCallback(
    async (id: string) => {
      const toDelete = {
        projectId: name,
        datasetId: dataset,
        questionId: selected!.title,
        rowIds: [id],
      };

      return await deleteRow(toDelete).unwrap();
    },
    [deleteRow, dataset, name, selected],
  );

  return {
    isLoading: isCreating || isUpdating || isDeleting,
    isError: isCreateError || isUpdateError || isDeleteError,
    saveCustomRow,
    deleteCustomRow,
  };
};
