export default (option, user, groups, updateOptionsValues) => {
  let disableOption = false;
  const groupPlan = groups.find(el => el.text === option?.group)?.plan;

  if (!user.isSubAccount) {
    switch (user.plan) {
      case 'basic':
        disableOption = groupPlan !== user.plan;
        break;
      case 'essential_yearly':
      case 'essential_monthly':
        disableOption = !(groupPlan === 'essential' || groupPlan === 'basic');
        break;
      case 'professional_monthly':
      case 'professional_yearly':
        disableOption = groupPlan === 'enterprise';
        break;
      default:
        disableOption = false;
    }
  }

  if (option && updateOptionsValues) {
    if (
      option.id === 'GChartHidePctSign' &&
      (updateOptionsValues.GChartShow === 4 || updateOptionsValues.GChartShow === 5)
    ) {
      disableOption = true;
    }
  }

  return disableOption;
};
