import type React from "react";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Tooltip } from "@progress/kendo-react-tooltip";

import type { RootState } from "../../../../../../../store/reducers/rootReducer";
import { commentCreatedAt, formatDate } from '../../../../../../shared/DateUtils/DateUtils';
import { surveyReviewsData } from '../../../helpers/SurveyComments/surveyReviewsData';
import { Icon } from "../../../../../../shared/Icon/Icon";
import { EmptySurveyComments } from './EmptySurveyComments';
import { FullHeightSpinner } from "../../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { CommentStatus, type Review, type StatusOptions, type Comment } from "./types/surveyCommentsTypes";
import { fetchDeleteWithBody, fetchPutResOrJson } from "../../../../../../../services/services";

export const SurveyComments = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const params: { name: string, survey: string } = useParams();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [status, setStatus] = useState(CommentStatus.ALL);

  const projectId = params.name;
  const surveyId = params.survey;
  const { theData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer));
  const selectedItemReview: Review = theData.selectedItemReview;
  const surveyReviews: Review[] = theData.surveyReviews;

  if (!theData.isSurveyCommentsLoading) {
    const selectedItem = theData.surveyReviews?.length ? theData.surveyReviews.find((element: Review) => element.questionId === theData.selectedItem.id) : null;

    if (theData.surveyReviews?.length && theData.selectedItemReview === null) {
      dispatch({ type: 'UPDATE_SURVEY_REVIEW_SELECT_ELEMENT', payload: { selectedItemReview: selectedItem } });
    }
  }

  const commentStatusResolved = selectedItemReview ? selectedItemReview.comments.filter((comment) => comment.status === CommentStatus.RESOLVED).length : 0;
  const commentStatusRejected = selectedItemReview ? selectedItemReview.comments.filter((comment) => comment.status === CommentStatus.REJECTED).length : 0;

  const statusOptions: StatusOptions[] = [
    { title: "Resolved", value: CommentStatus.RESOLVED, icon: "checkmark", count: commentStatusResolved },
    { title: "Rejected", value: CommentStatus.REJECTED, icon: "close", count: commentStatusRejected },
  ];

  const onChangeCommentStatus = (comment: Comment, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const inputValue = Number(event.currentTarget.value);

    if (comment.status === inputValue) return;

    setIsSubmitting(true);

    const updateStatusComment: Comment = { ...comment, status: inputValue };

    const updatedSelectedItemReviewComments = selectedItemReview.comments.map(item => item.id === updateStatusComment.id ? updateStatusComment : item);

    const updatedSelectedItemReview: Review = { ...theData.selectedItemReview, comments: updatedSelectedItemReviewComments };

    const updatedSurveyReviews = surveyReviewsData({ surveyReviews, selectedItemReview, updatedSelectedItemReview });

    fetchPutResOrJson(`su/projects/${projectId}/surveys/${surveyId}/comments/${comment.id}`, token, { status: inputValue })
      .then(async (res: { ok: boolean; error: Error; message: string; }) => {
        if (res.ok) {
          dispatch({ type: 'UPDATE_SURVEY_REVIEW_SELECT_ELEMENT', payload: { selectedItemReview: updatedSelectedItemReview } });
          dispatch({ type: 'UPDATE_SURVEY_REVIEW', payload: { surveyReviews: updatedSurveyReviews } });
          setIsSubmitting(false);
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
          setIsSubmitting(false);
        }

      })
  }

  const onDeleteComment = (comment: Comment) => {
    setIsSubmitting(true);
    const updatedComments = selectedItemReview.comments.filter((item) => item.id !== comment.id);

    const updatedSelectedItemReview = { ...theData.selectedItemReview, comments: updatedComments };

    const updatedSurveyReviews = surveyReviewsData({ surveyReviews, selectedItemReview, updatedSelectedItemReview });

    fetchDeleteWithBody(`su/projects/${projectId}/surveys/${surveyId}/comments/${comment.id}`, token, {})
      .then(async (res: { ok: boolean; error: Error; message: string; }) => {
        if (res.ok) {
          dispatch({ type: 'UPDATE_SURVEY_REVIEW_SELECT_ELEMENT', payload: { selectedItemReview: updatedSelectedItemReview } });
          dispatch({ type: 'UPDATE_SURVEY_REVIEW', payload: { surveyReviews: updatedSurveyReviews } });
          setIsSubmitting(false);
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `${res.error ? res.error : res.message}` } })
          setIsSubmitting(false);
        }
      })
  }

  if (theData.isSurveyCommentsLoading) {
    return <FullHeightSpinner />
  }

  const comments = selectedItemReview?.comments.length ? selectedItemReview?.comments.filter((comment) => {
    return status === CommentStatus.ALL ? comment : comment.status === status;
  }).sort((a, b) => b.createdAtUtc.localeCompare(a.createdAtUtc)) : [];

  return (
    <Fragment>
      <div className="d-flex align-items-center gap-md pb-2 border-bottom">
        <p className="m-0">Status:</p>
        <select className="form-control" onChange={(event) => setStatus(Number(event.target.value))} defaultValue={CommentStatus.ALL}>
          <option value={CommentStatus.ALL}>
            All ({selectedItemReview?.comments?.length ? selectedItemReview?.comments?.length : 0})
          </option>

          {statusOptions?.map((statusOption) => {
            return <option key={statusOption.title} value={statusOption.value}>
              {statusOption.title} ({statusOption.count})
            </option>
          })}
        </select>
      </div>

      {!comments?.length && <EmptySurveyComments commentStatus={status} />}

      <ul className="list-group compact-view gap-md">
        {
          comments?.map((comment) => {
            return <li key={comment.id} className="survey-comments-list-item d-flex flex-column gap-md">
              <div className="d-flex align-items-center gap-md">

                <p className="m-0 font-weight-bold text-truncate survey-comments-name" title={comment.createdByName}>
                  {comment.createdByName}
                </p>

                <Tooltip anchorElement="target" position="bottom">
                  <p className="text-muted mb-0" title={`${formatDate(comment.createdAtUtc, "DATETIME_MED")}`}>
                    {commentCreatedAt(comment.createdAtUtc)}
                  </p>
                </Tooltip>
              </div>

              <p className="m-0 survey-comments-comment">
                {comment.comment}
              </p>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-sm">
                  {statusOptions.map((statusOption) => {
                    return <div key={statusOption.title}>
                      <Tooltip anchorElement="target" position="bottom">
                        <button
                          type="button"
                          value={statusOption.value}
                          disabled={isSubmitting}
                          title={`Set status: ${statusOption.title}`}
                          onClick={(event) => onChangeCommentStatus(comment, event)}
                          className={`btn btn-shadow survey-comments-status-btn ${isSubmitting ? "disabled" : ""} ${statusOption.value === comment.status ? "bg-primary text-white" : ""}`}
                        >
                          <Icon className={`pe-none ${statusOption.value === comment.status ? "fill-white" : ""}`} type={statusOption.icon} size="sm" />
                        </button>
                      </Tooltip>
                    </div>
                  })}
                </div>

                <button
                  type="button"
                  className={`btn ${isSubmitting ? "disabled" : ""} survey-comments-delete-btn`}
                  title="Delete comment"
                  disabled={isSubmitting}
                  onClick={() => onDeleteComment(comment)}>
                  <Icon type="delete" />
                </button>
              </div>
            </li>
          })
        }
      </ul>
    </Fragment>
  )
}
