export const addCustomRowToColumn = (state: TODO, payload: TODO) => {
  const updatedState = { ...state };
  const newRow = payload.data.rows[payload.data.rows.length - 1];
  const { source, id }: { source: string, id: string } = payload.questionInfo;

  updatedState.state[source].find((el: { id: string }) => el.id === id).groups.push({
    id: newRow.id,
    active: true,
    selected: false,
    code: newRow.code,
    isCustom: newRow.isCustom,
    customExpr: newRow.customExpr,
    text: newRow.texts[payload.defaultLanguage],
    bannerWeight: {},
  })

  updatedState.state.updateTable = true;

  return updatedState;
}