import type { ItemType } from 'components/Analysis/Analyze/components/shared/helpers/returnQuestionIcon/returnQuestionIcon';
import QuestionIcon from '../../../question-icon/QuestionIcon';

const ContentHeader = ({
  title,
  subtitle,
  iconType,
}: {
  title: string;
  subtitle: string;
  iconType: ItemType;
}) => {
  return (
    <div className="d-flex flex-row gap-sm align-items-center">
      <QuestionIcon type={iconType} className="icon mr-2" size="lg" />

      <div className="d-flex flex-column">
        <span style={{ fontSize: '20px' }}>{title}</span>
        <small className="text-muted">{subtitle}</small>
      </div>
    </div>
  );
};

export default ContentHeader;
