import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';

import SubQuestionTabTitle from '../../../helpers/SubQuestionTabTitle/SubQuestionTabTitle';
import AdvancedAnswerControlModal from './AdvancedAnswerControlModal/AdvancedAnswerControlModal';
import { useDebounce } from '../../../../../../shared/customHooks/useDebounce';

export default ({ theData }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const [advancedModal, setAdvancedModal] = useState({ show: false, content: null, subQuesIndex: null });
  const dispatch = useDispatch();
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);

  const onChangeRequired = (value, subQuesIndex) => {
    const selectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const selectedElement = selectedItem.elements.filter(el => el.type === "subq")[subQuesIndex];

    if (value) {
      selectedElement.attributes["range"] = "*"
    } else {
      delete selectedElement.attributes["range"]
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    dispatch({ type: "SET_SELECTED_ELEMENT", payload: selectedElement })
    saveWithDebounce()
  }

  const onSaveAdvanced = (content) => {
    const selectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const selectedElement = selectedItem.elements.filter(el => el.type === "subq")[advancedModal.subQuesIndex];

    if (content !== '') {
      selectedElement.attributes["range"] = content
    } else {
      delete selectedElement.attributes["range"]
    }

    setAdvancedModal({ show: false, content: null, subQuesIndex: null })
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: selectedItem })
    dispatch({ type: "SET_SELECTED_ELEMENT", payload: selectedElement })
    saveWithDebounce()
  }

  return (
    <React.Fragment>
      {advancedModal.show &&
        <AdvancedAnswerControlModal
          content={advancedModal.content}
          handleClose={() => setAdvancedModal({ show: false, content: null, subQuesIndex: null })}
          onSaveAdvanced={onSaveAdvanced}
        />
      }
      {
        theData.selectedItem.elements.filter(el => el.type === 'subq').length === 1 ?
          <div className="d-flex flex-row justify-content-between h-32">
            <div className="d-flex align-items-center">
              <Switch className="mr-3"
                checked={theData.selectedItem.elements.find(el => el.type === "subq").attributes["range"] ? true : false}
                onChange={(e) => onChangeRequired(e.value, 0)}
                size="small"
              />
              <span>Require an answer</span>
            </div>
            <Button
              className="btn btn-secondary"
              disabled={!theData.selectedItem.elements.find(el => el.type === "subq").attributes["range"]}
              onClick={() => setAdvancedModal({ show: true, content: theData.selectedItem.elements.find(el => el.type === "subq").attributes["range"], subQuesIndex: 0 })}>
              Advanced answer control</Button>
          </div> :
          <TabStrip selected={selectedTab} className="subquestion-tabs h-100" onSelect={(e) => setSelectedTab(e.selected)}>
            {theData.selectedItem.elements.filter(el => el.type === 'subq').map((subquestion, key) => (
              <TabStripTab key={key} title={<SubQuestionTabTitle theData={theData} index={key} selected={selectedTab} />}>
                <div className="d-flex align-items-center justify-content-between py-3 pl-3">
                  <div className="d-flex align-items-center">
                    <Switch
                      className="mr-3"
                      checked={subquestion.attributes["range"] ? true : false}
                      onChange={(e) => onChangeRequired(e.value, key)} />
                    <span>Require an answer</span>
                  </div>
                  <Button
                    className="btn btn-secondary"
                    disabled={!subquestion.attributes["range"]}
                    onClick={() => setAdvancedModal({ show: true, content: subquestion.attributes["range"], subQuesIndex: key })}>
                    Advanced</Button>
                </div>
              </TabStripTab>
            ))}
          </TabStrip>
      }
    </React.Fragment>
  )
}
