import type { ReactElement } from 'react';
import { useCallback } from 'react';
//
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNotifications } from '../../../hooks/useNotifications';
import { useQuestionEditor } from '../contexts/QuestionEditorContext';
import { useActionDialog } from '../contexts/ActionDialogContext';
import PasteEditsForm from './PasteEditsForm';

import { getCompatibleQuestions, getCompatibilityMode } from './utils';
import type { EditableQuestion } from '../types';

// ----------------------------------------------------------------------

/**
 * Hook to manage copying and pasting of question edits
 * @returns Object containing copy/paste functions and state
 */
export const useCopyPasteEdits = () => {
  const dispatch = useAppDispatch();
  const notify = useNotifications();
  const { selected, questions, updateSelected, updateQuestions } =
    useQuestionEditor();
  const { showDialog: showActionDialog } = useActionDialog();

  const { crossTabQuestionEditorCopyData: copiedData } = useAppSelector(
    state => state.setInitialDataReducer,
  );

  const canPaste = selected && copiedData?.selectedQuestionId !== selected.id;

  /**
   * Applies copied edits to currently selected question
   */
  const applyEditsToQuestion = useCallback(() => {
    updateSelected({
      groups: copiedData.groups,
      subGroups: copiedData.subGroups,
    });
  }, [copiedData, updateSelected]);

  /**
   * Applies copied edits to all provided questions
   * @param questions List of questions to update
   */
  const applyEditsToAll = useCallback(
    (questions: EditableQuestion[]) => {
      const update = questions.map(question => {
        return {
          ...question,
          groups: copiedData.groups,
          subGroups: copiedData.subGroups,
        };
      });

      updateQuestions(update);
    },
    [copiedData, updateQuestions],
  );

  /**
   * Copies edits from currently selected question
   */
  const copyEdits = useCallback(() => {
    if (!selected) {
      notify.error('Please select a question to copy edits from.');
      return;
    }

    dispatch({
      type: 'SET_XT_QUESTION_EDITOR_COPY_DATA',
      payload: {
        selectedQuestionId: selected.id,
        selectedQuestionType: selected.type,
        groups: selected.groups,
        subGroups: selected.subGroups,
      },
    });

    notify.success('Edits copied successfully.');
  }, [dispatch, notify, selected]);

  /**
   * Shows paste confirmation modal with options
   * @param compatibility Compatibility mode string
   * @param questions List of compatible questions
   */
  const showModal = useCallback(
    (compatibility: string, questions: EditableQuestion[]) => {
      showActionDialog({
        title: 'Paste Edits',
        content: PasteEditsForm({
          compatibilityMode: compatibility,
          compatibleQuestions: questions,
          selectedQuestion: selected!,
        }) as ReactElement,
        actions: [
          {
            label: 'Paste to Active',
            variant: 'analyze',
            onClick: () => {
              applyEditsToQuestion();
              notify.success('Edits pasted to active question successfully.');
            },
            disabled:
              compatibility === 'multipleQuestionsExcludingActive' ||
              compatibility === 'oneQuestionNonActive',
          },
          {
            label: 'Paste to All',
            variant: 'analyze',
            onClick: () => {
              // TODO: Implement pasting to all questions
              applyEditsToAll(questions);
              notify.success('Edits pasted to all questions successfully.');
            },
          },
        ],
        cancelLabel: 'Cancel',
        width: 550,
      });
    },
    [applyEditsToAll, applyEditsToQuestion, notify, selected, showActionDialog],
  );

  /**
   * Pastes previously copied edits to selected question(s)
   */
  const pasteEdits = useCallback(() => {
    if (!selected) {
      notify.error('Please select a question to paste edits to.');
      return;
    }

    const excludedCopiedQuestionEdits = questions.filter(
      question => question.id !== copiedData.selectedQuestionId,
    );

    const compatibleQuestions = getCompatibleQuestions(
      excludedCopiedQuestionEdits,
      copiedData,
    );

    if (excludedCopiedQuestionEdits.length === 0) {
      notify.error(
        'Please select a different element to the one you copied the edits from.',
      );
      return;
    }

    if (compatibleQuestions.length === 0) {
      notify.error(
        'The copied edits are not compatible with any of the selected elements.',
      );
      return;
    }

    const { compatibility, shouldApplyEdits } = getCompatibilityMode(
      compatibleQuestions,
      selected!.id,
    );

    if (shouldApplyEdits) {
      applyEditsToQuestion();
      return;
    }

    showModal(compatibility, compatibleQuestions);
  }, [
    selected,
    questions,
    copiedData,
    showModal,
    notify,
    applyEditsToQuestion,
  ]);

  return {
    copyEdits,
    canPaste,
    pasteEdits,
  };
};
