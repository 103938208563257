import React, { useEffect, useState } from 'react';
import { Editor } from '@monaco-editor/react';
import { useParams } from 'react-router-dom';
import { fetchGetJson, fetchGetRes } from 'services/services';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import { Icon } from 'components/shared/Icon/Icon';

const SurveyDesignXMLTool: React.FC = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch();
  const [xmlData, setXmlData] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [editorTheme, setEditorTheme] = useState<'vs-dark' | 'vs-light'>(
    'vs-dark'
  );

  const changeTheme = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (editorTheme === "vs-light") {
      setEditorTheme("vs-dark");
    } else {
      setEditorTheme("vs-light");
    }
  }

  const params: { name: string; survey: string } = useParams();

  const formatXml = (xml: string) => {
    let formatted = '';
    let indent = '';
    xml.split(/>\s*</).forEach((node: string) => {
      if (node.match(/^\/\w/)) indent = indent.substring('\t'.length);
      formatted += indent + '<' + node + '>\r\n';
      if (node.match(/^<?\w[^>]*[^/]$/)) indent += '\t';
    });
    return formatted.substring(1, formatted.length - 3);
  };

  useEffect(() => {
    fetchGetRes(
      `su/projects/${params.name}/surveys/${params.survey}/xml`,
      token
    )
      .then((res: TODO) => {
        if (res.status === 200) {
          return res.text();
        }
        return res.json();
      })
      .then((res: TODO) => {
        if (res && !res.error && !res.message) {
          setLoading(false);
          const xml = res.toString();
          setXmlData(formatXml(xml));
        } else {
          dispatch({
            type: 'SHOW_ERROR_NOTIFICATION',
            payload: { msg: 'failed to get survey XML' },
          });
        }
      });
  }, [dispatch, params.name, params.survey, token]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className='d-flex flex-column h-100 w-100 overflow-hidden survey-main'>
      <Editor
        theme={editorTheme}
        defaultLanguage='xml'
        value={xmlData}
        options={{
          minimap: { enabled: false },
          glyphMargin: false,
          folding: true,
          overviewRulerLanes: 0,
          hideCursorInOverviewRuler: true,
          overviewRulerBorder: false,
        }}
      />
      <button type='button' className="btn btn-transparent btn-icon icon-l" onClick={changeTheme}>
        <Icon type="editor-theme" className="pe-none mr-1" />
        Theme
      </button>
    </div>
  );
};

export default SurveyDesignXMLTool;
