import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import type { Theme } from '../../../../../../../../interfaces/surveyInterfaces/themeTypes';
import type { RootState } from '../../../../../../../../store/reducers/rootReducer';
import { UnsavedChangesModal } from './ThemesAdvancedOptionsTabsContent/UnsavedChangesModal/UnsavedChangesModal';
import { ThemesAdvancedOptionsTabsContent } from './ThemesAdvancedOptionsTabsContent/ThemesAdvancedOptionsTabsContent';
import { fetchPutResOrJson } from '../../../../../../../../services/services';

interface Props {
  themeData: Theme
  editCSS: boolean
  onHide: () => void
  setThemeData: (data: TODO) => void
  setShouldUpdatePreview: (shouldUpdatePreview: boolean) => void
}

interface UnsavedChanges {
  htmlHeader: boolean
  htmlFooter: boolean
  js: boolean
  css: boolean
}

export default function ThemesAdvancedOptions({ onHide, setThemeData, setShouldUpdatePreview, themeData, editCSS }: Props) {
  const { theData, themeId, themeCss, themeJs } = useSelector((theState: RootState) => theState.surveyInitialDataReducer);
  const tabs = [{ text: 'Custom HTML header' }, { text: 'Custom HTML footer' }, { text: 'Javascript' }, { text: 'Custom CSS', disabled: theData.dcV2 === 2 ? false : !themeData.hasBeenEditedAsCss }]
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [isSavingTheme, setIsSavingTheme] = useState(false)
  const [selectedTab, setSelectedTab] = useState(editCSS ? 3 : 0)
  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>({ htmlHeader: false, htmlFooter: false, js: false, css: false })
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false)

  const onSaveChanges = () => {
    setIsSavingTheme(true)

    const body = {
      ...(unsavedChanges.css && { css: themeCss }),
      ...(unsavedChanges.htmlHeader && { htmlHeader: themeData.htmlHeader }),
      ...(unsavedChanges.htmlFooter && { htmlFooter: themeData.htmlFooter }),
      ...(unsavedChanges.js && { js: themeJs }),
    }

    fetchPutResOrJson(theData.dcV2 === 2 ? `su/themes/${themeId}` : `themes/${themeId}`, token, body)
      .then((res: TODO) => {
        setIsSavingTheme(false)
        if (res && !res.error && !res.message) {
          setUnsavedChanges({ ...unsavedChanges, htmlHeader: false, htmlFooter: false, js: false, css: false })
          dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The latest changes have been saved successfully.' } })
          setShouldUpdatePreview(true)
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
        }
      })
  }

  const onCloseOptionsModal = () => {
    const { htmlHeader, htmlFooter, js, css } = unsavedChanges
    if (htmlHeader || htmlFooter || js || css) {
      setShowUnsavedChangesModal(true)
    } else {
      onHide()
    }
  }

  const unsavedChangesItems: string[] = Object.keys(unsavedChanges)

  const discardUnsavedChanges = () => {
    const { js, css, htmlHeader, htmlFooter } = unsavedChanges
    const htmlContent = {
      ...htmlHeader && { htmlHeader: '' },
      ...htmlFooter && { htmlFooter: '' },
    }
    setThemeData({ ...themeData, ...htmlContent })
    if (css) {
      dispatch({ type: 'SET_THEME_CSS', payload: '' })
    }
    if (js) {
      dispatch({ type: 'SET_THEME_JS', payload: ''})
    }
  }

  return (
    <Dialog title="Advanced options" className='advanced-options-modal' onClose={onCloseOptionsModal} width={'90%'} height={'90%'}>
      {
        showUnsavedChangesModal &&
        <UnsavedChangesModal
          onClose={onHide}
          onHide={() => setShowUnsavedChangesModal(false)}
          discardUnsavedChanges={discardUnsavedChanges}
        />
      }
      <div className="d-flex flex-column h-100">
        <ul className="nav nav-pills content-tabs flex-grow-1 border-bottom pl-3">
          {
            tabs.map((tab, id) => (
              <li onClick={() => !tab.disabled ? setSelectedTab(id) : null} key={id} className={`nav-item`}>
                <span style={{ opacity: tab.disabled ? '0.5' : '1' }} className={`nav-link ${selectedTab === id ? 'active' : ''}`}>
                  <Tooltip openDelay={100} position='right' anchorElement={'target'}>
                    <span title={tab.disabled ? 'Custom CSS needs to be turned on to edit CSS' : ''}>{tab.text}</span>
                    {
                      // @ts-ignore
                      unsavedChanges[unsavedChangesItems[id]] &&
                      <i className="fas fa-sm fa-circle text-disabled ml-2 mt-1" />
                    }
                  </Tooltip>
                </span>
              </li>

            ))
          }
        </ul>
        <div className="p-4 h-100">
          <ThemesAdvancedOptionsTabsContent
            selectedTab={selectedTab}
            themeData={themeData}
            setThemeData={setThemeData}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
          />
        </div>
      </div>
      <DialogActionsBar>
        <button type="button" className="k-button btn btn-secondary" onClick={onCloseOptionsModal}>Close</button>
        <button
          type="submit"
          className="k-button btn btn-primary"
          onClick={onSaveChanges}
          disabled={isSavingTheme || (!unsavedChanges.htmlHeader && !unsavedChanges.htmlFooter && !unsavedChanges.css && !unsavedChanges.js)}>
          {
            isSavingTheme &&
            <div className="spinner-border spinner-border-sm mr-2" role="status" />
          }
          <span>Save changes</span>
        </button>
      </DialogActionsBar>
    </Dialog>
  )
}
