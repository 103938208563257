import { useCallback, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import type { Question, QuestionHierarchy } from '../types';

/**
 * Helper function to compare two banner lists for equality
 * @param list1 First list of questions to compare
 * @param list2 Second list of questions to compare
 * @returns True if lists have same length and matching title/type for each item
 */
const areBannerListsEqual = (list1: Question[], list2: Question[]): boolean => {
  if (list1.length !== list2.length) return false;

  return list1?.every(
    (item, index) =>
      item.title === list2[index].title && item.type === list2[index].type,
  );
};

/**
 * Hook to manage flexible banner option state and updates
 * @param state Current application state containing question options and columns
 * @returns Object containing option state, validation and update handlers
 */
export const useFlexBannersOption = (state: TODO) => {
  const dispatch = useAppDispatch();
  const isOptionEnabled = !!state.questionOptions.XtFlexBanner;

  const canChange = useMemo(() => {
    // allow to switch on/off the banner option when banner option is off
    if (!isOptionEnabled) return true;

    // allow to switch on/off the banner option when max 1 item in the first column
    if (state.firstColumn.length <= 1) return true;

    const bannerLists = Object.values(state.bannerColumn) as Array<Question[]>;
    if (bannerLists.length === 0) return true;

    const firstList = bannerLists[0];
    const allBannerListsMatch = bannerLists.every(list =>
      areBannerListsEqual(list, firstList),
    );

    return allBannerListsMatch;
  }, [isOptionEnabled, state.firstColumn, state.bannerColumn]);

  const message =
    'Switching on the flexible banners option will clear all the columns';

  const onUpdate = useCallback(
    (questionOptions: any) => {
      const value = questionOptions?.XtFlexBanner ?? false;

      if (isOptionEnabled === value) return;

      if (value) {
        // clear all columns
        dispatch({ type: 'XT_CLEAR_COLUMNS' });
      }

      if (state.firstColumn.length > 0) {
        const bannerColumn = Object.fromEntries(
          state.firstColumn.map((item: Question) => [item.id, []]),
        );
        dispatch({ type: 'XT_UPDATE_BANNER_COLUMN', payload: bannerColumn });
      }
    },
    [dispatch, isOptionEnabled, state.firstColumn],
  );

  return {
    id: 'XtFlexBanner',
    isOptionEnabled,
    canChange,
    hasMessage: true,
    message,
    onUpdate,
  };
};

/**
 * Hook to manage flexible banner functionality including selection and updates
 * @returns Object containing banner state and handlers for managing banner columns
 */
export const useFlexibleBanners = () => {
  const dispatch = useAppDispatch();
  const { state } = useAppSelector(rootState => rootState.stateReducer);
  const flexibleBannersOption = useFlexBannersOption(state);

  // Get the current banner row
  const currentBannerRow = state.firstColumn.find(
    (item: Question) => item.active,
  );

  // Get the current banner column
  const currentBannerColumn: Question[] = useMemo(() => {
    if (!flexibleBannersOption.isOptionEnabled) {
      return state.scndColumn;
    }

    if (!currentBannerRow) {
      return state.scndColumn;
    }

    return state.bannerColumn[currentBannerRow.id] || [];
  }, [
    flexibleBannersOption.isOptionEnabled,
    currentBannerRow,
    state.bannerColumn,
    state.scndColumn,
  ]);

  /**
   * Updates the banner column for the current banner row
   * @param questions New questions to set for current banner row
   */
  const updateBannerColumn = useCallback(
    (questions: Question[]) => {
      if (!currentBannerRow) return;

      const bannerColumn = {
        ...state.bannerColumn,
        [currentBannerRow.id]: questions,
      };

      dispatch({
        type: 'XT_UPDATE_BANNER_COLUMN',
        payload: bannerColumn,
      });
    },
    [currentBannerRow, dispatch, state.bannerColumn],
  );

  /**
   * Toggles selection state for a question in current banner column
   * @param questionId ID of question to toggle
   */
  const toggleSelected = useCallback(
    (questionId: string) => {
      const questions = currentBannerColumn.map(question => {
        const shouldToggle = question.id === questionId;
        return {
          ...question,
          selected: shouldToggle ? !question.selected : question.selected,
        };
      });

      updateBannerColumn(questions);
    },
    [currentBannerColumn, updateBannerColumn],
  );

  /**
   * Selects all questions in current banner column
   */
  const selectBannerColumn = useCallback(() => {
    const questions = currentBannerColumn.map(question => ({
      ...question,
      selected: true,
    }));

    updateBannerColumn(questions);
  }, [currentBannerColumn, updateBannerColumn]);

  /**
   * Deselects all questions in current banner column
   */
  const deselectBannerColumn = useCallback(() => {
    const questions = currentBannerColumn.map(question => ({
      ...question,
      selected: false,
    }));

    updateBannerColumn(questions);
  }, [currentBannerColumn, updateBannerColumn]);

  /**
   * Updates hierarchy for selected questions in current banner column
   * @param checkedQuestions New hierarchy to apply
   */
  const updateBannerColumnHierarchy = useCallback(
    (checkedQuestions: QuestionHierarchy[]) => {
      const currentSelectedBannerColumn = currentBannerColumn.filter(
        (el: Question) => el.selected,
      );
      const updatedBannerColumn = {
        ...currentSelectedBannerColumn[0],
        hierarchy: [...checkedQuestions],
      };
      const updatedItem = currentBannerColumn.map((el: Question) => {
        if (el.id === currentSelectedBannerColumn[0].id) {
          return updatedBannerColumn;
        }
        return el;
      });

      dispatch({
        type: 'XT_ADD_BANNER_COLUMN_HIERARCHY',
        payload: { ...state.bannerColumn, [currentBannerRow.id]: updatedItem },
      });
    },
    [currentBannerColumn, currentBannerRow?.id, state.bannerColumn, dispatch],
  );

  return {
    flexibleBannersOption,
    currentBannerRow,
    currentBannerColumn,
    bannerColumnMap: state.bannerColumn as Record<string, Question[]>,
    toggleSelected,
    selectBannerColumn,
    deselectBannerColumn,
    updateBannerColumnHierarchy,
  };
};
