import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from '@progress/kendo-react-form';
import { Stepper } from '@progress/kendo-react-layout';
import { DialogActionsBar, Dialog } from '@progress/kendo-react-dialogs';
import { Input, type InputChangeEvent, Switch } from '@progress/kendo-react-inputs';
import { DropDownList, type DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';

import languages from './languages'
import { parseJwt } from '../helpers/decodeJWT/decodeJWT';
import { useFetchTags } from '../customHooks/useFetchTags';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { BaseDropDownList, BaseMultiSelect } from "../Inputs";
import type { RootState } from '../../../store/reducers/rootReducer';
import { FullHeightSpinner } from '../FullHeightSpinner/FullHeightSpinner';
import type { Theme } from '../../../interfaces/surveyInterfaces/themeTypes';
import ProjectActionButtons from '../ProjectActionButtons/ProjectActionButtons';
import { fetchPostJson as addSurvey, fetchGetJson, fetchGetJsonData, rsAppUrl } from '../../../services/services';

type Props = {
  token: string;
  projectId: string;
  handleClose: () => void;
}

type Tags = {
  id: string;
  name: string;
}

type Languages = {
  text: string;
  value: string;
}

type FormData = {
  description?: string;
  name?: string;
  tags?: Tags[];
  themeId?: Theme;
  languages?: Languages[];
  panel?: {
    panelId: string;
    panelName: string;
  };
  subtype?: "Default" | "Profiler" | "Recruitment";
}

const items = [{ label: "General settings" }, { label: "Languages and style" }]

export default function AddSurveyModal({ token, projectId, handleClose }: Props) {
  const dispatch = useDispatch();
  const { panels } = useSelector((state: RootState) => (state.projectsReducer));
  const [formData, setFormData] = useState<FormData>({ subtype: 'Default' });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [stepperValue, setStepperValue] = useState(0);
  const [themes, setThemes] = useState<Theme[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [DCVersion, setDCVersion] = useState(1);
  const [isLoadingPanels, setIsLoadingPanels] = useState(false);
  const tags = useFetchTags(token);
  const history = useHistory<History>();

  // For now, only show the DC version toggle for the two customers that have access to it (Walr Staging and Walr Production customers)
  const parsedToken = parseJwt(token);
  const customerId = parsedToken[`${rsAppUrl}/customerid`];
  const showDCVersionToggle = !!(
    customerId === '024f7442-48b8-4b31-9b07-92421f0e602f' || // Walr Staging
    customerId === '3ef11f3f-290d-4a64-925c-34ae02f863ce' || // Walr Production
    customerId === '51a2068a-8e3e-4d63-90c6-cfb6daf9178a' || // MI Pro Production
    customerId === '9496e46b-9340-41a1-a0f9-dad0545e78f9'    // Opinion_Surveys Production
  );

  useEffect(() => {
    if (panels === null) {
      setIsLoadingPanels(true)
      fetchGetJson('su/projects/panels', token)
        .then((res: TODO) => {
          setIsLoadingPanels(false)
          dispatch({ type: 'UPDATE_PANELS', payload: res?.panels ? res.panels : [] })
        })
    }
  }, [token, dispatch, panels]);

  useEffect(() => {
    setThemes([]);
    if (stepperValue === 1) {
      fetchGetJsonData<Theme[]>(DCVersion === 2 ? 'su/themes' : 'themes', token)
        .then((res) => {
          setThemes(res)
        }).catch((errorMessage: string) => {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: errorMessage } })
        })
    }
  }, [dispatch, token, stepperValue, DCVersion])

  const onChangeHandler = (event: InputChangeEvent | DropDownListChangeEvent) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name && value) {
      setFormData({
        ...formData,
        [name]: value
      })
      if (DCVersion === 1 && name === 'subtype' && (value === 'Profiler' || value === 'Recruitment')) {
        setDCVersion(2)
      } else if (DCVersion === 2 && name === 'subtype' && value === 'Default') {
        setDCVersion(1)
      }
    }
  }
  const handleChange = (e: { value: number }) => {
    setStepperValue(e.value)
  }

  const onSubmitHandler = () => {
    const body = {
      name: formData?.name,
      description: formData?.description,
      languages: formData?.languages?.map(el => el.value),
      tags: formData?.tags,
      dataCollectionVersion: DCVersion,
      ...(DCVersion === 2 && { themeId: formData?.themeId?.id }),
      ...(DCVersion === 1 && { themeSettings: { id: formData?.themeId?.id } }),
      ...(formData?.panel && { panelId: formData?.panel?.panelId }),
      subtype: formData?.subtype
    }

    if (formData?.languages?.length === 0 || formData?.name === '') {
      setErrorMessage(`${formData?.languages?.length === 0 && formData?.name === '' ? "Survey name and languages" : formData?.languages?.length === 0 ? "Languages" : "Survey name"} cannot be empty`)
    } else {
      setIsLoading(true)
      addSurvey(`su/projects/${projectId}/surveys`, token, body)
        .then((res: TODO) => {
          setIsLoading(false)
          if (res && (res.message || res.error)) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (Object.entries(res).length !== 0) {
            handleClose()
            const location = history.location.pathname
            dispatch({ type: 'UPDATE_DOCUMENT_BREADCRUMB', payload: { id: res.surveyId, name: formData?.name } })
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'The survey has been created successfully' } })
            history.push({ pathname: `${location}/${res.surveyId}/survey-builder` })
          }
        })
    }
  }

  const listNoDataRender = () => {
    return (
      <h5 className="text-muted text-center">
        <span className="k-icon k-i-warning my-3" style={{ fontSize: '2.5em' }} />
        <br />
        <span>No tags created yet</span>
      </h5>
    );
  }

  return (
    <Dialog title="Create survey" onClose={handleClose} width={650} height={650}>
      {
        isLoadingPanels ?
          <FullHeightSpinner />
          :
          <Form
            onSubmit={onSubmitHandler}
            render={(_formRenderProps) => (
              <div className="p-4">
                <div className="pb-3">
                  <Stepper
                    className='create-survey-steps'
                    items={items}
                    value={stepperValue}
                    onChange={handleChange}
                  />
                </div>
                {stepperValue === 0 ?
                  <div className="d-flex flex-column">
                    <Input
                      name="name"
                      className="w-100 mb-2"
                      label="Name"
                      value={formData?.name}
                      onChange={onChangeHandler}
                    />

                    <Input
                      className="w-100 mb-2"
                      name="description"
                      label="Description"
                      value={formData?.description}
                      onChange={onChangeHandler}
                    />

                    {
                      panels?.length > 0 &&
                      <Fragment>
                        <DropDownList
                          name="subtype"
                          value={formData.subtype}
                          data={["Default", "Profiler", "Recruitment"]}
                          className="w-100 mb-2 mt-4 bg-white form-control d-flex px-0"
                          onChange={onChangeHandler}
                        />
                        <DropDownList
                          disabled={formData.subtype === "Default"}
                          name="panel"
                          value={formData.panel}
                          defaultItem={{ panelName: "Select panel" }}
                          data={panels}
                          textField='panelName'
                          className="w-100 mb-2 mt-4 bg-white form-control d-flex px-0"
                          onChange={onChangeHandler}
                        />
                      </Fragment>
                    }

                    <BaseMultiSelect
                      data={tags}
                      value={formData?.tags}
                      label="Tags"
                      textField="name"
                      dataItemKey="id"
                      name="tags"
                      allowCustom={true}
                      listNoDataRender={listNoDataRender}
                      onChange={onChangeHandler}
                    />
                    {showDCVersionToggle && (
                      <div className="d-flex justify-content-between align-items-center form-control mt-5 py-5">
                        <p className="mr-3 mb-0 text-primary">Use Data Collection V2</p>
                        <Switch checked={DCVersion === 2} onChange={(e) => setDCVersion(e.value ? 2 : 1)} />
                      </div>
                    )}
                  </div> :
                  <div className="d-flex flex-column">
                    <BaseMultiSelect
                      data={languages}
                      className="w-100 mb-2"
                      textField="text"
                      onChange={onChangeHandler}
                      label="Languages"
                      value={formData?.languages}
                      name="languages"
                    />
                    <BaseDropDownList
                      data={themes}
                      textField="name"
                      label="Themes"
                      value={themes.find(theme => theme.id === formData?.themeId?.id)}
                      className="w-100 mb-2"
                      onChange={onChangeHandler}
                      name="themeId" />

                  </div>}
                {
                  errorMessage &&
                  <ErrorMessage
                    type="alert"
                    errorMessage={errorMessage}
                  />
                }
              </div>
            )}>
          </Form>
      }
      <DialogActionsBar>
        <ProjectActionButtons
          submitAction={'Create'}
          onHide={handleClose}
          stepperValue={stepperValue}
          steps={items.length}
          isLoading={isLoading}
          handleChange={handleChange}
          onSubmitHandler={onSubmitHandler}
          formRef={undefined}
        />
      </DialogActionsBar>
    </Dialog>
  )
}
