import type { ButtonProps } from '@progress/kendo-react-buttons';
import { Button } from '@progress/kendo-react-buttons';

// ----------------------------------------------------------------------

type LoadingButtonProps = {
  isLoading: boolean;
  disabled?: boolean;
  children: React.ReactNode;
} & ButtonProps;

const LoadingButton = ({
  children,
  isLoading,
  disabled,
  ...props
}: LoadingButtonProps) => {
  return (
    <Button disabled={isLoading || disabled} {...props}>
      {isLoading && (
        <div className="spinner-border spinner-border-sm mr-2">
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {children}
    </Button>
  );
};

export default LoadingButton;
