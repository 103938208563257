import { Droppable } from '@hello-pangea/dnd';

import { useAppSelector } from 'app/hooks';
import type { User } from 'interfaces/userInterface/userInterface';
import DraggableItem from '../../../../DraggableItem/DraggableItem';
import type { Question } from '../../types';

// --------------------------------------------------------------------------------------------------------------------------------

type XtSecondColumnProps = {
  items: Question[];
  rangeItems: Question[];
  onCheck: ({...params}: TODO) => void;
  user: User;
};

const XtSecondColumn = ({
  items,
  rangeItems,
  onCheck,
  user,
}: XtSecondColumnProps) => {
  const userSettings = useAppSelector(state => state.userSettingsReducer);

  const isDisabled = user.plan === 'basic' ? true : false;
  const isCompactView = userSettings.compactView?.compactColumns;

  return (
    <Droppable
      key={'scndColumn'}
      droppableId={'scndColumn'}
      direction='horizontal'
      isDropDisabled={isDisabled}
    >
      {(provided, snapshot) => (
        <div
          className={`${snapshot.isDraggingOver && 'light-analyze-background'} droppable-column-scnd ${isCompactView && 'compact-view'}`}
          {...provided.droppableProps}
          ref={provided.innerRef}
        >
          {items.length === 0 && (
            <div className='h-100 w-100 d-flex align-items-center justify-content-center'>
              <span className='droppable-text h5 mb-0'>Drop columns here</span>
            </div>
          )}

          {items.length > 0 && (
            <ul className='list-group list-group-horizontal'>
              {items.map((item: Question, index: number) => (
                <DraggableItem
                  rangeItems={rangeItems}
                  scndColumnItem={true}
                  onCheck={onCheck}
                  key={item.id}
                  index={index}
                  item={item}
                  minimized={userSettings.minimize}
                  compactView={isCompactView}
                />
              ))}
            </ul>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

export default XtSecondColumn;
