import { useCallback } from 'react';

import { useAppDispatch } from 'app/hooks';

// ----------------------------------------------------------------------

export const useNotifications = () => {
  const dispatch = useAppDispatch();

  const showError = useCallback(
    (message: string) => {
      dispatch({
        type: 'SHOW_ERROR_NOTIFICATION',
        payload: {
          msg: message,
        },
      });
    },
    [dispatch],
  );

  const showSuccess = useCallback(
    (message: string) => {
      dispatch({
        type: 'SHOW_ACTION_NOTIFICATION',
        payload: { msg: message },
      });
    },
    [dispatch],
  );

  const showWarning = useCallback(
    (message: string) => {
      dispatch({
        type: 'SHOW_WARNING_NOTIFICATION',
        payload: { msg: message },
      });
    },
    [dispatch],
  );

  return {
    error: showError,
    success: showSuccess,
    warning: showWarning,
  };
};
