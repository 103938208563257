import { Fragment } from "react";

import { FullHeightSpinner } from "../FullHeightSpinner/FullHeightSpinner";
import type { Quota, QuotaCell } from "../../../interfaces/surveyInterfaces/surveyIterfaces";
import { QuotaProgressBar } from "./QuotaProgressBar/QuotaProgressBar";

interface Props {
  quota: Quota,
  calledFrom?: string,
  changeTargetValue?: (e: TODO, quotaCell: QuotaCell) => void,
  updateQuotaAnswers?: (value: string, quotaCellIndex: number) => void,
}

const TargetCellContent = ({ text, quota }: { text: string; quota: Quota }) => {
  return (
    <Fragment>
      <div className="text-left border-right h-100 w-100  priority-1">
        <span className="medium">{text}</span>
      </div>
      <div className="text-left w-100 priority-1">
        <div className="p-1 border-bottom" title="Achieved"><span className="strong medium">{`${!quota.quotaTable.showRemaining ? "Achieved" : "Remaining"}`}</span></div>
        <div className="p-1" title="Target"><span className="strong medium">Target</span></div>
      </div>
      <span className="medium priority-2">{text}</span>
    </Fragment>
  )
}

export const QuotaTable = ({ quota, calledFrom, changeTargetValue, updateQuotaAnswers }: Props) => {

  const calcTotals = (cellIndex: number, type: string) => {
    return (
      quota.quotaTable.rows.reduce((prevValue: number, currValue: TODO) => {
        if (currValue.cells.length > 0) {
          if (type === "target") {
            if (Number.isNaN(currValue.cells[cellIndex].targetValue)) {
              return prevValue + 0
            }
            return prevValue + currValue.cells[cellIndex].targetValue
          }
          if (Number.isNaN(currValue.cells[cellIndex].targetValue)) {
            return prevValue + 0
          }
          return prevValue + currValue.cells[cellIndex].currentValue
        }
        return prevValue
      }, 0)
    )
  }

  const checkForDisabledColumn = (cellIndex: number) => {
    return !quota.quotaTable.rows.every(row => row.cells[cellIndex].quotaCellId === null)
  }

  if (quota.cellOverview) {
    if (quota.quotaTable?.show) {
      return (
        <Fragment>
          <table className={`quota-table-view table ${quota.disabled ? "blur" : ""}`}>
            <thead>
              {quota.quotaTable?.dimensions === 2 &&
                <Fragment>
                  <tr className="row w-100 d-flex flex-nowrap">
                    <th className="header headerCol border-top-0 py-2 pl-3 pr-2" title={quota.quotaTable?.colHeader}>
                      <span className="strong medium">{quota.quotaTable?.colHeader}</span>
                    </th>
                    {quota.quotaTable?.rows[0].cells.map((columnHeaderCell: TODO, columnHeaderCellIndex: number) => {
                      if (checkForDisabledColumn(columnHeaderCellIndex)) {
                        return <th key={columnHeaderCellIndex} className="header headerCol border-top-0 p-2" title={columnHeaderCell.text}><span className="strong medium">{columnHeaderCell.text}</span></th>
                      }
                    })}
                    <th className="header headerCol border-top-0 pl-2 py-2 pr-3" title="Total"><span className="strong medium">Total</span></th>
                  </tr>
                  <tr className="row w-100 d-flex flex-nowrap">
                    <th className="header headerRow border-top-0 py-2 pl-3 pr-2" title={quota.quotaTable?.rowHeader}>
                      <span className="strong medium">{quota.quotaTable?.rowHeader}</span>
                    </th>
                    {quota.quotaTable?.rows[0].cells.map((_rowHeaderCell: TODO, rowHeaderCellIndex: number) => {
                      if (checkForDisabledColumn(rowHeaderCellIndex)) {
                        return (
                          <th key={rowHeaderCellIndex} className="header headerRow border-top-0 px-2">
                            <div className="d-flex">
                              <div className="col-9 pl-0 py-2 pr-0" title="Achieved"><span className="strong medium">{`${!quota.quotaTable.showRemaining ? "Achieved" : "Remaining"}`}</span></div>
                              <div className="col-3 pl-2 pr-0 py-2 border-left" title="Target"><span className="strong medium">Target</span></div>
                            </div>
                          </th>
                        )
                      }
                    })}
                    <th className="header headerRow border-top-0 pl-2 pr-3">
                      <div className="d-flex">
                        <div className="col-9 px-0 py-2" title="Achieved"><span className="strong medium">{`${!quota.quotaTable.showRemaining ? "Achieved" : "Remaining"}`}</span></div>
                        <div className="col-3 pl-2 pr-3 py-2 border-left" title="Target"><span className="strong medium">Target</span></div>
                      </div>
                    </th>
                  </tr>
                </Fragment>
              }
              {quota.quotaTable?.dimensions === 1 &&
                <tr className="row w-100 d-flex flex-nowrap">
                  <th className="header headerRow border-top-0 py-2 pr-2 pl-3"><span className="strong medium">{quota.quotaTable?.rowHeader}</span></th>
                  <th className="header headerRow border-top-0 pl-2 pr-3">
                    <div className="d-flex">
                      <div className="col-9 pl-0 py-2 pr-0" title="Achieved"><span className="strong medium">{`${!quota.quotaTable.showRemaining ? "Achieved" : "Remaining"}`}</span></div>
                      <div className="col-3 p-2 border-left" title="Target"><span className="strong medium">Target</span></div>
                    </div>
                  </th>
                </tr>
              }
            </thead>
            <tbody>
              {quota.quotaTable.rows?.map((row: TODO, rowIndex: number) => {
                if (row.cells.some((cell: TODO) => cell.isUsed === true)) { // If a row has all it's cells with isUsed === false, it means the row has been disabled when making the quota
                  const rowCurrentTotal = row.cells.reduce((prevValue: number, currValue: TODO) => prevValue + currValue.currentValue, 0)
                  const rowTargetTotal = row.cells.reduce((prevValue: number, currValue: TODO) => prevValue + currValue.targetValue, 0)
                  return (
                    <tr key={rowIndex} className="row w-100">
                      <td className="rowCell border-right pl-3 d-flex flex-nowrap" title={row.text}>
                        <TargetCellContent text={row.text} quota={quota} />
                      </td>
                      {row.cells.map((rowCell: TODO, rowCellIndex: string) => {
                        if (rowCell.quotaCellId === null) {
                          if (checkForDisabledColumn(Number.parseInt(rowCellIndex))) {
                            return (<td key={rowCellIndex} className="rowCell border-right-1"><span className="flex-fill p-1 bg-lightspan className">Disabled</span></td>)
                          }
                          return null
                        }
                        return (
                          <td key={rowCellIndex} className="rowCell border-right-1 py-0 pr-2">
                            <div className="col-9 h-100 pl-0 pr-2 py-0 pt-2 align-items-center">
                              <QuotaProgressBar currentValue={rowCell.currentValue} targetValue={rowCell.targetValue} showRemaining={!!quota.quotaTable.showRemaining} />
                            </div>
                            <div className="col-3 pl-2 pr-0 py-2 border-left ">
                              {calledFrom === "overviewTab" ?
                                <span className="medium">{rowCell.targetValue}</span> :
                                <input
                                  type="number"
                                  min={0}
                                  className="form-control"
                                  title={rowCell.targetValue.toString()}
                                  value={Number(rowCell.targetValue).toString()}
                                  onKeyDown={e => {
                                    if (e.key === "." || e.key === "-" || e.key === "e") {
                                      e.preventDefault();
                                    }
                                  }}
                                  onChange={(e) => changeTargetValue?.(e, rowCell)}
                                />
                              }
                            </div>
                          </td>
                        )
                      })}
                      {quota.quotaTable?.dimensions === 2 &&
                        <td className="rowCell border-right-0 py-0">
                          <div className="col-9 h-100 pl-0 pr-2 py-0 pt-2 align-items-center">
                            <QuotaProgressBar currentValue={rowCurrentTotal} targetValue={rowTargetTotal} showRemaining={!quota.quotaTable.showRemaining ? false : true} />
                          </div>
                          <div className="col-3 pl-2 pr-0 py-3 border-left text-left">
                            <span className="medium">{rowTargetTotal}</span>
                          </div>
                        </td>}
                    </tr>
                  )
                }
              })}
              <tr className="row w-100 border-bottom">
                <td className="footer pl-3 d-flex flex-nowrap"><TargetCellContent text='Total' quota={quota} /></td>
                {quota.quotaTable.columnTotals?.target.map((_, footerCellIndex: number) => {
                  if (checkForDisabledColumn(footerCellIndex)) {
                    return <td key={footerCellIndex} className="footer footer-color border-right-1 pl-2 pr-2 py-0">
                      <div className="col-9 h-100 pl-0 pr-2 py-0 pt-2 align-items-center">
                        <QuotaProgressBar currentValue={calcTotals(footerCellIndex, "current")} targetValue={calcTotals(footerCellIndex, "target")} showRemaining={!quota.quotaTable.showRemaining ? false : true} />
                      </div>
                      <div className="col-3 pl-2 pr-0 py-2 border-left text-left" title={calcTotals(footerCellIndex, "target").toString()}>
                        <span className="strong medium">
                          {calcTotals(footerCellIndex, "target")}
                        </span>
                      </div>
                    </td>
                  }
                })}
                {
                  quota.quotaTable?.dimensions === 2 &&
                  <td className="footer pr-3 border-right-0"><span className="strong medium" /></td>
                }
              </tr>
            </tbody>
          </table>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <table className={`quota-list-view table ${quota.disabled && "blur"}`}>
          <thead>
            <tr className="row w-100">
              <th className="col-md-4 px-0 py-2 border-bottom-0 border-top-0 medium" title="Name"><span className="pl-3 strong">Name</span></th>
              <th className="col-md-4 px-0 py-2 border-bottom-0 border-top-0 medium" title="Status"><span className="pl-3 strong">Status</span></th>
              <th className="col-md-1 px-0 py-2 border-bottom-0 border-top-0 medium" title="Target"><span className="pl-3 strong">Target</span></th>
              <th className="col-md-1 px-0 py-2 border-bottom-0 border-top-0 medium" title="Actual"><span className="pl-3 strong">Actual</span></th>
              <th className="col-md-2 px-0 py-2 border-bottom-0 border-top-0 medium">
                <span className="d-flex justify-content-between pl-3">
                  <div className="col-md-8 p-0" title="Remaining"><span className="strong">Remaining</span></div>
                  <div className="col-md-4 p-0" title="Percentage"><span className="strong">%</span></div>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {quota.cellCount !== 0 && quota.cellOverview.map((quotaCell: QuotaCell, cellIndex: number) => {
              return (
                <tr className="row w-100 border-bottom-0" key={quotaCell.id}>
                  <td className="col-md-4 py-2 border-bottom-0 border-right" title={`${quotaCell.name}`}>
                    {calledFrom === "overviewTab" ?
                      <p className="medium m-0 text-left line-clamp-3" style={{ maxWidth: "550px" }}>{quotaCell.name}</p> :
                      <input type="text" className="form-control" defaultValue={quotaCell.name} onChange={e => updateQuotaAnswers?.(e.target.value, cellIndex)} />
                    }
                  </td>
                  <td className="col-md-4 p-2 border-bottom-0 border-right d-flex flex-row align-items-center">
                    <QuotaProgressBar currentValue={quotaCell.actualValue} targetValue={quotaCell.targetValue} showRemaining={false} />
                  </td>
                  <td className="col-md-1 py-2 px-2 border-bottom-0 border-right text-left" title={quotaCell.targetValue.toString()}>
                    {calledFrom === "overviewTab" ?
                      <p className="d-flex align-items-center medium h-100 m-0">{quotaCell.targetValue}</p> :
                      <input
                        type="number"
                        min={0}
                        className="form-control"
                        title={quotaCell.targetValue.toString()}
                        value={Number(quotaCell.targetValue).toString()}
                        onKeyDown={e => {
                          if (e.key === "." || e.key === "-" || e.key === "e") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => changeTargetValue?.(e, quotaCell)} />
                    }
                  </td>
                  <td className="col-md-1 py-2 border-bottom-0 border-right text-left" title={quotaCell.actualValue.toString()}>
                    <p className="d-flex align-items-center medium h-100 m-0">{quotaCell.actualValue}</p>
                  </td>
                  <td className="col-md-2 py-0 border-bottom-0 d-flex text-left">
                    <div className="col-md-8 py-2 px-0 border-right" title={quotaCell.remainingValue.toString()}>
                      <p className="d-flex align-items-center medium h-100 m-0">{quotaCell.remainingValue}</p>
                    </div>
                    <div className="col-md-4 p-2 pl-2 text-left" title={`${quotaCell.remainingPercentageValue}%`}>
                      <p className="d-flex align-items-center medium h-100 m-0">{quotaCell.remainingPercentageValue}%</p>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Fragment >
    )
  }
  return <div className="h-100">
    <FullHeightSpinner />
  </div>
}
