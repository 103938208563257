import React, { Fragment } from 'react';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { ColorPicker, Input, NumericTextBox, Switch, TextArea } from '@progress/kendo-react-inputs';

import { Icon } from '../../../../../shared/Icon/Icon';
import { BaseDropDownList } from '../../../../../shared/Inputs';

export default function GroupSettings({
  groups,
  settings,
  onChangeSettingsValues,
  settingsValues,
  fonts,
  onShowFileManager,
  type,
  dropdownValues,
  dropdownPopupSettings,
}) {
  const fontWeightItems = [
    { text: 'Bold', value: 'bold' },
    { text: 'Bolder', value: 'bolder' },
    { text: 'Normal', value: 'normal' },
    { text: 'Lighter', value: 'lighter' },
  ];

  const itemRender = (e, itemProps) => {
    const itemChildren = <span> <span className="pr-4">{itemProps.dataItem.text}</span></span>;
    return React.cloneElement(e, e.props, itemChildren);
  }

  return (
    <Fragment>
      {
        groups?.map((group, key) => (
          <div key={group} className="border-bottom item">
            <div className="card-header border-0 rounded-0 pl-3 pr-2 py-2 cursor-pointer" id={`heading-${key + 1}`} data-toggle="collapse" data-target={`#item-${key + 1}`} aria-expanded="false" aria-controls={`item-${key + 1}`}>
              <div className="d-flex align-items-center justify-content-between">
                <span className='medium' id='groupTitle'>{group}</span>
                <Icon type='chevron-mini-down-alt' />
              </div>
            </div>
            <div id={`item-${key + 1}`} className="collapse" aria-labelledby={`heading-${key + 1}`}>
              <div className="d-flex flex-column px-3 pb-3 pt-2 gap-lg">
                {settings?.filter((el) => el.group === group)?.map((item, key) => (
                  <div
                    key={item.id}
                    className="form-group d-flex justify-content-between align-items-center m-0">
                    <div className="d-flex flex-column w-100">
                      <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                        <label title={item.descr} className="m-0 medium">{item.name}</label>
                      </Tooltip>
                    </div>
                    {item.mapName === 'font-family' || item.mapName === '--main_font_family' || item.mapName === '--font-family' ? (
                      <Fragment>
                        <BaseDropDownList
                          onChange={(e) => onChangeSettingsValues(e.value.name, item.id, item.type)}
                          data={fonts}
                          textField="name"
                          value={
                            settingsValues.find((el) => el.id === item.id)
                              ? fonts.find((el) => el.name === settingsValues.find((el) => el.id === item.id).value)
                              : fonts[0].value
                          }
                          disabled={type === 'standard-theme'}
                          popupSettings={dropdownPopupSettings}
                        />
                      </Fragment>
                    ) : item.mapName === 'font-weight' || item.mapName === '--main_font_weight' || item.type === 'Weight' ? (
                      <Fragment>
                        <BaseDropDownList
                          onChange={(e) => onChangeSettingsValues(e.value.value, item.id, item.type)}
                          data={fontWeightItems}
                          textField="text"
                          value={
                            settingsValues.find((el) => el.id === item.id)
                              ? fontWeightItems.find((el) => el.value === settingsValues.find((el) => el.id === item.id).value)
                              : fontWeightItems[0].value
                          }
                          disabled={type === 'standard-theme'}
                          popupSettings={dropdownPopupSettings}
                        />
                      </Fragment>
                    ) : item.type === 'Color' || item.type === 'color' ? (
                      <div className="color-picker">
                        <ColorPicker
                          view="gradient"
                          fillMode="flat"
                          value={
                            settingsValues.filter((el) => el.id === item.id).length !== 0 ? settingsValues.find((el) => el.id === item.id).value : ''
                          }
                          onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                          disabled={type === 'standard-theme'}
                        />
                      </div>
                    ) : item.type === 'String' || item.type === 'string' ? (
                      <div className="d-flex">
                        <Input
                          onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                          type="text"
                          value={
                            settingsValues.filter((el) => el.id === item.id).length !== 0 ? settingsValues.find((el) => el.id === item.id).value : ''
                          }
                          disabled={type === 'standard-theme'}
                        />
                      </div>
                    ) : item.type === 'Border' ? (
                      <div className="d-flex">
                        <Input
                          onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                          type="text"
                          value={
                            settingsValues.filter((el) => el.id === item.id).length !== 0 ? settingsValues.find((el) => el.id === item.id).value : ''
                          }
                          disabled={type === 'standard-theme'}
                        />
                      </div>
                    ) : item.type === 'Size' || item.type === 'size' || item.type === 'BorderRadius' || item.type === 'border' || item.type === 'Margin' || item.type === 'margin' || item.type === 'Padding' || item.type === 'padding' ?
                      <div className="d-flex">
                        <NumericTextBox
                          min={0}
                          onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                          value={
                            Number(settingsValues.filter((el) => el.id === item.id).length !== 0 ? settingsValues.find((el) => el.id === item.id).value : '')
                          }
                          disabled={type === 'standard-theme'}
                        />
                      </div>
                      : item.type === 'Url' || item.type === 'url' ? (
                        <div className="input-group themes-image">
                          <div className="input-group-prepend">
                            <button type='button' className="btn btn-outline-survey p-1" onClick={() => onShowFileManager(item.id)}>
                              <Icon type="image" />
                            </button>
                          </div>
                          <div className="input-group-append">
                            <button type='button' className="btn btn-outline-survey p-1" onClick={() => onChangeSettingsValues(null, item.id, item.type)} disabled={type === 'standard-theme'}>
                              <Icon type="delete-alt" />
                            </button>
                          </div>
                        </div>
                      ) : item.type === 'int' ? (
                        <div className="d-flex">
                          <NumericTextBox
                            onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                            value={
                              settingsValues.filter((el) => el.id === item.id).length !== 0 ? Number(settingsValues.find((el) => el.id === item.id).value) : 0
                            }
                            disabled={type === 'standard-theme'}
                          />
                        </div>
                      ) : item.type === 'number' ? (
                        <div className="d-flex">
                          <NumericTextBox
                            min={0}
                            format="n2"
                            onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                            value={
                              settingsValues.filter((el) => el.id === item.id).length !== 0 ? Number(settingsValues.find((el) => el.id === item.id).value) : 0
                            }
                            disabled={type === 'standard-theme'}
                          />
                        </div>
                      ) : item.type === 'Code' ? (
                        <Fragment>
                          <TextArea
                            className="h-100 w-100 overflow-auto"
                            value={
                              settingsValues.filter((el) => el.id === item.id).length === 0 ? '' : settingsValues.find((el) => el.id === item.id).value
                            }
                            onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                            disabled={type === 'standard-theme'}
                          />
                        </Fragment>
                      ) : item.type === 'boolean' ? (
                        <Fragment>
                          <Switch
                            checked={
                              settingsValues.filter((el) => el.id === item.id).length !== 0 ? settingsValues.find((el) => el.id === item.id).value : false
                            }
                            onChange={(e) => onChangeSettingsValues(e.value, item.id, item.type)}
                            disabled={type === 'standard-theme'}
                            size="small"
                          />
                        </Fragment>
                      ) : item.type === 'choice' || item.type === 'Choice' ? (
                        <div className="col-sm-6">
                          <BaseDropDownList
                            itemRender={itemRender}
                            onChange={(e) => onChangeSettingsValues(e.value.value, item.id, item.type)}
                            value={
                              settingsValues.filter((el) => el.id === item.id).length !== 0 ?
                                item.choices.find(el => el.value === settingsValues.find((el) => el.id === item.id).value)
                                : item.choices[0]
                            }
                            data={item.choices}
                            textField="text"
                            disabled={type === 'standard-theme'}
                          />
                        </div>
                      ) : (
                        <Fragment>
                          <BaseDropDownList
                            onChange={(e) => onChangeSettingsValues(e.value.name, item.id, item.type)}
                            data={dropdownValues.find((el) => el.name === item.type)?.enumValues}
                            textField="text"
                            value={
                              settingsValues.filter((el) => el.id === item.id).length !== 0
                                ? dropdownValues
                                  .find((el) => el.name === item.type)?.enumValues.find((el) => el.name === settingsValues.find((el) => el.id === item.id).value)
                                : dropdownValues.find((el) => el.name === item.type)?.enumValues[0]
                            }
                            disabled={type === 'standard-theme'}
                            popupSettings={dropdownPopupSettings}
                          />
                        </Fragment>
                      )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      }
    </Fragment>
  )
};
