const analyzeTabs = ['Topline', 'Cross Table', 'Chart', 'Q Table', 'Dig', 'Data', 'Create new weight', 'Existing weights'];

const isTabDisabledForPlan = (title: string, plan
  : string): boolean => {
  switch (plan) {
    case 'basic':
      return title !== 'Topline';
    case 'essential_monthly':
    case 'essential_yearly':
      return title !== 'Topline' && title !== 'Cross Table';
    case 'professional_monthly':
    case 'professional_yearly':
      return false;
    default:
      return false;
  }
};

const returnDisabledTab = (title: string, user: { isSubAccount: boolean, plan: string }): boolean => {
  if (!analyzeTabs.includes(title)) {
    return false;
  }

  if (user.isSubAccount) {
    return false;
  }

  return isTabDisabledForPlan(title, user.plan);
};

export default returnDisabledTab;
