import { Controller, useFormContext } from 'react-hook-form';

type RHFInputProps = {
  name: string;
  [key: string]: any;
};

const RHFTextField = ({ name, ...other }: RHFInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <input
          value={value ?? ''}
          name={name}
          onChange={e => onChange(e.target.value)}
          {...other}
        />
      )}
    />
  );
};

export default RHFTextField;
