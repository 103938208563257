import { useForm } from 'react-hook-form';
//
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
//
import { Field, FormProvider } from 'components/shared/hook-form';
import type { CustomRow } from '../types';
import { useCustomRowsActionHandlers } from '../hooks/useCustomRowsActionHandlers';
import LoadingButton from '../../components/LoadingButton';

// -----------------------------------------------------------------------------

const CustomRowForm = ({ editMode }: { editMode: boolean }) => {
  return (
    <div className="d-flex flex-column flex-fill">
      <div className="alert alert-info" role="alert">
        <i className="fa-light fa-circle-info mr-2"/>Creating or modifying custom rows will affect the source data
      </div>

      <div className="form-group">
        <label htmlFor="customRowCode">Code</label>
        <Field.NumberInput id="customRowCode" name="code" disabled={editMode} />
      </div>
      <div className="form-group">
        <label htmlFor="customRowText">Text</label>
        <Field.Text
          id="customRowText"
          name="text"
          className="form-control"
          disabled={editMode}
        />
      </div>
      <div className="form-group">
        <label htmlFor="customRowExpression">Expression</label>
        <Field.Text
          id="customRowExpression"
          name="customExpr"
          className="form-control"
        />
      </div>
    </div>
  );
};

interface DialogConfig {
  title: string;
  content: () => JSX.Element;
  submitLabel: string;
  cancelLabel: string;
  variant: 'analyze' | 'danger';
}

const DIALOG_CONFIGS: Record<'add' | 'edit' | 'delete', DialogConfig> = {
  add: {
    title: 'Add Custom Row',
    content: () => <CustomRowForm editMode={false} />,
    submitLabel: 'Add',
    cancelLabel: 'Cancel',
    variant: 'analyze'
  },
  edit: {
    title: 'Edit Custom Row',
    content: () => <CustomRowForm editMode={true} />,
    submitLabel: 'Update',
    cancelLabel: 'Cancel',
    variant: 'analyze'
  },
  delete: {
    title: '',
    content: () => (
      <div className="p-1 m-2">
        <h5>Remove Selected Custom Rows?</h5>
        <p className="pt-2 text-muted">
          Deleting custom rows will remove them from the list. This action
          cannot be undone.
        </p>
      </div>
    ),
    submitLabel: 'Remove',
    cancelLabel: 'Keep',
    variant: 'danger'
  }
};

interface CustomRowsDialogProps {
  action: 'add' | 'edit' | 'delete';
  row: CustomRow;
  isOpen: boolean;
  onClose: () => void;
}

const CustomRowsDialog: React.FC<CustomRowsDialogProps> = ({
  action,
  row,
  isOpen,
  onClose
}) => {
  const { executeAction, isLoading } = useCustomRowsActionHandlers();

  const dialogConfig = DIALOG_CONFIGS[action];
  const methods = useForm<CustomRow>({
    mode: 'onSubmit',
    defaultValues: {
      id: '',
      code: 0,
      text: '',
      customExpr: ''
    },
    values: row
  });

  const { handleSubmit, reset, formState } = methods;

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data: CustomRow) => {
    await executeAction(action, data)();
    handleClose();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Dialog title={dialogConfig.title} width={450} onClose={handleClose}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column flex-fill p-4">
          {dialogConfig.content()}
        </div>
        <DialogActionsBar>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            {dialogConfig.cancelLabel}
          </button>
          <LoadingButton
            isLoading={isLoading}
            disabled={!formState.isValid}
            className={`btn btn-${dialogConfig.variant}`}
            type="submit"
          >
            {dialogConfig.submitLabel}
          </LoadingButton>
        </DialogActionsBar>
      </FormProvider>
    </Dialog>
  );
};

export default CustomRowsDialog;
