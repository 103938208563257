import type { Type } from "../../../../../../../../interfaces/iconInterfaces/iconInterfaces";

export enum CommentStatus {
  CREATED,
  RESOLVED,
  REJECTED,
  ALL
}

export type Comment = {
  comment: string
  createdAtUtc: string,
  createdByName: string,
  id: string,
  questionId: string
  status: CommentStatus,
}

export type Review = {
  comments: Comment[],
  index: number,
  label: string,
  questionId: string,
}

export type StatusOptions = {
  title: string,
  value: CommentStatus,
  icon: Type,
  count: number | null,
}
