import type { EditableQuestion } from '../types';
import type { CompatibilityType, CopiedEditsData } from './types';

type GroupItem = { code: string; merged?: boolean };

const filterUnmerged = (items: GroupItem[]) => {
  return items.filter(item => item.merged !== true);
};

const areGroupsCompatible = (source: GroupItem[], target: GroupItem[]) => {
  return (
    source.length === target.length &&
    source.every((group, index) => group.code === target[index].code)
  );
};

export const getCompatibleQuestions = (
  questions: EditableQuestion[],
  copiedEditsData: CopiedEditsData,
) => {
  const { selectedQuestionType, groups, subGroups } = copiedEditsData;

  const filteredSourceGroups = filterUnmerged(groups);
  const filteredSourceSubGroups = filterUnmerged(subGroups);

  return questions.filter(question => {
    // Early return if question type doesn't match
    if (question.type !== selectedQuestionType) {
      return false;
    }

    const filteredQuestionGroups = filterUnmerged(question.groups as GroupItem[]);
    const filteredQuestionSubGroups = filterUnmerged(question.subGroups as GroupItem[]);

    switch (selectedQuestionType) {
      case 'n':
      case 'm':
        return areGroupsCompatible(
          filteredQuestionGroups,
          filteredSourceGroups,
        );

      case 'rn':
      case 'rm':
        return areGroupsCompatible(
          filteredQuestionSubGroups,
          filteredSourceSubGroups,
        );

      default:
        // For other types, just check if group lengths match
        return filteredQuestionGroups.length === filteredSourceGroups.length;
    }
  });
};

export const getCompatibilityMode = (
  compatibleQuestion: EditableQuestion[],
  selectedId: string,
): { compatibility: CompatibilityType; shouldApplyEdits: boolean } => {

  if (compatibleQuestion.length === 1) {
    if (compatibleQuestion[0].id === selectedId) {
      return { compatibility: 'oneQuestionActive', shouldApplyEdits: true };
    }
    return { compatibility: 'oneQuestionNonActive', shouldApplyEdits: false };
  }

  const includesActiveQuestion = compatibleQuestion.some(
    item => item.id === selectedId,
  );

  return {
    compatibility: includesActiveQuestion
      ? 'multipleQuestionsIncludingActive'
      : 'multipleQuestionsExcludingActive',
    shouldApplyEdits: false,
  };
};
