import { useEffect, useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import type { RootState } from '../../../../../../../../../store/reducers/rootReducer';

import { Icon } from '../../../../../../../../shared/Icon/Icon';
import { returnQuestionIcon } from '../../../../../shared/helpers/returnQuestionIcon/returnQuestionIcon';
import type {
  Question,
  QuestionHierarchy,
  QuestionsState,
} from '../../../../../../../../../interfaces/analysisInterfaces/analysisInterfaces';
import { useFlexibleBanners } from '../../hooks/useFlexibleBanners';

interface Props {
  handleClose: () => void;
  state: QuestionsState;
}

const AddToHierarchyModal = ({ handleClose, state }: Props) => {
  const dispatch = useDispatch();
  const { theData } = useSelector(
    (theState: RootState) => theState.setInitialDataReducer,
  );
  const [filteredQuestions, setFilteredQuestions] = useState<TODO[]>(
    JSON.parse(JSON.stringify(theData)),
  );
  const [checkedQuestions, setCheckedQuestions] = useState<QuestionHierarchy[]>(
    [],
  );
  const {
    flexibleBannersOption,
    updateBannerColumnHierarchy,
    currentBannerColumn,
  } = useFlexibleBanners();

  useEffect(() => {
    const sourceColumn = state.checkedNum[0].source;
    if (sourceColumn === 'firstColumn' || sourceColumn === 'scndColumn') {
      const flexibleBannerColumn = currentBannerColumn?.find(el => el.selected);
      const updatedColumn = flexibleBannersOption?.isOptionEnabled
        ? [flexibleBannerColumn]
        : [...state[sourceColumn]];
      const activeElement = updatedColumn.find(
        el => el?.id === state.checkedNum[0].id,
      );
      if (activeElement?.hierarchy) {
        setCheckedQuestions(activeElement.hierarchy);
      }
    }
  }, [state]);

  const checkboxAction = (item: Question) => {
    const activeElement = theData.find((el: Question) => el.id === item.id);
    const newElement = {
      id: activeElement.title,
      index: activeElement.id,
      subqIndex: activeElement.subqIndex,
      isDisabled: false,
      editedText: activeElement.content,
    };

    if (state.checkedNum[0].source === 'firstColumn') {
      if (
        checkedQuestions.find(
          (el: QuestionHierarchy) => el.index === newElement.index,
        )
      ) {
        setCheckedQuestions([]);
      } else {
        let updatedCheckedQuestions = [...checkedQuestions];
        updatedCheckedQuestions = [];
        updatedCheckedQuestions.push(newElement);
        setCheckedQuestions(updatedCheckedQuestions);
      }
    } else if (state.checkedNum[0].source === 'scndColumn') {
      if (
        !checkedQuestions.find(
          (el: QuestionHierarchy) => el.index === newElement.index,
        )
      ) {
        setCheckedQuestions([...checkedQuestions, newElement]);
      } else {
        const updatedCheckedQuestions = [...checkedQuestions];
        const deletedQuestion = updatedCheckedQuestions.find(
          el => el.index === activeElement.id,
        );
        if (deletedQuestion) {
          updatedCheckedQuestions.splice(
            checkedQuestions.indexOf(deletedQuestion),
            1,
          );
          setCheckedQuestions(updatedCheckedQuestions);
        }
      }
    }
  };

  const onAddHierarchyHandler = () => {
    if (checkedQuestions.length > 0) {
      const sourceColumn = state.checkedNum[0].source;
      if (
        sourceColumn === 'scndColumn' &&
        flexibleBannersOption.isOptionEnabled
      ) {
        updateBannerColumnHierarchy(checkedQuestions);
      } else {
        if (sourceColumn === 'firstColumn' || sourceColumn === 'scndColumn') {
          const updatedColumn = [...state[sourceColumn]];
          const activeElement = updatedColumn.find(
            el => el.id === state.checkedNum[0].id,
          );
          if (activeElement) {
            activeElement.hierarchy = checkedQuestions;
            dispatch({
              type: 'ADD_HIERARCHY',
              payload: {
                source: state.checkedNum[0].source,
                column: updatedColumn,
              },
            });
          }
        }
      }

      handleClose();
    }
  };

  const onSearchQuestionsHandler = (e: TODO) => {
    const searchValue = e.target.value.toLowerCase();
    const updatedFilteredQuestions = theData.filter(
      (item: TODO) =>
        item.title.toLowerCase().includes(searchValue) ||
        item.content.toLowerCase().includes(searchValue),
    );
    setFilteredQuestions(updatedFilteredQuestions);
  };

  return (
    <Dialog
      height="60%"
      width={'50%'}
      title="Add to hierarchy"
      onClose={handleClose}
    >
      <div className="overflow-auto analysis-container p-0 pl-2 pr-3 bg-white mt-2">
        <div
          style={{ top: '0', zIndex: 1 }}
          className="input-group position-sticky pb-2 px-2"
        >
          <input
            onChange={onSearchQuestionsHandler}
            type="text"
            className="form-control question-search"
          />
        </div>
        <ul className={'list-group bg-white p-1'}>
          {filteredQuestions.map((item: Question, key: number) => (
            <li
              key={key}
              onClick={() => checkboxAction(item)}
              className={`list-group-item list-group-item-question d-flex justify-content-between ${item.id === checkedQuestions.find((el: QuestionHierarchy) => el.index === item.id)?.index ? 'checked' : null}`}
            >
              <div className="d-flex align-items-center">
                <Icon
                  type={returnQuestionIcon(item.type)}
                  className="question-icon"
                />
                <div className="d-flex flex-column ml-2">
                  <span className={'question-title'}>{item.title}</span>
                  <span className={'question-description'}>{item.content}</span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <span
                  className={`${item.id === checkedQuestions.find((el: QuestionHierarchy) => el.index === item.id)?.index ? 'fas text-white fa-check-square' : 'far text-muted fa-square'} fa-lg `}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>

      <DialogActionsBar>
        <Button id="close" className="btn btn-secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button className="btn btn-primary" onClick={onAddHierarchyHandler}>
          Add
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default AddToHierarchyModal;
