import React, { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from '../../../../../shared/Icon/Icon';
import { ErrorMessage } from '../../../../../shared/ErrorMessage/ErrorMessage';
import { DropdownButton } from '../../../../../shared/DropdownButton/DropdownButton';
import { InProgressOverlay } from '../../../../../shared/InProgressOverlay/InProgressOverlay';
import { returnBrowser } from '../../../../../Analysis/Analyze/components/shared/helpers/returnBrowser/returnBrowser';
import { fetchPostJson } from '../../../../../../services/services';

const deviceItems = [{ text: 'Desktop', id: 0, icon: 'device-desktop' }, { text: 'Mobile', id: 2, icon: 'device-mobile' }]

export const PreviewTabContent = ({ token, type, previewItems, themes, shouldUpdatePreview, setShouldUpdatePreview, selectedTab, editingLanguage }) => {
  const params = useParams()
  const [previewUrl, setPreviewUrl] = useState(null)
  const [previewType, setPreviewType] = useState(0)
  const [loading, setLoading] = useState(false)
  const [resetIframe, setResetIframe] = useState(0)
  const [errorMessage, setErrorMessage] = useState(null)
  const [didMount, setDidMount] = useState(false)
  const { theData } = useSelector(theState => (theState.surveyInitialDataReducer))
  const [questionSelected, setQuestionSelected] = useState(type === 'themes' ? previewItems[0] : theData?.selectedItem)

  useEffect(() => {
    if ((!didMount && questionSelected) || shouldUpdatePreview) {
      setLoading(true)
      setPreviewType(0)
      setDidMount(true)
      if (type === 'themes') { setShouldUpdatePreview(false) }
      const body = {}
      if (themes?.selected && selectedTab === 'gallery') {
        body.previewThemeId = themes.selected
      }
      fetchPostJson(`su/projects/${params.name}/surveys/${params.survey}/elements/${questionSelected.id}/preview?lang=${editingLanguage}`, token, body)
        .then(res => {
          if (res?.error) {
            setErrorMessage(res.message ? res.message : res.error)
          } else if (res) {
            setPreviewUrl(res.url)
            setResetIframe((prev) => prev + 1)
          }
        })
    }
  }, [shouldUpdatePreview, questionSelected, didMount, params, token, themes, selectedTab, setShouldUpdatePreview, type])

  const onDeviceItemClick = (e) => {
    setPreviewType(e.item.id)
    setLoading(true);
  }

  return (
    <div className="d-flex flex-column h-100 edit-section">
      <div className="d-flex justify-content-center border-bottom px-3 py-2 h-48">
        {
          type === 'themes' &&
          <Fragment>
            <div className="d-flex align-items-center justify-content-between w-100">
              <DropdownButton
                items={previewItems}
                textField="label"
                menuPosition='left'
                className='btn-transparent icon-r mr-2'
                text={questionSelected.label ? questionSelected.label : 'Select element'}
                onItemClick={(e) => { setQuestionSelected(e.item); setShouldUpdatePreview(true) }}
              />
              <div className="d-flex">
                <Tooltip anchorElement="target" position="top">
                  <button
                    type='button'
                    disabled={questionSelected.id === previewItems[0].id}
                    title='Previous'
                    onClick={() => { setQuestionSelected(previewItems[previewItems.findIndex(el => el.id === questionSelected.id) - 1]); setShouldUpdatePreview(true) }}
                    className="btn btn-icon icon-b btn-transparent">
                    <Icon type="chevron-left" className='pe-none' />
                  </button>
                </Tooltip>
                <Tooltip anchorElement="target" position="top">
                  <button
                    type='button'
                    disabled={questionSelected.id === previewItems[previewItems.length - 1].id}
                    title='Next'
                    onClick={() => { setQuestionSelected(previewItems[previewItems.findIndex(el => el.id === questionSelected.id) + 1]); setShouldUpdatePreview(true) }}
                    className="btn btn-icon icon-b btn-transparent">
                    <Icon type="chevron-right" className='pe-none' />
                  </button>
                </Tooltip>
              </div>
            </div>
          </Fragment>
        }
        <div className="d-flex align-items-center">
          <div className="d-flex justify-content-end refresh">
            <Tooltip anchorElement="target" position="top">
              <button
                type='button'
                onClick={() => { setPreviewUrl(null); setShouldUpdatePreview(true) }}
                title='Restart'
                className="btn btn-icon icon-b btn-transparent mr-2" >
                <Icon type="refresh" className='pe-none' />
              </button>
            </Tooltip>
          </div>
          <div className="select-device">
            {
              deviceItems.map((item) => (
                <Tooltip anchorElement="target" position="top" key={item.id}>
                  <button
                    key={item.id}
                    title={item.text}
                    type="button"
                    onClick={() => onDeviceItemClick({ item: item })}
                    className={`btn select-device-item ${previewType === item.id ? 'active' : ''}`}
                  >
                    <Icon type={item.icon} className='pe-none' />
                  </button>
                </Tooltip>
              ))
            }
          </div>
        </div>
      </div>
      {
        errorMessage &&
        <ErrorMessage
          type="modal"
          errorMessage={errorMessage}
          onHide={() => setErrorMessage(null)} />
      }
      {
        !questionSelected ?
          <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
            <i className="fa fas fa-pen droppable-text fa-4x mb-3" />
            <span className="droppable-text h4">Select element to preview</span>
          </div>
          :
          previewUrl === null || theData.loadingState ?
            <InProgressOverlay theme="primary" type="fullscreen" />
            :
            <div className="h-100 position-relative">
              {returnBrowser() === 'Safari' &&
                <div className="d-flex align-items-center alert alert-warning w-100 preview-warning shadow alert-dismissible fade show" >
                  <div className="w-100">
                    <p className="m-0">Preview can't load correctly due to an issue with browser cookies. Try enabeling cookies in your browser and cross-site tracking for better experience or <a className="text-primary" href={previewUrl} target="_blank" rel="noopener noreferrer"> open preview in a new tab</a>.</p>
                  </div>
                  <button type="button" data-dismiss="alert" aria-label="Close" className="btn btn-icon btn-transparent shadow-none px-1">
                    <span aria-hidden="true"><Icon type="close" /></span>
                  </button>
                </div>
              }
              {theData.dcV2 === 2 ?
                <div className={`d-flex align-items-center h-100 w-100 survey-element-preview card ${previewType === 2 && type !== 'themes' ? "survey-mobile-preview " : "themes-mobile-preview"}`}>
                  <div className={`w-100 h-100 ${previewType === 2 ? "themes-mobile-preview inner" : ""}`}>
                    <div className={`${previewType === 2 ? "device mobile survey-element-preview card h-100 answer-layout" : "h-100"}`}>
                      <iframe
                        title="Survey Preview"
                        key={resetIframe}
                        onLoad={() => setLoading(false)}
                        src={previewUrl}
                        width={"100%"}
                        height={"100%"}
                        className="border-0"
                      />
                    </div>
                  </div>
                </div>
                :
                <Fragment>
                  {previewType === 0 &&
                    <div className="d-flex align-items-center h-100 w-100 survey-element-preview card">
                      <div className="w-100 h-100">
                        <iframe
                          title="Survey Preview"
                          key={resetIframe}
                          onLoad={() => setLoading(false)}
                          src={previewUrl}
                          width={"100%"}
                          height={"100%"}
                          className={`${loading ? 'd-none' : null} border-0`}
                        />
                        {loading &&
                          <InProgressOverlay theme="primary" type="fullscreen" />}
                      </div>
                    </div>
                  }
                  {previewType === 1 &&
                    <div className={`d-flex align-items-center h-100  survey-element-preview card ${type !== 'themes' ? "survey-tablet-preview" : "themes-tablet-preview"}`}>
                      <div className="device tablet">
                        <iframe
                          key={resetIframe}
                          onLoad={() => setLoading(false)}
                          title="Survey Preview"
                          src={previewUrl}
                          width={"100%"}
                          height={"100%"}
                          className={`${loading ? 'd-none' : null} border-0`}
                        />
                        {loading &&
                          <InProgressOverlay theme="primary" type="fullscreen" />}
                      </div>
                    </div>
                  }
                  {previewType === 2 &&
                    <div className={`d-flex align-items-center w-100 h-100 ${type !== 'themes' ? "survey-mobile-preview " : "themes-mobile-preview"}`}>
                      <div className='d-flex align-items-center w-100 h-100 themes-mobile-preview p-0 inner'>
                        <div className="device mobile survey-element-preview card h-100 answer-layout">
                          <iframe
                            key={resetIframe}
                            onLoad={() => setLoading(false)}
                            title="Survey Preview"
                            src={`${previewUrl}&rs_m=1`}
                            width={"100%"}
                            height={"100%"}
                            className={`${loading ? 'd-none' : null} border-0`}
                          />
                          {loading &&
                            <InProgressOverlay theme="primary" type="fullscreen" />}
                        </div>
                      </div>
                    </div>
                  }
                </Fragment>
              }
            </div>
      }
    </div >
  )
}


