import { useCallback } from 'react';

import { returnMultiSelectionData } from 'components/Analysis/Analyze/components/shared/helpers/returnMultiSelectionData/returnMultiSelectionData';
import { useAppDispatch } from 'app/hooks';
import type { ContentType, Question } from '../types';
import { useStateMapper } from './useStateMapper';

// ----------------------------------------------------------------------

export const useSelection = (contentType: ContentType) => {
  const dispatch = useAppDispatch();
  const { state } = useStateMapper(contentType);

  // Action type creators
  const createActionType = useCallback(
    (action: string) => `${contentType.toUpperCase()}_${action}`,
    [contentType]
  );

  // Helper function to update question selection
  const updateQuestions = useCallback(
    (questions: Question[], value: boolean): Question[] => {
      return questions.map(question => ({
        ...question,
        selected: value,
      }));
    },
    []
  );

  const setActiveRow = useCallback(
    (questionIndex: number) => {
      //  Set questions active when clicked
      const updatedFirstColumn = state.firstColumn.map(
        (question: Question, index: number) => ({
          ...question,
          active: index === questionIndex,
        })
      );

      dispatch({
        type: createActionType('SET_ACTIVE_ROW'),
        payload: updatedFirstColumn,
      });
    },
    [dispatch, state.firstColumn, createActionType]
  );

  // Select all rows
  const selectAllRows = useCallback(() => {
    const updatedRows = updateQuestions(state.firstColumn, true);
    dispatch({
      type: createActionType('UPDATE_ROWS'),
      payload: updatedRows,
    });
  }, [dispatch, state.firstColumn, updateQuestions, createActionType]);

  // Select all columns
  const selectAllColumns = useCallback(() => {
    const updatedColumns = updateQuestions(state.scndColumn, true);
    dispatch({
      type: createActionType('UPDATE_COLUMNS'),
      payload: updatedColumns,
    });
  }, [dispatch, state.scndColumn, updateQuestions, createActionType]);

  // Deselect all rows
  const deselectAllRows = useCallback(() => {
    const updatedRows = updateQuestions(state.firstColumn, false);
    dispatch({
      type: createActionType('UPDATE_ROWS'),
      payload: updatedRows,
    });
  }, [dispatch, state.firstColumn, updateQuestions, createActionType]);

  // Deselect all columns
  const deselectAllColumns = useCallback(() => {
    const updatedColumns = updateQuestions(state.scndColumn, false);
    dispatch({
      type: createActionType('UPDATE_COLUMNS'),
      payload: updatedColumns,
    });
  }, [dispatch, state.scndColumn, updateQuestions, createActionType]);

  // Helper function to get selected items from a column
  const getSelectedItems = useCallback(
    (items: Question[]) => {
      if (items === null || items === undefined) {
        return [];
      }

      return items.filter(item => item.selected).map(item => item.id) || []
    }, []);

  const selectQuestion = useCallback(
    (
      columnType: 'firstColumn' | 'scndColumn',
      questionId: string,
      isRangeSelection: boolean
    ) => {
      const isRow = columnType === 'firstColumn';
      const sourceData = isRow ? state.firstColumn : state.scndColumn;
      const currentSelection = getSelectedItems(sourceData);

      const updatedData = returnMultiSelectionData(
        sourceData,
        currentSelection,
        isRangeSelection,
        questionId
      );

      // Dispatch appropriate action based on column type
      dispatch({
        type: isRow
          ? createActionType('UPDATE_ROWS')
          : createActionType('UPDATE_COLUMNS'),
        payload: updatedData,
      });
    },
    [
      state.firstColumn,
      state.scndColumn,
      getSelectedItems,
      dispatch,
      createActionType,
    ]
  );

  const updateCheckedNum = useCallback(() => {
    const createSelectionItem = (source: string, id: string) => ({
      source,
      id,
    });

    const selectedRowNums = getSelectedItems(state.firstColumn).map(id =>
      createSelectionItem('firstColumn', id)
    );

    const selectedColumNums = getSelectedItems(state.scndColumn).map(id =>
      createSelectionItem('scndColumn', id)
    );

    dispatch({
      type: createActionType('UPDATE_CHECKED_NUM'),
      payload: [...selectedRowNums, ...selectedColumNums],
    });
  }, [
    getSelectedItems,
    state.firstColumn,
    state.scndColumn,
    dispatch,
    createActionType,
  ]);

  return {
    setActiveRow,
    selectAllRows,
    selectAllColumns,
    deselectAllRows,
    deselectAllColumns,
    selectQuestion,
    updateCheckedNum,
  };
};
