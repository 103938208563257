import React, { Fragment, useState } from 'react';
import { Input } from '@progress/kendo-react-inputs';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs"
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import { Icon } from '../../../../../../../shared/Icon/Icon';
import { useDebounce } from '../../../../../../../shared/customHooks/useDebounce';
import { DropdownButton } from '../../../../../../../shared/DropdownButton/DropdownButton';
import SurveyQuillEditor from '../../../../../../../shared/SurveyQuillEditor/SurveyQuillEditor';
import { returnReversedIndex } from '../../../../../../../shared/SurveyQuillEditor/SurveyQuillEditorHelpers';
import { TextFilterDropdownButton } from '../../../../helpers/TextFilterDropdownButton/TextFilterDropdownButton';
import { AnswerLayoutValidationErrorMessage } from '../../../../helpers/AnswerLayoutValidationErrorMessage/AnswerLayoutValidationErrorMessage';
import SurveyAnswersQuillEditor from '../../../../../../../shared/SurveyQuillEditor/SurveyAnswersQuillEditor';

export default function AdvancedStatementsTable({ onHide, subquestion, onChangeCode, showFilterBuilderHandler, onSaveChanges, subQuesIndex, editingLanguage, returnTextTypeText, renderItem, showLogic, onDeleteStatementText, onChangeTableOption, onDragEnd, onTextChangeHandler, onAddElement, showRowFilterBuilder }) {
  const dispatch = useDispatch()
  const { theData, autoSaveStatus } = useSelector((theState) => (theState.surveyInitialDataReducer));
  const { token } = useSelector((state) => state.tokenStateReducer);
  const { selectedElement } = useSelector((theState) => theState.surveyInitialDataReducer)
  const saveWithDebounce = useDebounce(() => dispatch({ type: 'SAVE_QUESTION' }), 1000);
  const [clickedField, setClickedField] = useState("");

  const isParentDisabled = theData.selectedItem.disabled
  const filterNAttributes = theData.elementTypes.find((el) => el.id === 'r').props.filter(el => el.id.includes('filter') && el.id !== 'filter')
  const skipValues = [{ text: 'None', id: '' }, { text: 'Next question', id: 'nextques' }, { text: 'Next section', id: 'nextsection' }, { text: 'Exit (complete)', id: 'exit' }]
  const allQuestions = []

  theData.data.forEach(el => {
    el.elements.forEach(item => {
      allQuestions.push(item)
    })
  })

  const selectedItemIndex = allQuestions.findIndex(el => el.id === theData.selectedItem.id)
  allQuestions.forEach((el, key) => {
    if (key > selectedItemIndex) {
      skipValues.push({ text: el.label, id: el.label, ignoreDeleteDisable: true })
    }
  })

  const onRowFilterValueChange = (value, filterId, rowIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes) {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes[filterId] = value
    } else {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes = { [filterId]: value }
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const onSkipItemClick = (e, rowIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes) {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes.skip = e.item.id
    } else {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes = { skip: e.item.id }
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    dispatch({ type: 'SAVE_QUESTION' })
  }

  const onLocalNameChange = (value, rowIndex) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes) {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes.localName = value
    } else {
      updatedSelectedItem.elements.find(item => item.type === 'rgroup').elements[rowIndex].attributes = { localName: value }
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    saveWithDebounce()
  }

  const checkSubquestions = theData.selectedItem.elements.filter((item) => item.type === 'subq')

  const isSingleMultiQuestion = theData.selectedItem?.type === "ques" &&
    (checkSubquestions[theData.selectedSubquestion]?.attributes.type === "n" || checkSubquestions[theData.selectedSubquestion]?.attributes.type === "m");

  return (
    <Dialog width={'95%'} height={'95%'} title={subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Statements' : 'Answers'} onClose={() => autoSaveStatus !== 'saving' && onHide()} className="import-dialog advanced-dialog">
      <div className="table-responsive h-100">
        <table className="table table-sm answer-layout-table">
          <thead className='table-header'>
            <tr>
              <td style={{ minWidth: '500px' }}><span>Text</span></td>
              {
                (subquestion.attributes.type === 'n' || subquestion.attributes.type === 'm') &&
                <td><span>Code</span></td>
              }
              <td className="icon">
                <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                  <span className='icon' title='Keep position'><Icon type="answer-layout-keep-position" /></span>
                </Tooltip>
              </td>
              <td className="icon">
                <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                  <span className='icon' title='Exclusive'><Icon type="answer-layout-exclusive" /></span>
                </Tooltip>
              </td>
              <td className="icon">
                <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                  <span className='icon' title='Other'><Icon type="answer-layout-other" />
                  </span>
                </Tooltip>
              </td>
              {
                (subquestion.attributes.type === 'n' || subquestion.attributes.type === 'm') &&
                <td style={{ minWidth: '180px' }}><span>Skip to</span></td>
              }
              <td style={{ minWidth: '180px' }} className={`${isSingleMultiQuestion ? "d-none" : ""}`}><span>Row filter</span></td>
              {
                theData.selectedItem.elements.filter(el => el.type === 'subq').length > 1 &&
                theData.selectedItem.elements.filter(el => el.type === 'subq').map((subq, subqIndex) => (
                  <td style={{ minWidth: '180px' }} className={`${isSingleMultiQuestion ? "d-none" : ""}`} key={subq.id}>
                    <span>Row filter (Q{subqIndex + 1})</span>
                  </td>
                ))
              }

              <td style={{ minWidth: '180px' }}><span>Custom attribute</span></td>
            </tr>
          </thead>
          <DragDropContext onDragEnd={(result) => onDragEnd(result, subQuesIndex)}>
            <Droppable droppableId="rows" type="rows">{
              (provided) => (
                <tbody ref={provided.innerRef}>
                  {
                    theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter(el => (el.type === 'r' || el.type === 'other' || el.type === "rc") && el.elements?.length > 0).map((row, rowIndex) => (
                      <Draggable key={row.id} draggableId={row.id} index={rowIndex}>{
                        (provided, snapshot) => (
                          <tr key={row.id} ref={provided.innerRef} {...provided.draggableProps} className={`${snapshot.isDragging ? 'dragging' : ''}`}>
                            <td className='w-100' colSpan={`${row.type === "rc" ? "1000" : ""}`}>
                              <div className="d-flex align-items-center pl-3">
                                <i className="fas fa-grip-lines-vertical text-disabled mr-3" {...provided.dragHandleProps} tabIndex="-1" />
                                <ul className="list-group flex-grow-1">
                                  {row.elements.filter(rowText => rowText.textValues).map((rowText, rowTextIndex) => (
                                    <li key={rowTextIndex} className="list-group-item statement-list-group-item d-flex">
                                      <div style={{ cursor: 'text' }} className="d-flex  align-items-center flex-grow-1" onClick={() => dispatch({ type: 'SET_SELECTED_ELEMENT', payload: { ...row, index: rowIndex } })}>
                                        {row.type === "rc" ?
                                          <Fragment>
                                            <input
                                              type="text"
                                              style={{ maxHeight: 'unset' }}
                                              className='form-control border-0 shadow-none text-primary bg-secondary'
                                              value={rowText.textValues[editingLanguage]}
                                              onChange={(e) => onTextChangeHandler(e.target.value, 'rc', rowIndex, rowTextIndex)}
                                              placeholder="Enter answer header"
                                            />
                                          </Fragment>
                                          :
                                          <Fragment>
                                            {!theData.htmlMode ?
                                              clickedField === rowIndex ?
                                                <SurveyAnswersQuillEditor
                                                  token={token}
                                                  value={rowText.textValues[editingLanguage]}
                                                  readOnly={row.disabled}
                                                  setValue={(e) => onTextChangeHandler(e, "r", rowIndex, rowTextIndex)}
                                                  orderNumber={returnReversedIndex(theData.selectedItem.elements.find(el => el.type === 'rgroup').elements.filter((row) => row.type === 'listref' || row.elements?.length > 0).length, rowIndex)}
                                                  selectedItemId={theData.selectedItem.id}
                                                  placeholder={rowText.attributes?.textType === 'do' ? 'Enter instruction text...' : rowText.attributes?.textType === 'say' ? 'Enter supplementary text...' : subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Enter statement text...' : 'Enter answer text...'}
                                                />
                                                :
                                                <input
                                                  id={`${rowText}_${rowIndex}`}
                                                  type="text"
                                                  style={{ maxHeight: 'unset' }}
                                                  className="form-control border-0 shadow-none text-answer bg-white"
                                                  disabled={row.disabled}
                                                  value={rowText.textValues[editingLanguage].replace(/(<([^>]+)>)/gi, '')}
                                                  onClick={() => setClickedField(rowIndex)}
                                                  readOnly
                                                  placeholder={rowText.attributes?.textType === 'do' ? 'Enter instruction text...' : rowText.attributes?.textType === 'say' ? 'Enter supplementary text...' : subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Enter statement text...' : 'Enter answer text...'}
                                                />
                                              :
                                              <input
                                                type="text"
                                                style={{ maxHeight: 'unset' }}
                                                className='form-control border-0 shadow-none text-primary'
                                                value={rowText.textValues[editingLanguage]}
                                                onChange={(e) => onTextChangeHandler(e.target.value, 'r', rowIndex, rowTextIndex)}
                                                placeholder={rowText.attributes?.textType === 'do' ? 'Enter instruction text...' : rowText.attributes?.textType === 'say' ? 'Enter supplementary text...' : 'Enter question text...'}
                                              />
                                            }
                                          </Fragment>
                                        }
                                      </div>
                                      {
                                        row.errorMessage !== "" &&
                                        <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                          <AnswerLayoutValidationErrorMessage errorMessage={row.errorMessage} className="mr-2" />
                                        </Tooltip>
                                      }

                                      {row.type !== "rc" && (
                                        <DropdownButton
                                          text={returnTextTypeText(rowText.attributes.textType)}
                                          items={[{ text: "textType" }, { text: "addText" }, { text: 'textFilter' }]}
                                          className={`p-0 mr-2 ml-2 ${returnTextTypeText(rowText.attributes.textType) === ' ' ? 'btn-transparent' : 'btn-secondary pl-2'}`}
                                          parentClass="d-flex align-items-center"
                                          renderItem={(e) => renderItem('r', e, rowText, rowIndex, rowTextIndex)}
                                        />
                                      )}

                                      {
                                        rowText.attributes?.filter && showLogic && row.type !== "rc" &&
                                        <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="top">
                                          <div className="d-flex align-items-center h-100 mr-2">
                                            <TextFilterDropdownButton
                                              type="r"
                                              text={rowText}
                                              showLogic={showLogic}
                                              itemIndex={rowIndex}
                                              itemTextIndex={rowTextIndex}
                                              showFilterBuilderHandler={showFilterBuilderHandler} />
                                          </div>
                                        </Tooltip>
                                      }
                                      <div className='d-flex p-2 align-items-center border-left'>
                                        <Tooltip className='answer-layout-table-tooltip' anchorElement="target" position="bottom">
                                          <button
                                            type="button"
                                            title='Delete'
                                            disabled={row.type !== "rc" ? theData.selectedItem.published : false}
                                            onClick={() => onDeleteStatementText(rowIndex, rowTextIndex)}
                                            className="btn btn-icon shadow-none btn-transparent p-0">
                                            <Icon type="delete-alt" className='pe-none' />
                                          </button>
                                        </Tooltip>
                                      </div>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </td>
                            {
                              (subquestion.attributes.type === 'n' || subquestion.attributes.type === 'm') && row.type !== "rc" &&
                              <td id='code'>
                                <input style={{ maxWidth: '20ch', width: row.attributes?.code?.length > 4 ? `${row.attributes?.code?.length + 1}ch` : '5ch' }} value={row.attributes?.code} onChange={(e) => onChangeCode(e.target.value, 'r', rowIndex)} type="input" min={1} className="form-control text-center text-primary w-100" />
                              </td>
                            }

                            {row.type !== "rc" && (
                              <Fragment>
                                <td id='checkbox'>
                                  <input checked={row.attributes?.exclusive === 'yes'} onChange={(e) => onChangeTableOption(e.target.checked, 'exclusive', 'r', rowIndex)} type="checkbox" className='form-control mx-auto shadow-none' />
                                </td>
                                <td id='checkbox'>
                                  <input checked={row.attributes?.rot === 'n'} onChange={(e) => onChangeTableOption(e.target.checked, 'rot', 'r', rowIndex)} type="checkbox" className='form-control mx-auto shadow-none' />
                                </td>
                                <td id='checkbox'>
                                  <input checked={row.type === 'other'} onChange={(e) => onChangeTableOption(e.target.checked, 'openRow', 'r', rowIndex)} type="checkbox" className='form-control mx-auto shadow-none' />
                                </td>
                              </Fragment>
                            )}


                            {
                              (subquestion.attributes.type === 'n' || subquestion.attributes.type === 'm') && row.type !== "rc" &&
                              <td id='input' style={{ minWidth: '180px' }}>
                                <DropdownButton
                                  items={skipValues}
                                  text={row.attributes?.skip ? skipValues.find(el => el.id === row.attributes.skip).text : "None"}
                                  parentClass='d-flex flex-column form-control p-0 skipto'
                                  onItemClick={(e) => onSkipItemClick(e, rowIndex)}
                                />
                              </td>
                            }
                            {row.type !== "rc" && (
                              <td id='input' style={{ minWidth: '180px' }} className={`${isSingleMultiQuestion ? "d-none" : ""}`}>
                                <div className="form-group mb-0 row-filter">
                                  <div className="d-flex align-items-center input-group" title={row.attributes?.filter}>
                                    <Input
                                      className="form-control"
                                      disabled={selectedElement.disabled || isParentDisabled}
                                      onChange={(e) => onRowFilterValueChange(e.target.value, 'filter', rowIndex)}
                                      type="text"
                                      value={row.attributes?.filter}
                                    />
                                    <div>
                                      <button
                                        type='button'
                                        className="btn btn-icon shadow-none dropdown-toggle btn-transparent p-0 mr-2 ml-2"
                                        disabled={selectedElement.disabled || isParentDisabled}
                                        onClick={(e) => { e.preventDefault(); showRowFilterBuilder('filter', row, rowIndex) }}>
                                        <Icon type="more-horizontal" />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            )}

                            {
                              row.type !== "rc" && theData.selectedItem.elements.filter(el => el.type === 'subq').length > 1 &&
                              theData.selectedItem.elements.filter(el => el.type === 'subq').map((subq, subqIndex) => (
                                <td id='input' key={subq.id}>
                                  <div className="form-group mb-0 row-filter">
                                    <div className="d-flex align-items-center input-group" title={row.attributes ? row.attributes[filterNAttributes[subqIndex].id] : ""}>
                                      <Input
                                        type="text"
                                        className="form-control"
                                        disabled={selectedElement.disabled || isParentDisabled}
                                        onChange={(e) => onRowFilterValueChange(e.target.value, filterNAttributes[subqIndex].id, rowIndex)}
                                        value={row.attributes ? row.attributes[filterNAttributes[subqIndex].id] : ""}
                                      />
                                      <div>
                                        <button
                                          type='button'
                                          className="btn btn-icon shadow-none dropdown-toggle btn-transparent p-0 mr-2 ml-2"
                                          disabled={selectedElement.disabled || isParentDisabled}
                                          onClick={(e) => { e.preventDefault(); showRowFilterBuilder(filterNAttributes[subqIndex].id, row, rowIndex) }}>
                                          <Icon type="more-horizontal" />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              ))
                            }
                            {row.type !== "rc" && (
                              <td id='input' style={{ minWidth: '180px' }}>
                                <input
                                  type="text"
                                  value={row.attributes?.localName ? row.attributes.localName : ""}
                                  className='form-control shadow-none text-primary'
                                  onChange={(e) => onLocalNameChange(e.target.value, rowIndex)}
                                />
                              </td>
                            )}

                          </tr>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </tbody>
              )}
            </Droppable>
          </DragDropContext>
        </table>

        <div className="d-flex p-3 gap-md">
          <button type="button" onClick={() => onAddElement('r', subQuesIndex)} className="btn btn-shadow">
            <strong>{subquestion.attributes.type === 'rn' || subquestion.attributes.type === 'rm' ? 'Add new statement' : 'Add new answer'}</strong>
          </button>

          <button type="button" onClick={() => onAddElement('rc', subQuesIndex)} className="btn btn-shadow">
            <strong>Add answer header</strong>
          </button>


        </div>
      </div>

      <DialogActionsBar layout='end'>
        {
          autoSaveStatus === 'saving' ?
            <div className='d-flex gap-sm align-items-center justify-content-end' style={{ width: "150px" }}>
              <p className='m-0 small text-gray'>
                Saving your changes
              </p>
              <Icon type="cloud-saving" />
            </div>
            :
            <div className='d-flex gap-sm align-items-center justify-content-end' style={{ width: "150px" }}>
              <p className='m-0 small text-gray'>
                All changes saved
              </p>
              <Icon type="cloud" />
            </div>
        }
        <button type="button" className="k-button btn btn-secondary" disabled={autoSaveStatus === 'saving'} onClick={onHide}>Close</button>
      </DialogActionsBar>
    </Dialog >
  )
}
