import { useCallback, useState } from 'react';
//
import QuestionIcon from '../../question-icon/QuestionIcon';
import type { EditableQuestion } from '../types';
import { ColumnSource } from '../types';
//
import '../styles.scss';

// ----------------------------------------------------------------
type QuestionItemProps = {
  question: EditableQuestion;
  selected: EditableQuestion | null;
  onSelect: (question: EditableQuestion) => void;
  config: { expandable: boolean };
};

const QuestionContent = ({ question }: { question: EditableQuestion }) => {
  const source = {
    [ColumnSource.ROW]: {
      text: 'row',
      bgcolor: '#cbecd6',
      color: '#000',
    },
    [ColumnSource.COLUMN]: {
      text: 'column',
      bgcolor: '#bf70cc',
      color: '#fff',
    },
  };

  return (
    <div className="question-editor__list-item__contents">
      <QuestionIcon type={question.type} className="icon" size="lg" />

      <div className="d-flex flex-row justify-content-between align-items-center w-100">
        <div
          className="d-flex flex-column text-truncate flex-grow-1"
          style={{ maxWidth: '130px' }}
        >
          <span className="title text-truncate">{question.title}</span>
          <span className="subtitle text-truncate">{question.content}</span>
        </div>

        <div
          style={{ fontSize: '0.8rem', fontWeight: 500, marginTop: '-1rem' }}
        >
          <span
            className="badge badge-pill badge-light"
            style={{
              backgroundColor: source[question.source].bgcolor,
              color: source[question.source].color,
            }}
          >
            {source[question.source].text}
          </span>
        </div>
      </div>
    </div>
  );
};

// ----------------------------------------------------------------

const QuestionItem = ({
  question,
  selected,
  onSelect,
  config,
}: QuestionItemProps) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setExpanded(prev => !prev);
  }, []);

  const handleSelect = useCallback(
    (question: EditableQuestion) => {
      onSelect(question);
    },
    [onSelect],
  );

  const renderQuestionItem = (
    item: EditableQuestion,
    isParent: boolean,
    showExpand: boolean,
  ) => {
    const isActive = selected?.id === item.id;
    const hasBannerColumns =
      item.bannerColumns && item.bannerColumns.length > 0;
    const itemClassName = [
      'question-editor__list-group-item',
      isActive ? 'active' : '',
    ].join(' ');

    return (
      <div className={itemClassName} onClick={() => handleSelect(item)}>
        <div className="d-flex align-items-center w-100 gap-sm">
          {isParent && hasBannerColumns ? (
            <ExpandIcon
              onClick={toggleExpand}
              expanded={expanded}
              isActive={isActive}
            />
          ) : (
            <div style={{ minWidth: showExpand ? '17px' : 0 }} />
          )}

          <QuestionContent question={item} />
        </div>
      </div>
    );
  };

  const renderBannerColumns = () =>
    question.bannerColumns?.map(banner => (
      <div key={banner.id}>{renderQuestionItem(banner, false, false)}</div>
    ));

  if (!config.expandable) {
    renderQuestionItem(question, true, false);
  }

  return (
    <div className="question-editor__list-group">
      {renderQuestionItem(question, true, false)}
      {expanded && renderBannerColumns()}
    </div>
  );
};

const ExpandIcon = ({
  expanded,
  onClick,
  isActive,
}: {
  expanded: boolean;
  onClick: (e: React.MouseEvent) => void;
  isActive: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={isActive ? 'text-white' : 'text-analyze'}
      style={{ marginRight: '3px', marginLeft: '3px' }}
    >
      <i className={`fa-solid fa-chevron-${expanded ? 'up' : 'down'}`} />
    </div>
  );
};

export default QuestionItem;
