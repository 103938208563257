import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Input } from "@progress/kendo-react-inputs";

import { fetchGetJsonOrEmptyArray as getSampleVariables } from "../../../../../../services/services";
import { ActionButtons } from "../../helpers/ActionButtons/ActionButtons";
import { RootState } from "../../../../../../store/reducers/rootReducer";
import { FullHeightSpinner } from "../../../../../shared/FullHeightSpinner/FullHeightSpinner";
import { Avatar } from "../../../../../shared/Avatar/Avatar";

interface Props {
  step: string;
  token: string;
  onNextStep: (type: string) => void;
  onReviewAndSubmit: () => void;
}

export const Dimensions = ({ step, token, onNextStep, onReviewAndSubmit }: Props) => {
  const { panelData } = useSelector((theState: RootState) => theState.panelStateReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!panelData.variables) {
      getSampleVariables("variables", token, true).then((res: any) => {
        dispatch({ type: "SET_SAMPLE_VARIABLES", payload: res });
      });
    }
  }, [token, panelData, dispatch]);

  const onDragEnd = (result: any) => {
    if (result.destination && result.source.droppableId === "variables") {
      const item = panelData.variables.find((item: any) => item.name === result.draggableId);
      if (result.destination.droppableId === "variablesFirstColumn") {
        const updatedColumn = [...panelData.variablesFirstColumn, item];
        dispatch({
          type: "UPDATE_SAMPLE_VARIABLES_COLUMNS",
          payload: { variablesFirstColumn: updatedColumn, variablesScndColumn: panelData.variablesScndColumn },
        });
      } else if (result.destination.droppableId === "variablesScndColumn") {
        const updatedColumn = [...panelData.variablesScndColumn, item];
        dispatch({
          type: "UPDATE_SAMPLE_VARIABLES_COLUMNS",
          payload: { variablesFirstColumn: panelData.variablesFirstColumn, variablesScndColumn: updatedColumn },
        });
      }
    }
  };

  const onSearchData = (e: any) => {
    if (e.target.value) {
      const updatedData = panelData.originalVariables.filter(
        (item: any) => item.label && item.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      dispatch({ type: "SEARCH_SAMPLE_VARIABLES", payload: updatedData });
    } else {
      dispatch({ type: "SEARCH_SAMPLE_VARIABLES", payload: panelData.originalVariables });
    }
  };

  return (
    <div className="d-flex flex-column container-fluid p-0 h-100 overflow-hidden">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="row no-gutters d-flex border-bottom flex-grow-1 overflow-hidden">
          <div className="d-flex flex-column col-lg-2 border-right border-top h-100 overflow-hidden">
            <div className="d-flex p-2 border-bottom">
              <Input onChange={(e) => onSearchData(e)} type="text" placeholder="Search" className="w-100" />
              <button className="btn btn-primary d-flex align-items-center">
                <i className="fa fa-search"></i>
              </button>
            </div>
            {!panelData.variables ? (
              <FullHeightSpinner />
            ) : panelData.variables.length !== 0 ? (
              <Droppable key={"variables"} droppableId={"variables"}>
                {(provided: any, snapshot: any) => (
                  <div
                    ref={provided.innerRef}
                    {...snapshot.isDraggingOver}
                    className={`d-flex flex-column h-100 overflow-auto ${snapshot.isDraggingOver ? "bg-light-blue" : "bg-white"}`}
                  >
                    {panelData.variables.map((item: any, key: number) => (
                      <Draggable key={item.name} draggableId={item.name} index={key}>
                        {(provided, snapshot) => (
                          <div
                            className={`d-flex py-2 px-3 align-items-center bg-white ${snapshot.isDragging ? "border" : "border-bottom"}`}
                            key={key}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // @ts-ignore TODO
                            {...snapshot.isDragging}
                            {...provided.dragHandleProps}
                          >
                            <Avatar icon="fas fa-circle" color="light-blue" class="avatar-lg" />
                            <h6 className="mb-0">{item.label}</h6>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            ) : (
              <div className="d-flex h-100 w-100 justify-content-center align-items-center">
                <span className="h5 text-center text-primary">No variables were found</span>
              </div>
            )}
          </div>
          <div className="col-lg-10 d-flex bg-light h-100 p-2">
            <Droppable key={"variablesFirstColumn"} droppableId={"variablesFirstColumn"}>
              {(provided: any, snapshot: any) => (
                <div
                  className="h-100 variable-column-first mr-2"
                  ref={provided.innerRef}
                  {...snapshot.isDraggingOver}
                  style={snapshot.isDraggingOver ? { background: "skyblue" } : {}}
                >
                  {panelData.variablesFirstColumn.length > 0 ? (
                    panelData.variablesFirstColumn.map((item: any, key: number) => (
                      <Draggable key={item.name} draggableId={"firstColumn"} index={key}>
                        {(provided, snapshot) => (
                          <div
                            className="d-flex py-2 px-3 align-items-center bg-white border"
                            key={key}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // @ts-ignore TODO
                            {...snapshot.isDragging}
                            {...provided.dragHandleProps}
                          >
                            <Avatar icon="fas fa-circle" color="light-blue" class="avatar-lg" />
                            <h6 className="mb-0">{item.label}</h6>
                          </div>
                        )}
                      </Draggable>
                    ))
                  ) : (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <span className="droppable-text h4">Drop variables here</span>
                    </div>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
            <div className="w-100 h-100 p-0 overflow-hidden">
              <Droppable key={"variablesScndColumn"} droppableId={"variablesScndColumn"} direction="horizontal">
                {(provided: any, snapshot: any) => (
                  <div
                    className="variable-column-scnd w-100"
                    ref={provided.innerRef}
                    {...snapshot.isDraggingOver}
                    style={snapshot.isDraggingOver ? { background: "skyblue" } : {}}
                  >
                    {panelData.variablesScndColumn.length > 0 ? (
                      panelData.variablesScndColumn.map((item: any, key: number) => (
                        <Draggable key={item.name} draggableId={"scndColumn"} index={key}>
                          {(provided, snapshot) => (
                            <div
                              className="d-flex py-2 px-3 align-items-center bg-white border"
                              key={key}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              // @ts-ignore TODO
                              {...snapshot.isDragging}
                              {...provided.dragHandleProps}
                            >
                              <Avatar icon="fas fa-circle" color="light-blue" class="avatar-lg" />
                              <h6 className="mb-0">{item.label}</h6>
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <div className="h-100 d-flex align-items-center justify-content-center m-auto">
                        <span className="droppable-text h4">Drop variables here</span>
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
              <div></div>
            </div>
          </div>
        </div>
      </DragDropContext>
      <ActionButtons
        step={step}
        onNextStep={onNextStep}
        onReviewAndSubmit={onReviewAndSubmit}
        targetInterviews={panelData.sampleSpecification.targetInterviews}
      />
    </div>
  );
};
