import React from 'react';
import { useSelector } from 'react-redux';
import { Droppable } from '@hello-pangea/dnd';

import DraggableItem from '../DraggableItem/DraggableItem';
import returnDisabledDrop from '../../shared/helpers/returnDisabledDrop/returnDisabledDrop';

const Column = (props) => {
  const userSettings = useSelector((state) => state.userSettingsReducer);
  let displayColumn = null;
  if (props.firstColumn) {
    const disabled = returnDisabledDrop(props)
    displayColumn =
      <Droppable key={'firstColumn'} droppableId={'firstColumn'} isDropDisabled={disabled}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={`${snapshot.isDraggingOver && 'light-analyze-background'} droppable-column-first ${props.isReports ? "" : "droppable-column-first--maximized"}`}
            style={{ overflow: 'auto' }}
            {...provided.droppableProps}>
            {props.state.firstColumn && props.state.firstColumn.length < 1 ?
              <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                <span className="droppable-text text-center h5">Drop rows here</span>
              </div>
              :
              <ul className={`list-group w-100 ${userSettings.compactView?.compactRows ? "compact-view" : ""}`}>
                {props.state.firstColumn
                  ? props.state.firstColumn.map((item, index) => (
                    <DraggableItem
                      rangeItems={props.rangeItems}
                      onCheck={props.onCheck}
                      firstColumnItem={true}
                      key={item.id}
                      index={index}
                      item={item}
                      isReports={props.isReports}
                      onSelectQuestion={props.onSelectQuestion}
                      minimized={userSettings.minimize}
                      compactView={userSettings.compactView?.compactRows}
                    />
                  ))
                  : null
                }
              </ul>
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
  } else if (props.secondColumn) {
    const disabled = returnDisabledDrop(props)
    displayColumn =
      <Droppable key={'scndColumn'} droppableId={'scndColumn'} direction='horizontal' isDropDisabled={disabled}>
        {(provided, snapshot) => (
          <div
            className={`${snapshot.isDraggingOver && 'light-analyze-background'} droppable-column-scnd ${userSettings.compactView?.compactColumns ? "compact-view" : ""}`}
            ref={provided.innerRef}
            {...provided.droppableProps}>
            {props.state.scndColumn && props.state.scndColumn.length < 1 ?
              <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                <span className="droppable-text h5 mb-0">Drop columns here</span>
              </div>
              :
              <ul className="list-group list-group-horizontal">
                {props.state.scndColumn
                  ? props.state.scndColumn.map((item, index) => (
                    <DraggableItem
                      rangeItems={props.rangeItems}
                      scndColumnItem={true}
                      onCheck={props.onCheck}
                      key={item.id}
                      index={index}
                      item={item}
                      minimized={userSettings.minimize}
                      compactView={userSettings.compactView?.compactColumns}
                    />
                  ))
                  : null
                }
              </ul>
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
  } else if (props.questions) {
    displayColumn =
      <Droppable droppableId="ITEMS" key="ITEMS">
        {(provided, snapshot) => (
          <>
            <ul
              className={`list-group ${snapshot.isDraggingOver ? 'light-analyze-background ' : ''}${userSettings.compactView?.compactDataset ? "compact-view" : ""}`}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {props.theData.map((item, index) => (
                <DraggableItem key={item.id}
                  index={index}
                  item={item}
                  onCheck={props.onCheck}
                  question={true}
                  rangeItems={props.rangeItems}
                  data={props.theData}
                  minimized={userSettings.minimize}
                  compactView={userSettings.compactView?.compactDataset}
                />
              ))}
            </ul>
            {provided.placeholder}
          </>
        )}
      </Droppable>
  }
  else if (props.customQuestions) {
    displayColumn =
      <Droppable droppableId="ITEMS" key="ITEMS">
        {(provided, snapshot) => (
          <>
            <ul className={`list-group ${userSettings.compactView?.compactDataset ? "compact-view" : ""}`}
              ref={provided.innerRef}
              {...provided.droppableProps}>
              {props.theData.map((item, index) => {
                return item.isCustom === true ?
                  <DraggableItem key={item.id}
                    index={index}
                    item={item}
                    onCheck={props.onCheck}
                    customQues={true}
                    rangeItems={props.rangeItems}
                    data={props.theData}
                    minimized={userSettings.minimize}
                    compactView={userSettings.compactView?.compactDataset}
                  />
                  : null
              })}
            </ul>
            {provided.placeholder}
          </>
        )}
      </Droppable>
  } else if (props.report) {
    displayColumn =
      <Droppable key={'questions'} droppableId={'questions'} isDropDisabled={false} >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            className={`droppable-column-first ${userSettings.minimize ? "droppable-column-first--minimized" : "droppable-column-first--maximized"} w-100`}
            style={{ overflow: 'auto', background: snapshot.isDraggingOver ? 'skyblue' : '' }}
            {...provided.droppableProps}>
            {props.theData.questions && props.theData.questions.length < 1 ?
              <div className="h-100 d-flex align-items-center justify-content-center">
                <span className="droppable-text text-center h5">Drop rows here</span>
              </div>
              :
              <ul className={`list-group w-100 ${userSettings.compactView?.compactDataset ? "compact-view" : ""}`}>
                {props.theData.questions
                  ? props.theData.questions.map((item, index) => (
                    <DraggableItem
                      reportTab={true}
                      key={index}
                      token={props.token}
                      index={index}
                      item={item}
                      onCheck={props.onCheck}
                      question={true}
                      rangeItems={props.rangeItems}
                      data={props.theData.questions}
                      columnData={props.theData.columnData[index]}
                      onSelectQuestion={props.onSelectQuestion}
                      minimized={userSettings.minimize}
                      firstColumnItem={true}
                      selected={props.theData.activeState}
                      selectedArray={props.theData.selected}
                      disabledArray={props.theData.disabled}
                      compactView={userSettings.compactView?.compactDataset}
                    />
                  ))
                  : null
                }
              </ul>
            }
            {provided.placeholder}
          </div>
        )}
      </Droppable>
  }
  return (
    displayColumn
  );
}

export default Column;
