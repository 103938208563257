import { createContext, useContext, useCallback, useState } from 'react';
//
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
//
import LoadingButton from '../components/LoadingButton';

// ----------------------------------------------------------------------

/**
 * Represents an action button configuration for the dialog
 */
export interface DialogAction {
  /** Text label for the action button */
  label: string;
  /** Visual style variant for the button */
  variant?: 'primary' | 'analyze' | 'danger' | 'secondary';
  /** Click handler function that can be async */
  onClick: () => void | Promise<void>;
  /** Whether the action button should be disabled */
  disabled?: boolean;
}

/**
 * Configuration options for the action dialog
 */
export interface ActionDialogConfig {
  /** Dialog title text */
  title: string;
  /** Dialog content as React node */
  content: React.ReactNode;
  /** Optional custom label for cancel button */
  cancelLabel?: string;
  /** Optional dialog width in pixels */
  width?: number;
  /** Array of action buttons to display */
  actions: DialogAction[];
}

/**
 * State interface for the action dialog
 */
export interface ActionDialogState {
  /** Whether the dialog is currently open */
  isOpen: boolean;
  /** Current dialog configuration */
  config: ActionDialogConfig;
}

/**
 * Context value interface for the action dialog
 */
interface DialogContextValue {
  /** Shows the dialog with the given configuration */
  showDialog: (config: ActionDialogConfig) => void;
  /** Renders the dialog component */
  renderDialog: () => React.ReactElement | null;
  /** Whether the dialog is currently open */
  isOpen: boolean;
  /** Whether an action is currently loading */
  isLoading: boolean;
}

const ActionDialogContext = createContext<DialogContextValue | null>(null);

/**
 * Provider component for the action dialog functionality
 */
export const ActionDialogProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [config, setConfig] = useState<ActionDialogConfig | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const showDialog = useCallback((config: ActionDialogConfig) => {
    setConfig(config);
    setIsOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    setConfig(null);
  }, []);

  const handleAction = useCallback(async (action: DialogAction) => {
    setIsLoading(true);

    try {
      await action.onClick();
      handleClose();
    } finally {
      setIsLoading(false);
    }
  }, [handleClose]);

  const renderDialog = useCallback(() => {
    if (!isOpen || !config) return null;

    return (
      <Dialog
        title={config.title}
        width={config.width || 450}
        onClose={handleClose}
      >
        {config.content}

        <DialogActionsBar>
          <Button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
            disabled={isLoading}
          >
            {config.cancelLabel || 'Cancel'}
          </Button>
          {config.actions.map((action, index) => (
            <LoadingButton
              key={index}
              type="button"
              isLoading={isLoading}
              className={`btn btn-${action.variant || 'primary'}`}
              onClick={() => handleAction(action)}
              disabled={action.disabled || isLoading}
            >
              {action.label}
            </LoadingButton>
          ))}
        </DialogActionsBar>
      </Dialog>
    );
  }, [config, handleClose, handleAction, isLoading, isOpen]);

  const value = {
    showDialog,
    renderDialog,
    isOpen,
    isLoading,
  };

  return (
    <ActionDialogContext.Provider value={value}>
      {children}
    </ActionDialogContext.Provider>
  );
};

/**
 * Hook to access the action dialog context
 * @throws Error if used outside of ActionDialogProvider
 * @returns DialogContextValue containing dialog control methods and state
 */
// eslint-disable-next-line react-refresh/only-export-components
export const useActionDialog = () => {
  const context = useContext(ActionDialogContext);

  if (!context) {
    throw new Error('useActionDialog must be used within DialogProvider');
  }

  return context;
};
