import { Controller, useFormContext } from 'react-hook-form';
import type { NumericTextBoxProps } from '@progress/kendo-react-inputs';
import { NumericTextBox } from '@progress/kendo-react-inputs';

type RHFNumberInputProps = {
  name: string;
} & NumericTextBoxProps;

const RHFNumberInput = ({ name, ...other }: RHFNumberInputProps) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <NumericTextBox
          value={value}
          onChange={e => onChange(e.value)}
          {...other}
        />
      )}
    />
  );
};

export default RHFNumberInput;
