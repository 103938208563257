import { useDispatch } from 'react-redux';

import { Icon } from '../../../../../../shared/Icon/Icon';

interface Props {
  theData: any
  subquestion?: any
  selectedElement: { type: string }
}

export const SurveyOptionsHeader = ({ theData, selectedElement, subquestion }: Props) => {
  const dispatch = useDispatch()

  const onGoBackHandler = () => {
    if (theData.selectedItem.type === 'ques') {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: JSON.parse(JSON.stringify(theData.selectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[0])) })
    } else {
      dispatch({ type: 'SET_SELECTED_ELEMENT', payload: JSON.parse(JSON.stringify(theData.selectedItem)) })
    }
  }

  if (selectedElement) {
    if (selectedElement.type === 'ques' || selectedElement.type === 'subq') {
      return (
        <div className='overflow-auto'>
          <ul className="nav nav-pills content-tabs flex-grow-1 w-100 border-bottom">
            <li className="nav-item w-33" onClick={() => {
              dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem.elements.filter((el: any) => el.type === 'subq')[theData.selectedSubquestion] })
              dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: false })
            }}>
              <span className={`nav-link justify-content-start px-4 ${selectedElement.type === 'subq' && !theData.isSurveyComments ? 'active' : ''}`}>Question</span>
            </li>
            <li className="nav-item w-33" onClick={() => {
              dispatch({ type: 'SET_SELECTED_ELEMENT', payload: theData.selectedItem })
              dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: false })
            }}>
              <span className={`nav-link justify-content-start px-4 ${selectedElement.type === 'ques' && !theData.isSurveyComments ? 'active' : ''}`}>Container</span>
            </li>
            <li className="nav-item w-33" onClick={() => dispatch({ type: 'SET_SELECTED_ELEMENT_COMMENTS', payload: true })}>
              <span className={`nav-link justify-content-start px-3 ${theData.isSurveyComments ? 'active' : ''}`}>Comments</span>
            </li>
          </ul>
        </div>
      )
    } if (selectedElement.type === 'r' || selectedElement.type === 'h' || selectedElement.type === 'other' || selectedElement.type === 'rc') {
      return (
        <div className="d-flex align-items-center justify-content-between p-3 h-48 border-bottom">
          <span className='medium strong'>{selectedElement.type === 'r' && (subquestion?.attributes?.type === 'rn' || subquestion?.attributes?.type === 'rm') ? 'Statement options' : 'Answer options'}</span>
          <button onClick={onGoBackHandler} className="btn btn-icon btn-transparent p-05">
            <Icon type="close" />
          </button>
        </div>
      )
    } if (selectedElement.type === 'quota') {
      return (
        <div className='overflow-auto'>
          <ul className="nav nav-pills content-tabs flex-grow-1 w-100 border-bottom">
            <li className="nav-item w-50">
              <span className={`nav-link justify-content-start px-4 ${theData.isSurveyComments ? 'active' : ''}`}>Comments</span>
            </li>
          </ul>
        </div>
      )
    } return null
  } return null
}
