import type { Question } from '../../types';

export enum ColumnSource {
  ROW = 'firstColumn',
  COLUMN = 'scndColumn',
}

export interface EditableQuestion extends Question {
  source: ColumnSource;
  bannerColumns?: EditableQuestion[];

  swapRowsCols?: boolean;
  summaryValuesOnly?: boolean;
};

export interface EditorChanges {
  firstColumn: Question[];
  scndColumn: Question[];
  bannerColumn?: Record<string, Question[]>;
}
