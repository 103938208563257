import { useState } from 'react';
//
import { Button } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import type { ButtonProps } from '@progress/kendo-react-buttons';
//
import type { CustomRow } from './types';
import CustomRowsDialog from './components/CustomRowsDialog';

// -----------------------------------------------------------------------------

type CustomRowButtonProps = Omit<ButtonProps, 'onClick'> &
  (
    | {
        row: CustomRow;
        title?: string;
        action: 'edit' | 'delete';
      }
    | {
        row: Pick<CustomRow, 'code'>;
        action: 'add';
      }
  );

const CustomRowsAction = ({ row, action, ...rest }: CustomRowButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAction = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const initValues = {
    id: '',
    code: 0,
    text: '',
    customExpr: '',
  }

  return (
    <>
      <Tooltip anchorElement="target" position="top" parentTitle={true}>
        <Button type="button" onClick={handleAction} {...rest} />
      </Tooltip>

      {isOpen && row && (
        <CustomRowsDialog
          action={action}
          row={{
            ...initValues,
            ...row,
            code: typeof row.code === 'string' ? parseInt(row.code, 10) : row.code,
          }}
          isOpen={isOpen}
          onClose={handleClose}
        />
      )}
    </>
  );
};


export default CustomRowsAction;
