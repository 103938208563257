import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';

import { Icon } from '../../../../../../shared/Icon/Icon';
import usePrevious from '../../../../../../shared/customHooks/usePrevious';
import type { RootState } from '../../../../../../../store/reducers/rootReducer';
import { DropdownButton } from '../../../../../../shared/DropdownButton/DropdownButton';
import type { IconsType } from '../../../../../../../interfaces/iconInterfaces/iconInterfaces';
import questionTypeValues from "../../../SurveyDesignTabs/EditTabContent/questionTypeValues";
import { returnChangeTypeValues, returnQuesElementIcon } from '../../../helpers/returnDataHelpers/returnDataHelpers';
import VisualisationOptionsModal from '../../../helpers/VisualisationOptionsModal/VisualisationOptionsModal';
import { fetchGetJson } from '../../../../../../../services/services';

interface Item {
  type: string
  attributes: TODO
  disabled: boolean
  published: boolean
  visual?: TODO
  elements?: Item[]
  customQuestionId?: string
  customQuestionProperties?: TODO
}

interface Element {
  type: string
  index: number
  attributes: TODO
  disabled: boolean
  isDisabled: boolean
  isParentDisabled: boolean
}

interface Props {
  theData: {
    optionsType?: TODO
    selectedSubquestion: number;
    originalSelectedItem?: {
      elements: Item[]
    } | null;
    supportedFonts: { name: string }[]
    dropdownValues: TODO[]
    selectedItem?: {
      disabled: boolean
      elements: Item[]
      type: string
      id: string
    } | null;
    elementTypes?: {
      id: string
      name: string
    }[],
    visualisation?: TODO,
    dcV2?: number | null
  }
  selectedElement: Element
  onValueChange: (e: TODO, type: TODO, option: { id: string }) => void
}

export const SurveyAdditionalOptions = ({ theData, selectedElement, onValueChange }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const dispatch = useDispatch()
  const [didMount, setDidMount] = useState(false)
  const [showVisualisationOptionsModal, setShowVisualisationOptionsModal] = useState<boolean>(false)
  const [showConfirmElementTypeChangeModal, setShowConfirmElementTypeChangeModal] = useState<{ show: boolean, item: TODO }>({ show: false, item: null })
  const { customQuestionsData } = useSelector((theState: RootState) => (theState.surveyInitialDataReducer))
  const prevSelectedItem: TODO = usePrevious(theData.selectedItem)
  const prevSelectedSubquestion: TODO = usePrevious(theData.selectedSubquestion)

  let selectedCustomQuestion: TODO = null
  if (customQuestionsData) {
    selectedCustomQuestion = customQuestionsData.find((customQues: { selected?: boolean }) => customQues.selected === true)
  }

  //This is commented out because copy options have been comented out
  // const copyOptionsDropdown: TODO[] = selectedElement && theData.optionsType ? returnCopyOptionsData(selectedElement, theData.originalSelectedItem, theData) : []
  const isParentDisabled = theData?.selectedItem?.disabled

  const getAllElements = useCallback((folders: TODO) => {
    let elements: TODO = [];
    for (const folder of folders) {
      elements = elements.concat(folder.questionItems)
      if (folder.elementFolders) {
        elements = elements.concat(getAllElements(folder.elementFolders))
      }
    }
    return elements
  }, [])

  useEffect(() => {
    // Update selected custom question when switching between elements
    if (customQuestionsData && theData.selectedItem && theData.selectedItem.type === 'ques') {
      if (theData.selectedItem.id !== prevSelectedItem?.id || theData.selectedSubquestion !== prevSelectedSubquestion) {
        const updatedCustomQuestionsData: { customQuestionId: string, selected?: boolean, props: TODO }[] = JSON.parse(JSON.stringify(customQuestionsData))
        updatedCustomQuestionsData.forEach(el => {
          el.selected = false
        })
        const selectedItemCustomQuesId = theData.selectedItem?.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion]?.customQuestionId
        if (selectedItemCustomQuesId) {
          const selectedItemCustomQuesProperties = theData.selectedItem?.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion]?.customQuestionProperties
          updatedCustomQuestionsData.forEach((el) => {
            if (el.customQuestionId === selectedItemCustomQuesId) {
              el.selected = true
              el.props = selectedItemCustomQuesProperties
            }
          })
        }
        dispatch({ type: 'SET_CUSTOM_QUESTIONS_DATA', payload: updatedCustomQuestionsData })
      }
    }
  }, [dispatch, customQuestionsData, prevSelectedItem?.id, theData.selectedItem, theData.selectedSubquestion, prevSelectedSubquestion])

  useEffect(() => {
    // Get data for all custom questions only if the data hasn't been set before
    if (!didMount && theData.selectedItem?.type === 'ques') {
      if (!customQuestionsData) {
        setDidMount(true)
        fetchGetJson(`su/customquestions${theData.dcV2 === 2 ? '?version=2' : ''}`, token)
          .then((res: TODO) => {
            if (res.error || res.message) {
              dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.error ? res.error : res.message } });
            } else {
              const customQuestions = res.map((ques: TODO) => { return { customQuestionId: ques.id, ...ques } })
              const selectedItemCustomQuesId = theData.selectedItem?.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionId
              const selectedItemCustomQuesProperties = theData.selectedItem?.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionProperties
              if (selectedItemCustomQuesId) {
                customQuestions.forEach((el: { selected: boolean, props: TODO, customQuestionId: string }) => {
                  if (el.customQuestionId === selectedItemCustomQuesId) {
                    el.selected = true
                    el.props = selectedItemCustomQuesProperties
                  }
                })
              }
              dispatch({ type: 'SET_CUSTOM_QUESTIONS_DATA', payload: customQuestions })
            }
          })
      }
    }
  }, [dispatch, didMount, customQuestionsData, theData.selectedItem, theData.selectedSubquestion, token])

  //This is commented out because copy options have been comented out
  // const onCopyOptions = (e: { value: { text: string, id: number } }) => {
  //   const updatedSelectedElement = JSON.parse(JSON.stringify(selectedElement))
  //   const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
  //   let updatedAttributes = {}
  //   let originalAttributes = selectedElement.attributes ? selectedElement.attributes : {}
  //   if (selectedElement.type === 'subq') {
  //     const newAttributes = theData.originalSelectedItem?.elements.filter(el => el.type === 'subq')[e.value.id].attributes
  //     updatedAttributes = Object.assign(originalAttributes, newAttributes)
  //     updatedSelectedItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].attributes = updatedAttributes
  //   } else if (selectedElement.type === 'r') {
  //     const newAttributes = theData.originalSelectedItem?.elements.find(el => el.type === 'rgroup')?.elements?.filter(el => el.type === 'r')[e.value.id].attributes
  //     updatedAttributes = Object.assign(originalAttributes, newAttributes)
  //     updatedSelectedItem.elements.find((el: TODO) => el.type === 'rgroup').elements.filter((el: TODO) => el.type === 'r')[selectedElement.index].attributes = updatedAttributes
  //   } else if (selectedElement.type === 'h') {
  //     const newAttributes = theData.originalSelectedItem?.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion].elements?.find(el => el.type === 'hgroup')?.elements?.filter(el => el.type === 'h')[e.value.id].attributes
  //     updatedAttributes = Object.assign(originalAttributes, newAttributes)
  //     updatedSelectedItem.elements.filter((el: TODO) => el.type === 'subq')[theData.selectedSubquestion].elements.find((el: TODO) => el.type === 'hgroup').elements.filter((el: TODO) => el.type === 'h')[selectedElement.index].attributes = updatedAttributes
  //   }
  //   updatedSelectedElement.attributes = updatedAttributes
  //   dispatch({ type: 'SET_SELECTED_ELEMENT', payload: updatedSelectedElement })
  //   dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
  // }

  const changeTypeAndResetVisualisation = () => {
    const { item } = showConfirmElementTypeChangeModal
    // reset visualisation
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    const updatedCustomQuestionsData = JSON.parse(JSON.stringify(customQuestionsData))
    updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionId = null
    updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionProperties = null
    if (updatedCustomQuestionsData.find((el: { selected: boolean }) => el.selected)) {
      updatedCustomQuestionsData.find((el: { selected: boolean }) => el.selected).selected = false
    }
    dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
    dispatch({ type: 'SET_CUSTOM_QUESTIONS_DATA', payload: updatedCustomQuestionsData })
    // change type
    if ((selectedType?.id === "rm" || selectedType?.id === "rn") && (item.id === "m" || item.id === "n")) {
      dispatch({ type: 'SET_CHANGE_TYPE', payload: { show: true, toType: item, fromType: selectedType.id } })
    } else {
      dispatch({ type: 'SET_CHANGE_TYPE', payload: { show: false, toType: item, fromType: selectedType?.id } })
    }
    setShowConfirmElementTypeChangeModal({ show: false, item: null })
  }

  const onItemClick = (item: TODO) => {
    if (selectedType?.id !== item.id) {
      if (theData.selectedItem?.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion].customQuestionId) {
        setShowConfirmElementTypeChangeModal({ show: true, item: item })
      } else {
        if ((selectedType?.id === "rm" || selectedType?.id === "rn") && (item.id === "m" || item.id === "n")) {
          dispatch({ type: 'SET_CHANGE_TYPE', payload: { show: true, toType: item, fromType: selectedType.id } })
        } else {
          dispatch({ type: 'SET_CHANGE_TYPE', payload: { show: false, toType: item, fromType: selectedType?.id } })
        }
      }
    }
  }

  const renderItem = (item: TODO, selectedType: TODO) => {
    return (
      <button
        type="button"
        className={`d-flex align-items-center ${item.id === selectedType.id ? 'dropdown-item px-3 bg-light' : 'dropdown-item px-3'}`}
        onClick={() => onItemClick(item)}>
        <Icon type={returnQuesElementIcon(item.id)} />
        <span className='ml-2'>{item.text}</span>
      </button>
    )
  }

  const onUpdateSelectedCustomQuestion = ((selectedCustomQuesId: string, selectedCustomQuesName: string) => {
    const updatedCustomQuestionsData = JSON.parse(JSON.stringify(customQuestionsData))
    if (updatedCustomQuestionsData.find((el: { selected: boolean }) => el.selected)) {
      updatedCustomQuestionsData.find((el: { selected: boolean }) => el.selected).selected = false
    }
    updatedCustomQuestionsData.find((el: { customQuestionId: string, name: string }) => el.customQuestionId === selectedCustomQuesId && el.name === selectedCustomQuesName).selected = true
    dispatch({ type: 'SET_CUSTOM_QUESTIONS_DATA', payload: updatedCustomQuestionsData })
  })

  const selectCustomQuestionFromDropdown = (item: { name: string, customQuestionId: string, props?: TODO }) => {
    if (item.name === 'No visualisation') {
      // Remove visualisation
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      const updatedCustomQuestionsData = JSON.parse(JSON.stringify(customQuestionsData))
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionId = null
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionProperties = null
      if (updatedCustomQuestionsData.find((el: { selected: boolean }) => el.selected)) {
        updatedCustomQuestionsData.find((el: { selected: boolean }) => el.selected).selected = false
      }
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      dispatch({ type: 'SET_CUSTOM_QUESTIONS_DATA', payload: updatedCustomQuestionsData })
      dispatch({ type: 'SAVE_QUESTION' })
    } else {
      // Select a custom question
      onUpdateSelectedCustomQuestion(item.customQuestionId, item.name)
      const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionId = item.customQuestionId
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionProperties = item.props
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      dispatch({ type: 'SAVE_QUESTION' })
    }
  }

  const saveVisualisationSettings = (settings: { id: string, value: TODO }[]) => {
    const updatedSelectedItem = JSON.parse(JSON.stringify(theData.selectedItem))
    if (selectedCustomQuestion) {
      const updatedProps = JSON.parse(JSON.stringify(selectedCustomQuestion.props))
      updatedProps.forEach((originalSetting: { id: string, value: TODO }) => {
        settings.forEach((newSetting: { id: string, value: TODO }) => {
          if (originalSetting.id === newSetting.id) {
            originalSetting.value = newSetting.value
          }
        });
      })
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionId = selectedCustomQuestion.customQuestionId
      updatedSelectedItem.elements.filter((el: { type: string }) => el.type === 'subq')[theData.selectedSubquestion].customQuestionProperties = updatedProps
      dispatch({ type: 'ON_EXPAND_SUBQUESTION', payload: updatedSelectedItem })
      dispatch({ type: 'SAVE_QUESTION' })
      setShowVisualisationOptionsModal(false)
    }
  }

  const renderVisualisationItems = (item: { name: string, icon: IconsType, customQuestionId: string; props?: TODO }) => {
    if (item.name === 'No visualisation') {
      return (
        <button onClick={() => selectCustomQuestionFromDropdown(item)} type="button" className={`dropdown-item px-4 ${!selectedCustomQuestion ? 'selected' : ''}`}>
          <div className="d-flex justify-content-between align-items-center">
            <span className="text-truncate ml-1">{item.name}</span>
          </div>
        </button>
      )
    }
    return (
      <button onClick={() => selectCustomQuestionFromDropdown(item)} type="button" className={`dropdown-item px-4 ${selectedCustomQuestion && selectedCustomQuestion.name === item.name ? 'selected' : ''}`}>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <Icon type={item.icon ? item.icon : 'general'} />
            <span className="text-truncate ml-2">{item.name}</span>
          </div>
        </div>
      </button>
    )
  }

  const elementTypeItems: { text: string, id: string }[] = returnChangeTypeValues(questionTypeValues, theData.selectedItem?.elements?.filter(el => el.type === 'subq')[theData.selectedSubquestion]?.attributes?.type, theData.selectedItem?.elements?.filter(el => el.type === 'subq')[theData.selectedSubquestion]?.published)
  let selectedType: { text: string, id: string } | undefined = { text: '', id: '' }

  if (theData?.selectedItem?.type === 'ques') {
    if (elementTypeItems.find(el => el.id === questionTypeValues.find(el => el.id === theData.selectedItem?.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion]?.attributes?.type)?.id)) {
      selectedType = elementTypeItems.find(el => el.id === questionTypeValues.find(el => el.id === theData.selectedItem?.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion]?.attributes?.type)?.id)
    }
  }

  const subquestion = theData.selectedItem?.elements?.filter(el => el.type === 'subq')[theData.selectedSubquestion]
  const isVisualisationDisabled = !!(theData.selectedItem?.disabled || subquestion?.disabled)

  return (
    <div className='d-flex flex-column gap-lg'>
      {
        showConfirmElementTypeChangeModal.show &&
        <Dialog title="Change element type" width={560} onClose={() => setShowConfirmElementTypeChangeModal({ show: false, item: null })}>
          <div className="p-4">
            <p className='m-0'>Changing the element type on this question will reset it's visualisation options. Are you sure would like to change the element type?</p>
          </div>
          <DialogActionsBar>
            <button type='button' className="k-button btn btn-secondary" onClick={() => setShowConfirmElementTypeChangeModal({ show: false, item: null })}>Cancel</button>
            <button type='button' className="k-button btn btn-primary" onClick={changeTypeAndResetVisualisation}>Continue</button>
          </DialogActionsBar>
        </Dialog>
      }
      {
        selectedCustomQuestion && showVisualisationOptionsModal &&
        <VisualisationOptionsModal
          name={selectedCustomQuestion.name}
          settings={selectedCustomQuestion.props}
          settingsValues={[]}
          handleClose={() => setShowVisualisationOptionsModal(false)}
          saveVisualSettings={(settings: { id: string, value: TODO }[]) => saveVisualisationSettings(settings)}
        />
      }
      {
        selectedElement && (selectedElement.type !== 'rgroup' && selectedElement.type !== 'hgroup' && selectedElement.type !== 'say' && selectedElement.type !== 'subq') ?
          <button
            type='button'
            disabled={selectedElement.type === 'ques' || selectedElement.type === 'info' || selectedElement.type === 'goto' || selectedElement.type === 'quotastop' || selectedElement.type === 'quota' ? false : isParentDisabled}
            className={`btn ${selectedElement.disabled ? "btn-outline-success" : "btn-outline-danger"} mb-3 py-2`}
            onClick={() => onValueChange({ value: { text: selectedElement.disabled ? 'false' : 'true' } }, null, { id: 'miext:disabled' })}>
            {
              !selectedElement.disabled ? `Disable this ${theData.elementTypes?.find((el: TODO) => el.id === selectedElement.type)?.name.toLowerCase()}` :
                `Enable this ${theData.elementTypes?.find((el: TODO) => el.id === selectedElement.type)?.name.toLowerCase()}`
            }
          </button>
          : null
      }
      {
        selectedElement.type === 'subq' && theData.selectedItem?.type === 'ques' &&
        <React.Fragment>
          <div className='d-flex flex-column gap-md survey-option pb-4 border-bottom'>
            <p className='strong medium m-0'>
              Element type
            </p>

            <DropdownButton
              textField="text"
              renderItem={(item) => renderItem(item, selectedType)}
              text={selectedType?.text}
              items={elementTypeItems}
              className='btn-block element-type'
              icon={returnQuesElementIcon(questionTypeValues.find(el => el.id === theData.selectedItem?.elements.filter(el => el.type === 'subq')[theData.selectedSubquestion].attributes.type)?.id)}
              disabled={selectedElement.disabled || isParentDisabled} />
          </div>
        </React.Fragment>
      }
      {
        selectedElement.type === 'subq' && customQuestionsData && customQuestionsData.filter((el: { supportedTypes?: string[] }) => el.supportedTypes?.includes(selectedElement.attributes.type)) &&
        <React.Fragment>
          <div className='customize'>
            <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
              <p className="text-primary m-0 text-truncate medium" title="Enable custom questions">
                Visualisation
              </p>
            </Tooltip>
          </div>

          <div className="d-flex gap-md">
            <div className="d-flex flex-column flex-grow-1 survey-option visualisations-dropdown">
              <DropdownButton
                textField='name'
                className='btn-block'
                renderItem={renderVisualisationItems}
                items={[{ name: 'No visualisation' }, ...customQuestionsData.filter((el: { supportedTypes?: string[] }) => el.supportedTypes?.includes(selectedElement.attributes.type) || el.supportedTypes?.includes('*'))]}
                text={selectedCustomQuestion ? selectedCustomQuestion.name : 'No visualisation'}
                disabled={isVisualisationDisabled}
                onItemClick={(e) => selectCustomQuestionFromDropdown(e.item)}
              />
            </div>
            <button
              type='button'
              className="btn btn-shadow px-1 w-32"
              onClick={() => setShowVisualisationOptionsModal(true)}
              disabled={!(selectedCustomQuestion && selectedCustomQuestion?.props?.length > 0 && !isVisualisationDisabled)}>
              <Icon type="more-horizontal" />
            </button>
          </div>
        </React.Fragment>
      }
      {/*this has been comented out and not deleted because the some of the logic works and some of it doesn't. Now it is not a priority to fix this and that is why this is commented out */}
      {/* {
        selectedElement && (selectedElement.type === 'r' || selectedElement.type === 'h' || selectedElement.type === 'subq') && selectedElement.index !== null ?
          <div className="form-group mb-2 d-none">
            <div className="d-flex flex-column">
              <label className={`mb-1 medium ${(selectedElement.disabled || isParentDisabled) && "text-disabled"}`}>Copy options</label>
            </div>
            <div className="d-flex">
              <BaseDropDownList
                disabled={selectedElement.disabled || isParentDisabled}
                popupSettings={{
                  className: "dropdown-hight-unset survey-dropdown",
                  appendTo: wrapperRef ? wrapperRef.current : undefined
                }}
                onChange={(e: TODO) => onCopyOptions(e)}
                className="form-control"
                data={copyOptionsDropdown}
                textField="text"
                defaultValue={copyOptionsDropdown[0]}
              />
            </div>
          </div>
          : null
      } */}
    </div>
  )
}
