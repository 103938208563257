//
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';

//
import { useQuestionEditor } from '../../contexts/QuestionEditorContext';
import { ActionContext } from '../../config/actions';
import type { ActionConfig} from '../../config/actions';
import { useEditorActions } from '../../hooks/useEditorActions';

// -------------------------------------------------------------------------------------------------

const ContextActions = () => {
  const { currentContext, setContext } = useQuestionEditor();
  const { allActions } = useEditorActions(currentContext);

  // Only use first two default actions
  const actions = allActions.slice(0, 2) as ActionConfig[];

  const navigationItems = [
    {
      id: ActionContext.ROWS,
      label: 'Response Rows',
      isActive: currentContext === ActionContext.ROWS,
    },
    {
      id: ActionContext.COLUMNS,
      label: 'Response Columns',
      isActive: currentContext === ActionContext.COLUMNS,
    },
  ];

  return (
    <div className="d-flex align-items-center gap-sm">
      <ul className="nav" role="tablist">
        {navigationItems.map(item => (
          <li
            key={item.id}
            className={`nav-item ${item.isActive ? 'active' : ''}`}
            onClick={() => setContext(item.id)}
            role="tab"
            aria-selected={item.isActive}
            tabIndex={0}
          >
            {item.label}
          </li>
        ))}
        <li
          className="vertical-divider"
          role="separator"
          aria-orientation="vertical"
        />
      </ul>

      <div className="d-flex align-items-center gap-sm">
        {actions.map(action => (
          <Button
            key={action.id}
            type="button"
            fillMode="flat"
            themeColor="primary"
            disabled={action.isDisabled?.()}
            onClick={() => action.handler()}
          >
            <SvgIcon icon={action.svgIcon} className="text-analyze" />{' '}
            {action.label}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default ContextActions;
