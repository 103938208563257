import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Tooltip } from '@progress/kendo-react-tooltip';

import { Icon } from "../../../shared/Icon/Icon";
import { useDebounce } from "../../../shared/customHooks/useDebounce";
import PinboardPageModal from "../../PinboardPageModal/PinboardPageModal";
import type { Pinboard, PinboardPage } from "../../../../interfaces/pinboardInterfaces/pinboardInterfaces";

import { fetchGetJson, fetchPatchJson, fetchPostJson } from "../../../../services/services";

interface Props {
  token: string
  setLoadingPinboard: (value: boolean) => void
  hideSidebar: () => void
  openPageOptions: (page: PinboardPage) => void
}

export const SidebarPagesContent = ({ token, setLoadingPinboard, hideSidebar, openPageOptions }: Props) => {
  const dispatch = useDispatch();
  const pinboardData: Pinboard = useSelector((theState: TODO) => theState.pinboardStateReducer)

  const [showPageOptions, setShowPageOptions] = useState<{ show: boolean, pageId: string }>({ show: false, pageId: "" });
  const [showPageModal, setShowPageModal] = useState<{ show: boolean, type: string, selectedPage: PinboardPage | null }>({ show: false, type: "add", selectedPage: null })

  const [sendPageOrder, setSendPageOrder] = useState<boolean>(false);
  const shouldSavePageOrder = useDebounce(() => setSendPageOrder(true), 2000)

  useEffect(() => {
    // Saves the pages order to backend, can be called by just setting sendPageOrder to true,
    // or just call shouldSavePageOrder() if you would like the call debounced and throttled by 2 seconds
    setSendPageOrder(false);
    if (sendPageOrder) {
      const newPageOrder = pinboardData.pages.map(page => page.id)

      pinboardData.pages.forEach(page => {
        if (page.subpages.length > 0) {
          const parentIndex = newPageOrder.findIndex(el => el === page.id)
          const subpages = page.subpages.map((subpage: PinboardPage) => `${subpage.id}`)
          newPageOrder.splice(parentIndex + 1, 0, ...subpages)
        }
      })

      const body = {
        pageIds: newPageOrder,
        pinboardIndexEtag: pinboardData._etag
      }

      fetchPatchJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pageOrder`, token, body)
        .then((result: TODO) => result.json())
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
          } else {
            const updatedPinboard = { ...pinboardData, _etag: res._etag }
            dispatch({ type: "SET_PINBOARD_DATA", payload: updatedPinboard })
          }
        })
    }
  }, [sendPageOrder, pinboardData, dispatch, token])

  const selectPageHandler = (e: TODO, pinboardPage: PinboardPage) => {
    e.stopPropagation();
    if (!e.defaultPrevented) {
      if (pinboardData.activePage?.id !== pinboardPage.id) {
        setLoadingPinboard(true)

        fetchGetJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages/${pinboardPage.id}`, token)
          .then((res: TODO) => {
            if (res.error || res.message) {
              dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
            } else {
              dispatch({ type: "SET_ACTIVE_PINBOARD_PAGE", payload: { page: res, reload: true } })
            }
          })
      }
    }
  }

  const confirmModalHandler = (inputValue: string, type: string) => {
    if (type === "Add") {
      const body = {
        title: inputValue,
        parentPageId: showPageModal.selectedPage ? showPageModal.selectedPage.id : null,
        pinboardIndexEtag: pinboardData._etag
      }
      fetchPostJson(`pb/projects/${pinboardData.projectId}/pinboards/${pinboardData.pinboardId}/pages`, token, body)
        .then((res: TODO) => {
          if (res.error || res.message) {
            dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: res.error ? res.error : res.message } })
          } else {
            const updatedPages = [...pinboardData.pages]

            if (showPageModal.selectedPage) {
              const pageIndex = updatedPages.findIndex(el => el.id === showPageModal.selectedPage?.id)
              updatedPages[pageIndex].subpages.push({ id: res.page.id, parentPageId: res.page.parentPageId, title: res.page.title })
            } else {
              updatedPages.push({ id: res.page.id, parentPageId: null, title: res.page.title, expanded: false, subpages: [] })
            }

            const pinboardPayload = { ...pinboardData, _etag: res.pinboard._etag, pages: updatedPages }
            dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardPayload, reloadPage: false } })
            setShowPageModal({ show: false, type: showPageModal.type, selectedPage: null, })
          }
        })
    }
  }

  const pageOptionsClick = (e: TODO, type: string, page: PinboardPage) => {
    e.stopPropagation();

    switch (type) {
      case "layout":
        setShowPageOptions({ show: false, pageId: page.id });
        openPageOptions(page);
        break;
      case "delete":
        setShowPageModal({ show: true, type: "Delete", selectedPage: page, });
        setShowPageOptions({ ...showPageOptions, show: false })
        break;
      default:
        break;
    }
  }

  const openPageModal = (e: TODO, type: string, pinboardPage: PinboardPage | null,) => {
    e.stopPropagation();
    setShowPageModal({ show: true, type: type, selectedPage: pinboardPage, });
  }

  const closePageModal = () => {
    setShowPageOptions({ ...showPageOptions, show: false })
    setShowPageModal({ ...showPageModal, show: false, });
  }

  const onExpandClick = (e: TODO, pinboardPage: PinboardPage) => {
    e.stopPropagation();
    const updatedPages = [...pinboardData.pages];
    const pageIndex = updatedPages.findIndex(el => el.id === pinboardPage.id);

    updatedPages[pageIndex].expanded = !updatedPages[pageIndex].expanded;
    dispatch({ type: "SET_PINBOARD_DATA", payload: { ...pinboardData, pages: updatedPages, reloadPage: false } });
  }

  const onDragPageEnd = (result: TODO) => {
    if (!result.destination) {
      return
    }

    if (result.destination.droppableId === result.source.droppableId) {
      if (result.destination.droppableId === "pagesDroppable") {
        const updatedPages = Array.from(pinboardData.pages);
        updatedPages.splice(result.destination.index, 0, ...updatedPages.splice(result.source.index, 1))
        shouldSavePageOrder();
        dispatch({ type: "UPDATE_PINBOARD_PAGES", payload: updatedPages })
      } else {
        const parentId = result.destination.droppableId.split("/")[1]
        const parentIndex = pinboardData.pages.findIndex(el => el.id === parentId)
        const updatedPages = [...pinboardData.pages]
        const updatedSubpages = updatedPages[parentIndex].subpages
        updatedSubpages.splice(result.destination.index, 0, ...updatedSubpages.splice(result.source.index, 1))

        shouldSavePageOrder();
        dispatch({ type: "UPDATE_PINBOARD_PAGES", payload: updatedPages })
      }
    } else {
      dispatch({ type: "SHOW_ERROR_NOTIFICATION", payload: { msg: "We do not support the option to move a subpage to a different parent page yet." } })
    }
  }

  return (
    <Fragment>
      {showPageModal.show &&
        <PinboardPageModal
          onHide={closePageModal}
          onConfirm={confirmModalHandler}
          type={showPageModal.type}
          originalTitle={showPageModal.selectedPage?.title}
        />
      }
      <div className="header border-bottom flex-shrink-0">
        <span className="ml-1">Boards</span>
        <div className="d-flex">
          <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
            <button type="button" className="btn btn-transparent p-1" onClick={hideSidebar} title="Close"><Icon type="close" className="pe-none" /></button>
          </Tooltip>
        </div>
      </div>
      {/* {searchRender("pages")} */} {/* HIDDEN UNTIL PROPERLY IMPLEMENTED */}
      <DragDropContext onDragEnd={onDragPageEnd}>
        <Droppable droppableId="pagesDroppable">
          {(provided, _snapshot) => (
            <div ref={provided.innerRef} {...provided.droppableProps} className="pinboard-pages">
              {pinboardData.pages.map((pinboardPage: PinboardPage, index: number) => {
                return (
                  <Draggable
                    index={index}
                    key={pinboardPage.id}
                    draggableId={pinboardPage.id}>
                    {(provided, snapshot) => (
                      <div key={pinboardPage.id} ref={provided.innerRef} {...provided.draggableProps} onClick={(e) => { e.stopPropagation(); selectPageHandler(e, pinboardPage) }}>
                        <div className={`list-page ${pinboardData.activePage?.id === pinboardPage.id ? "active" : ""} ${snapshot.isDragging ? 'dragging' : ''} ${!pinboardPage.expanded ? "" : "expanded"}`}>
                          <div className="d-flex align-items-center h-100 w-100">
                            {pinboardPage.subpages?.length > 0 ?
                              <span className="d-flex align-items-center h-100 w-100">
                                <span className="page-expand h-100 align-items-center" onClick={e => { onExpandClick(e, pinboardPage) }}>
                                  <Icon type="chevron-mini-down" style={{ transform: `${!pinboardPage.expanded ? "rotate(-90deg)" : "rotate(0)"}`, transition: "transform 0.25s" }} />
                                </span>
                                <span className="d-flex align-items-center">
                                  <Icon type="list-page" className="mr-1" />
                                  <span className={"title strong text-truncate"}>{pinboardPage.title}</span>
                                </span>
                              </span>
                              :
                              <span className="d-flex">
                                <span className="spacer">
                                </span>
                                <span className="d-flex align-items-center">
                                  <Icon type="list-page" className="mr-1" />
                                  <span className="title strong text-truncate">{pinboardPage.title}</span>
                                </span>
                              </span>
                            }
                          </div>
                          <div className="align-items-center h-100 actions">
                            <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                              <button type="button" className="btn btn-transparent p-0 mr-1" onClick={e => openPageModal(e, "Add", pinboardPage)} title="Add sub board"><Icon type="plus" className="pe-none" /></button>
                            </Tooltip>
                            <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                              <button type="button" className="btn btn-transparent p-0 mr-1" onClick={(e) => pageOptionsClick(e, "layout", pinboardPage)} title="Edit board"><Icon type="dashboard-edit" className="pe-none" /></button>
                            </Tooltip>
                            <div className="page-drag-handle h-100 align-items-center" {...provided.dragHandleProps}>
                              <Icon type="draggable" />
                            </div>
                          </div>
                        </div>
                        <Droppable droppableId={`subdroppable/${pinboardPage.id}`}>
                          {(provided, _snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} className={`list-subpage ${pinboardPage.subpages?.length > 0 && pinboardPage.expanded ? "subpage-appear" : "subpage-disappear"}`}>
                              {pinboardPage.subpages?.length > 0 && pinboardPage.expanded &&
                                pinboardPage.subpages?.map((subpage: PinboardPage, subpageIndex: number) => {
                                  return (
                                    <Draggable
                                      index={subpageIndex}
                                      key={subpage.id}
                                      draggableId={subpage.id}>
                                      {(provided, snapshot) => (
                                        <div ref={provided.innerRef} {...provided.draggableProps}>
                                          <div
                                            onClick={e => { e.stopPropagation(); selectPageHandler(e, subpage) }}
                                            className={`list-page sub-page ${pinboardData.activePage.id === subpage.id ? "active" : ""} ${snapshot.isDragging ? 'dragging' : ''}`}
                                            key={subpageIndex}>
                                            <div className="d-flex align-items-center">
                                              <span className="d-flex align-items-center ">
                                                <Icon type="sub-page" className="mr-1" />
                                                <span className="title text-truncate">{subpage.title}</span>

                                              </span>
                                            </div>
                                            <div className="align-items-center h-100 actions">
                                              <Tooltip openDelay={100} position='bottom' anchorElement={'target'}>
                                                <button type="button" className="btn btn-transparent p-0 mr-1" onClick={(e) => pageOptionsClick(e, "layout", subpage)} title="Edit sub page"><Icon type="dashboard-edit" className="pe-none" /></button>
                                              </Tooltip>
                                              <div className="page-drag-handle h-100 align-items-center" {...provided.dragHandleProps}>
                                                <Icon type="draggable" />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                                })
                              }
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    )}
                  </Draggable>
                )
              })
              }
              {provided.placeholder}
              <div className="add-page pl-2 ml-3" onClick={e => openPageModal(e, "Add", null)}>
                <span className="text-blue-survey d-flex align-items-center strong"><Icon type="add-circle" className="fill-blue-survey mr-1 circle" />
                  Add board
                </span>
              </div>
            </div>
          )}
        </Droppable>

      </DragDropContext>
    </Fragment >
  )
}

export default SidebarPagesContent;
