import { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
//
import { Checkbox, Switch } from '@progress/kendo-react-inputs';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Button } from '@progress/kendo-react-buttons';
import { plusIcon } from '@progress/kendo-svg-icons';
//
import { Field } from 'components/shared/hook-form';
import { ColumnSource } from '../../types';
import { useQuestionEditor } from '../../contexts/QuestionEditorContext';
import CustomRowsAction from '../../custom-rows/CustomRowsAction';
import type { EditableQuestion } from '../../types';

// ----------------------------------------------------------------------

const QuestionRows = () => {
  const { options } = useQuestionEditor();
  const { control } = useFormContext<EditableQuestion>();
  const { fields, update, remove } = useFieldArray({
    control: control,
    name: 'groups',
  });

  const groups = useWatch({ control, name: 'groups', defaultValue: [] });
  const subGroups = useWatch({ control, name: 'subGroups', defaultValue: [] });
  const source = useWatch({ control, name: 'source' });

  const displayWeight = subGroups.length === 0;

  const getRowAtIndex = useCallback((index: number) => groups[index], [groups]);

  const getCodeValue = () => {
    return groups.reduce((maxCode, currentGroup) => {
      const currentCode = Number(currentGroup.code);
      return Math.max(maxCode, currentCode);
    }, 0);
  };

  const toggleSelectAll = useCallback(
    (value: boolean) => {
      groups.forEach((group, index) => {
        update(index, { ...group, selected: value });
      });
    },
    [groups, update],
  );

  const toggleActivateAll = useCallback(
    (value: boolean) => {
      groups.forEach((group, index) => {
        update(index, { ...group, active: value });
      });
    },
    [groups, update],
  );

  const deleteRow = useCallback(
    (index: number) => {
      const row = getRowAtIndex(index);
      if (row.merged || row.isCustom) {
        remove(index);
      }
    },
    [getRowAtIndex, remove],
  );

  return (
    <div className="d-flex flex-column">
      <table className="table table-hover table-sm">
        <thead className="text-center bg-light">
          <tr>
            <th className="checkbox">
              <Checkbox
                name="selectAll"
                checked={
                  groups.length > 0 && groups.every(item => item.selected)
                }
                onChange={e => toggleSelectAll(e.value)}
              />
            </th>
            <th className="switch">
              <Switch
                size="small"
                name="activateAll"
                checked={groups.length > 0 && groups.every(item => item.active)}
                onChange={e => toggleActivateAll(e.value)}
              />
            </th>
            <th style={{ width: '100px' }}>Code</th>
            <th style={{ minWidth: '260px' }}>Text</th>
            {displayWeight && <th>Scaling factor</th>}
            <th style={{ minWidth: '100px' }}>Define row</th>
            <th style={{ minWidth: '35px' }} />
          </tr>
        </thead>
        <tbody>
          {fields.map((field, index) => (
            <tr key={field.id}>
              <td className="checkbox">
                <Field.Checkbox
                  name={`groups.${index}.selected`}
                  size="small"
                />
              </td>
              <td className="switch">
                <Field.Switch name={`groups.${index}.active`} size="small" />
              </td>
              <td>{field.code}</td>
              <td>
                <Field.Text
                  name={`groups.${index}.text`}
                  className="form-control form-control-sm"
                  placeholder="Enter text"
                  disabled={field.isCustom}
                />
              </td>
              {displayWeight && (
                <td style={{ maxWidth: '120px' }}>
                  <Field.NumberInput
                    name={`groups.${index}.weightValue`}
                    disabled={field.merged || source === ColumnSource.COLUMN}
                    size="small"
                  />
                </td>
              )}
              <td>
                <Field.Text
                  name={`groups.${index}.customExpr`}
                  className="form-control form-control-sm"
                  disabled
                />
              </td>

              <td>
                <span className="d-flex flex-row justify-content-end">
                  {field.merged && (
                    <Tooltip
                      anchorElement="target"
                      position="top"
                      parentTitle={true}
                    >
                      <Button
                        id={`delete-column-row_${index}`}
                        type="button"
                        iconClass="fa fa-regular fa-trash-can text-danger"
                        fillMode={'flat'}
                        title="Delete merged"
                        onClick={() => deleteRow(index)}
                        size="small"
                      />
                    </Tooltip>
                  )}

                  {options.enableCustomRows && field.isCustom && (
                    <>
                      <CustomRowsAction
                        id={`update-column-row_${index}`}
                        action="edit"
                        row={getRowAtIndex(index)}
                        iconClass="fa fa-regular fa-pen-to-square"
                        fillMode={'flat'}
                        title="Update custom"
                        size={'small'}
                      />

                      <CustomRowsAction
                        id={`delete-column-row_${index}`}
                        action="delete"
                        row={getRowAtIndex(index)}
                        iconClass="fa fa-regular fa-trash-can text-danger"
                        fillMode={'flat'}
                        title="Delete custom"
                        size={'small'}
                      />
                    </>
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="d-flex justify-content-start pb-5">
        {options.enableCustomRows && (
          <CustomRowsAction
            action={'add'}
            row={{ code: getCodeValue() + 1 }}
            svgIcon={plusIcon}
            fillMode={'flat'}
          >
            Add new custom row
          </CustomRowsAction>
        )}
      </div>
    </div>
  );
};

export default QuestionRows;
